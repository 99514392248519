import { Fragment } from "react"

import { useBdAuth } from "@trueskin-web/context"
import { plusCircleFilledIcon } from "@trueskin-web/theme/icons/plusCircleFilledIcon"
import { profileFilledIcon } from "@trueskin-web/theme/icons/profileFilledIcon"
import { profileIcon } from "@trueskin-web/theme/icons/profileIcon"
import { questionnaireFilledIcon } from "@trueskin-web/theme/icons/questionnaireFilledIcon"
import { questionnaireIcon } from "@trueskin-web/theme/icons/questionnaireIcon"
import { Routes, useTranslation } from "@trueskin-web/translations"

import { TabItem } from "../tab-item"

export const BDTabs = () => {
  const { t } = useTranslation()
  const { user: bdUser } = useBdAuth()
  return (
    <Fragment>
      <TabItem
        icon={questionnaireIcon}
        iconSelected={questionnaireFilledIcon}
        label={t("Cases.navItem")}
        href={Routes.App.Diagnose.Home.url}
      />
      {bdUser && (
        <>
          <TabItem
            icon={plusCircleFilledIcon}
            iconSelected={plusCircleFilledIcon}
            label={t("NewCase.navItem")}
            href={Routes.App.Diagnose.NewCase.url}
          />

          <TabItem
            icon={profileIcon}
            iconSelected={profileFilledIcon}
            label={t("Account.navItem")}
            href={Routes.App.Diagnose.Account.url}
          />
        </>
      )}
    </Fragment>
  )
}
