// do not remove - this allows the tsdoc to work without doing repeat import() statements
// if this doesn't work in webstorm, try using {import('@trueskin/vortex-sdk').VortextApi} etc
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { VortexApi } from "@trueskin/vortex-sdk"

import { apiVortex } from "./api"

/**
 * @returns {ReturnType<VortexApi['vortex']['getUsersMeConsultationsSummaries']>}
 */
function getAll() {
  return apiVortex().getUsersMeConsultationsSummaries()
}

/**
 * @param variables {{
 *  queryKey: [path?: string, id: string]
 * }}
 * @returns {ReturnType<VortexApi['vortex']['getUsersMeConsultationsDetails']>}
 */
function getById({ queryKey: [, consultationId] }) {
  return apiVortex().getUsersMeConsultationsDetails(consultationId)
}

/**
 * @returns {ReturnType<VortexApi['vortex']['getUsersMeConsultationsOpened']>}
 */
function getOpenedConsultations() {
  return apiVortex().getUsersMeConsultationsOpened()
}

/**
 * @returns {ReturnType<VortexApi['vortex']['getUsersMeConsultationsCheckIns']>}
 */
function getClosedCheckinConsultations() {
  return apiVortex().getUsersMeConsultationsCheckIns()
}

/**
 * @returns {ReturnType<VortexApi['vortex']['getUsersMeConsultationsAyds']>}
 */
function getClosedAydConsultations() {
  return apiVortex().getUsersMeConsultationsAyds()
}

// now this one naturally infers the response!
/**
 * @param variables {{
 *  queryKey: [path?: string, category: string]
 * }}
 */
function getConsultations({ queryKey: [, category] }) {
  if (category.type === "opened") {
    return getOpenedConsultations()
  }

  if (category.type === "checkin") {
    return getClosedCheckinConsultations()
  }

  if (category.type === "askYourDoctor") {
    return getClosedAydConsultations()
  }

  return getAll()
}

/**
 * @param variables {{
 *  category: string,
 *  sideEffectsSeverity?: number,
 *  text: string,
 *  skipImageUpload?: boolean
 * }}
 * @returns {ReturnType<VortexApi['vortex']['postUsersMeConsultationsAskYourDoctor']>}
 */
function createAskYourDoctorConsultation({
  category,
  sideEffectsSeverity,
  text,
  skipImageUpload = true,
}) {
  const body = {
    category,
    text,
    sideEffectsSeverity,
    skipImageUpload,
  }

  return apiVortex().postUsersMeConsultationsAskYourDoctor(body)
}

/**
 * @param variables {{
 *  category: string,
 *  text: string,
 * }}
 * @returns {ReturnType<VortexApi['vortex']['requestNewFormulaCreateNewAyd']>}
 */
function requestNewFormulaCreateNewAyd({ category, text }) {
  const body = {
    category,
    text,
  }

  return apiVortex().requestNewFormulaCreateNewAyd(body)
}

/**
 * @param variables {{
 *  consultationId: string,
 *  text: string,
 *  skipImageUpload?: boolean
 * }}
 * @returns {ReturnType<VortexApi['vortex']['putUsersMeConsultationsAskYourDoctorAnswer']>}
 */
function answerAskYourDoctorConsultation({
  consultationId,
  text,
  skipImageUpload = true,
}) {
  const body = {
    text,
    skipImageUpload,
  }

  return apiVortex().putUsersMeConsultationsAskYourDoctorAnswer(
    consultationId,
    body
  )
}

function rateAskYourDoctorConsultation({ consultationId, score, feedback }) {
  const body = { score, feedback }

  return apiVortex().postUsersMeConsultationsRate(consultationId, body)
}

function sayThankYou({ consultationId, thankYouMessage }) {
  const body = { text: thankYouMessage }

  return apiVortex().putUsersMeConsultationsAskYourDoctorThankYou(
    consultationId,
    body
  )
}

function getConsultationsFaqs() {
  return apiVortex().getAskYourDoctorFaqs()
}

function getInitialConsultation() {
  return apiVortex().getUsersMeConsultationsInitialAnswers()
}

function sendNoteToDoctor({ content }) {
  return apiVortex().setNoteForDoctor({ content })
}

/**
 * @param {string} consultationId
 * @returns {ReturnType<VortexApi['vortex']['postUsersMeConsultationsFinishUpload']>}
 */
function finishUpload(consultationId) {
  return apiVortex().postUsersMeConsultationsFinishUpload(consultationId)
}

export {
  getAll,
  getById,
  getConsultationsFaqs,
  getConsultations,
  createAskYourDoctorConsultation,
  requestNewFormulaCreateNewAyd,
  answerAskYourDoctorConsultation,
  rateAskYourDoctorConsultation,
  getInitialConsultation,
  sayThankYou,
  sendNoteToDoctor,
  finishUpload,
}
