import { addDays, addMonths, isAfter } from "date-fns"

const SHIFT_RENEWAL_DAY = 6

const getRenewalDate = (subscription, summary) => {
  const resumedOn = subscription.resumeOn
    ? new Date(subscription.resumeOn)
    : null
  const maxDate = getMaxRenewalDate(subscription)

  const renewalDate =
    subscription.status === "paused" || subscription.pauseOn
      ? isAfter(resumedOn, maxDate)
        ? maxDate
        : resumedOn
      : new Date(summary.renewalDate)

  return renewalDate
}

const getShiftMonths = (paymentOrder) => {
  return paymentOrder.instalments?.count + 1 || 2
}

const getMaxRenewalDate = (
  { paymentOrder, createdAt },
  shiftDay = SHIFT_RENEWAL_DAY
) => {
  const shiftMonth = getShiftMonths(paymentOrder)
  const maxDate = addDays(addMonths(new Date(createdAt), shiftMonth), shiftDay)

  return maxDate
}

export { getRenewalDate, getMaxRenewalDate, getShiftMonths }
