import * as Popover from "@radix-ui/react-popover"
import { useLocation } from "@reach/router"
import { ThemeUIStyleObject } from "@theme-ui/css/src/types"
import { navigate } from "gatsby-link"
import { Fragment, useState } from "react"

import { amplitudeClient } from "@trueskin-web/apis"
import {
  AccountLink,
  Button,
  Icon,
  IconButton,
  Link,
} from "@trueskin-web/components"
import { variants } from "@trueskin-web/components/src/button"
import { useAuth } from "@trueskin-web/context"
import { useApp } from "@trueskin-web/portal/src/app/hooks/use-app"
import { authService } from "@trueskin-web/services"
import { getBdJwt } from "@trueskin-web/services/src/auth"
import { useIsScreenSizeSmall } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { fileIcon } from "@trueskin-web/theme/icons/fileIcon"
import { logoutIcon } from "@trueskin-web/theme/icons/logoutIcon"
import { questionnaireIcon } from "@trueskin-web/theme/icons/questionnaireIcon"
import { userDrawingIcon } from "@trueskin-web/theme/icons/userDrawingIcon"
import { Routes, Trans } from "@trueskin-web/translations"

const accountUrls = {
  default: Routes.App.Account.url,
  bd: Routes.App.Diagnose.Account.url,
}

const menuItemStyles = {
  p: 3,
  fontSize: 1,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  "&:visited": {
    color: "text",
  },
  "&:hover, &:active, &:focus": {
    backgroundColor: "primary",
    color: "white",
  },
}

function getName(value?: string | null): string {
  return new String(value || authService.getFirstname()).valueOf()
}

const NavDisabledMenu = ({
  isUserLogged,
  handleLogout,
  backAction,
  backUrl,
  handleNavigateBack,
}: Pick<HeaderProps, "backAction" | "backUrl"> & {
  isUserLogged: boolean
  handleLogout: () => void
  handleNavigateBack: () => void
}) => {
  return (
    <Fragment>
      {backAction ? (
        <Fragment>
          {isUserLogged ? (
            <IconButton
              icon={logoutIcon}
              iconSize="lg"
              onClick={handleLogout}
              sx={{
                display: [null, "none"],
                mr: 2,
                ml: "auto",
              }}
            />
          ) : null}

          <IconButton
            icon={closeIcon}
            iconSize="lg"
            onClick={backAction}
            sx={{
              display: [null, "none"],
            }}
          />

          {isUserLogged ? (
            <Button
              size="sm"
              variant="outline"
              onClick={handleLogout}
              sx={{
                display: ["none", "inline-flex"],
                fontSize: 12,
                ml: 4,
                whiteSpace: "nowrap",
                alignSelf: "center",
              }}
            >
              <Trans i18nKey="Header.logout" />
            </Button>
          ) : null}
        </Fragment>
      ) : backUrl ? (
        <Fragment>
          <IconButton
            icon={closeIcon}
            iconSize="lg"
            onClick={handleNavigateBack}
            sx={{
              display: [null, "none"],
            }}
          />

          <Button
            variant="link"
            size="sm"
            onClick={handleNavigateBack}
            sx={{
              display: ["none", "inline-flex"],
              variant: "text.caps",
              fontSize: 12,
              textDecoration: "none",
            }}
          >
            <Trans i18nKey="Header.cancel" />
            <Icon icon={closeIcon} sx={{ ml: 2 }} />
          </Button>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

const DualAccountMenu = ({ displayName }: { displayName: string }) => {
  const app = useApp()
  const isMobile = useIsScreenSizeSmall()

  const [open, setIsOpen] = useState(false)

  return (
    <Popover.Root onOpenChange={(x) => setIsOpen(x)}>
      <Popover.Trigger asChild>
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            textDecoration: "none",
            overflow: "initial",
            py: 1,
            px: 2,

            ...variants.solid,
            fontWeight: "normal",
            borderRadius: "8px",
            border: "none",
            position: "relative",
            backgroundColor: "transparent",
            color: "body",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",

            button: {
              backgroundColor: "transparent",
            },

            "&:hover, &:focus, &.focus": {
              backgroundColor: "blue",
            },
            "&.active": {
              backgroundColor: "blue",
            },
          }}
        >
          <span
            sx={{
              fontSize: "11px",
              borderRadius: "50%",
              backgroundColor: "primary",
              fontWeight: "bold",
              color: "white",
              width: "24px",
              height: "24px",
              display: "flex",
              textAlign: "center",
              alignSelf: "center",
              alignItems: "center",
              m: 1,
              p: "8.5px",
              verticalAlign: "baseline",
              lineHeight: "11px",
            }}
          >
            {displayName?.trim().charAt(0).toUpperCase()}
          </span>

          <span sx={{ display: ["none", "block"], color: "black" }}>
            {displayName}
          </span>

          <Icon
            icon={arrowRightIcon}
            sx={{
              color: "black",
              transform: open ? "rotate(270deg)" : "rotate(90deg)",
            }}
          />
        </Button>
      </Popover.Trigger>
      <Popover.Content align={isMobile ? "end" : "start"} sx={{ zIndex: 3 }}>
        <div
          sx={{
            minWidth: 200,
            backgroundColor: "white",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "lightGrey",
            mt: 2,
            "a:first-child": {
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            },
            "a:last-child": {
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            },
          }}
        >
          {app !== "bd" ? (
            <>
              <Link
                sx={menuItemStyles}
                // as={Link}
                href={Routes.App.Account.url}
              >
                <Icon icon={userDrawingIcon} />
                <Trans i18nKey="Header.appSelector.profile" />
              </Link>

              <Link sx={menuItemStyles} href={Routes.App.Diagnose.Home.url}>
                <Icon icon={questionnaireIcon} />
                <Trans i18nKey="Header.appSelector.cases" />
              </Link>
            </>
          ) : null}

          {app === "bd" && (
            <Link href={Routes.App.url} sx={menuItemStyles}>
              <Icon icon={fileIcon} />
              <Trans i18nKey="Header.appSelector.plan" />
            </Link>
          )}

          <Link
            href={Routes.App.Logout.url}
            sx={{ ...menuItemStyles, display: ["flex", "none"] }}
          >
            <Icon icon={logoutIcon} />
            <Trans i18nKey="Header.logout" />
          </Link>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

const CoreOnlyMenu = () => {
  const app = useApp()

  return (
    <Fragment>
      <AccountLink url={accountUrls[app ?? "default"]} />

      <Button
        size="sm"
        variant="outline"
        as={"a"}
        sx={{
          display: ["none", "inline-flex"],
          fontSize: 12,
          ml: 4,
          whiteSpace: "nowrap",
          alignSelf: "center",
        }}
        href={Routes.App.Logout.url}
      >
        <Trans i18nKey="Header.logout" />
      </Button>
    </Fragment>
  )
}

export const HeaderActions = ({
  disableNav,
  backAction,
  backUrl,
}: HeaderProps) => {
  const { user, userIdentity: { firstName } = {} } = useAuth()
  const location = useLocation()

  const displayName = getName(firstName)
  // TODO: fork logic
  const handleLogout = () => {
    navigate(Routes.App.Logout.url, { replace: true })
  }

  const handleNavigateBack = () => {
    if (
      location.pathname.includes(Routes.App.Consultations.NewCheckIn.path) &&
      location.pathname.includes(
        Routes.App.Consultations.NewCheckIn.Questionnaire.path
      )
    ) {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.CHECK_IN_QUESTIONNAIRE_CLOSED
      )
    }

    if (
      location.pathname.includes(Routes.App.Consultations.NewCheckIn.path) &&
      location.pathname.includes(
        Routes.App.Consultations.NewCheckIn.Photos.path
      )
    ) {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.CHECK_IN_PICTURES_CLOSED
      )
    }

    if (
      location.pathname.includes(Routes.App.Consultations.MidCheckIn.path) &&
      location.pathname.includes(
        Routes.App.Consultations.MidCheckIn.Questionnaire.path
      )
    ) {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.MID_2M_QUESTIONNAIRE_CLOSED
      )
    }

    if (
      location.pathname.includes(Routes.App.Consultations.MidCheckIn.path) &&
      location.pathname.includes(
        Routes.App.Consultations.MidCheckIn.Photos.path
      )
    ) {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.MID_2M_PICTURES_CLOSED
      )
    }

    backUrl && navigate(backUrl)
  }

  if (disableNav) {
    return (
      <NavDisabledMenu
        isUserLogged={!!user}
        backUrl={backUrl}
        backAction={backAction}
        handleLogout={handleLogout}
        handleNavigateBack={handleNavigateBack}
      />
    )
  }

  return (
    <div
      sx={{
        display: "flex",
      }}
    >
      {user && getBdJwt() ? (
        <Fragment>
          <DualAccountMenu displayName={displayName} />

          <Button
            size="sm"
            variant="outline"
            as={"a"}
            sx={{
              display: ["none", "inline-flex"],
              fontSize: 12,
              ml: 4,
              whiteSpace: "nowrap",
              alignSelf: "center",
            }}
            href={Routes.App.Logout.url}
          >
            <Trans i18nKey="Header.logout" />
          </Button>
        </Fragment>
      ) : (
        <CoreOnlyMenu />
      )}
    </div>
  )
}

export interface HeaderProps {
  disableNav?: boolean
  disableActions?: boolean
  disableSeparator?: boolean
  backAction?: () => void
  backUrl?: string
  additionalStyles?: ThemeUIStyleObject
}
