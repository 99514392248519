import { ThemeUIStyleObject } from "@theme-ui/css/src/types"
import React from "react"
import { forwardRef } from "react"

interface HeaderProps {
  children: React.ReactNode
  size?: keyof typeof sizes
  as?: "h1" |"h2" | "h3" | "h4" | "h5"
  mt?: number
  mb?: number
  sx?: ThemeUIStyleObject
}

const sizes = {
  xl: {
    fontSize: 7,
  },
  lg: {
    fontSize: 6,
  },
  md: {
    fontSize: 5,
  },
  sm: {
    fontSize: 4,
  },
  xsm: {
    fontSize: 3,
  },
} as const

const Heading = forwardRef<HTMLHeadingElement, HeaderProps>(
  (
    { as: Comp = "h2", size = "md", mt = 0, mb = 0, sx = {}, ...otherProps },
    ref
  ) => (
    <Comp
      ref={ref}
      sx={{
        fontWeight: "normal",
        lineHeight: "heading",
        mt,
        mb,
        ...sizes[size],
        ...sx,
      }}
      {...otherProps}
    />
  )
)

Heading.displayName = "Heading"

export default Heading
export { sizes }
