import { useCallback, useEffect, useState } from "react"

import { AB_TESTS, isABTestActive } from "@trueskin-web/ab-experiments"
import {
  AB_TESTS_DATA,
  AB_TESTS_OPTIONS,
} from "@trueskin-web/ab-experiments/src/ab-tests"
import * as userClient from "@trueskin-web/apis/src/user"
import { parseCookie } from "@trueskin-web/cookies"

import * as authService from "./auth"
import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()

const getItem = (id: string) => storageService.getItem({ id })

const removeItem = (id: string) => storageService.removeItem({ id })

const isEnabled = (id: string) => !!getItem(id)

const cleanupStorage = () =>
  Object.keys(storageKeys)
    .filter((storageKey) => storageKey.startsWith("VWO_"))
    .forEach((storageKey) =>
      storageService.removeItem({
        id: storageKeys[storageKey],
      })
    )

const getInitialQuestionnaireId = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_ID)

const removeInitialQuestionnaireId = () =>
  removeItem(storageKeys.VWO_INITIAL_QUESTIONNARE_ID)

const getTofuInitialQuestionnaireId = () =>
  getItem(storageKeys.VWO_TOFU_INITIAL_QUESTIONNARE_ID)

const getInitialQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_PROVIDER)

const getQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_QUESTIONNARE_PROVIDER)

const isSideEffectsSelfServiceEnabled = () =>
  isEnabled(storageKeys.VWO_SIDE_EFFECTS_SELF_SERVICE)

const getTestPresetType = () => getItem(storageKeys.VWO_TEST_PRESET_TYPE)

const isPlanSelectionTestReleaseEnabled = () =>
  getItem(storageKeys.VWO_PLAN_SELECTION_TEST_RELEASE)

// soft rollback of new ayd chat experiment RG-283
const isNewChatLookAndFeel = () => false // isEnabled(storageKeys.VWO_NEW_CHAT)

const isJourneyStatusEnabled = () => isEnabled(storageKeys.VWO_JOURNEY_STATUS)

const getExperimentVariationName = (variationId) =>
  +variationId === 1 ? "Control" : `Variation ${+variationId - 1}`

const getVwoExperiments = () =>
  Object.entries(parseCookie(document.cookie))
    .filter(
      ([key, value]) =>
        key.startsWith("_vis_opt_exp_") && key.endsWith("_combi") && value
    )
    .map(([key, value]) => ({
      experimentId: +key.replace(/[^0-9]/g, ""),
      variationName: getExperimentVariationName(value),
    }))

const getVwoExperimentsEventData = () => {
  const vwoExperiments = getVwoExperiments()

  return {
    VWOExperimentID: vwoExperiments?.map((item) => item.experimentId).join(","),
    VWOExperimentVariant: vwoExperiments
      ?.map((item) => item.variationName)
      .join(","),
  }
}

function addVariationListener() {
  window.VWO = window.VWO || []
  window.VWO.push([
    "onVariationApplied",
    function (data) {
      if (!data) return
      const expId = data[1]
      const variationId = data[2]
      const isLoggedIn = authService.getJwt()
      if (
        isLoggedIn &&
        expId &&
        variationId &&
        ["VISUAL_AB", "VISUAL", "SPLIT_URL"].includes(
          window._vwo_exp[expId].type
        )
      ) {
        const variationName = getExperimentVariationName(variationId)
        userClient.saveVwoExperiments([{ experimentId: +expId, variationName }])
      }
    },
  ])
}

if (typeof window !== "undefined") {
  addVariationListener()
}

function useVWOExperimentStatus<T extends AB_TESTS_OPTIONS>(
  key: T
): {
  key: T
  localStorageKey: string
  variation: number | null
  isInControl: boolean
  isRunning: boolean
} & {
  [k in typeof key as `is${Capitalize<k>}VariantActive`]: (
    variant?: number
  ) => boolean
} {
  const localStorageKey = AB_TESTS_DATA[key].lsKey
  let currentVariation: number | null = null
  const storedVariation = window.localStorage.getItem(localStorageKey)
  if (!isNaN(Number(storedVariation))) {
    currentVariation = Number(storedVariation)
  }
  const isRunning = Boolean(
    window.localStorage.getItem(localStorageKey + "_running")
  )
  const isInControl = isRunning && !currentVariation
  const [refreshId, forceRefresh] = useState(0)

  // listen to events changing some experiments
  useEffect(() => {
    let isMounted = true
    if (typeof window !== "undefined") {
      // @ts-expect-error window exists
      window.VWO = window.VWO || []
      // @ts-expect-error window exists
      window.VWO.push([
        "onVariationApplied",
        function () {
          if (isMounted) {
            forceRefresh((prev) => prev + 1)
          }
        },
      ])
    }
    return () => {
      isMounted = false
    }
  }, [])

  const isActive = useCallback(
    (variant?: number) => {
      return isABTestActive(key, variant)
    },
    [currentVariation, refreshId]
  )

  return {
    key,
    localStorageKey,
    isInControl,
    isRunning,
    variation: currentVariation,
    [`is${Capitalize(key)}VariantActive`]: isActive,
  } as any
}
function Capitalize<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>
}

function triggerVWOCampaign(test: AB_TESTS_OPTIONS) {
  const campaignURL = AB_TESTS_DATA[test].campaignURL
  if (campaignURL) {
    const testUrl = new URL(campaignURL, window.location.href)
    triggerVWOCampaignByURL(testUrl.href)
  } else {
    throw new Error("VWO Campaign URL not found for " + test)
  }
}

function triggerVWOCampaignByURL(campaignURL: string) {
  console.log("activating VWO campaign", campaignURL)
  // @ts-expect-error window exists
  window.VWO.push([
    "activate",
    {
      customUrl: campaignURL,
    },
  ])
}

const isChurnPreventionPauseExperimentEnabled = () =>
  [1, "1"].includes(getItem(AB_TESTS_DATA[AB_TESTS.ChurnPreventionPause].lsKey))

export {
  cleanupStorage,
  getInitialQuestionnaireId,
  getInitialQuestionnaireProvider,
  getQuestionnaireProvider,
  getTestPresetType,
  isPlanSelectionTestReleaseEnabled,
  isSideEffectsSelfServiceEnabled,
  isNewChatLookAndFeel,
  getVwoExperimentsEventData,
  getVwoExperiments,
  getTofuInitialQuestionnaireId,
  isJourneyStatusEnabled,
  removeInitialQuestionnaireId,
  triggerVWOCampaign,
  useVWOExperimentStatus,
  isChurnPreventionPauseExperimentEnabled,
}
