import * as Collapsible from "@radix-ui/react-collapsible"
import { useState } from "react"

import { Card, Icon } from "@trueskin-web/components"
import { formatDate } from "@trueskin-web/functions"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import { OrderPriceBreakdownHistoryCardContent } from "./order-price-breakdown-history-card-content"

export const OrderPriceBreakdownHistoryCard = ({
  orderHistory,
  currentV2OrderId,
}: {
  orderHistory: any
  currentV2OrderId?: string | null
}) => {
  const { orderId, orderAt } = orderHistory
  const [open, setOpen] = useState(
    Boolean(currentV2OrderId) && orderHistory.v2OrderId === currentV2OrderId
  )

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Card sx={{ border: "1px solid lightGray", px: 0, gap: 0 }}>
        <Collapsible.Trigger asChild>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 4,
            }}
          >
            <div>
              <div
                sx={{
                  fontSize: 18,
                  lineHeight: "26px",
                  fontWeight: 700,
                }}
              >
                {formatDate(new Date(orderAt), {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </div>

              <div
                sx={{
                  fontSize: 12,
                  lineHeight: "16px",
                  color: "neutralsDarkGrey",
                }}
              >
                Bestell-ID #{orderId}
              </div>
            </div>

            <Icon
              icon={arrowRightIcon}
              sx={{
                transform: open ? "rotate(-90deg)" : "rotate(90deg)",
                transition: "all .2s ease-in",
              }}
            />
          </div>
        </Collapsible.Trigger>

        <Collapsible.Content>
          {open && (
            <OrderPriceBreakdownHistoryCardContent
              orderHistory={orderHistory}
              currentV2OrderId={currentV2OrderId}
            />
          )}
        </Collapsible.Content>
      </Card>
    </Collapsible.Root>
  )
}
