import { Trans } from "@trueskin-web/translations"

const Addon = ({ className, isSpotlightImage, data: addon }) => {
  const AddonImage = isSpotlightImage
    ? addon.spotlightImage
    : addon.productImage

  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr 2fr", "1fr 4fr"],
        gap: [3, 4],
      }}
      className={className}
    >
      <div>
        <AddonImage />
      </div>
      <div>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div data-testid="web-portal-addon-title">{addon.title}</div>
          <div
            data-testid="web-portal-addon-price"
            sx={{
              fontSize: 2,
              fontWeight: "bold",
              fontFamily: "body",
            }}
          >
            {addon.isNotMonthlyPrice ? (
              addon.price
            ) : (
              <Trans
                i18nKey="Account.Subscription.Treatment.Addons.priceTitle"
                values={{ price: addon.price }}
              />
            )}
          </div>
        </div>
        <div
          sx={{
            color: "textDark",
            fontSize: 0,
            fontFamily: "body",
          }}
        >
          <div
            data-testid="web-portal-addon-description"
            sx={{
              mt: 3,
            }}
          >
            {addon.description}
          </div>
          {addon.count ? (
            <Trans
              i18nKey="Account.Subscription.Treatment.AddonDialog.quantity"
              values={{ count: addon.count }}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Addon
