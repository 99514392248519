import { Suspense, forwardRef, lazy } from "react"

import ClientOnly from "../client-only"

const Image = lazy(() => import("./image"))

const ImageSsr = forwardRef((props, ref) => (
  <ClientOnly>
    <Suspense>
      <Image {...props} ref={ref} />
    </Suspense>
  </ClientOnly>
))

export default ImageSsr
