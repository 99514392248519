import Confetti from "react-confetti"

import { Button, Dialog, Icon } from "@trueskin-web/components"
import { formatDate } from "@trueskin-web/functions"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"

import { useLongTermDiscounts } from "./context"

export const LongTermDiscountsSuccessDialog = () => {
  const { customer, subscription, closeAllDialogs } = useLongTermDiscounts()

  return (
    <Dialog
      isOpen={true}
      noDismissIcon
      sx={{
        "&[data-reach-dialog-content]": {
          p: 0,
          height: "100vh",

          "& > section": {
            mt: "0px !important",
            height: "100vh",

            "& > section": {
              display: "flex",
              height: "100vh",
            },
          },
        },
      }}
    >
      <Confetti />

      <div
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <div
          sx={{
            flex: 1,
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon icon={checkCircleFilledIcon} sx={{ width: 48, height: 48 }} />

          <div
            sx={{
              textAlign: "center",
              fontSize: 24,
              lineHeight: "32px",
              fontWeight: 700,
            }}
          >
            <div>Super {customer.firstName}!</div>
            <div>Dein neuer Plan ist bereit.</div>
            <div>Deine Haut wird es Dir danken!</div>
          </div>

          <div sx={{ fontSize: 14, lineHeight: "20px" }}>
            Dein Plan wird am{" "}
            {formatDate(new Date(subscription.renewalDate ?? "n/a"), {
              day: "numeric",
              month: "long",
            })}{" "}
            starten
          </div>
        </div>

        <div
          sx={{
            px: 4,
            width: "100%",
            pb: 5,
          }}
        >
          <Button
            rounded
            isFullWidth
            onClick={() => closeAllDialogs()}
            data-testid="longTermDiscounts-CloseAllDialogs-btn"
          >
            Fertig
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
