import type { PlatformBannerConfig } from "@trueskin/vortex-sdk"
import type { UseQueryOptions, UseQueryResult } from "react-query"

import { siteConfigClient, useQuery } from "@trueskin-web/apis"

type BannersSettingsResponse = PlatformBannerConfig
type BannersSettingsError = unknown

type TUseBannersSettings<
  TData = BannersSettingsResponse,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  bannersSettings: BannersSettingsResponse | undefined
  isBannersSettingsLoading: boolean
  bannersSettingsError: unknown
}

export const useBannersSettings = ({
  enabled = true,
  staleTime = 10 * 60 * 1000, // 10 minute
  ...options
}: UseQueryOptions<
  BannersSettingsResponse,
  BannersSettingsError,
  BannersSettingsResponse
> = {}): TUseBannersSettings => {
  const query = useQuery<
    BannersSettingsResponse,
    BannersSettingsError,
    BannersSettingsResponse
  >("bannersSettings", siteConfigClient.getBannerConfig, {
    enabled,
    staleTime,
    ...options,
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    bannersSettings: data,
    isBannersSettingsLoading: isLoading,
    bannersSettingsError: error,
  }
}
