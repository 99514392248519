import { Fragment, useCallback, useState } from "react"

import {
  amplitudeClient,
  orderClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@trueskin-web/apis"
import {
  Button,
  FieldError,
  ReadonlyField,
  Spinner,
} from "@trueskin-web/components"
import { formatCurrency } from "@trueskin-web/functions"
import { Trans, useTranslation } from "@trueskin-web/translations"

import { productResources } from "../../../../../utils/resources"

import Addon from "./addon"
import AddonDialog from "./addon-dialog"

const ADDON_ACTION_TYPES = {
  ADDED: "added",
  REMOVED: "removed",
}

const unsupportedAddons = ["p_pimple_patches_day_night_30pcs_1"]

export const NON_MONTHLY_ADDONS = [
  "p_peeling",
  "p_pimple_patches_day_night_40pcs_1",
]

const mapUpcomingPresetsToAddonProduct = ({
  id,
  isSelected,
  details,
  summary,
  computedMetadata,
}) => ({
  id,
  isSelected,
  title: details.title,
  out_of_stock: productResources[id]?.out_of_stock,
  spotlightImage: productResources[id]?.spotlightImage,
  productImage: productResources[id]?.productImage,
  price: summary.instalments.count
    ? formatCurrency(
        computedMetadata.amount / summary.instalments.count,
        summary.values.currency
      )
    : computedMetadata.mainLabel,
  isNotMonthlyPrice:
    NON_MONTHLY_ADDONS.includes(id) && summary.instalments.count === 1,
  description: details.description,
})

const AddonActions = ({ className, data, onSelect }) => {
  const queryClient = useQueryClient()

  const [setIsRemoveUpcomingProductSyncing, setIsRemoveAddonSyncing] =
    useState(false)

  const {
    isLoading: isRemoveUpcomingProductLoading,
    mutateAsync: removeUpcomingProduct,
    error: removeUpcomingProductError,
  } = useMutation(orderClient.removeUpcomingProduct, {
    onSuccess: () =>
      (async () => {
        setIsRemoveAddonSyncing(true)

        await Promise.all([
          queryClient.refetchQueries("addonsUpcomingPresets"),
          queryClient.refetchQueries("upcomingOrderSummary"),
        ])

        setIsRemoveAddonSyncing(false)
      })(),
  })

  const trackAddonButtonClick = useCallback(
    (actionType) =>
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.NEXT_ORDER_ADDON_CLICKED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.ACTION]: actionType,
          [amplitudeClient.ANALYTIC_EVENT_PROPS.PRODUCT]:
            amplitudeClient.ADD_ON_PRODUCT_NAME[data.id],
        }
      ),
    []
  )

  const handleOpenAddonDialog = useCallback(() => {
    onSelect(data)

    trackAddonButtonClick(ADDON_ACTION_TYPES.ADDED)
  }, [data, onSelect, trackAddonButtonClick])

  const handleAddonRemoval = useCallback(() => {
    removeUpcomingProduct(data.id)

    trackAddonButtonClick(ADDON_ACTION_TYPES.REMOVED)
  }, [data, removeUpcomingProduct, trackAddonButtonClick])

  return (
    <div className={className}>
      <Addon data={data} isSpotlightImage />

      {data.isSelected ? (
        <Fragment>
          <Button
            data-testid="web-portal-addon-remove-btn"
            variant="outline"
            sx={{
              width: ["100%", "auto"],
              mt: 5,
              px: [undefined, 7],
            }}
            isLoading={
              isRemoveUpcomingProductLoading ||
              setIsRemoveUpcomingProductSyncing
            }
            onClick={handleAddonRemoval}
          >
            <Trans i18nKey="Account.Subscription.Treatment.Addons.removeButton" />
          </Button>

          {removeUpcomingProductError && (
            <FieldError sx={{ mt: 6 }}>
              {removeUpcomingProductError.message}
            </FieldError>
          )}
        </Fragment>
      ) : (
        <Button
          isDisabled={data.out_of_stock}
          data-testid="web-portal-addon-add-btn"
          sx={{
            width: ["100%", "auto"],
            ...(data.out_of_stock
              ? {
                  backgroundColor: "#e5e5e5!important",
                  borderColor: "transparent!important",
                  color: "white!important",
                }
              : {}),
            mt: 5,
            px: [undefined, 7],
          }}
          onClick={handleOpenAddonDialog}
        >
          {data.out_of_stock ? (
            "Bald wieder verfügbar"
          ) : NON_MONTHLY_ADDONS.includes(data.id) ? (
            <Trans i18nKey="Account.Subscription.Treatment.Addons.addButtonOnce" />
          ) : (
            <Trans i18nKey="Account.Subscription.Treatment.Addons.addButtonMonthly" />
          )}
        </Button>
      )}
    </div>
  )
}

const Addons = () => {
  const { t } = useTranslation()

  const {
    isLoading: isAddonsUpcomingPresetsLoading,
    data: { data: addonsUpcomingPresets = [] } = {},
    error: addonsUpcomingPresetsError,
  } = useQuery("addonsUpcomingPresets", orderClient.getAddonsUpcomingPresets)

  const [selectedAddon, selectAddon] = useState()

  const addons = addonsUpcomingPresets
    .filter((item) => !unsupportedAddons.includes(item.id))
    .map(mapUpcomingPresetsToAddonProduct)

  return (
    <Fragment>
      <ReadonlyField
        label={t("Account.Subscription.Treatment.Addons.title")}
        value={
          isAddonsUpcomingPresetsLoading ? (
            <div
              sx={{
                textAlign: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div data-testid="web-portal-addon-section">
              {addons.map((addon) => (
                <AddonActions
                  key={addon.id}
                  data={addon}
                  onSelect={selectAddon}
                  sx={{
                    boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
                    px: 4,
                    py: 5,
                    mt: 4,
                    border: "1px",
                    borderColor: addon.isSelected ? "primary" : "transparent",
                  }}
                />
              ))}

              {addonsUpcomingPresetsError && (
                <FieldError sx={{ mt: 4 }}>
                  {addonsUpcomingPresetsError.message}
                </FieldError>
              )}
            </div>
          )
        }
        sx={{
          ":before": {
            content: `"+"`,
            display: "block",
            textAlign: "center",
            color: "primary",
            fontSize: 6,
            fontWeight: "bold",
            lineHeight: 1,
            mb: 5,
          },
        }}
      />

      {selectedAddon && (
        <AddonDialog
          data={selectedAddon}
          onDismiss={() => {
            selectAddon(undefined)
          }}
        />
      )}
    </Fragment>
  )
}

export default Addons
