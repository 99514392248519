import { ThemeUIStyleObject } from "@theme-ui/css/src/types"

export const SeparatorWithText = ({ text }: { text: string }) => {
  const afterAndBeforeSx: ThemeUIStyleObject = {
    backgroundColor: ({ colors }) => `${colors?.grey}`,
    content: `""`,
    display: "inline-block",
    height: "1px",
    position: "relative",
    verticalAlign: "middle",
    width: "50%",
  }
  const beforeSx: ThemeUIStyleObject = {
    right: "0.5em",
    marginLeft: "-50%",
  }
  const afterSx: ThemeUIStyleObject = {
    left: "0.5em",
    marginRight: "-50%",
  }

  return (
    <div
      sx={{
        overflow: "hidden",
        textAlign: "center",
        my: [3, 3],
        ":after": {
          ...afterAndBeforeSx,
          ...afterSx,
        },
        ":before": {
          ...afterAndBeforeSx,
          ...beforeSx,
        },
      }}
    >
      {text}
    </div>
  )
}
