import { StaticImage } from "gatsby-plugin-image"

import { CardV2 } from "@trueskin-web/components/src/card/card-v2"

export const V2Cards = function () {
  return (
    <>
      <CardV2
        image={
          <StaticImage
            alt={"alt image"}
            src="../../../../images/rebranded/product-set-slim.png"
            sx={{ maxWidth: "25%", borderRadius: 8 }}
          />
        }
        margin={"4"}
        marginBottom={"0"}
        headingLeft={"heading left"}
        headingRight={"heading right"}
      >
        Some inner content
      </CardV2>
    </>
  )
}
