import { formatCurrency } from "@trueskin-web/functions"

import { OrderSummary } from "../../../account/subscription/order.utils"

export type OrderPriceDetails = {
  /**
   * Number of instalmentsCount over which the price is distributed
   */
  instalmentsCount: number

  /**
   * The currency of the order
   */
  currency: string

  renewalDate: string

  /**
   * The total that the customer ends up paying over the complete course of `numberOfMonths`
   */
  total: number
  totalFormatted: string

  /**
   * The total discount that the customer receives over the complete course of `numberOfMonths`
   */
  discount: number
  discountFormatted: string

  /**
   * The total that the customer is/will be paying without any discounts over the course of `numberOfMonths`
   */
  totalWithoutDiscounts: number
  totalWithoutDiscountsFormatted: string

  /**
   * The total that the customer is/will be paying per month
   */
  totalPerMonth: number
  totalPerMonthFormatted: string

  totalPerMonthWithoutDiscounts: number
  totalPerMonthWithoutDiscountsFormatted: string

  subscriptionRenewalDate?: string

  totalSetPriceWithoutDiscounts: number
  totalSetPriceWithoutDiscountsFormatted: string

  monthlySetPriceWithoutDiscounts: number
  monthlySetPriceWithoutDiscountsFormatted: string
}

export const computeOrderPriceDetails = (
  order: OrderSummary,
  overrideInstalmentsCount?: number,
  subscriptionRenewalDate?: string | null
): OrderPriceDetails => {
  const instalmentsCount = overrideInstalmentsCount ?? order.instalments.count
  const currency = order.currency

  const setLineItems = order.lineItems.filter((item) =>
    ["other", "medical_service"].includes(item.type)
  )

  const monthlySetPriceWithoutDiscounts = setLineItems.reduce((prev, crt) => {
    return prev + crt.amount
  }, 0)

  const totalSetPriceWithoutDiscounts =
    monthlySetPriceWithoutDiscounts * instalmentsCount

  const fakeDiscountLineItems = order.lineItems.filter(
    (item) => item.type === "discount" && item.products?.length
  )

  const monthlyFakeDiscount = Math.abs(
    fakeDiscountLineItems.reduce((prev, crt) => {
      return prev + crt.amount
    }, 0)
  )
  const totalFakeDiscount = monthlyFakeDiscount * instalmentsCount

  const totalDiscount = (order.values.discount || 0) + totalFakeDiscount

  const totalWithoutDiscounts =
    order.values.totalWithoutDiscounts + totalFakeDiscount
  const totalPerMonthWithoutDiscounts =
    order.values.perInstalment.totalWithoutDiscounts + monthlyFakeDiscount

  return {
    instalmentsCount,
    currency,
    monthlySetPriceWithoutDiscounts,
    totalSetPriceWithoutDiscounts,
    monthlySetPriceWithoutDiscountsFormatted: formatCurrency(
      monthlySetPriceWithoutDiscounts / 100,
      currency,
      2
    ),
    totalSetPriceWithoutDiscountsFormatted: formatCurrency(
      totalSetPriceWithoutDiscounts / 100,
      currency,
      2
    ),
    renewalDate: subscriptionRenewalDate ?? "n/a",
    total: order.values.total,
    totalFormatted: formatCurrency(order.values.total / 100, currency, 2),
    totalPerMonth: order.values.perInstalment.total,
    totalPerMonthFormatted: formatCurrency(
      order.values.perInstalment.total / 100,
      currency,
      2
    ),
    discount: totalDiscount,
    discountFormatted: formatCurrency(totalDiscount / 100, currency, 2),
    totalWithoutDiscounts: totalWithoutDiscounts,
    totalWithoutDiscountsFormatted: formatCurrency(
      totalWithoutDiscounts / 100,
      currency,
      2
    ),
    totalPerMonthWithoutDiscounts,
    totalPerMonthWithoutDiscountsFormatted: formatCurrency(
      totalPerMonthWithoutDiscounts / 100,
      currency,
      2
    ),
  }
}
