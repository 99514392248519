import React from "react"

import { Dialog, Heading } from "@trueskin-web/components"

import { DialogProps } from "./dialog"

export interface BottomSheetProps extends DialogProps {
  children: React.ReactNode
  replaceTitle?: boolean
}
const BottomSheet: React.FC<BottomSheetProps> = ({
  title,
  replaceTitle = true,
  children,
  ...props
}) => {
  if (!replaceTitle) {
    props.title = title
  }
  return (
    <Dialog
      sx={{
        textAlign: "center",
        borderRadius: ["16px 16px 0 0", "16px"],
        overflow: "hidden",
      }}
      stickTo="bottom"
      noMobileFullscreen={true}
      {...props}
    >
      {/* TODO: use h2, override styles in Section */}
      {replaceTitle && (
        <Heading as={"h2"} sx={{ fontWeight: 500, my: 2 }}>
          {title}
        </Heading>
      )}
      {children}
    </Dialog>
  )
}

export { BottomSheet }
