import { forwardRef, useEffect, useState } from "react"

import { Button, Container } from "@trueskin-web/components"
import { useIsScreenSizeSmall } from "@trueskin-web/theme"

const MobileCta = forwardRef(
  ({ title, description, targetUrl, rounded, onClick, dataTestId }, ref) => {
    const isMobile = useIsScreenSizeSmall()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      setIsLoading(false)
    }, [])

    if (isLoading || !isMobile || !title) {
      return null
    }

    return (
      <div
        ref={ref}
        sx={{
          width: "100vw",
          py: 4,
          backgroundColor: "white",
        }}
        data-testid="mobile-sticky-cta-wrapper"
      >
        <Container>
          <Button
            data-testid={dataTestId}
            size="lg"
            isFullWidth
            rounded={rounded}
            onClick={
              targetUrl
                ? () => {
                    Object.assign(document.createElement("a"), {
                      href: targetUrl,
                    }).click()
                  }
                : onClick
            }
          >
            {title}
          </Button>

          {description && (
            <div
              sx={{
                mt: 1,
                fontSize: 1,
                textAlign: "center",
              }}
            >
              {description}
            </div>
          )}
        </Container>
      </div>
    )
  }
)

export default MobileCta
