import { forwardRef } from "react"

import Image from "./image"

const CDN_URL = process.env.GATSBY_CDN_FRONT_URL

const prepareUrl = (originalUrl) => {
  if (typeof originalUrl !== "string") {
    return originalUrl
  }

  if (originalUrl.startsWith("https://s3.")) {
    return originalUrl.replace(/https:\/\/([^/]*\/){2}/, CDN_URL)
  }

  if (originalUrl.includes("trueskin-api-development-assets.s3")) {
    return originalUrl.replace(/https:\/\/[^/]*\//, CDN_URL)
  }

  return originalUrl
}

const CdnImage = forwardRef(({ src, ...props }, ref) => (
  <Image ref={ref} src={prepareUrl(src)} {...props} />
))

export default CdnImage
