import { useLocation } from "@reach/router"

import { Routes } from "@trueskin-web/translations"

export enum Apps {
  "default" = "default",
  "bd" = "bd",
}

// TODO @fox: This is weird, needs rechecking
export const useApp = () => {
  const location = useLocation()
  const mode = location.href.includes(Routes.App.Diagnose.path)
    ? Apps.bd
    : Apps.default
  return mode
}
