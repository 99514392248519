import { apiV2, apiVortex } from "./api"

const UserRatingTypes = {
  SELF_SERVICE_PAGE: "SELF_SERVICE_PAGE",
  TREATMENT_PLAN: "TREATMENT_PLAN",
  DIAGNOSIS_DETAILS: "DIAGNOSIS_DETAILS",
}

function saveVwoExperiments(data) {
  return apiVortex().postUsersMeVwoExperiments(data)
}

function update(updates) {
  return apiVortex().putUsersMe(updates)
}

function setCountry(countryCode) {
  return update({ country: countryCode })
}

function uploadImage({
  consultationId,
  consultationMessageId,
  file,
  orientation,
  totalImagesToUpload,
}) {
  if (consultationMessageId) {
    return apiVortex().postUsersMeConsultationsMessagesImagesOrientations(
      consultationId,
      consultationMessageId,
      orientation,
      { file, totalImagesToUpload }
    )
  }

  return apiVortex().postUsersMeConsultationsImagesOrientations(
    consultationId,
    orientation,
    { file, totalImagesToUpload }
  )
}

function tempUploadImage({ tempId, file, orientation }) {
  return apiVortex().postUsersMeConsultationsTempImagesOrientations(
    tempId,
    orientation,
    { file }
  )
}

function uploadDocuments({ files }) {
  return apiVortex().postUsersMePraxisReferralLetter({ files })
}

function skipImageUpload(consultationId) {
  return apiVortex().postUsersMeConsultationsImagesSkip(consultationId)
}

function tempSkipImageUpload(tempId) {
  return apiVortex().postUsersMeConsultationsImagesSkipTypeform(tempId)
}

function getReferralDetails() {
  return apiV2("customer/referral").then((d) => d.data)
}

function getTreatmentSummary() {
  return apiVortex().getUsersMeTreatmentSummary()
}

function getTreatmentProgressOverview() {
  return apiVortex().getUsersMeTreatmentProgressOverview()
}

function getTreatmentProgress(skip, take) {
  return apiVortex().getUsersMeTreatmentProgress(skip, take)
}

function getTreatmentDiscovery() {
  return apiVortex().getUsersMeTreatmentDiscover()
}

function createImageUploadConsultation() {
  return apiVortex().postUsersMeConsultationsImageUpload()
}

function getDataCheckDetails() {
  return apiVortex().getUsersMeDataCheck()
}

function markDataCheckAsSubmitted() {
  return apiVortex().postUsersMeDataCheck()
}

function changeRenewalDate({ subscriptionId, renewalDate, renewalReason }) {
  return apiVortex().postUsersMeSubscriptionsUpdateRenewal(subscriptionId, {
    renewalDate,
    renewalReason,
  })
}

function getSubscriptionHistory() {
  return apiVortex().getUsersMeSubscriptionsHistory()
}

function getSubscriptionSummary({ queryKey: [, subscriptionId, renewalDate] }) {
  return apiVortex().getUsersMeSubscriptionsSummary(subscriptionId, renewalDate)
}

function getMyRoutineV2() {
  return apiVortex().getUsersMeRoutineV2()
}

function submitTelemedicineAgreement({ telemedicine }) {
  return apiVortex().postUsersMeAgreements({ telemedicine })
}

function submitTermsAgreement() {
  return apiVortex().postUsersMeAgreements({ terms: true })
}

function getReviewLink({ customer, email }) {
  // hotfix: forcing trustedshops because trustpilot got disabled
  const body = { customer, email, platform: "trustedshops" }

  return apiVortex().postUsersMeReviewLink(body)
}

function getHealthRecord() {
  return apiVortex().getUsersMeHealthRecord()
}

function getMedicalData(userId) {
  return apiVortex().getUsersHealthRecordMedicalData(userId)
}

function updateMedicalData(values) {
  return apiVortex().postHealthRecordMedicalDataUpdate(values)
}

function updateMedicalDataConsent() {
  return apiVortex().postHealthRecordMedicalDataConsent()
}

function createUserRating({ type, id, rating, feedback }) {
  const body = {
    ratedEntityType: type,
    ratedEntityId: id,
    rating,
    feedback,
  }

  return apiVortex().postUserRatings(body)
}

function findUserRating({ type, id }) {
  return apiVortex().getUserRatingsFind(type, id)
}

function getUserMetadata(params = {}) {
  return apiVortex().postCustomerMetadataMeGetMetadata(params)
}

function setUserMetadata(body) {
  return apiVortex().postCustomerMetadataMeSetMetadata(body)
}

export {
  update,
  setCountry,
  uploadImage,
  tempUploadImage,
  uploadDocuments,
  skipImageUpload,
  tempSkipImageUpload,
  getReferralDetails,
  getTreatmentSummary,
  getTreatmentProgressOverview,
  getTreatmentProgress,
  getTreatmentDiscovery,
  createImageUploadConsultation,
  getDataCheckDetails,
  markDataCheckAsSubmitted,
  changeRenewalDate,
  getSubscriptionHistory,
  getMyRoutineV2,
  submitTelemedicineAgreement,
  submitTermsAgreement,
  saveVwoExperiments,
  getSubscriptionSummary,
  getReviewLink,
  getHealthRecord,
  getMedicalData,
  updateMedicalData,
  updateMedicalDataConsent,
  UserRatingTypes,
  createUserRating,
  findUserRating,
  getUserMetadata,
  setUserMetadata,
}
