import { differenceInYears } from "date-fns"
import get from "lodash.get"
import { isPossiblePhoneNumber } from "react-phone-number-input"

import { i18nCountry } from "@trueskin-web/locales"
import i18next from "@trueskin-web/translations"

const AGE_LIMIT = 14

const getCountryCode = (countryFieldPath, values) =>
  countryFieldPath ? get(values, countryFieldPath) : i18nCountry()

export const required = (value, customMessage) => {
  const errorMessage =
    typeof customMessage === "string"
      ? customMessage
      : i18next.t("Generic.errors.required")

  if (typeof value === "string") {
    return value.trim() ? undefined : errorMessage
  }

  return value !== undefined && value !== null ? undefined : errorMessage
}

export const mustBeEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (re.test(value) || !value) {
    return undefined
  } else {
    return i18next.t("Generic.errors.invalidEmail")
  }
}

export const mustBeTheSame = (fieldToMatch) => (value, allValues) =>
  value === allValues[fieldToMatch]
    ? undefined
    : i18next.t("Generic.errors.mustBeTheSame", { value: fieldToMatch })

export const minLength = (min) => (value) =>
  !value || value.length >= min
    ? undefined
    : i18next.t("Generic.errors.minLength", { value: min })

export const maxLength = (max) => (value) =>
  !value || value.length <= max
    ? undefined
    : i18next.t("Generic.errors.maxLength", { value: max })

export const mustBeTrue = (value) =>
  value === true ? undefined : i18next.t("Generic.errors.mandatoryField")

const postalCodeLength = {
  DE: 5,
  CH: 4,
}

export const postalCodeRegex = {
  DE: /^\d{5}$/,
  CH: /^\d{4}$/,
}

export const mustBePostalCodeByCountry =
  (countryFieldPath) => (value, allValues) => {
    const countryCode = getCountryCode(countryFieldPath, allValues)
    const numbers = value.replace(new RegExp("[^0-9]+", "ig"), "")

    const requiredLength = postalCodeLength[countryCode]

    if (!requiredLength) {
      return undefined
    }

    if (numbers.length !== requiredLength) {
      return i18next.t("Generic.errors.requiredLength", {
        value: requiredLength,
      })
    }

    if (!postalCodeRegex[countryCode].test(value)) {
      return i18next.t("Generic.errors.invalidPostalCode")
    }
  }

export const mustContainNumber = (value) =>
  /\d/.test(value) ? undefined : i18next.t("Generic.errors.mustContainNumber")

export const mustNotContainNumbers = (value) =>
  !/\d/.test(value)
    ? undefined
    : i18next.t("Generic.errors.mustNotContainNumbers")

export const mustContainOnlyNumbers = (value) =>
  !value || /^\d+$/.test(value)
    ? undefined
    : i18next.t("Generic.errors.onlyNumbersAllowed")

export const mustBeValidYear = (value) =>
  !value || /^(?:(?:19|20)[0-9]{2})$/.test(value)
    ? undefined
    : i18next.t("Generic.errors.invalidYear")

export const mustBeValidDay = (day, month, year) =>
  !/^(0?[1-9]|[12][0-9]|3[01])$/.test(day) ||
  new Date(year, month, day).getMonth() !== month
    ? i18next.t("Generic.errors.invalidDate")
    : undefined

export const mustBeValidBirthday = (value) => {
  const regExp = /(^0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2]).((19|20)(\d{2}$))/

  if (regExp.test(value)) {
    const formattedValue = value.split(".").reverse().join("-")

    return differenceInYears(new Date(), new Date(formattedValue)) < AGE_LIMIT
      ? i18next.t("Generic.errors.invalidAge")
      : undefined
  }

  return i18next.t("Generic.errors.invalidDate")
}

export const mustBeValidStreetAddress =
  (countryFieldPath) => (value, allValues) => {
    const countryCode = getCountryCode(countryFieldPath, allValues)

    if (countryCode === "DE") {
      // TODO: remove useless escape for eslint autofix
      // eslint-disable-next-line no-useless-escape
      const regExp = /^[a-zA-ZäöüßÄÖÜ0-9.\-\s]+\s\d+\s?\/?\-?([a-zA-Z\d]+)?$/

      return regExp.test(value)
        ? undefined
        : i18next.t("Generic.errors.invalidStreetAddress")
    }

    return mustContainNumber(value)
  }

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    )

export const mustBePhoneNumber = (value) =>
  isPossiblePhoneNumber(value)
    ? undefined
    : i18next.t("Generic.errors.invalidPhoneNumber")
