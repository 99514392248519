import { Button, Section, Spinner } from "@trueskin-web/components"
import { mapDataPlaceholders } from "@trueskin-web/functions"
import { useTranslation } from "@trueskin-web/translations"

import { useReferralDetails } from "../../../hooks"

const Promoters = ({ links, onDismiss, onClick }) => {
  const { t } = useTranslation()

  const { data, isLoading } = useReferralDetails()

  return (
    <Section
      title={t("Nps.SubmittedPositive.heading")}
      description={t("Nps.SubmittedPositive.description")}
      content={
        isLoading ? (
          <Spinner />
        ) : (
          <div sx={{ display: "grid", gap: 4 }}>
            {links.map((link) => (
              <Button
                id={`nps_review_${link.platform}`}
                key={link.id}
                variant={
                  link.platform.toLowerCase() === "referral"
                    ? "outline"
                    : "solid"
                }
                isFullWidth
                onClick={() => onClick(link)}
              >
                {mapDataPlaceholders(link.label, data)}
              </Button>
            ))}
          </div>
        )
      }
      onDismiss={onDismiss}
    />
  )
}

export default Promoters
