import { Widget } from "@typeform/embed-react"

import { envDetails } from "@trueskin-web/core"

// import "../styles/widget.css"

const TypeformWidget = ({
  id,
  url,
  hidden,
  onReady,
  dataTestId = "",
  ...props
}) => (
  <div data-testid={dataTestId}>
    <Widget
      id={id || url.split("/").pop()}
      hideHeaders
      inlineOnMobile
      onReady={() => {
        window.scrollTo(0, 0)

        onReady?.()
      }}
      className={"full-screen-widget"}
      hidden={Object.assign({}, hidden || {}, {
        api_prefix: envDetails.isProduction
          ? "/production-de" // TODO @fox: switch to using envDetails.apiPrefix once funnel and cla are moved to hit gateway.formelskin.de
          : envDetails.apiPrefix,
      })}
      {...props}
    />
  </div>
)

export default TypeformWidget
