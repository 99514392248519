import { formatCurrency } from "./formatters"

export const removeTrailingSlash = (path) => path.replace(/^[.*]+|[\\/]+$/, "")

export const mapDataPlaceholders = (text, data) => {
  if (!data) {
    return text
  }

  return text.replace(/{([^{}]+)}/g, (_match, placeholder) =>
    placeholder
      .split(".")
      .reduce(
        (value, key) =>
          key === "amount"
            ? formatCurrency(
                Number.parseInt(value.amount) / 100,
                value.currency
              )
            : value[key],
        data
      )
  )
}
