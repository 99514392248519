// a nice positional equal for arrays with primitives
export const equalArrays = (a, b) =>
  a.length === b.length && a.every((v, i) => v === b[i])

export const equalArraysValues = (a, b) => {
  if (a.length !== b.length) {
    return false
  }

  const uniqueValues = new Set([...a, ...b])

  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length
    const bCount = b.filter((e) => e === v).length
    if (aCount !== bCount) {
      return false
    }
  }

  return true
}
