const ACADEMIC_TITLES = ["Dr. med.", "Dr. med. univ."]

interface DoctorProfile {
  title?: string | null
  name: string
}

export const getDoctorNameWithTitle = (doctor?: DoctorProfile) => {
  if (!doctor) {
    return undefined
  }
  const { title, name } = doctor
  if (!title) {
    return name
  }
  if (ACADEMIC_TITLES.includes(title.trim())) {
    return `${title.trim()} ${name}`
  }

  return name
}

const DoctorNameWithTitle = ({ doctor }: { doctor?: DoctorProfile | null }) => {
  if (!doctor) {
    return undefined
  }
  return <>{getDoctorNameWithTitle(doctor)}</>
}

export default DoctorNameWithTitle
