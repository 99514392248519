import { sizer } from "@trueskin-web/functions"

import { useFormControl } from "./form-control"

const sizes = {
  xlg: {
    height: 40,
    width: 40,
  },
  lg: {
    height: 32,
    width: 32,
  },
  md: {
    height: 24,
    width: 24,
  },
  sm: {
    height: 16,
    width: 16,
  },
  xsm: {
    height: 12,
    width: 12,
  },
}

const Radio = ({ className, ...props }) => {
  const {
    size = "sm",
    color = "primary",
    children,
    isFullWidth,
    ...otherProps
  } = props
  const formControl = useFormControl(props)

  return (
    <label
      sx={{
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        width: isFullWidth && "100%",
        opacity: formControl.isDisabled ? 0.4 : 1,
      }}
      className={className}
    >
      <input
        type="radio"
        disabled={formControl.isDisabled}
        {...otherProps}
        sx={{
          appearance: "none",
          backgroundColor: "transparent",
          margin: 0,
          position: "absolute",
        }}
      />
      <div
        sx={{
          display: "inline-flex",
          flexShrink: 0,
          justifyContent: "center",
          border: "1px",
          position: "relative",
          borderRadius: "50%",
          ...sizer(sizes, size),
          ":after": {
            opacity: 0,
            content: `""`,
            position: "absolute",
            top: "2px",
            right: "2px",
            bottom: "2px",
            left: "2px",
            borderRadius: "50%",
            bg: color,
          },
          "input:checked ~ &": {
            borderColor: color,
            "&:after": {
              opacity: 1,
            },
          },
          "input:focus ~ &, input:hover ~ &": {
            boxShadow: "0 0 0 1px",
          },
          "input[aria-invalid=true] ~ &": {
            borderColor: "attention",
          },
        }}
      />
      {children && (
        <div
          sx={{
            alignSelf: "center",
            ml: 2,
            userSelect: "none",
          }}
        >
          {children}
        </div>
      )}
    </label>
  )
}

export default Radio
export { sizes }
