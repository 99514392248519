const Card = (props) => (
  <div
    sx={{
      padding: 4,
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      gap: 4,
      background: "white",
    }}
    {...props}
  />
)

export default Card
