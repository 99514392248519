import { OrderSummary } from "../../app/account/subscription/order.utils"

import { LongTermDiscountOffer, LongTermDiscountOffers } from "./types"

const findDiscountOffer = (
  order?: OrderSummary
): LongTermDiscountOffer | undefined => {
  const orderVoucherCodes = (order?.vouchers || [])
    .filter((voucher: any) => voucher.used === true)
    .map((voucher: any) => voucher?.voucher?.code)

  return LongTermDiscountOffers.find((offer) =>
    orderVoucherCodes.includes(offer.voucherCode)
  )
}

const orderIncludesLongTermDiscountOffer = (order?: OrderSummary) => {
  return !!findDiscountOffer(order)
}

/**
 * Check if a long term discount offer is active on the order
 *
 * @param orderSummary Order summary which is checked for the existence of a long term discount offer
 */
const isOfferActive = (orderSummary?: OrderSummary) => {
  return orderIncludesLongTermDiscountOffer(orderSummary)
}

/**
 * Apply the long term discount offer to the order line items by updating the discount line item related to 2-Monats-Set Rabatt
 * and the general discount line item (Rabatt).
 * @param orderSummary
 */
const applyOfferToOrderLineItems = (orderSummary: OrderSummary) => {
  const discountOffer = findDiscountOffer(orderSummary)

  if (!discountOffer) {
    return orderSummary
  }

  const tempOrderSummary = Object.assign({}, orderSummary)
  tempOrderSummary.lineItems = tempOrderSummary.lineItems
    .map((lineItem) => {
      if (
        lineItem.type === "discount" &&
        lineItem.title === "2-Monats-Set Rabatt"
      ) {
        return {
          ...lineItem,
          amount: discountOffer.discountPerMonth * -1 + lineItem.amount,
          title: `${discountOffer.months}-Monats-Set Rabatt`,
        }
      }

      if (lineItem.type === "discount" && lineItem.title === "Rabatt") {
        return {
          ...lineItem,
          amount: discountOffer.discountPerMonth + lineItem.amount,
        }
      }

      return lineItem
    })
    .filter((lineItem) => Boolean(lineItem?.amount))

  const discountOfferVoucher = tempOrderSummary.vouchers?.find(
    (voucher) => voucher.voucher.code === discountOffer.voucherCode
  )
  if (discountOfferVoucher?.used) {
    tempOrderSummary.discounts = tempOrderSummary.discounts.filter(
      (discount) => discount.refId !== discountOfferVoucher.voucher.id
    )
  }

  return tempOrderSummary
}

/**
 * Reset all discounts applied on the upcoming order, but leave the long term discount offer applied.
 * This is to help the user to see the impact of the long term discount offer on the upcoming order.
 * @param orderSummary
 */
const resetDiscounts = (orderSummary: OrderSummary) => {
  const discountOffer = findDiscountOffer(orderSummary)

  if (!discountOffer) {
    return orderSummary
  }

  const referralDiscount = orderSummary.discounts.find(
    (discount) => discount.ref === "referralReward"
  )

  const tempOrderSummary = Object.assign({}, orderSummary)

  if (referralDiscount) {
    tempOrderSummary.discounts = orderSummary.discounts.filter(
      (discount) => discount.ref !== "referral"
    )

    tempOrderSummary.lineItems = tempOrderSummary.lineItems
      .map((lineItem) => {
        if (lineItem.type === "discount" && lineItem.title === "Rabatt") {
          return {
            ...lineItem,
            amount:
              lineItem.amount +
              referralDiscount.amount / orderSummary.instalments.count,
          }
        }

        return lineItem
      })
      .filter((lineItem) => Boolean(lineItem?.amount))
  }

  return tempOrderSummary
}

/**
 * Check if the offer can be removed from the upcoming order
 *
 * @param lastOrderSummary
 * @param upcomingOrderSummary
 */
const canRemoveOffer = (
  lastOrderSummary?: OrderSummary,
  upcomingOrderSummary?: OrderSummary
): boolean => {
  const lastOrderOffer = findDiscountOffer(lastOrderSummary)
  const upcomingOrderOffer = findDiscountOffer(upcomingOrderSummary)

  return (
    (Boolean(upcomingOrderOffer) && !Boolean(lastOrderOffer)) ||
    (Boolean(upcomingOrderOffer) &&
      Boolean(lastOrderOffer) &&
      upcomingOrderOffer?.months !== lastOrderOffer?.months)
  )
}

/**
 * Check if the subscription cancellation is allowed
 *
 * @param lastOrderSummary
 * @param upcomingOrderSummary
 */
const canCancel = (
  lastOrderSummary?: OrderSummary,
  upcomingOrderSummary?: OrderSummary
): boolean => {
  const lastOrderOffer = findDiscountOffer(lastOrderSummary)
  const upcomingOrderOffer = findDiscountOffer(upcomingOrderSummary)

  return !(
    Boolean(lastOrderOffer) &&
    Boolean(upcomingOrderOffer) &&
    upcomingOrderOffer?.months === lastOrderOffer?.months
  )
}

export const longTermDiscountsService = {
  findDiscountOffer,
  isOfferActive,
  applyOfferToOrderLineItems,
  resetDiscounts,
  canRemoveOffer,
  canCancel,
}
