import { Icon } from "@trueskin-web/components"

type IconListItem = {
  icon: string
  text: string
}

export const IconList = ({ items }: { items: IconListItem[] }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        columnGap: 5,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          sx={{
            mb: 4,
            textAlign: "left",
            fontSize: 0,
            display: "flex",
            alignItems: "flex-start",
            alignContent: "space-around",
            columnGap: 2,
          }}
        >
          <Icon
            icon={item.icon}
            sx={{ display: "block", mx: "auto", color: "#75756C" }}
          />
          <div sx={{ color: "#75756C" }}>{item.text}</div>
        </div>
      ))}
    </div>
  )
}
