import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"

import { FieldError } from "@trueskin-web/components"
import { hosts } from "@trueskin-web/core"
import { useTranslation } from "@trueskin-web/translations"

const objectToBase64 = (obj: Record<string, string | number | boolean>) => {
  return btoa(JSON.stringify(obj))
}

function initAppleSignIn(authType: "login" | "register") {
  let redirectURI = hosts.v2ApiUrl + "/vortex/appleid/return"

  // if it doesn't have origin, append current origin
  if (!redirectURI.includes("http")) {
    redirectURI = new URL(redirectURI, window.location.origin).href
  }

  AppleID.auth.init({
    clientId: "de.formelskin.de.sign-in-service",
    scope: "email",
    redirectURI,
    state: objectToBase64({ authType, url: window.location.href }),
    nonce:
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15),
    usePopup: false,
  })
}

export const AppleAuthButton = ({
  authType = "login",
  disabled = false,
  onSubmitDisabled = () => {
    // no-op
  },
}: {
  authType?: "login" | "register"
  disabled?: boolean
  onSubmitDisabled?: () => void
} = {}) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const errorCode = searchParams.get("error")

  const submitHandler = () => {
    initAppleSignIn(authType)
    if (disabled) {
      return onSubmitDisabled()
    }
    AppleID.auth.signIn()
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        ></script>
      </Helmet>
      {/* TODO: The onClick should be on the button itself, but then it won't trigger the terms validation */}
      <div id="appleid-auth" onClick={submitHandler}>
        <div
          sx={{
            width: "100%",
            height: "44px",
            minWidth: "130px",
            maxWidth: "375px",
            border: "1px solid #000000",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            opacity: disabled ? "0.9" : "1",
            margin: "0 auto",
            cursor: "pointer",
            pointerEvents: disabled ? "none" : "all",
          }}
          role="button"
          aria-label="Weiter mit Apple"
        >
          <div sx={{ marginRight: "7px" }}>
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
            >
              <path
                d="M7.31054 4.48641C8.0927 4.48641 9.07315 3.95762 9.65701 3.25258C10.1858 2.61363 10.5714 1.72131 10.5714 0.82899C10.5714 0.707811 10.5604 0.586631 10.5383 0.487484C9.66803 0.520533 8.62148 1.07135 7.99355 1.80944C7.49781 2.37128 7.04614 3.25258 7.04614 4.15592C7.04614 4.28811 7.06818 4.42031 7.07919 4.46438C7.13427 4.47539 7.22241 4.48641 7.31054 4.48641ZM4.55646 17.8162C5.62504 17.8162 6.09874 17.1001 7.43172 17.1001C8.78672 17.1001 9.08416 17.7941 10.2739 17.7941C11.4417 17.7941 12.2238 16.7145 12.9619 15.657C13.7881 14.4452 14.1296 13.2554 14.1517 13.2003C14.0746 13.1783 11.8382 12.2639 11.8382 9.69713C11.8382 7.47183 13.6009 6.46935 13.7 6.39223C12.5323 4.71775 10.7586 4.67369 10.2739 4.67369C8.96298 4.67369 7.8944 5.46686 7.22241 5.46686C6.49533 5.46686 5.53691 4.71775 4.40223 4.71775C2.24303 4.71775 0.0507812 6.5024 0.0507812 9.87339C0.0507812 11.9665 0.865989 14.1808 1.86847 15.6129C2.72775 16.8247 3.47686 17.8162 4.55646 17.8162Z"
                fill="#000000"
              ></path>
            </svg>
          </div>

          <span
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              letterSpacing: "0.5px",
            }}
          >
            Mit Apple fortfahren
          </span>
        </div>
      </div>
      {errorCode && (
        <div sx={{ width: "100%", textAlign: "center", mt: 2 }}>
          <FieldError>{t(`SocialSignIn.errors.${errorCode}`)}</FieldError>
        </div>
      )}
    </>
  )
}
