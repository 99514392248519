import { ReactNode, createContext, useContext } from "react"

import { Card } from "@trueskin-web/components"

import { OrderSummary } from "../../account/subscription/order.utils"

import {
  OrderPriceDetails,
  computeOrderPriceDetails,
} from "./utils/compute-order-price-details"

type TOrderCardProps = {
  order: OrderSummary
  invoices?: any[]
  products?: any[]
  overrideInstalmentsCount?: number
  subscriptionRenewalDate?: string | null
  className?: string
  children: ReactNode
}

type TOrderCardContextProps = Pick<
  TOrderCardProps,
  "order" | "invoices" | "products"
> & {
  orderPrices: OrderPriceDetails
}

const OrderCardContext = createContext<TOrderCardContextProps | null>(null)

const useOrderCard = () => {
  const context = useContext(OrderCardContext)

  if (!context) {
    throw new Error(
      "useOrderCard must be used within a <OrderPriceBreakdownCard />"
    )
  }

  return context
}

// TODO @fox: maybe we should rename this to OrderCard? since it support multiple subcomponents which allow advanced composition
//  also maybe move it into sub-folder
const OrderPriceBreakdownCard = ({
  order,
  overrideInstalmentsCount,
  subscriptionRenewalDate,
  className,
  children,
}: TOrderCardProps) => {
  const orderPrices: OrderPriceDetails = computeOrderPriceDetails(
    order,
    overrideInstalmentsCount,
    subscriptionRenewalDate
  )

  return (
    <OrderCardContext.Provider value={{ order, orderPrices }}>
      <Card
        sx={{
          border: "1px solid lightGray",
          px: 0, // Reset horizontal padding to 0 since we're missing an inset component. This is a temporary workaround to correctly apply dividers which span the entire card width.
        }}
        className={className}
      >
        {children}
      </Card>
    </OrderCardContext.Provider>
  )
}
OrderPriceBreakdownCard.displayName = "OrderPriceBreakdownCard"

const OrderPriceCardContainer = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <Card
      sx={{
        border: "1px solid lightGray",
        px: 0, // Reset horizontal padding to 0 since we're missing an inset component. This is a temporary workaround to correctly apply dividers which span the entire card width.
      }}
      className={className}
    >
      {children}
    </Card>
  )
}

const OrderPriceBreakdownRoot = ({
  order,
  invoices,
  products,
  overrideInstalmentsCount,
  subscriptionRenewalDate,
  children,
}: TOrderCardProps) => {
  const orderPrices: OrderPriceDetails = computeOrderPriceDetails(
    order,
    overrideInstalmentsCount,
    subscriptionRenewalDate
  )

  return (
    <OrderCardContext.Provider
      value={{ order, invoices, products, orderPrices }}
    >
      {children}
    </OrderCardContext.Provider>
  )
}

export {
  OrderPriceBreakdownCard,
  OrderPriceCardContainer,
  OrderPriceBreakdownRoot,
  useOrderCard,
}
