/* eslint-disable react/display-name */
import { forwardRef } from "react"

const Tappable = forwardRef(({ onClick, ...props }, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    sx={{
      display: "inline-flex",
      appearance: "none",
      mx: 0,
      p: 0,
      userSelect: "none",
      textAlign: "left",
      verticalAlign: "middle",
      textDecoration: "none",
      lineHeight: "1.2",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "text",
      backgroundColor: "transparent",
      border: 0,
      cursor: !props.disabled && onClick && "pointer",
      opacity: props.disabled && "0.3",
    }}
    {...props}
  />
))

export default Tappable
