import StarRating, { StarRatingIcon } from "./star-rating"

const TrustpilotRating = ({ className, rating, compact = false }) => (
  <div
    className={className}
    sx={{
      display: "inline-grid",
      gridTemplateColumns: compact
        ? "auto"
        : ["repeat(2, auto)", "repeat(3, auto)"],
      gridGap: 2,
      alignItems: "center",
      justifyItems: "center",
      fontSize: 12,
      fontWeight: "bold",
    }}
  >
    <span
      sx={{
        display: compact ? null : ["none", "inline-grid"],
      }}
    >
      Hervorragend
    </span>
    <StarRating
      rating={rating}
      size="sm"
      sx={{
        color: "white",
        svg: {
          backgroundColor: "trustpilotGreen",
          "&:not(:last-of-type)": {
            mr: "2px",
          },
        },
      }}
    />
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <StarRatingIcon
        percent="100"
        color="trustpilotGreen"
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
      <span
        sx={{
          mt: "1px",
          ml: "1px",
        }}
      >
        Trustpilot
      </span>
    </div>
  </div>
)

export default TrustpilotRating
