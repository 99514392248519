const ButtonGroup = ({ items, className }) => (
  <div
    sx={{
      p: 1,
      display: "inline-flex",
      backgroundColor: "lightGray",
      borderRadius: 8,
    }}
    className={className}
  >
    {items.map(({ label, active, onClick }, index) => (
      <button
        key={index}
        sx={{
          flex: 1,
          py: 1,
          px: [null, 6],
          fontSize: "100%",
          fontWeight: active ? "bold" : "normal",
          border: "none",
          borderRadius: 4,
          whiteSpace: "nowrap",
          bg: active ? "background" : "transparent",
          boxShadow: active ? "0px 0px 4px rgba(0, 0, 0, 0.21);" : "none",
          cursor: active ? "default" : "pointer",
          pointerEvents: active ? "none" : "default",
        }}
        onClick={onClick}
      >
        {label}
      </button>
    ))}
  </div>
)

export default ButtonGroup
