import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { useEffect } from "react"

import * as storageService from "@trueskin-web/services/src/storage"

const storageKeys = storageService.getStorageKeys()
const param = "initial_q_id"
const defaultLsKey = storageKeys.VWO_INITIAL_QUESTIONNARE_ID

const QuestionnaireIdUrl = ({ lsKey = defaultLsKey }) => {
  const { search, pathname } = useLocation()
  const searchParams = new URLSearchParams(search)
  const urlInitialQId = searchParams.get(param)

  searchParams.delete(param)

  const queryString = `${searchParams}` ? `?${searchParams}` : ""

  if (urlInitialQId) {
    storageService.setItem({ id: lsKey, data: urlInitialQId })
  }

  useEffect(() => {
    if (urlInitialQId) {
      navigate(`${pathname}${queryString}`, {
        replace: true,
      })
    }
  }, [urlInitialQId, pathname, queryString])

  return null
}

export default QuestionnaireIdUrl
