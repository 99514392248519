import Nps from "."
import { useLocation } from "@reach/router"
import { useState } from "react"

import { amplitudeClient, useQueryClient } from "@trueskin-web/apis"
import { Dialog } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { npsService, portalService } from "@trueskin-web/services"
import { useTranslation } from "@trueskin-web/translations"

import { useNpsSettings } from "../../../hooks"

const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile"
  }
  return "desktop"
}

const NpsDialog = () => {
  const {
    user: { showNps, subscription },
  } = useAuth()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const [dontShowNps, setDontShowNps] = useState(npsService.isNpsDismissed())

  const { status: npsSettingsStatus, data: npsSettings } = useNpsSettings()

  const onDismiss = () => {
    npsService.dismissNps()
    queryClient.refetchQueries("currentUser")
    setDontShowNps(true)

    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.NPS_OVERLAY_CLOSED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: pathname,
      }
    )
  }

  if (
    npsSettingsStatus !== "success" ||
    npsSettings.active === false ||
    showNps === false ||
    dontShowNps ||
    portalService.isSubscriptionDisabled(subscription)
  ) {
    return null
  }

  return (
    <Dialog
      data-testid="web-portal-nps-dialog"
      aria-label={t("NpsDialog.ariaLabel")}
      onDismiss={onDismiss}
      noDismissIcon
      noMobileFullscreen
      sx={{
        margin: "5vh auto !important",
        height: ["var(--nps-dialog-height)", null],
        maxHeight: [null, "var(--nps-dialog-height)"],
      }}
    >
      <Nps source={`overlay-${getDeviceType()}`} onDismiss={onDismiss} />
    </Dialog>
  )
}
export default NpsDialog
