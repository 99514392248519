import { Icon } from "@trueskin-web/components"

type IconListItem = {
  icon: string
  text: string
}

export const HorizontalIconList = ({ items }: { items: IconListItem[] }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          sx={{
            mb: 4,
            textAlign: "left",
            fontSize: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            columnGap: 2,
          }}
        >
          <Icon icon={item.icon} sx={{ display: "block", mx: "auto", mb: 3 }} />
          <div sx={{ textAlign: "center" }}>{item.text}</div>
        </div>
      ))}
    </div>
  )
}
