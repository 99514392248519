import { Container, SegmentedStepper } from "@trueskin-web/components"

const Steppers = () => {
  return (
    <Container
      sx={{ p: 20, display: "flex", flexDirection: "column", gap: 40 }}
    >
      <div sx={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <SegmentedStepper
          segments={13}
          steps={["One", "Two", "Three"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={18}
          height={6}
        />
        <SegmentedStepper
          segments={13}
          activeStep={1}
          steps={["One", "Two", "Three"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={18}
          height={6}
        />
        <SegmentedStepper
          segments={13}
          activeStep={2}
          steps={["One", "Two", "Three"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={18}
          height={6}
        />
        <SegmentedStepper
          segments={13}
          activeStep={3}
          steps={["One", "Two", "Three"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={18}
          height={6}
        />
      </div>
      <div sx={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <SegmentedStepper
          segments={20}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={1}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={2}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={3}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={4}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={5}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"lightGray"}
          fullFilledColor={"text"}
          width={15}
          height={5}
        />
      </div>
      <div sx={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <SegmentedStepper
          segments={20}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          disabledIconColor={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={1}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={2}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={3}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={4}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
        <SegmentedStepper
          segments={20}
          activeStep={5}
          steps={["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]}
          color={"goldDark"}
          fullFilledColor={"trustpilotGreen"}
          width={15}
          height={5}
        />
      </div>
    </Container>
  )
}

export default Steppers
