import { TOrdersHistory } from "../../../hooks"

import { OrderPriceBreakdownHistoryCard } from "./order-price-breakdown-history-card"

type TOrderPriceBreakdownHistoryProps = {
  ordersHistory: TOrdersHistory
  currentV2OrderId?: string | null
  className?: string
}

export const OrderPriceBreakdownHistoryContainer = ({
  ordersHistory,
  currentV2OrderId,
  className,
}: TOrderPriceBreakdownHistoryProps) => {
  return (
    <div
      sx={{ display: "flex", flexDirection: "column", gap: 5, px: [4, 0] }}
      className={className}
    >
      {ordersHistory.map((orderHistory) => (
        <OrderPriceBreakdownHistoryCard
          key={orderHistory.orderId}
          orderHistory={orderHistory}
          currentV2OrderId={currentV2OrderId}
        />
      ))}
    </div>
  )
}
