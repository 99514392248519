import { DialogContent, DialogOverlay } from "@reach/dialog"
import "@reach/dialog/styles.css"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import { useIsFetching } from "@trueskin-web/apis"
import { sizes } from "@trueskin-web/components/src/icon"
import { useIsScreenSizeSmall } from "@trueskin-web/theme"

// import "../styles/reach-dialog.css"
import Section from "./section"

interface CustomRef {
  onDismiss: () => void
  scrollToTop: () => void
}
export interface DialogProps extends Props {
  isOpen?: boolean
  initOnApiCooldown?: boolean
  noMobileFullscreen?: boolean
  stickTo?: "bottom"
  children?: React.ReactNode
}

interface Props {
  onDismiss?: () => void
  className?: string
  children?: React.ReactNode
  title?: string | React.ReactNode
  description?: React.ReactNode
  onBack?: () => void
  backLabel?: string
  primaryAction?: () => void
  primaryActionLabel?: string
  isPrimaryActionLoading?: boolean
  isPrimaryActionDisabled?: boolean
  secondaryAction?: () => void
  secondaryActionLabel?: string
  isSecondaryActionLoading?: boolean
  isSecondaryActionDisabled?: boolean
  isSecondaryActionStylePrimary?: boolean
  reverseActions?: boolean
  rounded?: boolean
  actionError?: string
  footnote?: string
  noDismissIcon?: boolean
  noBackdropDismiss?: boolean
  noMobileFullscreen?: boolean
  stickTo?: "bottom"
  blur?: number
  dismissIconSize?: keyof typeof sizes
  dataTestId?: string
}

const stickToStyleOptions = {
  bottom: {
    alignItems: ["baseline", "center"],
    flexWrap: "wrap",
    marginBottom: [0, "auto"],
    marginTop: "auto",
    "&[data-reach-dialog-content]": {
      maxWidth: ["100%", "549px"],
    },
  },
}

const BasicDialog = forwardRef<CustomRef, Props>(function BasicDialog(
  {
    className,
    children,
    title,
    description,
    onBack,
    backLabel,
    onDismiss,
    primaryAction,
    primaryActionLabel,
    isPrimaryActionLoading,
    isPrimaryActionDisabled,
    secondaryAction,
    secondaryActionLabel,
    isSecondaryActionLoading,
    isSecondaryActionDisabled,
    isSecondaryActionStylePrimary,
    reverseActions,
    rounded,
    actionError,
    footnote,
    noDismissIcon,
    noBackdropDismiss,
    noMobileFullscreen,
    stickTo,
    blur = 0,
    dismissIconSize,
    dataTestId = "",
    ...props
  },
  ref
) {
  const dialogRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    onDismiss,
    scrollToTop: () => {
      if (dialogRef.current) {
        dialogRef.current.firstElementChild.scrollTo({ top: 0 })
      }
    },
  }))

  const isMobile = useIsScreenSizeSmall()
  const isMobileFullscreen = isMobile && !noMobileFullscreen
  const stickToStyles = stickToStyleOptions[stickTo] ?? {}

  return (
    <DialogOverlay
      ref={dialogRef}
      dangerouslyBypassFocusLock
      onDismiss={noBackdropDismiss ? undefined : onDismiss}
      sx={{
        background: isMobileFullscreen ? "none" : null,
        backdropFilter: `blur(${blur}px)`,
        ...stickToStyles,
      }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 auto",
      }}
      data-testid={dataTestId}
      {...props}
    >
      <DialogContent
        className={className}
        sx={{
          alignSelf: "start",
          height: isMobileFullscreen ? "100vh!important" : null,
          my: isMobileFullscreen ? "0px!important" : null,
        }}
      >
        <Section
          title={title}
          description={description}
          onBack={onBack}
          backLabel={backLabel}
          onDismiss={noDismissIcon ? null : onDismiss}
          content={children}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          isPrimaryActionLoading={isPrimaryActionLoading}
          isPrimaryActionDisabled={isPrimaryActionDisabled}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          isSecondaryActionLoading={isSecondaryActionLoading}
          isSecondaryActionDisabled={isSecondaryActionDisabled}
          isSecondaryActionStylePrimary={isSecondaryActionStylePrimary}
          reverseActions={reverseActions}
          rounded={rounded}
          actionError={actionError}
          footnote={footnote}
          dismissIconSize={dismissIconSize}
        />
      </DialogContent>
    </DialogOverlay>
  )
})

const Dialog = forwardRef<CustomRef, DialogProps>(function Dialog(
  { isOpen, initOnApiCooldown, ...props },
  ref
) {
  const isFetching = useIsFetching() !== 0
  const [isInited, setIsInited] = useState(!initOnApiCooldown)

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isInited) {
        return
      }

      if (isFetching) {
        return
      }

      setIsInited(true)
    }, 0) // react query warmup

    return () => clearTimeout(timerId)
  }, [isInited, isFetching])

  if (isOpen === false) {
    return null
  }

  if (!isInited) {
    return null
  }

  return <BasicDialog ref={ref} {...props} />
})

export default Dialog
