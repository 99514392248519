import { getLocaleCookie, setLocaleCookie } from "@trueskin-web/cookies"

const localeCurrencies = {
  "de-DE": {
    "de-DE": "EUR",
    "de-CH": "CHF",
  },
  // @ts-expect-error process is not defined
}[process.env.GATSBY_I18N_LOCALE] as Record<"de-DE" | "de-CH", "EUR" | "CHF">

const countryDisplayNames = {
  "de-DE": {
    DE: "Deutschland",
    CH: "Schweiz",
  },
  "de-CH": {
    DE: "Deutschland",
    CH: "Schweiz",
  },
}

const locales = Object.keys(localeCurrencies) as ("de-DE" | "de-CH")[]

const currencyLocales = Object.entries(localeCurrencies).reduce(
  (all, [locale, currency]) => ({
    ...all,
    [currency]: locale,
  }),
  {}
)

const countryLocales = Object.keys(localeCurrencies).reduce((all, locale) => {
  const country = locale.split("-")[1]

  return {
    ...all,
    [country]: locale,
  }
}, {})

const i18nCountryDisplayName = ({
  locale,
}: {
  locale?: string
} = {}): string | undefined => {
  const localeName = i18nLocale({ locale })
  const country = i18nCountry({ locale })

  if (!localeName) {
    return
  }
  // @ts-expect-error any string
  return countryDisplayNames[localeName][country] as string | undefined
}

const i18nLocale = ({
  locale,
  currency,
  country,
}: {
  locale?: string
  currency?: string
  country?: string
} = {}): "de-DE" | "de-CH" | undefined => {
  if (locale) {
    if (locales.find((iLocale) => iLocale === locale)) {
      return locale as "de-DE" | "de-CH"
    }
  }

  if (currency) {
    // @ts-expect-error country can be any string
    const currencyLocale = currencyLocales[currency]

    if (locales.find((iLocale) => iLocale === currencyLocale)) {
      return currencyLocale as "de-DE" | "de-CH"
    }
  }

  if (country) {
    // @ts-expect-error country can be any string
    const countryLocale = countryLocales[country]

    if (locales.find((iLocale) => iLocale === countryLocale)) {
      return countryLocale as "de-DE" | "de-CH"
    }
  }

  if (typeof window !== "undefined") {
    const cookieLocale = getLocaleCookie()

    if (locales.find((iLocale) => iLocale === cookieLocale)) {
      return cookieLocale as "de-DE" | "de-CH"
    } else {
      setLocaleCookie(process.env.GATSBY_I18N_LOCALE)
    }
  }

  // @ts-expect-error process is not defined
  return process.env.GATSBY_I18N_LOCALE
}
type LocalProps = {
  locale?: string | undefined
  currency?: string | undefined
  country?: string | undefined
}
const i18nLanguage = (props?: LocalProps) =>
  (i18nLocale(props) || "").split("-")[0]

const i18nCountry = (props: LocalProps) =>
  (i18nLocale(props) || "").split("-")[1]

const i18nCurrency = (props: LocalProps) =>
  // @ts-expect-error any string
  localeCurrencies[i18nLocale(props) || ""] as "EUR" | "CHF" | undefined

const localeCountry = (locale: string) => {
  const [lang, code] = locale.split("-")

  return {
    lang,
    code,
    locale,
    label: i18nCountryDisplayName({ locale }),
    currency: i18nCurrency({ locale }),
  }
}

const countries = locales.map(localeCountry)

const getCountries = () => {
  // @ts-expect-error process is not defined
  const [gatsby_i18n_lang] = process.env.GATSBY_I18N_LOCALE.split("-")

  return countries.filter(({ lang }) => lang === gatsby_i18n_lang)
}

const getCountry = (isCountryCode = true) => {
  const locale = getLocaleCookie() || process.env.GATSBY_I18N_LOCALE

  const country = countries.find(
    ({ locale: countryLocale }) => countryLocale === locale
  )

  if (isCountryCode) {
    const [, countryCode] = (locale || "").split("-")

    return countryCode
  }

  return country
}

const saveCountry = (countryCode: string) => {
  const country = countries.find(({ code }) => code === countryCode)

  if (!country) {
    return
  }

  setLocaleCookie(country.locale)
}

export {
  locales,
  i18nLocale,
  i18nLanguage,
  i18nCurrency,
  i18nCountry,
  i18nCountryDisplayName,
  getCountries,
  getCountry,
  saveCountry,
}
