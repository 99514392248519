import differenceInDays from "date-fns/differenceInDays"
import { useCallback, useMemo, useState } from "react"

import { Dialog } from "@trueskin-web/components"
import { useAuth, useSubscriptionContext } from "@trueskin-web/context"
import { renewalDialogService } from "@trueskin-web/services"

import useCurrentSubscriptionSummary from "../../../app/hooks/use-current-subscription-summary"

import { ChurnPreventionPauseEntryScreen } from "./churn-prevention-pause-entry-screen"
import { ChurnPreventionPauseRenewalScreen } from "./churn-prevention-pause-renewal-screen"
import { ChurnPreventionPauseSaveNewRenewalScreen } from "./churn-prevention-pause-save-new-renewal-screen"
import { EntryScreenType } from "./types"

type RenewalDateValues = {
  renewalDate: Date | null
  renewalReason: string | null
}

export const ChurnPreventionPauseDialog = ({
  entryScreen: entryScreenProp,
  onDismiss,
  onBack,
  goToCancellation,
}: {
  entryScreen?: EntryScreenType
  onDismiss: () => void
  onBack: () => void
  goToCancellation?: () => void
}) => {
  const subscriptionContext = useSubscriptionContext()
  const entryScreen = entryScreenProp || subscriptionContext.entryScreen

  const {
    user: { subscription },
  } = useAuth()

  const { data: subscriptionSummary } = useCurrentSubscriptionSummary()

  const [currentStep, setCurrentStep] = useState(0)
  const [renewalValues, setRenewalValues] = useState<RenewalDateValues>({
    renewalDate: null,
    renewalReason: null,
  })

  const { renewalDate } = renewalValues

  const oldRenewalDate = useMemo(() => {
    return renewalDialogService.getRenewalDate(
      subscription,
      subscriptionSummary
    )
  }, [subscription, subscriptionSummary])

  const isOrderInDelivery = useCallback(
    (date = renewalDate) =>
      differenceInDays(new Date(date), new Date(subscription?.createdAt)) < 10,
    [subscription, renewalDate]
  )

  const goToNextScreen = useCallback(
    () => setCurrentStep((step) => step + 1),
    []
  )

  const goToPreviousScreen = useCallback(() => {
    if (currentStep === 0) {
      if (onBack) {
        return onBack()
      } else {
        onDismiss()
      }
    } else if (entryScreen && currentStep === 1) {
      setRenewalValues({ renewalDate: null, renewalReason: null })
      setCurrentStep(0)
    } else {
      setCurrentStep((step) => step - 1)
    }
  }, [currentStep, entryScreen, onBack, onDismiss])

  const storeNewRenewalData = useCallback(
    (values: RenewalDateValues) => {
      const { renewalDate, renewalReason } = values

      setRenewalValues({ renewalDate, renewalReason })

      goToNextScreen()
    },
    [renewalValues, goToNextScreen]
  )

  const screens = useMemo(() => {
    const components = [
      <ChurnPreventionPauseRenewalScreen
        key="renewal-setup"
        currentRenewalDate={oldRenewalDate}
        newRenewalDate={renewalDate}
        onContinue={storeNewRenewalData}
        onBack={goToPreviousScreen}
        onDismiss={onDismiss}
      />,
      <ChurnPreventionPauseSaveNewRenewalScreen
        key="renewal-save"
        currentRenewalDate={oldRenewalDate}
        renewalDate={renewalDate}
        renewalReason={renewalValues.renewalReason}
        onBack={goToPreviousScreen}
        onDismiss={onDismiss}
      />,
    ]

    if (entryScreen) {
      components.unshift(
        <ChurnPreventionPauseEntryScreen
          key="entry"
          entryScreen={entryScreen}
          currentRenewalDate={oldRenewalDate}
          onContinue={goToNextScreen}
          onDismiss={onDismiss}
          onBack={
            [
              EntryScreenType.PauseTreatmentFromCancellation,
              EntryScreenType.TakeABreak,
              EntryScreenType.EarlySet,
            ].includes(entryScreen)
              ? goToPreviousScreen
              : undefined
          }
          goToCancellation={goToCancellation}
        />
      )
    }

    return components
  }, [
    onDismiss,
    isOrderInDelivery,
    renewalDate,
    storeNewRenewalData,
    entryScreen,
  ])

  return (
    <Dialog
      data-testid="web-portal-renewal-dialog"
      aria-label="Renewal Dialog"
      onDismiss={onDismiss}
      noDismissIcon
      sx={{
        "&[data-reach-dialog-content]": {
          display: "flex",
          flexDirection: "column",
          "& > section": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
          },
        },
        "&[data-reach-dialog-content] > section > .section-content": {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          mt: "12px !important",
        },
        ".section-header": {
          gridRowGap: 2,
          gridColumnGap: 0,
        },
      }}
    >
      {screens[currentStep]}
    </Dialog>
  )
}
