export const RoundBordered = ({
  children,
  margin,
  marginBottom,
  marginTop,
  radius = 8,
  fullWidth,
  padding = "16px  16px",
}: {
  children: React.ReactNode
  margin?: string
  padding?: string
  radius?: number
  fullWidth?: boolean
  marginTop?: string
  marginBottom?: string
}) => (
  <div
    sx={{
      padding,
      borderRadius: radius,
      borderColor: "#D8D8C8",
      border: "1px solid #D8D8C8",
      backgroundColor: "white",
      margin,
      marginBottom,
      marginTop,
      gap: 4,
      display: "flex",
      width: fullWidth ? "100%" : undefined,
      flexDirection: "column",
    }}
  >
    {children}
  </div>
)
