import { Router, navigate, useLocation } from "@reach/router"
import { useEffect, useState } from "react"
import { ThemeUIProvider } from "theme-ui"

import { storageService } from "@trueskin-web/services"

import LegacyTheme from "./legacy-theme"
import RebrandedTheme from "./rebranded-theme"

const storageKeys = storageService.getStorageKeys()

const themes = [
  {
    param: "brand_experience",
    value: "legacy",
    questionnaireId:
      process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"
        ? "1026-conversational-v2"
        : "dev-1026-conversational-v2",
    styles: LegacyTheme,
  },
  {
    param: "brand_experience",
    value: "new",
    styles: RebrandedTheme,
  },
]

const defaultTheme = themes[1]

const Theme = ({ element }) => {
  const { pathname, search } = useLocation()

  const [isLoading, setIsLoading] = useState(true)

  const [theme, setTheme] = useState()

  useEffect(() => {
    const searchParams = new URLSearchParams(search)

    let overrideTheme = themes.find(({ param, value }) => {
      if (searchParams.has(param, value)) {
        searchParams.delete(param)

        return true
      }

      return false
    })

    if (!overrideTheme) {
      overrideTheme =
        themes.find(
          ({ styles: { name } }) =>
            name ===
            storageService.getItem({ id: storageKeys.VWO_THEME_VARIANT })
        ) || defaultTheme
    }

    if (overrideTheme.styles.name === theme?.styles?.name) {
      return
    }

    setTheme(overrideTheme)

    const queryString = `${searchParams}` ? `?${searchParams}` : ""

    navigate(`${pathname}${queryString}`, {
      replace: true,
    })
  }, [search])

  useEffect(() => {
    if (!theme) {
      return
    }

    if (theme.styles.name !== defaultTheme.styles.name) {
      storageService.setItem({
        id: storageKeys.VWO_THEME_VARIANT,
        data: theme.styles.name,
      })
    } else {
      storageService.removeItem({ id: storageKeys.VWO_THEME_VARIANT })
    }

    if (theme.questionnaireId) {
      storageService.setItem({
        id: storageKeys.VWO_INITIAL_QUESTIONNARE_ID,
        data: theme.questionnaireId,
      })
    }

    setIsLoading(false)
  }, [theme])

  if (isLoading) {
    return null
  }

  return <ThemeUIProvider theme={theme.styles}>{element}</ThemeUIProvider>
}

const ThemeProvider = ({ children }) => (
  <Router>
    <Theme default element={children} />
  </Router>
)

export default ThemeProvider
