const CardHint = ({ className, description, icon }) => (
  <div
    className={className}
    sx={{
      display: "flex",
      alignItems: "flex-start",
      gap: 2,
      color: "textDark",
    }}
  >
    {icon}

    <div
      sx={{
        fontSize: 12,
      }}
    >
      {description}
    </div>
  </div>
)

export default CardHint
