import React, { useEffect, useMemo, useState } from "react"

import { Icon } from "@trueskin-web/components"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { dotCircleOpacityIcon } from "@trueskin-web/theme/icons/dotCircleOpacityIcon"

import Step from "./step"
import UnfinishedStepLine from "./unfinished-step-line"

export interface SegmentedStepperProps {
  segments: number
  steps: string[]
  activeStep?: number
  color: string
  fullFilledColor: string
  disabledIconColor?: string
  width: number
  height: number
}

export const SegmentedStepper: React.FC<SegmentedStepperProps> = ({
  segments,
  steps,
  activeStep = 0,
  color,
  width,
  height,
  fullFilledColor,
  disabledIconColor = "grey",
}) => {
  const [unfinishedSegments, setUnfinishedSegments] = useState(segments)
  const stepperWidth = useMemo(() => {
    const gapBetweenSegments = 6
    const iconWidthWithMargin = 18
    const totalGapsBetweenSegments = gapBetweenSegments * (segments - 1)
    const segmentsWidthWithoutExternal = (segments - 2) * width
    const externalSegmentsWidth = 2 * (width / 1.5)

    return (
      totalGapsBetweenSegments +
      segmentsWidthWithoutExternal +
      externalSegmentsWidth +
      iconWidthWithMargin
    )
  }, [segments, width])

  useEffect(() => {
    if (activeStep) {
      if (activeStep >= steps.length) {
        setUnfinishedSegments(0)
        return
      }
      if (activeStep === 1) {
        setUnfinishedSegments(Math.floor(segments * 0.95))
        return
      }

      setUnfinishedSegments(
        Math.floor(segments * (1 - (activeStep - 1) / (steps.length - 1)))
      )
      return
    }
    setUnfinishedSegments(segments)
  }, [activeStep])

  return (
    <div sx={{ width: stepperWidth }}>
      <div
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          mb: 3,
        }}
      >
        {activeStep ? (
          <Step
            width={width}
            height={height}
            isFullWidth={true}
            backgroundColor={fullFilledColor}
            sx={{ flex: 1 }}
          />
        ) : null}
        <Icon
          icon={
            unfinishedSegments ? dotCircleOpacityIcon : checkCircleFilledIcon
          }
          size={"xsmsm"}
          color={activeStep ? fullFilledColor : disabledIconColor}
        />

        {unfinishedSegments ? (
          <UnfinishedStepLine
            color={color}
            segments={unfinishedSegments}
            width={width}
            height={height}
          />
        ) : null}
      </div>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          gap: 4,
          fontSize: 12,
          lineHeight: 1.33,
          color: "textDark",
          [`& > div:nth-of-type(${activeStep})`]: {
            color: "text",
          },
          "& > div:first-of-type": {
            textAlign: "start",
          },
          "& > div:last-of-type": {
            textAlign: "end",
          },
        }}
      >
        {steps.map((item, index) => (
          <div key={`${item} + ${index}`} sx={{ flex: 1 }}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
