import { Fragment } from "react"

export const tiktokIcon = {
  path: (
    <Fragment>
      <rect width="24" height="24" rx="6" fill="currentColor" />
      <path
        d="M14.7157 5H12.1085V15.0811C12.1085 16.2823 11.1058 17.269 9.85787 17.269C8.60997 17.269 7.6072 16.2823 7.6072 15.0811C7.6072 13.9015 8.58769 12.9362 9.79103 12.8933V10.3623C7.13924 10.4052 5 12.4858 5 15.0811C5 17.698 7.18381 19.8 9.88016 19.8C12.5765 19.8 14.7603 17.6765 14.7603 15.0811V9.91187C15.7408 10.5983 16.9441 11.0058 18.2143 11.0273V8.49623C16.2533 8.43189 14.7157 6.88753 14.7157 5Z"
        fill="black"
      />
    </Fragment>
  ),
}
