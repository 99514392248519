import { useLocation } from "@reach/router"
import { ThemeUIStyleObject } from "@theme-ui/css/src/types"
import { type ReactNode, forwardRef } from "react"

import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Button from "./button"
import Icon from "./icon"
import Link from "./link"

export interface NavLinkProps {
  doNotTrack?: boolean
  href: string
  isExpandable?: boolean
  children: ReactNode
  className?: HTMLAnchorElement["className"]
  target?: HTMLAnchorElement["target"]
  sx?: ThemeUIStyleObject
}

const NavLink = forwardRef<HTMLButtonElement, NavLinkProps>(
  (
    {
      doNotTrack,
      href,
      isExpandable,
      target = "_self",
      children,
      className,

      ...props
    },
    ref
  ) => {
    const location = useLocation()

    return (
      <Button
        ref={ref}
        variant="link"
        size="xsm"
        as={doNotTrack ? undefined : Link}
        href={doNotTrack ? undefined : href}
        target={target}
        onClick={
          doNotTrack && !isExpandable
            ? () => {
                Object.assign(document.createElement("a"), {
                  target,
                  href,
                }).click()
              }
            : undefined
        }
        className={
          location.pathname === href ? `active ${className}` : className
        }
        sx={{
          fontSize: 2,
          letterSpacing: "initial",
          lineHeight: "body",
          textDecoration: "none",
          "&[aria-expanded=false] svg": {
            transform: [null, "rotate(90deg)"],
          },
          "&[aria-expanded=true] svg": {
            transform: ["rotate(90deg)", "rotate(-90deg)"],
          },
        }}
        {...props}
      >
        {children}
        {isExpandable ? (
          <Icon
            icon={arrowRightIcon}
            sx={{
              transition: [
                "transform 0.4s cubic-bezier(0, 0.4, 0.2, 1)",
                "none",
              ],
            }}
          />
        ) : null}
      </Button>
    )
  }
)

NavLink.displayName = "NavLink"

export default NavLink
