import { Icon, Tappable } from "@trueskin-web/components"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

export const Action = ({
  onClick,
  icon,
  iconColor,
  title,
  description,
  isDisabled,
  isRounded,
}: {
  onClick?: () => void
  icon?: string
  iconColor?: string
  title?: string
  description?: string
  isDisabled?: boolean
  isRounded?: boolean
}) => (
  <Tappable
    sx={{
      width: "100%",
      p: 4,
      pr: 3,
      mt: 4,
      border: "1px",
      borderColor: "grey",
      opacity: 1,
      borderRadius: isRounded ? "8px" : "0px",
    }}
    onClick={onClick}
    disabled={isDisabled}
  >
    {icon ? (
      <Icon
        icon={icon}
        sx={{
          mr: 2,
        }}
        color={iconColor}
      />
    ) : null}
    <div>
      <div
        sx={{
          lineHeight: "24px",
        }}
      >
        {title}
      </div>
      {description ? (
        <div
          sx={{
            mt: 1,
            fontSize: 1,
            color: "textLight",
          }}
        >
          {description}
        </div>
      ) : null}
    </div>

    {isDisabled ? null : (
      <Icon
        icon={arrowRightIcon}
        sx={{
          ml: "auto",
        }}
      />
    )}
  </Tappable>
)
