import type { UseQueryOptions, UseQueryResult } from "react-query"

import { orderClient, useQuery } from "@trueskin-web/apis"

import { OrderSummary } from "../../app/account/subscription/order.utils"
import { longTermDiscountsService } from "../../experiments"

type TUpcomingOrderSummaryApiResponse = { data: OrderSummary }
type TUpcomingOrderSummaryError = unknown

type TUseUpcomingOrderSummary<
  TData = OrderSummary,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  upcomingOrderSummary: OrderSummary | undefined
  isUpcomingOrderSummaryLoading: boolean
  upcomingOrderSummaryError: unknown
}

export const useUpcomingOrderSummary = ({
  enabled = true,
  staleTime = 60 * 1000, // 1 minute
  ...options
}: UseQueryOptions<
  TUpcomingOrderSummaryApiResponse,
  TUpcomingOrderSummaryError,
  OrderSummary
> = {}): TUseUpcomingOrderSummary => {
  const query = useQuery<
    TUpcomingOrderSummaryApiResponse,
    TUpcomingOrderSummaryError,
    OrderSummary
  >("upcomingOrderSummary", orderClient.getUpcomingSummary, {
    enabled,
    staleTime,
    ...options,
    select: (response: TUpcomingOrderSummaryApiResponse) =>
      longTermDiscountsService.applyOfferToOrderLineItems(response.data),
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    upcomingOrderSummary: data,
    isUpcomingOrderSummaryLoading: isLoading,
    upcomingOrderSummaryError: error,
  }
}
