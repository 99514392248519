import type { UseQueryOptions, UseQueryResult } from "react-query"

import { useQuery, userClient } from "@trueskin-web/apis"

type ReferralDetailsResponse = {
  code: string
  count: number
  currentBalance: {
    amount: number
    currency: string
  }
  refereeBonus: {
    amount: number
    currency: string
  }
  referralClaMessage: string
  referrerBonus: {
    amount: number
    currency: string
  }
}
type ReferralDetailsError = unknown

type TUseReferralDetails<
  TData = ReferralDetailsResponse,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  referralDetails: ReferralDetailsResponse | undefined
  isReferralDetailsLoading: boolean
  referralDetailsError: unknown
}

export const useReferralDetails = ({
  enabled = true,
  staleTime = 5 * 60 * 1000, // 5 minute
  ...options
}: UseQueryOptions<
  ReferralDetailsResponse,
  ReferralDetailsError,
  ReferralDetailsResponse
> = {}): TUseReferralDetails => {
  const query = useQuery<
    ReferralDetailsResponse,
    ReferralDetailsError,
    ReferralDetailsResponse
  >("referralDetails", userClient.getReferralDetails, {
    enabled,
    staleTime,
    ...options,
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    referralDetails: data,
    isReferralDetailsLoading: isLoading,
    referralDetailsError: error,
  }
}
