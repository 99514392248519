import { formatCurrency } from "@trueskin-web/functions"
import { useTranslation } from "@trueskin-web/translations"

import PaymentLogo from "./payment-logo"

const PaymentBanner = ({ size, payment, isLoading }) => {
  const { t } = useTranslation(null, {
    keyPrefix: "Payment.Methods",
  })

  const title = t(`${payment?.type}.title`, "")

  const details = {
    partner_card: payment?.partner_card
      ? payment?.partner_card?.partnerCardNumber
      : "",
    paypal: payment?.paypal?.email,
    card: payment?.card?.lastFourDigits
      ? t(`${payment?.type}.lastFourDigits`, {
          value: payment.card.lastFourDigits,
          defaultValue: "",
        })
      : "",
  }[payment?.type]

  return (
    <div
      data-testid={`web-portal-payment-banner-${payment?.type}`}
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        columnGap: 3,
        border: "1px solid",
        borderColor: "lightGray",
        p: 4,
        alignItems: "center",
      }}
    >
      <PaymentLogo size={size} payment={payment} isLoading={isLoading} />

      <div>{title}</div>

      {details ? (
        <div
          data-testid="web-portal-payment-banner-details"
          sx={{
            gridColumn: 2,
            mt: -1,
            fontSize: 1,
            color: "textLight",
            wordBreak: "break-all",
          }}
        >
          {details}
        </div>
      ) : null}
    </div>
  )
}

export default PaymentBanner
