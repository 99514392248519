import { AB_TESTS } from "@trueskin-web/ab-experiments"
import { Card, Heading } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { formatCurrency } from "@trueskin-web/functions"
import { useVWOExperimentStatus } from "@trueskin-web/services"

import { useLastOrderSummary } from "../../../hooks"
import { useLongTermDiscountsTimeline } from "../hooks"
import { longTermDiscountsService } from "../long-term-discounts.service"

import { LongTermDiscountsTimelineCardItem } from "./long-term-discounts-timeline-card-item"

const LongTermDiscountsPeriodBreakdownCardInternal = ({
  heading,
}: {
  heading?: string
}) => {
  const { user } = useAuth()

  const { isLastOrderSummaryLoading, lastOrderSummary } = useLastOrderSummary()

  if (
    isLastOrderSummaryLoading ||
    !longTermDiscountsService.isOfferActive(lastOrderSummary)
  ) {
    return null
  }

  const { longTermPlan } = useLongTermDiscountsTimeline({
    renewalDate: user?.subscription?.renewalDate as string,
  })

  return (
    <>
      {!!heading && (
        <Heading
          size="xsm"
          sx={{
            lineHeight: 1.33,
            fontWeight: "bold!important",
            marginBottom: "2",
          }}
        >
          {heading}
        </Heading>
      )}

      <Card
        sx={{
          border: "1px solid lightGrey",
          borderRadius: 12,
        }}
      >
        <div
          sx={{
            display: "flex",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "26px",
          }}
        >
          <div sx={{ flex: 1 }}>
            {longTermPlan?.commitment.length}-Monats-Set
          </div>

          <div sx={{ display: "flex", gap: 1 }}>
            <span>
              {formatCurrency(
                (lastOrderSummary?.values?.perInstalment.total as number) / 100,
                lastOrderSummary?.values.currency as string,
                2
              ) + " mtl"}
            </span>
          </div>
        </div>
        <div sx={{ display: "flex", gap: 2, overflowY: "scroll" }}>
          {longTermPlan?.timeline.map((item, index) => (
            <LongTermDiscountsTimelineCardItem key={index} {...item} />
          ))}
        </div>
        <div
          sx={{
            color: "brandPrimary",
            fontSize: 18,
            lineHeight: "26px",
            fontWeight: 700,
          }}
        >
          {longTermPlan?.commitment.monthsToGo
            ? `Du hast noch ${longTermPlan?.commitment.monthsToGo} Monate Laufzeit`
            : "Du bist im letzten Monat der Laufzeit"}
        </div>
        <div
          sx={{
            color: "neutralsDarkGrey",
            fontSize: 14,
            lineHeight: "20px",
            whiteSpace: "normal",
          }}
        >
          {longTermPlan?.commitment.monthsToGo
            ? `Du erhältst weiterhin Deine Skin-Sets und hast vollen Zugriff auf alle
          Funktionen für die restliche Laufzeit des Treue-Pakets. Die
          verbleibenden Zahlungen für die vereinbarten
          ${longTermPlan?.commitment.length} Monate bleiben weiterhin
          erforderlich.`
            : `Nach Ende der Laufzeit wird Dein FORMEL SKIN-Set wie gewohnt alle 2
        Monate geliefert. Die Kosten für die nächsten 2 Monate belaufen sich auf
        44,00 € pro Monat, insgesamt also auf 88,00 €. Falls du andere Rabatte
        hast, werden diese automatisch angewendet.`}
        </div>
      </Card>
    </>
  )
}

export const LongTermDiscountsTimelineCard = ({
  heading,
}: {
  heading?: string
}) => {
  const { isLongTermDiscountsVariantActive } = useVWOExperimentStatus(
    AB_TESTS.LongTermDiscounts
  )

  if (!isLongTermDiscountsVariantActive(1)) {
    return null
  }

  return <LongTermDiscountsPeriodBreakdownCardInternal heading={heading} />
}
