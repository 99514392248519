import { Button } from "@trueskin-web/components"
import { useSubscriptionContext } from "@trueskin-web/context"

import { EntryScreenType } from "./churn-prevention-pause-dialog"

export const ChurnPreventionPostponeDeliveryCta = () => {
  const { openRenewalDateDialog } = useSubscriptionContext()

  return (
    <Button
      data-testid="web-portal-renewal-date-btn"
      variant="outline"
      rounded
      size="md"
      isFullWidth
      color="black"
      sx={{
        borderColor: "neutralsMediumGrey",
      }}
      onClick={() => {
        openRenewalDateDialog(
          EntryScreenType.PauseTreatmentFromNextOrderManagement
        )
      }}
    >
      Behandlung pausieren
    </Button>
  )
}
