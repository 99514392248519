import { Global, css } from "@emotion/react"
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { toast } from "react-toastify"

import { useIsScreenSizeSmall } from "@trueskin-web/theme"
import { chatbotIcon } from "@trueskin-web/theme/icons/chatbotIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { Trans } from "@trueskin-web/translations"

import Button from "./button"
import Icon from "./icon"
import IconButton from "./icon-button"

const chatbotUrl = process.env.GATSBY_CHATBOT_ID
  ? `https://www.chatbase.co/chatbot-iframe/${process.env.GATSBY_CHATBOT_ID}`
  : ""

const loadIframe = (iframe: HTMLIFrameElement, src: string) => {
  return new Promise((resolve, reject) => {
    iframe.src = src

    const handleLoadSuccess = () => {
      resolve(true)
      cleanup()
    }

    const handleLoadError = () => {
      reject(new Error(`Failed to load iframe with URL: ${src}`))
      cleanup()
    }

    const cleanup = () => {
      iframe.removeEventListener("load", handleLoadSuccess)
      iframe.removeEventListener("error", handleLoadError)
    }

    iframe.addEventListener("load", handleLoadSuccess)
    iframe.addEventListener("error", handleLoadError)
  })
}

const ChatbotMessenger = forwardRef((_, ref) => {
  const isMobile = useIsScreenSizeSmall()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useImperativeHandle(ref, () => ({
    loadChatbot() {
      return new Promise((resolve) => {
        if (isLoaded) {
          setIsOpen(true)
          resolve(true)
          return
        }

        toast(() => <Trans i18nKey="ContactSupport.supportUnavailable" />)
      })
    },
  }))

  useEffect(() => {
    const handleIframeLoad = async () => {
      if (iframeRef.current) {
        try {
          await loadIframe(iframeRef.current, chatbotUrl)
          setIsLoaded(true)
        } catch (error) {
          console.error("Iframe failed to load.", error)
        }
      }
    }

    handleIframeLoad()
  }, [])

  if (!chatbotUrl) {
    return null
  }

  return (
    <Fragment>
      {isOpen && isMobile && (
        <Global
          styles={css`
            body {
              // mobile ios hack 🙄
              position: fixed;
              width: 100%;
              overflow-y: hidden;
            }
          `}
        />
      )}
      <div
        sx={{
          position: "fixed",
          height: ["100%", "572px"],
          width: ["100%", "374px"],
          right: [0, 6],
          pt: 2,
          bottom: isOpen ? [0, 2] : ["-100%", "-572px"],
          zIndex: 9,
          background: "white",
          boxShadow: "0px 6px 12px rgba(29, 22, 15, 0.17)",
          opacity: isOpen ? 1 : 0,
          transitionDuration: "0.25s",
          transitionProperty: "opacity, top, bottom, display",
          transitionTimingFunctioncubicBezier: "(0.645, 0.045, 0.355, 1)",
        }}
      >
        <IconButton
          icon={closeIcon}
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            px: 1,
            py: 1,
            zIndex: 10,
          }}
        />
        <iframe
          ref={iframeRef}
          src={chatbotUrl}
          style={{
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
        ></iframe>
      </div>
    </Fragment>
  )
})

const ChatbotButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    id="chatbot-button"
    onClick={onClick}
    sx={{
      width: "55px",
      height: "55px",
      mx: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
    }}
  >
    <Icon
      icon={chatbotIcon}
      sx={{
        width: 48,
        height: 48,
      }}
    />
  </Button>
)

const ChatbotSupport = ({
  mobileNavReposition = false,
}: {
  mobileNavReposition?: boolean
}) => {
  const isMobile = useIsScreenSizeSmall()
  const chatbotRef = useRef<{ loadChatbot: () => Promise<void> }>(null)

  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)
  const [verticalOffset, setVerticalOffset] = useState<string | undefined>()

  const openMessenger = useCallback(async () => {
    if (chatbotRef.current) {
      await chatbotRef.current.loadChatbot()
      setIsButtonVisible(false)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setVerticalOffset(isMobile ? "70px" : "10px")

      if (
        Math.ceil(window.scrollY) >=
        Math.min(
          window.innerHeight / 2,
          document.body.clientHeight - window.innerHeight
        )
      ) {
        setIsButtonVisible(true)
      } else {
        setIsButtonVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [isButtonVisible, isMobile])

  useEffect(() => {
    if (!isMobile || !mobileNavReposition) {
      return
    }

    setVerticalOffset("100px")
  }, [isMobile])

  if (!chatbotUrl) {
    return null
  }

  return (
    <>
      <div>
        <div
          sx={{
            position: "fixed",
            zIndex: 9,
            right: 0,
            bottom: [
              isButtonVisible ? verticalOffset : "-70px",
              isButtonVisible ? 0 : "-70px",
            ],
            m: "10px 0 12px",
            opacity: isButtonVisible ? 1 : 0,
            transitionDuration: "0.25s",
            transitionProperty: "opacity, top, bottom, display",
            transitionTimingFunctioncubicBezier: "(0.645, 0.045, 0.355, 1)",
          }}
        >
          <ChatbotButton onClick={openMessenger} />
        </div>
      </div>

      <ChatbotMessenger ref={chatbotRef} />
    </>
  )
}

ChatbotMessenger.displayName = "ChatbotMessenger"

export { ChatbotMessenger }
export default ChatbotSupport
