import React from "react"

import Step from "./step"

interface UnfinishedStepLineProps {
  segments: number
  color: string
  width: number
  height: number
}

const UnfinishedStepLine: React.FC<UnfinishedStepLineProps> = ({
  width,
  height,
  color,
  segments,
}) => {
  return (
    <div sx={{ display: "flex", gap: "6px" }}>
      {Array.from({ length: segments }).map((_, index) => {
        const isExtremalStep = index === 0 || index === segments - 1
        return (
          <Step
            key={index}
            width={width}
            height={height}
            backgroundColor={color}
            isExtremal={isExtremalStep}
          />
        )
      })}
    </div>
  )
}

export default UnfinishedStepLine
