import { ReactNode } from "react"

import { Icon, Link } from "@trueskin-web/components"

/*
 * TabItem renders an individual tab and applies conditional styling if it is the active tab.
 * Clicking a tab will trigger setActiveTab, updating the activeTab state across the application.
 */
export interface TabItemProps extends Partial<HTMLAnchorElement> {
  icon: ReactNode
  iconSelected?: boolean
  label: string
  notifications?: number
  onClick?: () => void
}

export const TabItem = ({
  icon,
  iconSelected,
  label,
  notifications,
  href,
  style = {},
  onClick,
}: TabItemProps) => (
  <Link
    className="mobile-tab-link"
    activeClassName="active"
    partiallyActive
    onClick={onClick}
    sx={{
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      mt: "-2px",
      pt: 3,
      pb: 3,
      fontSize: 0,
      color: "text",
      textDecoration: "none",
      borderTop: "2px solid transparent",
      "&:hover": {
        color: "primary",
      },
      "& > .filled": {
        display: "none",
      },
      "&.active": {
        color: "primary",
        borderColor: "primary",
        "& > .outlined": {
          display: "none",
        },
        "& > .filled": {
          display: "block",
        },
      },
      ...style,
    }}
    href={href}
  >
    <Icon icon={icon} className="outlined" />
    <Icon icon={iconSelected} className="filled" />
    <div sx={{ mt: 1 }}>{label}</div>
    {notifications > 0 && (
      <div
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: "5px",
          left: "50%",
          width: 20,
          height: 20,
          fontSize: 1,
          borderRadius: "50%",
          border: "1px solid #fff",
          bg: "attention",
          color: "background",
        }}
      >
        {notifications}
      </div>
    )}
  </Link>
)
