import { Routes } from "@trueskin-web/translations"

const homeUrls = {
  default: Routes.App.url,
  bd: Routes.App.Diagnose.Home.url,
}

// TODO @fox: review the updates of this fn and where it's used as it looks weird
export const getHomepageUrl = (isUserLogged) => {
  if (window.location.pathname.includes("diagnose")) {
    return isUserLogged ? homeUrls.bd + "/" : process.env.GATSBY_WEBSITE_URL
  }

  return isUserLogged ? homeUrls.default + "/" : process.env.GATSBY_WEBSITE_URL
}
