import { Fragment } from "react"

export const whatsappIcon = {
  path: (
    <Fragment>
      <path
        d="M15 3C8.373 3 3 8.373 3 15C3 17.2512 3.63234 19.3501 4.71094 21.1504L3.10742 27L9.08203 25.4316C10.8294 26.4251 12.8465 27 15 27C21.627 27 27 21.627 27 15C27 8.373 21.627 3 15 3ZM10.8926 9.40234C11.0876 9.40234 11.2879 9.40116 11.4609 9.41016C11.6749 9.41516 11.9079 9.43083 12.1309 9.92383C12.3959 10.5098 12.9729 11.9799 13.0469 12.1289C13.1209 12.2779 13.1733 12.4534 13.0703 12.6484C12.9723 12.8484 12.9213 12.9695 12.7773 13.1465C12.6283 13.3185 12.4651 13.5321 12.3301 13.6621C12.1811 13.8111 12.0272 13.9745 12.1992 14.2715C12.3712 14.5685 12.9686 15.5421 13.8516 16.3281C14.9866 17.3421 15.9442 17.6537 16.2422 17.8027C16.5402 17.9517 16.7128 17.9285 16.8848 17.7285C17.0618 17.5335 17.6281 16.8644 17.8281 16.5664C18.0231 16.2684 18.2222 16.32 18.4922 16.418C18.7662 16.516 20.2274 17.2358 20.5254 17.3848C20.8234 17.5338 21.0187 17.6075 21.0938 17.7285C21.1707 17.8535 21.1708 18.4486 20.9238 19.1426C20.6768 19.8356 19.4639 20.5057 18.9199 20.5527C18.3709 20.6037 17.8586 20.7995 15.3516 19.8125C12.3276 18.6215 10.4205 15.5242 10.2715 15.3242C10.1225 15.1292 9.06055 13.7139 9.06055 12.2539C9.06055 10.7889 9.82866 10.0714 10.0977 9.77344C10.3717 9.47544 10.6926 9.40234 10.8926 9.40234Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 30 30",
  fill: "none",
}
