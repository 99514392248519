import { StaticImage } from "gatsby-plugin-image"
import { useRef } from "react"

import {
  Button,
  ChatbotMessenger,
  Container,
  Heading,
  Seo,
} from "@trueskin-web/components"
import { Trans, useTranslation } from "@trueskin-web/translations"

import Layout from "../layout"

const ErrorFallback = ({ error }) => {
  const { t } = useTranslation()

  const chatbotRef = useRef(null)

  const handleSupportClick = () => chatbotRef.current.loadChatbot()

  // TODO @julianna: https://trueskin.atlassian.net/browse/AC-892
  // const handleContactSupport = useCallback(() => {
  //   if (!window.zE && !window.loadZendesk) {
  //     toast(() => <Trans i18nKey="ContactSupport.supportUnavailable" />)

  //     return
  //   }

  //   setIsSupportLoading(true)

  //   if (window.zE) {
  //     window.zE("webWidget", "open")
  //   } else {
  //     // if (window.loadZendesk)
  //     window.loadZendesk()
  //   }

  //   conditionalFunction({
  //     condition: () => window.zE?.("webWidget:get", "display") === "answerBot",
  //     success: () => {
  //       setIsSupportLoading(false)
  //     },
  //     interval: 500,
  //   })
  // }, [])

  return (
    <Layout disableNav disableSupportChat>
      <Seo title={t("ErrorFallback.title")} />

      <Container sx={{ maxWidth: 700, textAlign: "center", py: 5 }}>
        <Heading mb={6}>
          <Trans i18nKey="ErrorFallback.heading" />
        </Heading>

        <div
          sx={{
            mb: 6,
          }}
        >
          <Trans i18nKey="ErrorFallback.messageDetails" />:{" "}
          {error.message || error}
        </div>

        <Button sx={{ mb: 6, minWidth: 200 }} onClick={handleSupportClick}>
          <Trans i18nKey="ErrorFallback.action" />
        </Button>
        <StaticImage src="../../images/error.jpg" alt="error" />
      </Container>
      <ChatbotMessenger ref={chatbotRef} />
    </Layout>
  )
}

export default ErrorFallback
