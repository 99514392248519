import { Fragment } from "react"

import { useAuth } from "@trueskin-web/context"
import { helpService } from "@trueskin-web/services"
import { chartLineIcon } from "@trueskin-web/theme/icons/chartLineIcon"
import { fileFilledIcon } from "@trueskin-web/theme/icons/fileFilledIcon"
import { fileIcon } from "@trueskin-web/theme/icons/fileIcon"
import { homeFilledIcon } from "@trueskin-web/theme/icons/homeFilledIcon"
import { homeIcon } from "@trueskin-web/theme/icons/homeIcon"
import { medicalKitFilledIcon } from "@trueskin-web/theme/icons/medicalKitFilledIcon"
import { medicalKitIcon } from "@trueskin-web/theme/icons/medicalKitIcon"
import { messageFilledIcon } from "@trueskin-web/theme/icons/messageFilledIcon"
import { messageIcon } from "@trueskin-web/theme/icons/messageIcon"
import { subscriptionFilledIcon } from "@trueskin-web/theme/icons/subscriptionFilledIcon"
import { subscriptionIcon } from "@trueskin-web/theme/icons/subscriptionIcon"
import { Routes, useTranslation } from "@trueskin-web/translations"

import { TabItem } from "../tab-item"

export const CoreTabs = () => {
  const { t } = useTranslation()

  const {
    user: {
      treatmentPlan,
      activeIngredients,
      notifications,
      subscription: { status: subscriptionStatus } = {},
    },
  } = useAuth()

  const isSideEffectsSelfServiceEnabled =
    helpService.isSideEffectsSelfServiceEnabled(
      treatmentPlan,
      activeIngredients
    )

  const notificationsCount = notifications?.length

  if (
    !treatmentPlan &&
    (subscriptionStatus === "canceled" || subscriptionStatus === "expired")
  ) {
    return null
  }
  return (
    <Fragment>
      <TabItem
        icon={homeIcon}
        iconSelected={homeFilledIcon}
        label={t("Header.navMenu.home")}
        href={Routes.App.Home.url}
      />
      <TabItem
        icon={fileIcon}
        iconSelected={fileFilledIcon}
        label={t("Header.navMenu.plan")}
        href={Routes.App.Plan.url}
      />
      {!treatmentPlan ? null : (
        <TabItem
          icon={chartLineIcon}
          iconSelected={chartLineIcon}
          label={t("Header.navMenu.progress")}
          href={Routes.App.Progress.url}
        />
      )}
      <TabItem
        icon={isSideEffectsSelfServiceEnabled ? medicalKitIcon : messageIcon}
        iconSelected={
          isSideEffectsSelfServiceEnabled
            ? medicalKitFilledIcon
            : messageFilledIcon
        }
        label={t("Header.navMenu.consultations")}
        notifications={notificationsCount}
        href={Routes.App.Consultations.url}
      />
      {!treatmentPlan ? null : (
        <TabItem
          icon={subscriptionIcon}
          iconSelected={subscriptionFilledIcon}
          label={t("Header.navMenu.subscription")}
          href={Routes.App.Account.Subscription.url}
        />
      )}
    </Fragment>
  )
}
