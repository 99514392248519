import Heading from "../heading"

const CardContent = ({ title, description, image, showImageOnLeft }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
    }}
  >
    <div
      sx={{
        display: "flex",
        flexDirection: showImageOnLeft ? "row-reverse" : "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Heading size="sm" sx={{ lineHeight: 1.33, fontWeight: "bold" }}>
        {title}
      </Heading>

      {image}
    </div>

    {description && (
      <div
        sx={{
          fontSize: 1,
          color: "textDark",
        }}
      >
        {description}
      </div>
    )}
  </div>
)

export default CardContent
