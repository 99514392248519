import { i18nCurrency, i18nLocale } from "@trueskin-web/locales"

export const formatDate = (
  date: number | Date,
  options?: Intl.DateTimeFormatOptions
) => new Intl.DateTimeFormat(i18nLocale(), options).format(new Date(date))

export const formatNumber = (value: number | bigint) =>
  new Intl.NumberFormat(i18nLocale(), {
    maximumFractionDigits: 10,
  }).format(value)

export const formatCurrency = (
  value: number | bigint,
  currency: string,
  minimumFractionDigits = 0
) => {
  const locale = i18nLocale({ currency })

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: i18nCurrency({ locale }),
    minimumFractionDigits,
  }).format(value)
}

export const formatCurrencySymbol = (currency: string) =>
  new Intl.NumberFormat(i18nLocale({ currency }), {
    style: "currency",
    currency,
  })
    .formatToParts(0)
    .filter(({ type }) => type === "currency")
    .reduce((_acc, { value }) => value, "")

export const formatDateISO = (date: string) =>
  date && new Date(date).toISOString().split("T")[0]

export const mapFormelOnlyDowngradePreset = (
  presets = [],
  // @ts-expect-error mapPresetResources is any
  mapPresetResources
) => {
  const downgradePreset = presets.find(
    ({ occurrence, isRecommended, isSelected }) =>
      occurrence !== "occ_once" && !isRecommended && !isSelected
  )

  if (!downgradePreset) {
    return
  }

  const {
    id,
    details: { title },
    computedMetadata: { mainLabel: price },
    summary: { items: presetItems },
  } = downgradePreset

  const products = mapPresetResources(presetItems)

  return {
    id,
    title,
    price,
    products,
  }
}

export const mapSubscriptionType = (
  {
    type,
    paymentOrder: {
      instalments: { count: instalmentsCount } = {
        count: 0,
      },
    } = {
      instalments: { count: 0 },
    },
  }: { type: string; paymentOrder: { instalments: { count: number } } } = {
    type: "",
    paymentOrder: { instalments: { count: 0 } },
  }
) =>
  type === "recurring"
    ? `recurring_${instalmentsCount}m`
    : type === "single"
    ? "single"
    : type === "receipt"
    ? "prescription"
    : "" // not_supported

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  return str.substring(0, maxLength) + "..."
}

export const formatStringToTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
