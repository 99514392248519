import React, { Fragment } from "react"

import { vwoService } from "@trueskin-web/services"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import i18next from "@trueskin-web/translations"

import Button from "../button"
import Heading from "../heading"
import Icon, { sizes } from "../icon"
import IconButton from "../icon-button"

interface SectionHeaderProps extends Partial<HTMLDivElement> {
  title?: string
  description?: string | React.ReactNode
  onBack?: () => void
  backLabel?: string
  onDismiss?: () => void
  heading?: string
  dismissIconSize?: keyof typeof sizes
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  className,
  heading,
  title,
  description,
  onBack,
  backLabel = i18next.t("Button.goBack"),
  onDismiss,
  dismissIconSize,
}) => {
  const isNewChat = vwoService.isNewChatLookAndFeel()

  return title || description || onBack || onDismiss ? (
    <section
      className={className}
      sx={{
        display: "grid",
        gridTemplateColumns: onBack ? "auto 1fr auto" : "1fr auto",
        gap: 3,
        whiteSpace: "pre-line",
      }}
    >
      {onBack || onDismiss || title || heading ? (
        <Fragment>
          {onBack ? (
            <Button
              data-testid="web-portal-back-dialog-btn"
              variant="link"
              onClick={onBack}
              sx={{
                mr: "auto",
                p: 0,
                fontSize: 1,
                textDecoration: "none",
                zIndex: 1,
              }}
            >
              <Icon
                icon={arrowRightIcon}
                sx={{
                  transform: "rotate(180deg)",
                  ml: -2,
                }}
              />
              {backLabel}
            </Button>
          ) : null}
          {heading ? (
            <Heading
              data-testid="web-portal-dialog-heading"
              sx={{
                gridColumn: onBack || title ? null : "span 2",
                p: 0,
                fontWeight: "bold",
                fontSize: 1,
                height: "min-content",
                display: "inline-flex",
                position: "relative",
                justifySelf: "center",
                alignSelf: "center",
              }}
            >
              {heading}
            </Heading>
          ) : null}
          {onDismiss ? (
            <IconButton
              data-testid="web-portal-close-dialog-btn"
              icon={closeIcon}
              onClick={onDismiss}
              iconSize={dismissIconSize}
              sx={{
                gridColumn: onBack || title || heading ? null : "span 2",
                ml: "auto",
                height: "min-content",
                zIndex: 1,
              }}
            />
          ) : null}

          {title ? (
            <Heading
              sx={{
                gridColumn: onBack || heading ? "span 2" : null,
                order: onBack || heading ? null : -1,
                fontWeight: isNewChat ? null : "bold",
              }}
            >
              {title}
            </Heading>
          ) : null}
        </Fragment>
      ) : null}

      {description ? (
        typeof description === "string" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            sx={{
              gridColumn: "span 2",
              whiteSpace: "pre-line",
            }}
          />
        ) : (
          <div
            sx={{
              gridColumn: "span 2",
              whiteSpace: "pre-line",
            }}
          >
            {description}
          </div>
        )
      ) : null}
    </section>
  ) : null
}

export default SectionHeader
