import React from "react"

interface SeparatedLineProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: number
  color?: string
}
const SeparatedLine: React.FC<SeparatedLineProps> = ({
  height = 1,
  color = "grey",
  ...props
}) => {
  return <div sx={{ height: height, backgroundColor: color }} {...props} />
}

export default SeparatedLine
