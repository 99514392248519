import { Button, Icon } from "@trueskin-web/components"
import { openInNewIcon } from "@trueskin-web/theme/icons/openInNewIcon"

const DownloadInvoice = ({ invoiceUrl }: { invoiceUrl: string }) => {
  return (
    <Button
      variant="outline"
      color="black"
      as="a"
      href={invoiceUrl}
      target="_blank"
      isFullWidth
      rounded
    >
      Rechnung herunterladen
      <Icon icon={openInNewIcon} sx={{ ml: 1 }} />
    </Button>
  )
}
DownloadInvoice.displayName = "DownloadInvoice"

export { DownloadInvoice }
