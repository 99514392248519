import { add } from "date-fns"
import { useCallback, useEffect, useMemo } from "react"

import { amplitudeClient } from "@trueskin-web/apis"
import { Button, Icon, Section } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { formatDate } from "@trueskin-web/functions"
import { renewalDialogService } from "@trueskin-web/services"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"

import {
  entryScreenContinueClickedTracking,
  entryScreenCtaId,
  entryScreenDescription,
  entryScreenDetailsList,
  entryScreenHeading,
  entryScreenImage,
  entryScreenPageViewTracking,
} from "./entry-screen-content"
import { EntryScreenType } from "./types"

export const ChurnPreventionPauseEntryScreen = ({
  entryScreen,
  currentRenewalDate,
  onContinue,
  onBack,
  onDismiss,
  goToCancellation,
}: {
  entryScreen: EntryScreenType
  currentRenewalDate: Date
  onContinue: () => void
  onBack?: () => void
  onDismiss: () => void
  goToCancellation?: () => void
}) => {
  const currentRenewalDateFormatted = formatDate(currentRenewalDate, {
    month: "long",
    day: "numeric",
  })
  const earliestDateFormatted = formatDate(
    add(new Date(), {
      days: 1,
    }),
    {
      month: "long",
      day: "numeric",
    }
  )
  const {
    user: { subscription },
  } = useAuth()

  const shiftMonths = useMemo(
    () => renewalDialogService.getShiftMonths(subscription?.paymentOrder),
    [subscription]
  )

  const isPauseScreen = [
    EntryScreenType.PauseTreatmentFromNextOrderManagement,
    EntryScreenType.PauseTreatmentFromCancellation,
  ].includes(entryScreen)

  const trackOnDismiss = useCallback(() => {
    if (
      [EntryScreenType.TakeABreak, EntryScreenType.EarlySet].includes(
        entryScreen
      )
    ) {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_CLOSED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
            entryScreen === EntryScreenType.TakeABreak
              ? "pause_treatment_more"
              : "pause_treatment_product_less",
        }
      )
    }
  }, [entryScreen])

  const trackContinueCancellation = useCallback(() => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_CONFIRMED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
          entryScreen === EntryScreenType.TakeABreak
            ? "pause_treatment_more"
            : "pause_treatment_product_less",
      }
    )
  }, [entryScreen])

  useEffect(() => {
    if (entryScreen) {
      entryScreenPageViewTracking[entryScreen]?.()
    }
  }, [entryScreen])

  return (
    <Section
      title={entryScreenHeading[entryScreen]}
      description={
        <span sx={{ color: "neutralsDarkGrey" }}>
          {entryScreenDescription[entryScreen].replace(
            "{DATE}",
            currentRenewalDateFormatted
          )}
        </span>
      }
      content={
        <div sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <div sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {entryScreenImage[entryScreen]}
          </div>

          <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {entryScreenDetailsList[entryScreen].map((detail, index) => (
              <div
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Icon icon={checkCircleFilledIcon} color="brandPrimary" />
                {detail
                  .replace("{SHIFT_MONTHS}", shiftMonths)
                  .replace("{EARLIEST_DATE}", earliestDateFormatted)}
              </div>
            ))}
          </div>
        </div>
      }
      onDismiss={() => {
        trackOnDismiss()
        onDismiss()
      }}
      onBack={onBack}
      backLabel={onBack ? "Zurück" : undefined}
      sx={{
        '[type="button"]': {
          borderRadius: 8,
        },
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "> .section-content": {
          flex: 1,
        },
      }}
      footnote={
        <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            data-testid={entryScreenCtaId[entryScreen]}
            isFullWidth
            rounded
            onClick={() => {
              entryScreenContinueClickedTracking[entryScreen]?.()

              onContinue()
            }}
          >
            {entryScreen === EntryScreenType.TakeABreak
              ? "Ja, weiter zum Pausieren"
              : "Weiter"}
          </Button>

          {isPauseScreen ? (
            <div
              sx={{
                textAlign: "center",
                fontSize: 12,
                lineHeight: "16px",
                color: "neutralsDarkGrey",
              }}
            >
              Deine Account-Daten werden nicht gelöscht.
            </div>
          ) : (
            <Button
              data-testid="churnPrevention-pauseTreatment-entryScreen-continueToCancellation"
              isFullWidth
              rounded
              variant="ghost"
              color="black"
              onClick={() => {
                trackContinueCancellation()
                goToCancellation?.()
              }}
            >
              Weiter zur Kündigung
            </Button>
          )}
        </div>
      }
    />
  )
}
