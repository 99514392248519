import { useEffect, useState } from "react"

import { getCountries, getCountry, saveCountry } from "@trueskin-web/locales"

const countries = getCountries()

const useSelectCountry = () => {
  const [currentCountry, setCurrentCountry] = useState("")

  useEffect(() => {
    setCurrentCountry(getCountry(false))
  }, [])

  const onSelect = (country) => {
    saveCountry(country.code)
    setCurrentCountry(country)

    window.location.assign(window.location.origin)
  }

  return {
    countries,
    onSelect,
    currentCountry,
  }
}

export default useSelectCountry
