import { getVoucherErrorType } from "@trueskin-web/functions"

import { apiV2, apiVortex } from "./api"
import * as marketingClient from "./marketing"

function getCurrentPresets(type) {
  return apiV2("orders/current/presets", {
    params: {
      type,
    },
  })
}

async function setCurrentPreset(preset) {
  return apiV2(`orders/current/preset`, {
    body: {
      preset,
    },
  })
}

function getCurrentSummary() {
  return apiV2("orders/current/summary")
}

/**
 * @returns {ReturnType<VortexApi['vortex']['experimentsCommitmentPlansGetSummary']>}
 */
function getLongTermDiscountedPlanSummary() {
  return apiVortex().experimentsCommitmentPlansGetSummary()
}

/**
 * Get the last order summary with status paid, partially_paid or payment_due
 *
 * @returns {Promise<OrderSummary|{}>}
 */
function getLastOrderSummary() {
  return apiVortex().experimentsCommitmentPlansGetLastOrderSummary()
}

function addCurrentVoucher(voucherCode, rawVoucher = "") {
  return apiV2(`orders/current/add-voucher`, {
    body: {
      voucherCode,
    },
  }).catch((e) => {
    marketingClient.sendEvent({
      name: marketingClient.EVENT_TYPES_ENUM.ADD_VOUCHER_ERROR,
      data: {
        errorType: getVoucherErrorType(e.message),
        voucherCode: rawVoucher || voucherCode,
      },
    })

    throw e
  })
}

function removeCurrentVoucher(voucherCode) {
  return apiV2(`orders/current/remove-voucher`, {
    body: {
      voucherCode,
    },
  })
}

function getUpcomingPresets(type) {
  return apiV2("orders/upcoming/presets", {
    params: {
      type,
    },
  })
}

function getUpcomingOrderVariations() {
  return apiV2("orders/upcoming/variations")
}

function getFormelOnlyUpcomingPresets() {
  const type = "formel-only"

  return getUpcomingPresets(type)
}

function getAddonsUpcomingPresets() {
  const type = "addons"

  return getUpcomingPresets(type)
}

function setUpcommingPreset(preset) {
  return apiV2(`orders/upcoming/preset`, {
    body: {
      preset,
    },
  })
}

function updateUpcomingOrderVariation(orderVariationId) {
  return apiV2("orders/upcoming/variation", {
    body: {
      orderVariationId,
    },
  })
}

function getUpcomingSummary() {
  return apiV2("orders/upcoming/summary")
}

function addUpcomingProducts(products) {
  return apiV2("orders/upcoming/customize", {
    body: products,
  })
}

function customizeUpcomingOrder(products) {
  return apiV2("orders/upcoming/customize", {
    body: products,
  })
}

function simulateCustomizeUpcomingOrder({
  addProducts,
  removeProductIds,
  addVoucherCodes,
}) {
  // this API always removes all moisturizers and cleansers
  // so you don't have to remove those manually
  return apiV2(
    `orders/upcoming/customize?isSimulation=true${
      removeProductIds?.length
        ? "&removeProductIds=" + removeProductIds.join(",")
        : ""
    }${
      addVoucherCodes?.length
        ? "&addVoucherCodes=" + addVoucherCodes.join(",")
        : ""
    }`,
    {
      body: addProducts || [],
    }
  )
}

function addUpcomingProduct(product) {
  return apiV2("orders/upcoming/add-product", {
    body: {
      product,
    },
  })
}

function removeUpcomingProduct(product) {
  return apiV2("orders/upcoming/remove-product", {
    body: {
      product,
    },
  })
}

function addUpcomingVoucher(voucherCode) {
  return apiV2(`orders/upcoming/add-voucher`, {
    body: {
      voucherCode,
    },
  })
}

function removeUpcomingVoucher(voucherCode) {
  return apiV2(`orders/upcoming/remove-voucher`, {
    body: {
      voucherCode,
    },
  })
}

function getHistoricalOrderInfo(v2OrderId) {
  return apiVortex().experimentsPricingTransparencyOrderInfo(v2OrderId)
}

export {
  setCurrentPreset,
  getCurrentSummary,
  getLastOrderSummary,
  getFormelOnlyUpcomingPresets,
  getAddonsUpcomingPresets,
  getCurrentPresets,
  setUpcommingPreset,
  getUpcomingOrderVariations,
  getUpcomingSummary,
  customizeUpcomingOrder,
  simulateCustomizeUpcomingOrder,
  addUpcomingProducts,
  addCurrentVoucher,
  removeCurrentVoucher,
  addUpcomingProduct,
  updateUpcomingOrderVariation,
  removeUpcomingProduct,
  addUpcomingVoucher,
  removeUpcomingVoucher,
  getLongTermDiscountedPlanSummary,
  getHistoricalOrderInfo,
}
