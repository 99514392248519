import { Slugs, Trans } from "@trueskin-web/translations"

import ALink from "./a-link"

const FaqLink = ({ className }) => (
  <div className={className}>
    <span sx={{ fontWeight: "bold" }}>
      <Trans i18nKey="Generic.support.title" />
    </span>{" "}
    <ALink
      isTextColor
      isUnderlined
      href={Slugs.help.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Trans i18nKey="Generic.support.description" />
    </ALink>
  </div>
)

export default FaqLink
