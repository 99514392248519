import { apiVortex } from "./api"

function getInitial({ id, provider }) {
  return apiVortex().postQuestionnairesInitial({
    id,
    provider,
  })
}

function getMidTwoMonthsUpdate({ id, provider }) {
  const body = {
    id,
    provider,
  }

  return apiVortex().postQuestionnairesMid2MUpdate(body)
}

async function verifyInitial({ id }) {
  const res = await window.fetch(`https://mini-serve.prd.heyflow.com/${id}`)

  if (!res.ok || res.status === 404) {
    throw res
  }

  return true
}

function postUsersSetRedoInitialQuestionnaire() {
  const body = {
    redoInitialQuestionnaire: true,
  }

  return apiVortex().postUsersMeQuestionnaireSetRedoInitial(body)
}

export {
  getInitial,
  getMidTwoMonthsUpdate,
  verifyInitial,
  postUsersSetRedoInitialQuestionnaire,
}
