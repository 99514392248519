import { useLazyQuery, useMutation, useQuery } from "@apollo/client"

export * from "./user.graphql"

// TODO: when replacing this with generated
// per-query/mutation hooks, be sure to add
// the same clientName config you see here
// for the bd scoped graphql-code-generator project

export const useBdQuery: typeof useQuery = (query, options) =>
  useQuery(query, { ...options, context: { clientName: "bd" } })

export const useBdLazyQuery: typeof useLazyQuery = (query, options) =>
  useLazyQuery(query, { ...options, context: { clientName: "bd" } })

export const useBdMutation: typeof useMutation = (mutation, options) =>
  useMutation(mutation, { ...options, context: { clientName: "bd" } })
