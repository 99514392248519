import { useLocation } from "@reach/router"
import { Fragment, useEffect, useState } from "react"

import { amplitudeClient, npsClient, useMutation } from "@trueskin-web/apis"
import { FieldError, Section, Spinner } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { getErrorMessage } from "@trueskin-web/functions"
import { Trans, useTranslation } from "@trueskin-web/translations"

import { useNpsSettings } from "../../../hooks"
import TypeformWidget from "../../components/typeform-widget"

import FeedbackConfirmation from "./feedback-confirmation"
import FeedbackForm from "./feedback-form"
import FeedbackTags from "./feedback-tags"
import Promoters from "./promoters"

const neutralReviewThreshold = 7
const positiveReviewThreshold = 9
const isFeedbackQuestionnaireEnabled = true

const Nps = ({ source, onDismiss }) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const { user } = useAuth()

  const [score, setScore] = useState(null)
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false)
  const [hasSubmittedScore, setHasSubmittedScore] = useState(false)

  const {
    isLoading: npsSettingsIsLoading,
    data: npsSettings,
    error: npsSettingsError,
  } = useNpsSettings()

  const {
    mutateAsync: submitScore,
    isLoading: isSubmitScoreLoading,
    error: submitScoreError,
  } = useMutation(npsClient.submitScore, {
    onSuccess: () => {
      setHasSubmittedScore(true)

      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.NPS_SUBMITTED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: pathname,
          [amplitudeClient.ANALYTIC_EVENT_PROPS.NPS_VALUE]: score,
        }
      )
    },
  })

  const {
    mutateAsync: submitFeedback,
    isLoading: isSubmitFeedbackLoading,
    error: submitFeedbackError,
  } = useMutation(
    ({ feedback, tags }) =>
      npsClient.submitFeedback({
        npsScoreId: user.npsScore.id,
        feedback,
        tags,
        sourcePath: pathname,
      }),
    {
      onSuccess: () => {
        setHasSubmittedFeedback(true)

        amplitudeClient.trackEvent(
          amplitudeClient.ANALYTIC_EVENTS.NPS_REASON_SUBMITTED,
          {
            [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: pathname,
            [amplitudeClient.ANALYTIC_EVENT_PROPS.NPS_VALUE]: score,
          }
        )
      },
    }
  )

  const onSubmit = () => {
    setHasSubmittedFeedback(true)

    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.NPS_REASON_SUBMITTED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: pathname,
        [amplitudeClient.ANALYTIC_EVENT_PROPS.NPS_VALUE]: score,
      }
    )
  }

  const onLinkClick = (link) => {
    npsClient.trackReview({
      npsScoreId: user.npsScore.id,
      platform: link.platform,
    })

    window.open(link.externalUrl, "_blank", "noopener noreferrer")
  }

  useEffect(() => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.NPS_OVERLAY_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: pathname,
      }
    )
  }, [pathname])

  if (npsSettingsIsLoading) {
    return <Spinner />
  }

  if (npsSettingsError) {
    return <FieldError>{getErrorMessage(npsSettingsError)}</FieldError>
  }

  if (npsSettings.active === false || user.showNps === false) {
    return null
  }

  if (hasSubmittedFeedback) {
    if (score < positiveReviewThreshold) {
      return <FeedbackConfirmation onDismiss={onDismiss} />
    }

    return (
      <Promoters
        links={npsSettings.links.filter((link) => link.isVisible)}
        onClick={onLinkClick}
        onDismiss={onDismiss}
      />
    )
  }

  if (hasSubmittedScore) {
    if (isFeedbackQuestionnaireEnabled) {
      return (
        <Section
          onBack={() => setHasSubmittedScore(false)}
          onDismiss={onDismiss}
          content={
            <TypeformWidget
              id={process.env.GATSBY_NPS_TYPEFORM_ID}
              hidden={{ user_id: user._id, nps_score_id: user.npsScore.id }}
              onSubmit={onSubmit}
              className={"full-screen-widget-nps"}
            />
          }
        />
      )
    }

    if (score < positiveReviewThreshold) {
      return (
        <Section
          title={
            score < neutralReviewThreshold
              ? null
              : t("Nps.SubmittedNegative.heading")
          }
          onBack={() => setHasSubmittedScore(false)}
          onDismiss={onDismiss}
          content={
            score < neutralReviewThreshold ? (
              <FeedbackTags
                tags={npsSettings.tags}
                onSubmit={submitFeedback}
                isLoading={isSubmitFeedbackLoading}
                error={submitFeedbackError}
              />
            ) : (
              <FeedbackForm
                onSubmit={submitFeedback}
                error={submitFeedbackError}
              />
            )
          }
        />
      )
    }

    return (
      <Promoters
        links={npsSettings.links.filter((link) => link.isVisible)}
        onClick={onLinkClick}
        onDismiss={onDismiss}
      />
    )
  }

  return (
    <Section
      onDismiss={onDismiss}
      description={t("Nps.description")}
      content={
        <Fragment>
          <div sx={{ mb: 3, fontSize: 1, fontWeight: "bold" }}>
            <Trans i18nKey="Nps.lowestScore" />
          </div>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {[...Array(11).keys()].map((item) => {
              const isFilled = score !== null && item <= score

              return (
                <div
                  key={item}
                  sx={{
                    position: "relative",
                    width: `calc(${100 / 6}% - 16px)`,
                    minWidth: "32px",
                    mx: 2,
                    my: 3,
                    "&:before": {
                      height: 0,
                      content: `""`,
                      display: "block",
                      pt: "100%",
                    },
                    "&:after": {
                      content: `""`,
                      position: "absolute",
                      top: "50%",
                      left: "100%",
                      width: "16px",
                      borderTop: ({ colors }) => `1px dashed ${colors.grey}`,
                    },
                    "&:last-child:after, &:nth-of-type(6):after": {
                      display: "none",
                    },
                  }}
                >
                  <button
                    data-testid="web-portal-nps-score-btn"
                    id={`form_nps_score_${item}`}
                    onClick={() => setScore(item)}
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      display: "block",
                      p: 0,
                      m: 0,
                      appearance: "none",
                      textAlign: "center",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      borderRadius: "50%",
                      bg: isFilled ? "primary" : "transparent",
                      color: isFilled ? "background" : "primary",
                      border: "1px solid",
                      borderColor: "grey",
                      boxShadow: "2px 2px 10px rgba(29, 22, 15, 0.05)",
                      userSelect: "none",
                      outline: "none",
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 2,
                      }}
                    >
                      {item}
                    </div>
                  </button>
                </div>
              )
            })}
          </div>
          <div
            sx={{ mt: 3, fontSize: 1, fontWeight: "bold", textAlign: "right" }}
          >
            <Trans i18nKey="Nps.highestScore" />
          </div>
        </Fragment>
      }
      primaryAction={() => {
        submitScore({ npsScoreId: user.npsScore.id, score, source })
      }}
      primaryActionLabel={t("Nps.sendButton")}
      isPrimaryActionLoading={isSubmitScoreLoading}
      isPrimaryActionDisabled={score === null}
      actionError={submitScoreError ? getErrorMessage(submitScoreError) : null}
    />
  )
}

export default Nps
