import { StaticImage } from "gatsby-plugin-image"

import { amplitudeClient } from "@trueskin-web/apis"
import { Button, Card, Dialog, Icon, Spinner } from "@trueskin-web/components"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { emptyCircleIcon } from "@trueskin-web/theme/icons/emptyCircleIcon"

import { useLongTermDiscounts } from "./context"
import { LongTermDiscountOffer } from "./types"

const OfferCard = ({ offer }: { offer: LongTermDiscountOffer }) => {
  const { selectedOffer, selectOffer } = useLongTermDiscounts()
  const isSelected = offer.months === selectedOffer?.months

  return (
    <Card
      sx={{
        border: "1px solid",
        borderColor: isSelected ? "brandPrimary" : "lightGray",
        flexDirection: "row",
        gap: 3,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => selectOffer(offer)}
    >
      <Icon
        color={isSelected ? "primary" : "neutralsMediumGrey"}
        icon={isSelected ? checkCircleFilledIcon : emptyCircleIcon}
        sx={{ width: 24, height: 24 }}
      />

      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          fontSize: 14,
          lineHeight: "20px",
        }}
      >
        <div sx={{ flex: 1 }}>
          {offer.percentage}% Rabatt für {offer.months} Monate
        </div>

        <div sx={{ fontWeight: 700 }}>{offer.pricePerMonth} € mtl.</div>
      </div>
    </Card>
  )
}

export const LongTermDiscountsOfferDialog = () => {
  const currentDialog = "offer"
  const nextDialog = "confirmation"

  const {
    isLoadingUpcomingOrder,
    availableOffers,
    selectedOffer,
    openDialog,
    closeDialog,
  } = useLongTermDiscounts()

  return (
    <Dialog
      isOpen={true}
      onDismiss={() => closeDialog(currentDialog)}
      title={"Vielen Dank für deine Treue!"}
      sx={{
        "&[data-reach-dialog-content]": {
          pb: 0,
        },
      }}
    >
      {isLoadingUpcomingOrder ? (
        <Spinner isFullPage />
      ) : (
        <div sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
          <div>
            Als Dankeschön möchten wir Dir einen Rabatt anbieten, wenn Du Dich
            für eine längere Behandlung mit uns entscheidest.
          </div>

          <div>
            <StaticImage
              src="../../images/rebranded/long-term-discounts-promo.png"
              alt=""
              objectFit={"contain"}
              sx={{ height: 226 }}
            />
          </div>

          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              // pb: [140, null],
            }}
          >
            {availableOffers.map((offer) => (
              <OfferCard key={offer.percentage} offer={offer} />
            ))}
          </div>

          <div
          // sx={{
          //   position: ["fixed", "initial"],
          //   left: [0, null],
          //   // bottom: isVisible ? 0 : "-100%",
          //   bottom: [0, null],
          //   zIndex: [1, null],
          //   // transitionProperty: "opacity, top, bottom",
          //   // transitionDuration: "250ms",
          //   // transitionTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
          //   // boxShadow: "0 12px 24px 0 #000",
          //   // borderTop: totalProducts > 3 ? "1px solid" : null,
          //   // borderColor: totalProducts > 3 ? "#E1E1E1" : null,
          //   borderTop: "1px solid #E1E1E1",
          //   backgroundColor: "white",
          //   // px: 2,
          //   width: ["100vw", "100%"],
          //   // pb: "40px",
          //   // pt: 2,
          //   p: 4,
          //   pt: 3,
          //   display: "flex",
          //   flexDirection: "column",
          //   gap: 2,
          // }}
          >
            <Button
              rounded
              isFullWidth
              data-testid="longTermDiscounts-OpenOfferConfirmationDialog-btn"
              onClick={() => {
                amplitudeClient.trackEvent(
                  amplitudeClient.ANALYTIC_EVENTS.PROMO_OFFER_CONTINUE_CLICKED,
                  {
                    [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
                      "longterm_discounts",
                    discount_selection: `${selectedOffer?.percentage}%`,
                  }
                )
                openDialog(nextDialog)
              }}
              isDisabled={!selectedOffer}
            >
              Mit Rabattangebot fortfahren
            </Button>

            <Button
              rounded
              variant="ghost"
              isFullWidth
              onClick={() => closeDialog(currentDialog)}
            >
              Abbrechen
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}
