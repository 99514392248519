import { StaticImage } from "gatsby-plugin-image"

export const acneSuccessStoriesImages = {
  "65afab1c266af000299618b0": {
    // Christin
    before: (
      <StaticImage
        src={"./christin_before.png"}
        alt={"Christin before"}
        // aspectRatio={1}
        // objectFit={"scale-down"}
        objectFit="unset"
        height={316}
        // width={311}
        sx={{
          width: "100%",
          // height: [320, 350],
          borderRadius: 8,
        }}
      />
    ),
    after: (
      <StaticImage
        src={"./christin_after.png"}
        alt={"Christin after"}
        // aspectRatio={1}
        height={316}
        objectFit="unset"
        sx={{
          width: "100%",
          // height: 300,
          borderRadius: 8,
        }}
      />
    ),
  },
  "600de8066660bf002439ad91": {
    // Alissa
    before: (
      <StaticImage
        src={"./alissa_before.png"}
        alt={"Alissa before"}
        objectFit="unset"
        // sx={{ width: "100%", height: 300 }}
        // aspectRatio={1}
        height={316}
        sx={{
          width: "100%",
          // height: 300,
          borderRadius: 8,
        }}
      />
    ),
    after: (
      <StaticImage
        src={"./alissa_after.png"}
        alt={"Alissa after"}
        objectFit="unset"
        // sx={{ width: "100%", height: 300 }}
        // aspectRatio={1}
        height={316}
        sx={{
          width: "100%",
          // height: 300,
          borderRadius: 8,
        }}
      />
    ),
  },
  "65231687fef9770029a29483": {
    // Berna
    before: (
      <StaticImage
        src={"./berna_before.png"}
        alt={"Berna before"}
        objectFit="unset"
        // sx={{ width: "100%", height: 300 }}
        // aspectRatio={1}
        height={316}
        sx={{
          width: "100%",
          // height: 300,
          borderRadius: 8,
        }}
      />
    ),
    after: (
      <StaticImage
        src={"./berna_after.png"}
        alt={"Berna after"}
        objectFit="unset"
        // sx={{ width: "100%", height: 300 }}
        // aspectRatio={1}
        height={316}
        sx={{
          width: "100%",
          // height: 300,
          borderRadius: 8,
        }}
      />
    ),
  },
}
