import { Fragment, useState } from "react"

import {
  Button,
  Dialog,
  Input,
  Select,
  Textarea,
  ToggleSwitch,
} from "@trueskin-web/components"

const Dialogs = () => {
  const [title, setTitle] = useState(
    "Personalisierte Behandlung durch das Ärzt:innen-Team"
  )
  const [heading, setHeading] = useState()

  const [description, setDescription] = useState(
    "Unsere Methode besteht aus der Kombination von bewährten Inhaltsstoffen und enger ärztlicher Betreuung.\nDabei wird Deine Formel individuell auf Deine Haut angepasst und bei Bedarf verändert."
  )

  const [content, setContent] = useState(`
  <div>Die Produkte kombinieren arzneilich wirksame und freiverkäufliche Wirkstoffe, um Deinem Ziel von gesünderer Haut näher zu kommen. Du wählst flexibel zwischen monatlicher, zweimonatlicher oder einmaliger Lieferung. Außerdem kannst Du jederzeit kündigen.</div>
  <div>Azelainsäure wird aus der Hefe von Weizen oder Gerste gewonnen. Es ist ein stark entzündungshemmender, antibakterieller und antikomedogener Wirkstoff, der seit Jahren in der Akne- und Rosazea-Therapie eingesetzt wird und häufig in geringer Konzentration in Kosmetika aufzufinden ist. Es wirkt als sanftes Peeling, verfeinert somit die Hautoberfläche und unterstützt das Verblassen von Pigmentflecken. Aufgrund der guten Verträglichkeit, eignet sich Azelainsäure besonders zur Langzeit- und Erhaltungstherapie unreiner Haut.</div>
  <div>Niacinamid, auch bekannt als Vitamin B3, ist ein wasserlösliches Vitamin. Es aktiviert natürliche Substanzen der Haut, die vergrößerte Poren sichtbar minimieren, einen ungleichmäßigen Hautton ausgleichen, feine Linien und Fältchen reduzieren, sowie die Oberflächenstruktur stärken.</div>
  <div>Vitamin-A-Säure ist durch seine antiinflammatorische, komedolytische und antikomedogene Wirkung einer der effektivsten Wirkstoffe um Hautunreinheiten zu reduzieren. Es gehört zu der Gruppe der Retinoide (wie z.b. auch Retinol), sodass es nicht nur Akne sondern auch Falten reduzieren und vorbeugen kann. Es verkleinert die Poren, mindert die Talgproduktion und wirkt entzündungshemmend. Stimulation der Kollagen- und Elastinbildung verjüngt die Haut.</div>`)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [onBack, setOnBack] = useState(() => () => setIsDialogOpen(false))

  const [backLabel, setBackLabel] = useState("Zurück")

  const [onDismiss, setOnDismiss] = useState(() => () => setIsDialogOpen(false))

  const [primaryAction, setPrimaryAction] = useState(
    () => () => setIsDialogOpen(false)
  )
  const [primaryActionLabel, setPrimaryActionLabel] = useState("")

  const [secondaryAction, setSecondaryAction] = useState(
    () => () => setIsDialogOpen(false)
  )
  const [secondaryActionLabel, setSecondaryActionLabel] = useState("")

  const [footnote, setFootnote] = useState(true)

  const [noDismissIcon, setNoDismissIcon] = useState(false)
  const [noBackdropDismiss, setNoBackdropDismiss] = useState(false)
  const [noMobileFullscreen, setNoMobileFullscreen] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [stickTo, setStickTo] = useState(null)
  return (
    <Fragment>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridGap: 2,
          p: 2,
        }}
      >
        <div>title</div>
        <Input
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />

        <div>heading</div>
        <Input
          value={heading}
          onChange={(event) => setHeading(event.target.value)}
        />

        <div>description</div>
        <Textarea
          value={description}
          rows="4"
          onChange={(event) => setDescription(event.target.value)}
          sx={{
            resize: "auto",
          }}
        />

        <div>content</div>
        <Textarea
          value={content}
          rows="12"
          columns="200"
          onChange={(event) => setContent(event.target.value)}
          sx={{
            resize: "auto",
          }}
        />

        <div>mobile fullscreen</div>
        <ToggleSwitch
          value={!noMobileFullscreen}
          onChange={() =>
            setNoMobileFullscreen((value) => {
              if (!value) {
                setAnimate(false)
              }

              return !value
            })
          }
        />

        <div sx={{ mb: 6 }}>mobile fullscreen animate</div>
        <ToggleSwitch
          isDisabled={noMobileFullscreen}
          value={animate}
          onChange={() => setAnimate((value) => !value)}
        />

        <div>dismiss action</div>
        <ToggleSwitch
          value={!!onDismiss}
          onChange={() =>
            setOnDismiss((prev) => {
              if (prev) {
                setNoDismissIcon(false)
                setNoBackdropDismiss(true)
                // return null
              } else {
                return () => setIsDialogOpen(false)
              }
            })
          }
        />

        <div>no dismiss icon</div>
        <ToggleSwitch
          value={noDismissIcon}
          isDisabled={!onDismiss}
          onChange={() => setNoDismissIcon((value) => !value)}
        />

        <div sx={{ mb: 6 }}>backdrop dismiss</div>
        <ToggleSwitch
          value={!noBackdropDismiss}
          isDisabled={!onDismiss}
          onChange={() => setNoBackdropDismiss((value) => !value)}
        />

        <div sx={{ mb: 6 }}>back action</div>
        <ToggleSwitch
          value={!!onBack}
          onChange={() =>
            setOnBack((prev) => (prev ? null : () => setIsDialogOpen(false)))
          }
        />

        <div sx={{ mb: 7 }}>back label</div>
        <Input
          disabled={!onBack}
          value={backLabel}
          onChange={(event) => setBackLabel(event.target.value)}
        />

        <div>primary action</div>
        <ToggleSwitch
          value={!!primaryAction}
          onChange={() =>
            setPrimaryAction((prev) =>
              prev ? null : () => setIsDialogOpen(false)
            )
          }
        />

        <div sx={{ mb: 7 }}>primary label</div>
        <Input
          disabled={!primaryAction}
          value={primaryActionLabel}
          onChange={(event) => setPrimaryActionLabel(event.target.value)}
        />

        <div>secondary action</div>
        <ToggleSwitch
          value={!!secondaryAction}
          onChange={() =>
            setSecondaryAction((prev) =>
              prev ? null : () => setIsDialogOpen(false)
            )
          }
        />

        <div sx={{ mb: 7 }}>secondary label</div>
        <Input
          disabled={!secondaryAction}
          value={secondaryActionLabel}
          onChange={(event) => setSecondaryActionLabel(event.target.value)}
        />

        <div>default footnote</div>
        <ToggleSwitch
          value={footnote === true}
          onChange={() =>
            setFootnote((prev) => (prev === true ? setFootnote("") : true))
          }
        />

        <div>footnote</div>
        <Input
          disabled={footnote === true}
          value={footnote}
          onChange={(event) => setFootnote(event.target.value)}
        />
        <div>stickTo</div>
        <Select onChange={(e) => setStickTo(e.target.value)}>
          <option>n/a</option>
          <option value="bottom">bottom</option>
        </Select>
        <Button onClick={() => setIsDialogOpen(true)}>Open dialog</Button>
      </div>

      <Dialog
        aria-label="dialog"
        isOpen={isDialogOpen}
        animate={animate}
        noDismissIcon={noDismissIcon}
        noBackdropDismiss={noBackdropDismiss}
        noMobileFullscreen={noMobileFullscreen}
        title={title}
        heading={heading?.length ? heading : false}
        description={description}
        backLabel={backLabel?.length ? backLabel : false}
        onBack={onBack}
        onDismiss={onDismiss}
        primaryAction={primaryAction}
        primaryActionLabel={primaryActionLabel}
        secondaryAction={secondaryAction}
        secondaryActionLabel={secondaryActionLabel}
        footnote={footnote}
        stickTo={stickTo}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Dialog>
    </Fragment>
  )
}

export default Dialogs
