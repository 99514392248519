import { StaticImage } from "gatsby-plugin-image"

import { Card } from "@trueskin-web/components"

import { productResources } from "../../../utils/resources"

import { CardSection } from "./card-helpers"
import { useOrderCard } from "./order-price-breakdown-card"

export const OrderProductsBreakdown = () => {
  const { order, products } = useOrderCard()

  const isPrescriptionBasedOrder = order?.items
    ?.map((item: any) => item.product)
    .includes("p_prescription_1")

  return (
    <CardSection>
      <OrderProductsList>
        <OrderProductsHeader
          text={isPrescriptionBasedOrder ? "Deine Bestellung" : "Dein Skin-Set"}
          description={
            isPrescriptionBasedOrder
              ? undefined
              : `${order.instalments.count}-Monats-Vorrat`
          }
        />

        {isPrescriptionBasedOrder ? (
          <PrescriptionBasedOrderContent />
        ) : (
          <StandardOrderContent products={products} />
        )}
      </OrderProductsList>
    </CardSection>
  )
}

const OrderProductsHeader = ({
  text,
  description,
}: {
  text: string
  description?: string
}) => {
  return (
    <div>
      <div
        sx={{
          fontSize: 16,
          lineHeight: "24px",
          fontWeight: 700,
        }}
      >
        {text}
      </div>

      {description && (
        <div
          sx={{
            fontSize: 14,
            lineHeight: "20px",
          }}
        >
          {description}
        </div>
      )}
    </div>
  )
}

const OrderProductsList = ({ children }: any) => {
  return (
    <div sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      {children}
    </div>
  )
}

const PrescriptionBasedOrderContent = () => {
  return (
    <div sx={{ display: "flex", gap: 4, alignItems: "center" }}>
      <StaticImage
        src="./images/rezept-product-image.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: 80,
          maxHeight: 80,
        }}
      />

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2px",
          flex: 1,
        }}
      >
        <div sx={{ fontSize: 14, lineHeight: "20px" }}>Privat-Rezept</div>

        <div
          sx={{
            fontSize: 12,
            lineHeight: "16px",
            color: "neutralsDarkGrey",
          }}
        >
          Einlösbar in einer Apotheke deiner Wahl
        </div>
      </div>
    </div>
  )
}

const StandardOrderContent = ({ products }: { products: any }) => {
  if (!products?.length) {
    const MedicalProductImage = productResources.p_medical_cream_1.productImage
    const CleanserProductImage = productResources.p_cleanser_1.productImage
    const MoisturizerProductImage =
      productResources.p_moisturizer_2.productImage

    return (
      <Card
        sx={{
          p: 5,
          background: "hsla(0, 0%, 0%, 0.025)",
          alignItems: "center",
          justifyContent: "center",
          opacity: 0.75,
        }}
      >
        <div sx={{ display: "flex", gap: 2 }}>
          <div
            sx={{
              width: 64,
              height: 64,
              background: "#EEE",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MedicalProductImage sx={{ width: 46, height: 46 }} />
          </div>

          <div
            sx={{
              width: 64,
              height: 64,
              background: "#EEE",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CleanserProductImage sx={{ width: 46, height: 46 }} />
          </div>

          <div
            sx={{
              width: 64,
              height: 64,
              background: "#EEE",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MoisturizerProductImage sx={{ width: 46, height: 46 }} />
          </div>
        </div>

        <div
          sx={{
            fontSize: 12,
            lineHeight: "16px",
            color: "neutralsDarkGrey",
            textAlign: "center",
          }}
        >
          Die Informationen zu deinem Skin-Set erhältst Du, sobald Dein
          Arzt/Deine Ärztin Dir Deinen Behandlungsplan erstellt hat.
        </div>
      </Card>
    )
  }

  return (
    <div sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      {products?.map((product: any, index: number) => {
        const productId =
          product.v2ProductId === "p_medical_cream_1_xl"
            ? "p_medical_cream_1"
            : product.v2ProductId

        const ProductImage =
          productResources[productId]?.productImage ||
          productResources[productId]?.spotlightImage

        const productTitle = productResources[productId]?.title || product.title

        return (
          <div
            key={index}
            sx={{ display: "flex", gap: 4, alignItems: "center" }}
          >
            <div
              sx={{
                width: 80,
                height: 80,
                background: "#EEE",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {ProductImage && <ProductImage sx={{ width: 46, height: 46 }} />}
            </div>

            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "2px",
                flex: 1,
              }}
            >
              <div sx={{ fontSize: 14, lineHeight: "20px" }}>
                {productTitle}
              </div>

              {product.activeIngredients?.map((p: string) => (
                <div
                  key={p}
                  sx={{
                    fontSize: 12,
                    lineHeight: "16px",
                    color: "neutralsDarkGrey",
                  }}
                >
                  {p}
                </div>
              ))}

              <div
                sx={{
                  fontSize: 12,
                  lineHeight: "16px",
                  color: "neutralsDarkGrey",
                }}
              >
                {product.volume} ({product.duration})
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
