import { Container } from "@trueskin-web/components"

const Sizes = () => (
  <Container
    sx={{
      my: 2,
      backgroundColor: "grey",
    }}
  >
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        backgroundColor: "grey",
        ":before": {
          content: [`"mobile"`, `"desktop"`],
          fontWeight: "bold",
        },
        ":after": {
          content: `"resize window size"`,
          fontSize: 0,
        },
      }}
    />
  </Container>
)

export default Sizes
