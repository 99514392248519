import { ReactNode } from "react"

const MobileTabBar = ({ children }: { children: ReactNode }) => {
  return (
    <nav
      sx={{
        position: "sticky",
        left: 0,
        right: 0,
        bottom: 0,
        display: ["flex", "none"],
        alignItems: "center",
        pb: "env(safe-area-inset-bottom)",
        backdropFilter: "saturate(180%) blur(20px)",
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        borderTop: "2px solid",
        borderColor: "grey",
        zIndex: 1,
      }}
    >
      {children}
    </nav>
  )
}

export default MobileTabBar
