import { Icon } from "@trueskin-web/components"
import { formatDate } from "@trueskin-web/functions"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { subscriptionFilledIcon } from "@trueskin-web/theme/icons/subscriptionFilledIcon"

import { TLongTermDiscountsTimelineItem } from "../types"

export const LongTermDiscountsTimelineCardItem = ({
  date,
  showCheckIcon,
  showPackageIcon,
}: TLongTermDiscountsTimelineItem) => {
  return (
    <div
      sx={{
        borderRadius: 4,
        height: 64,
        width: 70,
        display: "flex",
        px: 2,
        py: 1,
        color: showCheckIcon ? "white" : "neutralsDarkGrey",
        background: showCheckIcon ? "brandPrimary" : "white",
        border: `1px solid ${showCheckIcon ? "brandPrimary" : "lightGrey"}`,
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <div sx={{ fontSize: 12, lineHeight: "12px" }}>
          {formatDate(new Date(date), {
            day: "numeric",
          })}
        </div>

        <div sx={{ fontWeight: 700, fontSize: 14, lineHeight: "20px" }}>
          {formatDate(new Date(date), {
            month: "short",
          })}
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "2px",
          justifyContent: "space-between",
          alignSelf: "stretch",
        }}
      >
        <Icon
          icon={checkCircleFilledIcon}
          size="sm"
          sx={{ visibility: showCheckIcon ? "visible" : "hidden" }}
        />

        <Icon
          icon={subscriptionFilledIcon}
          size="sm"
          sx={{ visibility: showPackageIcon ? "visible" : "hidden" }}
        />
      </div>
    </div>
  )
}
