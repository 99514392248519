const CardBadge = ({ description }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
      px: 2,
      py: 1,
      borderRadius: 16,
      gap: 1,
      background: "lightBlue",
      width: "fit-content",
      fontSize: 12,
    }}
  >
    <div
      sx={{
        width: 8,
        height: 8,
        backgroundColor: "primaryDark",
        borderRadius: "50%",
      }}
    />
    <div sx={{ color: "primaryDark" }}>{description}</div>
  </div>
)

export default CardBadge
