import { StaticImage } from "gatsby-plugin-image"

import { AB_TESTS } from "@trueskin-web/ab-experiments"
import {
  amplitudeClient,
  orderClient,
  useMutation,
  useQueryClient,
} from "@trueskin-web/apis"
import { Button, Card } from "@trueskin-web/components"
import { useVWOExperimentStatus } from "@trueskin-web/services"

import { useLastOrderSummary, useUpcomingOrderSummary } from "../../hooks"

import { longTermDiscountsService } from "./long-term-discounts.service"
import { LongTermDiscountOffer } from "./types"

const LongTermDiscountsCancelCardInternal = ({
  offer,
  className,
}: {
  offer: LongTermDiscountOffer
  className?: string
}) => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: removeUpcomingVoucher,
    error: removeUpcomingVoucherError,
    isLoading: removeUpcomingVoucherIsLoading,
  } = useMutation(orderClient.removeUpcomingVoucher, {
    onSuccess: () =>
      (async () => {
        amplitudeClient.trackEvent(
          amplitudeClient.ANALYTIC_EVENTS.VOUCHER_REMOVE_CLICKED,
          {
            [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "longterm_discounts",
            discount_selection: `${offer.percentage}%`,
          }
        )
        await Promise.allSettled([
          queryClient.refetchQueries("upcomingOrderSummary"),
          queryClient.refetchQueries("getUpcomingSummary"),
          queryClient.refetchQueries("upcomingOrderVariations"),
          // queryClient.refetchQueries("formelOnlyUpcomingPresets"),
          queryClient.refetchQueries("currentSubscriptionSummary"),
          queryClient.refetchQueries("addonsUpcomingPresets"),
        ])
      })(),
  })

  return (
    <Card
      sx={{
        gap: 2,
        border: "1px solid lightGray",
      }}
      className={className}
    >
      <div sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <span sx={{ fontSize: 18, lineHeight: "26px", fontWeight: 700 }}>
          Du erhältst einen {offer?.months}-Monats-Rabatt!
        </span>

        <StaticImage
          src="../../images/rebranded/long-term-discounts-promo-small.png"
          alt=""
          sx={{ width: 115, height: 70 }}
        />
      </div>

      <div sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Button
          data-testid="longTermDiscounts-CancelOffer-btn"
          rounded
          onClick={() => removeUpcomingVoucher(offer.voucherCode)}
          isDisabled={removeUpcomingVoucherIsLoading}
        >
          Rabatt entfernen
        </Button>

        <div
          sx={{
            fontSize: 12,
            lineHeight: "16px",
            color: "neutralsDarkGrey",
            textAlign: "center",
          }}
        >
          Wenn Du den Rabatt entfernst, werden Dir ab der nächsten Lieferung
          wieder wie gewohnt 44,00 € monatlich für das 2-Monats-Set berechnet.
        </div>
      </div>
    </Card>
  )
}

export const LongTermDiscountsCancelCard = ({
  className,
}: {
  className?: string
}) => {
  const { isLongTermDiscountsVariantActive } = useVWOExperimentStatus(
    AB_TESTS.LongTermDiscounts
  )

  const { lastOrderSummary, isLastOrderSummaryLoading } = useLastOrderSummary({
    enabled: isLongTermDiscountsVariantActive(1),
  })

  const { upcomingOrderSummary, isUpcomingOrderSummaryLoading } =
    useUpcomingOrderSummary({
      enabled:
        isLongTermDiscountsVariantActive(1) &&
        !isLastOrderSummaryLoading &&
        !longTermDiscountsService.isOfferActive(lastOrderSummary),
    })

  if (
    !isLongTermDiscountsVariantActive(1) ||
    isUpcomingOrderSummaryLoading ||
    !longTermDiscountsService.canRemoveOffer(
      lastOrderSummary,
      upcomingOrderSummary
    )
  ) {
    return null
  }

  return (
    <LongTermDiscountsCancelCardInternal
      className={className}
      offer={
        longTermDiscountsService.findDiscountOffer(
          upcomingOrderSummary
        ) as LongTermDiscountOffer
      }
    />
  )
}
