import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.TERMS_AND_CONDITIONS_DISMISSED

const storageTypes = storageService.getStorageTypes()

const isTermsAndConditionsDismissed = () =>
  !!storageService.getItem({
    id: storageKey,
    storageType: storageTypes.SESSION,
  })

const dismissTermsAndConditions = () =>
  storageService.setItem({
    id: storageKey,
    data: true,
    storageType: storageTypes.SESSION,
  })

export { isTermsAndConditionsDismissed, dismissTermsAndConditions }
