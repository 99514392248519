import { add } from "date-fns"

import { Icon } from "@trueskin-web/components"
import { formatCurrency, formatDate } from "@trueskin-web/functions"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"

import { CardSection } from "./card-helpers"
import { useOrderCard } from "./order-price-breakdown-card"

type OrderPayment = {
  /**
   * Payment index in the timeline.
   * It must start from 1 for proper translations.
   */
  index: number

  /**
   * Payment amount that has already been paid, or will be paid in the future.
   * The value is in cents.
   */
  amount: number

  /**
   * Currency code of the payment.
   * It currently supports EUR, CHF, and BRL.
   */
  currency: string

  /**
   * Shows the gap (currently implemented as a whitespace) between the amount and the currency.
   * Defaults to true.
   */
  amountToCurrencyGap?: boolean

  /**
   * Date and time of the payment.
   * You can use any format value that you want to display, since this value is typed as string.
   */
  dateTime: string

  /**
   * Boolean that indicates if the payment has already been paid.
   */
  paid?: boolean
}

type OrderPayments = OrderPayment[]

const paymentLabels = ["Erste Zahlung", "Zweite Zahlung", "Dritte Zahlung"]

const TimelineItemLine = ({
  hideCircle,
  hideLine,
  icon,
  color,
}: {
  hideCircle?: boolean
  hideLine?: boolean
  icon?: any
  color?: string
}) => {
  return (
    <div
      sx={{
        width: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!hideCircle &&
        (icon ? (
          <Icon
            icon={icon}
            size="sm"
            sx={{
              transform: "scale(1.25)",
              color: color,
              // my: "-2px",
            }}
          />
        ) : (
          <div
            sx={{
              border: "2px solid",
              borderColor: color ?? "neutralsMediumGrey",
              borderRadius: "50%",
              width: 16,
              height: 16,
            }}
          />
        ))}

      {!hideLine && (
        <div
          sx={{
            borderRightWidth: 2,
            borderRightStyle: "solid",
            borderRightColor: color ?? "neutralsMediumGrey",
            flex: 1,
            height: "100%",
          }}
        />
      )}
    </div>
  )
}

const EmptyTimelineItem = ({ color }: { color?: string }) => {
  return (
    <div sx={{ display: "flex", gap: 3, alignSelf: "stretch", height: 12 }}>
      <TimelineItemLine hideCircle color={color} />

      <div sx={{ display: "flex", alignSelf: "stretch" }}></div>
    </div>
  )
}

const TimelineItem = ({
  payment,
  isLastPayment,
}: {
  payment: OrderPayment
  isLastPayment: boolean
}) => {
  return (
    <>
      <div sx={{ display: "flex", gap: 3, alignSelf: "stretch" }}>
        <TimelineItemLine
          hideLine={isLastPayment}
          {...(payment.paid && { icon: checkCircleFilledIcon, color: "black" })}
        />

        <div
          sx={{
            display: "flex",
            gap: 3,
            flex: 1,
            mt: -1,
          }}
        >
          <div sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div
              sx={{
                fontSize: 14,
                lineHeight: "20px",
                fontWeight: payment.paid ? 700 : undefined,
              }}
            >
              {paymentLabels[payment.index - 1] ?? `Zahlung ${payment.index}`}
            </div>

            <div
              sx={{
                fontSize: 14,
                lineHeight: "20px",
                color: "neutralsDarkGrey",
              }}
            >
              {payment.dateTime}
            </div>
          </div>

          <div sx={{ fontSize: 14, lineHeight: "20px" }}>
            {formatCurrency(payment.amount / 100, payment.currency, 2)}
          </div>
        </div>
      </div>

      {!isLastPayment && (
        <EmptyTimelineItem {...(payment.paid && { color: "black" })} />
      )}
    </>
  )
}

const PaymentTimeline = ({ simulate }: { simulate?: boolean }) => {
  const { order, invoices, orderPrices } = useOrderCard()
  const subscriptionRenewalDate = orderPrices.renewalDate // TODO @fox: very weird to access renewalDate from orderPrices, even if it works

  const simulateInvoices = () => {
    return Array.from({ length: order.instalments.count }, (_, index) => {
      const amount = orderPrices.total / order.instalments.count

      return {
        index: index + 1,
        amount: amount,
        currency: order.currency,
        dateTime: formatDate(
          index === 0
            ? new Date(subscriptionRenewalDate)
            : add(new Date(subscriptionRenewalDate), {
                [`${order.instalments.frequency.period}s`]:
                  order.instalments.frequency.value + index - 1,
              }),
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ),
        paid: false,
      }
    })
  }

  const processInvoicesFromState = () => {
    return order.instalments.invoices.map((invoice, index) => {
      const rawInvoice = invoices?.find((i) => i.id === invoice.id)
      const rawInvoiceDate = rawInvoice?.paidAt || rawInvoice?.collectAt

      return {
        index: index + 1,
        amount: invoice.total,
        currency: invoice.currency,
        dateTime: rawInvoiceDate
          ? formatDate(new Date(rawInvoiceDate), {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "",
        paid: invoice.status === "paid",
      }
    })
  }

  const payments =
    !order?.instalments?.invoices?.length && simulate
      ? simulateInvoices()
      : processInvoicesFromState()

  if (order.instalments.count < 2) {
    return null
  }

  return (
    <CardSection
      title="Zahlungszeitplan"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {payments.map((payment, index) => (
        <TimelineItem
          key={index}
          payment={payment}
          isLastPayment={index === payments.length - 1}
        />
      ))}
    </CardSection>
  )
}
PaymentTimeline.displayName = "PaymentTimeline"

export { PaymentTimeline }
