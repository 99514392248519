import { useMemo } from "react"

import { usePrimaryPaymentMethod } from "@trueskin-web/portal/src/hooks"

import PaymentBanner from "./payment-banner"
import PaymentLogo from "./payment-logo"

const PaymentVendor = ({ size, asBanner }) => {
  const { data: { data } = {}, isFetching } = usePrimaryPaymentMethod()

  const Component = useMemo(
    () => (asBanner ? PaymentBanner : PaymentLogo),
    [asBanner]
  )

  return <Component size={size} payment={data} isLoading={isFetching} />
}

export default PaymentVendor
