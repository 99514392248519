import { Card, CardContent } from "../../card"
import CdnImage from "../../cdn-image"

const DoctorImage = () => (
  <CdnImage
    src={
      "https://a.storyblok.com/f/239902/334x334/0bc855e288/doctor_17.webp/m/1272x0"
    }
    width="56"
    height="56"
    sx={{
      borderRadius: "50%",
      aspectRatio: 1,
    }}
  />
)

const cardContents = [
  {
    title: "Your first monthly check-in with Sarah is in 23 days",
  },
  {
    title: "Your first monthly check-in with Sarah is in 23 days",
    description:
      "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
  },
  {
    title: (
      <div>
        Your first monthly check-in with Sarah is in{" "}
        <span sx={{ color: "primary" }}>23 days</span>
      </div>
    ),
    description:
      "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
  },
  {
    title: (
      <div>
        Your first monthly check-in with Sarah is in{" "}
        <span sx={{ color: "primary" }}>23 days</span>
      </div>
    ),
    description:
      "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
    rightImage: <DoctorImage />,
  },
]

const CardsWithContent = () => {
  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {cardContents.map((cardContent, index) => (
        <Card key={index}>
          <CardContent {...cardContent} />
        </Card>
      ))}
    </div>
  )
}

export default CardsWithContent
