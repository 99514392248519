import { ReactNode } from "react"

export const HorizontalListItem = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      className={className}
      sx={{
        // maxWidth: props.maxWidth ?? 300,
        wordBreak: "break-all",
        textAlign: "left",
        fontSize: 12,
        // lineHeight: "16px",
        display: "inline-flex",
        flexGrow: 1,
        alignItems: "center",
        // pr: 5,
        pr: 3,
        // mr: 2,
        position: "relative",
        verticalAlign: "middle",
        "::before": {
          backgroundColor: "neutralsDarkGrey",
          borderRadius: "50%",
          content: '""',
          height: 4,
          width: 4,
          position: "absolute",
          left: "-6px",
          top: "55%",
          transform: "translate(-50%,-50%)",
        },
      }}
    >
      {children}
    </div>
  )
}

export const HorizontalList = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <div
      sx={{
        listStyleType: "none",
        overflow: "hidden",
        m: 0,
      }}
      className={className}
    >
      {children}
    </div>
  )
}
