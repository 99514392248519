import { forwardRef } from "react"

import NavLink, { type NavLinkProps } from "./nav-link"

const HeaderNavLink = forwardRef<HTMLButtonElement, NavLinkProps>(
  (props, ref) => (
    <NavLink
      ref={ref}
      sx={{
        justifyContent: "start",
      }}
      {...props}
    />
  )
)

HeaderNavLink.displayName = "HeaderNavLink"

export default HeaderNavLink
