import { type UserSubscriptionGetHistoricalSubscriptionsResponse } from "@trueskin/vortex-sdk"
import { type UseQueryOptions, type UseQueryResult } from "react-query"

import { useQuery, userClient } from "@trueskin-web/apis"

type TOrdersHistoryApiResponse =
  UserSubscriptionGetHistoricalSubscriptionsResponse
type TOrdersHistoryApiError = unknown

export type TOrdersHistory = TOrdersHistoryApiResponse

type TUseOrdersHistory<
  TData = TOrdersHistoryApiResponse,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  ordersHistory: TOrdersHistory | undefined
  isOrdersHistoryLoading: boolean
  ordersHistoryError: unknown
}

export const useOrdersHistory = ({
  enabled = true,
  staleTime = 60 * 1000, // 1 minute
  ...options
}: UseQueryOptions<
  TOrdersHistoryApiResponse,
  TOrdersHistoryApiError,
  TOrdersHistory
> = {}): TUseOrdersHistory => {
  const query = useQuery<
    TOrdersHistoryApiResponse,
    TOrdersHistoryApiError,
    TOrdersHistory
  >("ordersHistory", userClient.getSubscriptionHistory, {
    enabled,
    staleTime,
    ...options,
    select: (response: TOrdersHistoryApiResponse) => response as TOrdersHistory,
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    ordersHistory: data,
    isOrdersHistoryLoading: isLoading,
    ordersHistoryError: error,
  }
}
