import { navigate } from "gatsby"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"

import { Routes } from "@trueskin-web/translations"

import Spinner from "./spinner"

function usePrevious(value?: boolean) {
  const ref = useRef<undefined | boolean>()

  useEffect(() => {
    if (ref) {
      ref.current = value
    }
  }, [value])

  return ref.current
}

const Redirect = ({ fallbackUrl = Routes.App.url }) => {
  navigate(fallbackUrl, { replace: true })
  return null
}

export type RouteGuardProps = {
  token: string
  condition: () => Promise<boolean>
  component: ReactNode
  fallback: (props: { fallbackUrl?: string }) => React.JSX.Element | null
}

const RouteGuard = ({
  token,
  condition = async () => true,
  component: Component,
  fallback: Fallback = Redirect,
}: RouteGuardProps) => {
  const [isRouteBlocked, setIsRouteBlocked] = useState()

  const isRoutePrevBlocked = usePrevious(isRouteBlocked)

  const Route = useMemo(
    () =>
      isRouteBlocked === undefined
        ? () => <Spinner isFullPage />
        : isRouteBlocked
        ? Fallback
        : Component,
    [Component, Fallback, isRouteBlocked]
  )

  useEffect(() => {
    const ctrl = new AbortController()
    const { signal } = ctrl

    checkGuards({ signal })

    async function checkGuards({ signal }) {
      // TODO: add abort signal support in fetch client services
      // TODO: pass signal from condition to query/fetch function
      const isConditionSatisfied = await condition({ signal, token })

      if (signal.aborted) {
        return
      }

      if (!isConditionSatisfied === isRoutePrevBlocked) {
        return
      }

      if (!isConditionSatisfied) {
        setIsRouteBlocked(true)
        return
      }

      setIsRouteBlocked(false)
    }

    return () => {
      ctrl.abort()
    }
  }, [condition, token, isRoutePrevBlocked])

  return <Route />
}

export default RouteGuard
