import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  fromPromise,
  split,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { apiVortex } from "@trueskin-web/apis/src/api"
import { logout } from "@trueskin-web/apis/src/auth"
import { navigate } from "@trueskin-web/components/src/navigate"
import { hosts } from "@trueskin-web/core"
import { i18nLocale } from "@trueskin-web/locales"
import { authService } from "@trueskin-web/services"
import { Routes } from "@trueskin-web/translations"

const refreshToken = async () => {
  const { tofuJwt } = await apiVortex().postAuthTofu()

  if (tofuJwt) {
    authService.saveBdJwt(tofuJwt)
  }

  return authService.getBdJwt()
}

const handleBDAuthErrors = onError(({ graphQLErrors, operation, forward }) => {
  if (
    graphQLErrors?.some(
      (error) => error.extensions?.response?.statusCode === 403
    )
  ) {
    // this should only execute if the bd jwt is expired
    // so the user should have already been validated a while back
    return fromPromise(
      refreshToken().catch((error) => {
        // if the refresh token fails with 401/403, it means the core session never existed or expired
        // so we need to force a complete logout
        if ([401, 403].includes(error?.statusCode)) {
          logout()

          navigate(Routes.App.Login.url, {
            replace: true,
          })

          return
        }

        throw error
      })
    )
      .filter((value) => Boolean(value))
      .flatMap(() => forward(operation))
  }
})

// middleware to add auth headers based off the core jwt to requests
const coreAuthLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...(authService.getJwt() && {
        authorization: `Bearer ${authService.getJwt()}`,
      }),
      ...headers,
    },
  }))

  return forward(operation)
})

// middleware to add auth headers based off the bd jwt to requests
const bdAuthLink = from([
  handleBDAuthErrors,
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...(authService.getBdJwt() && {
          authorization: `Bearer ${authService.getBdJwt()}`,
        }),
        ...headers,
      },
    }))
    return forward(operation)
  }),
])

// middleware to add localization headers to requests
const localeLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      "x-fs-locale": i18nLocale(),
      ...headers,
    },
  }))

  return forward(operation)
})

// middleware to update the bd jwt if the current registration is fetched from the API and it returns a token
// the API does not return a new jwt if the registration is completed
const updateCurrentRegistrationJwtLink = new ApolloLink(
  (operation, forward) => {
    return forward(operation).map((response) => {
      const { operationName } = operation

      if (
        operationName === "GetCurrentRegistrationDetails" &&
        response.data?.currentRegistration?.jwt
      ) {
        authService.saveBdJwt(response.data?.currentRegistration?.jwt)
      }

      return response
    })
  }
)

// terminating link that sends the request to gql bff API service
const bffApiLink = createHttpLink({
  uri: hosts.bffApiUrl,
})

// terminating link that sends the request to tofu API service
const tofuApiLink = createHttpLink({
  uri: `${hosts.tofuApiUrl}/graphql`,
})

// requests chain for BFF API requests
const bffRequestsLink = from([localeLink, coreAuthLink, bffApiLink])

// requests chain for BD API requests
const bdRequestsLink = from([
  localeLink,
  bdAuthLink,
  updateCurrentRegistrationJwtLink,
  tofuApiLink,
])

// global gql request splitter, sends requests to the appropriate link based off the client name
const gqlRouterLink = split(
  (operation) => operation.getContext().clientName === "bd",
  bdRequestsLink,
  bffRequestsLink
)

const GraphQLProvider = (props) => {
  const client = new ApolloClient({
    link: gqlRouterLink,
    cache: new InMemoryCache({
      typePolicies: {
        CurrentPatient: {
          // fixes a cache mismatch warning from apollo
          keyFields: ["customerId"],
        },
      },
    }),
  })

  return <ApolloProvider client={client} {...props} />
}

export { GraphQLProvider }
