import { useTranslation } from "@trueskin-web/translations"

import { Card } from "../../card"
import OrderStatus from "../../order-status"

const TrackingCards = () => {
  const { t } = useTranslation()

  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Card>
        <OrderStatus />
      </Card>
      <Card>
        <OrderStatus step={t("OrderStatus.steps.planCreated")} />
      </Card>
      <Card>
        <OrderStatus step={t("OrderStatus.steps.formulaProduced")} />
      </Card>
      <Card>
        <OrderStatus step={t("OrderStatus.steps.packageSent")} />
      </Card>
    </div>
  )
}

export default TrackingCards
