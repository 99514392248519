import { gql } from "@apollo/client"

export const patientFragment = `#graphql
  fragment AccountDetails on Patient {
    id
    accountId
    email
    registrationDate
    profile {
      id
      salutation
      firstName
      lastName
      phoneNumber
      billingAddress {
        id
        country
        city
        address
        postalCode
      }
      shippingAddress {
        id
        country
        city
        address
        postalCode
      }
    }
    medicalData {
      gender
      birthdate
    }
  }
`

export const GET_CURRENT_PATIENT_PROFILE = gql`
  query GetCurrentPatientProfile {
    #    currentRegistration {
    #      id
    #      jwt
    #      account {
    #        id
    #        emailVerified
    #      }
    #    }
    currentPatient {
      id
      accountId
      email
      registrationDate
      agreedToTermsAndConditionsAt
      metadata {
        systemGenerated
      }
      profile {
        id
        salutation

        firstName
        lastName
        phoneNumber
        billingAddress {
          id
          country
          city
          address
          postalCode
        }
      }
      medicalData {
        gender
        birthdate
      }
    }
    #    nextScreen {
    #      type
    #      showWelcomeBackScreen
    #    }
  }
`

export const GET_PROFILE_NAME_SALUTATION = gql`
  query GetCurrentPatientProfile {
    currentPatient {
      id
      profile {
        id
        salutation
        firstName
        lastName
      }
    }
  }
`

export const GET_PROFILE_BIRTH_PHONE = gql`
  query GetCurrentPatientProfile {
    currentPatient {
      id
      profile {
        id
        phoneNumber
      }
      medicalData {
        birthdate
      }
    }
  }
`

export const GET_BILLING_ADDRESS = gql`
  query GetCurrentPatientProfile {
    currentPatient {
      id
      profile {
        id
        billingAddress {
          id
          country
          city
          address
          postalCode
        }
      }
    }
  }
`

export const INITIATE_REGISTRATION = gql`
  mutation InitiateRegistration($questionnaireType: String) {
    initiateRegistration(questionnaireType: $questionnaireType) {
      id
      jwt
    }
  }
`

export const REGISTER_CUSTOMER = gql`
  mutation RegisterCustomerMutation(
    $email: String!
    $password: String
    $deviceToken: DeviceToken
    $appsflyerId: String
  ) {
    registerAccount(
      email: $email
      password: $password
      deviceToken: $deviceToken
      appsflyerId: $appsflyerId
    ) {
      id
    }
  }
`

export const SKIP_WELCOME_BACK_SCREEN = gql`
  mutation {
    skipWelcomeBackScreen {
      id
    }
  }
`

export const RESEND_EMAIL = gql`
  mutation {
    resendAccountConfirmationEmail {
      success
    }
  }
`

export const LOGIN = gql`
  mutation LogIntoAccount(
    $email: String!
    $password: String!
    $deviceToken: DeviceToken
    $appsflyerId: String
    $registrationId: String
  ) {
    login(
      email: $email
      password: $password
      deviceToken: $deviceToken
      appsflyerId: $appsflyerId
      registrationId: $registrationId
    ) {
      jwt
    }
  }
`

export const AUTO_LOGIN = gql`
  mutation AutoLogIntoAccount($coreJwt: String!, $bdJwt: String!) {
    autoLogin(coreJwt: $coreJwt, bdJwt: $bdJwt) {
      jwt
    }
  }
`

export const LOGOUT = gql`
  mutation LogoutAccount {
    logout {
      id
    }
  }
`

export const SEND_MAGIC_LINK_TO_EMAIL = gql`
  mutation LoginWithEmailOtp($email: String!) {
    loginWithEmailOtp(email: $email) {
      success
    }
  }
`

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOtp($emailOtpToken: String!) {
    verifyEmailOtp(emailOtpToken: $emailOtpToken) {
      tofu {
        jwt
      }
      core {
        jwt
      }
    }
  }
`

export const SEND_EMAIL = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      emailSent
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ResetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password) {
      success
    }
  }
`

export const DELETE_ACCOUNT = gql`
  mutation {
    deleteAccount {
      deleted
    }
  }
`

export const CREATE_PATIENT_PROFILE = gql`
  mutation CreatePatientProfile($profileBody: CreatePatientProfile!) {
    createPatientProfile(profile: $profileBody) {
      id
      profile {
        id
        salutation
        firstName
        lastName
      }
    }
  }
`

export const CREATE_PATIENT_MEDICAL_PROFILE = gql`
  mutation CreatePatientMedicalProfile(
    $profileBody: CreatePatientMedicalProfile!
  ) {
    createPatientMedicalProfile(profile: $profileBody) {
      id
      medicalData {
        birthdate
      }
      profile {
        phoneNumber
      }
    }
  }
`

export const CREATE_PATIENT_ADDRESS_PROFILE = gql`
  mutation CreatePatientMedicalProfile($profileBody: CreatePatientAddress!) {
    createPatientAddressProfile(profile: $profileBody) {
      id
      profile {
        billingAddress {
          country
          address
          city
          postalCode
        }
      }
    }
  }
`

export const UPDATE_PATIENT_PROFILE = gql`
  mutation UpdatePatientProfile($profileBody: UpdatePatientProfile!) {
    updatePatientProfile(profile: $profileBody) {
      id
      accountId
      email
      profile {
        id
        salutation
        firstName
        lastName
        phoneNumber
        billingAddress {
          id
          country
          city
          address
          postalCode
        }
      }
      medicalData {
        birthdate
        gender
      }
    }
  }
`

export const GET_PAYMENT_DETAILS = gql`
  query GetCasePaymentDetails($caseId: String!) {
    casePaymentDetails(caseId: $caseId) {
      price {
        amount
        currency
      }
    }
  }
`

export const GET_CASE_PAYMENT_STATUS = gql`
  query GetCasePaymentStatus($caseId: String!) {
    casePaymentStatus(caseId: $caseId) {
      paymentAcknowledged
    }
  }
`

export const REQUEST_PAYMENT_LINK = gql`
  mutation RequestPaymentLink($caseId: String!) {
    requestPaymentLink(caseId: $caseId) {
      url
    }
  }
`

export const ACKNOWLEDGE_CASE_PAYMENT = gql`
  mutation AcknowledgeCasePayment($caseId: String!) {
    acknowledgeCasePayment(caseId: $caseId) {
      id
      extendedStatus
    }
  }
`

export const UPDATE_PATIENT_SHIPPING_ADDRESS = gql`
  mutation UpdatePatientProfile($addressBody: UpdatePatientShippingAddress!) {
    updatePatientShippingAddress(address: $addressBody) {
      id
      profile {
        shippingAddress {
          id
          salutation
          country
          city
          address
          postalCode
        }
      }
    }
  }
`

export const UPDATE_DEVICE_TOKEN = gql`
  mutation UpdateDeviceToken($deviceToken: DeviceToken!) {
    updateDeviceToken(deviceToken: $deviceToken) {
      updated
    }
  }
`
