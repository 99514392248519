import { Fragment } from "react"

export const linkedinIcon = {
  path: (
    <Fragment>
      <rect width="24" height="24" rx="6" fill="currentColor" />
      <g>
        <path
          d="M5 6.86765C5 6.41719 5.15766 6.04557 5.47297 5.75279C5.78828 5.45999 6.1982 5.3136 6.7027 5.3136C7.1982 5.3136 7.59909 5.45773 7.90541 5.74603C8.22072 6.04333 8.37838 6.43071 8.37838 6.90819C8.37838 7.34063 8.22523 7.70098 7.91892 7.98927C7.60361 8.28657 7.18919 8.43522 6.67568 8.43522H6.66216C6.16666 8.43522 5.76577 8.28657 5.45946 7.98927C5.15315 7.69198 5 7.3181 5 6.86765ZM5.17568 18.692V9.66495H8.17568V18.692H5.17568ZM9.83784 18.692H12.8378V13.6514C12.8378 13.3361 12.8739 13.0929 12.9459 12.9217C13.0721 12.6154 13.2635 12.3564 13.5203 12.1447C13.777 11.933 14.0991 11.8271 14.4865 11.8271C15.4955 11.8271 16 12.5073 16 13.8677V18.692H19V13.5163C19 12.183 18.6847 11.1717 18.0541 10.4825C17.4234 9.79333 16.5901 9.44873 15.5541 9.44873C14.3919 9.44873 13.4865 9.94873 12.8378 10.9487V10.9758H12.8243L12.8378 10.9487V9.66495H9.83784C9.85585 9.95323 9.86486 10.8496 9.86486 12.3541C9.86486 13.8586 9.85585 15.9712 9.83784 18.692Z"
          fill="black"
        />
      </g>
    </Fragment>
  ),
  viewBox: "0 0 24 24",
}
