import { isAfter } from "date-fns"
import { useCallback, useState } from "react"

import { useMutation, useQueryClient, userClient } from "@trueskin-web/apis"
import { Dialog } from "@trueskin-web/components"
import { useAuth, useBdAuth } from "@trueskin-web/context"
import { getErrorMessage } from "@trueskin-web/functions"
import { termsAndConditionsService } from "@trueskin-web/services"
import { useTranslation } from "@trueskin-web/translations"

const termsAndConditionsDate =
  process.env.GATSBY_TERMS_AND_CONDITIONS_UNIX_TIMESTAMP * 1000

const TermsAndConditions = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  let { user: { firstName, agreedToTermsAndConditionsAt } = {} } = useAuth()
  const { user } = useBdAuth()

  // use the tofu acceptance date if one for user isn't resolved
  if (user?.agreedToTermsAndConditionsAt && !agreedToTermsAndConditionsAt) {
    agreedToTermsAndConditionsAt = user.agreedToTermsAndConditionsAt
  }
  const [isDismissed, setIsDismissed] = useState(
    termsAndConditionsService.isTermsAndConditionsDismissed()
  )

  const isTermsAndConditionSubmitted = isAfter(
    new Date(agreedToTermsAndConditionsAt),
    new Date(termsAndConditionsDate)
  )

  const {
    mutateAsync: submitTermsAgreement,
    isLoading,
    error,
  } = useMutation(userClient.submitTermsAgreement, {
    onSuccess: () => queryClient.refetchQueries("currentUser"),
  })

  const dismissTermsAgreement = useCallback(() => {
    termsAndConditionsService.dismissTermsAndConditions()
    setIsDismissed(true)
  }, [])

  if (!termsAndConditionsDate) {
    return null
  }

  if (isTermsAndConditionSubmitted) {
    return null
  }

  if (isDismissed) {
    return null
  }

  return (
    <Dialog
      sx={{
        "section >ul >li": {
          "&, &:not(:first-child)": {
            mt: 1,
          },
        },
      }}
      initOnApiCooldown
      title={t("TermsAndConditions.title")}
      onDismiss={dismissTermsAgreement}
      primaryAction={submitTermsAgreement}
      primaryActionLabel={t("TermsAndConditions.primaryAction")}
      isPrimaryActionLoading={isLoading}
      secondaryAction={dismissTermsAgreement}
      secondaryActionLabel={t("TermsAndConditions.secondaryAction")}
      isSecondaryActionDisabled={isLoading}
      actionError={error ? getErrorMessage(error) : null}
    >
      {t("TermsAndConditions.content", { firstName })}
    </Dialog>
  )
}

export default TermsAndConditions
