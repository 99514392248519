import { ElementType } from "react"

import { storageService, useVWOExperimentStatus } from "@trueskin-web/services"

import { AB_TESTS_OPTIONS } from "./ab-tests"
import { AB_TESTS_DATA } from "./ab-tests"

const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

/**
 * @deprecated use useVWOExperimentStatus instead
 */
export const isABTestActive = (
  testName: AB_TESTS_OPTIONS,
  variant?: number
) => {
  const activeVariant = storageService.getItem({
    id: AB_TESTS_DATA[testName]?.lsKey,
  })
  if (variant) {
    return activeVariant === variant
  } else return activeVariant > 0
}

const ABTestContainer = ({
  testName,
  variants,
  fallbackComponent: FallbackComponent,
  ...props
}: {
  testName: AB_TESTS_OPTIONS
  variants: [number, ElementType<{ variant: number }>][]
  fallbackComponent?: ElementType
}) => {
  const experiment = useVWOExperimentStatus(testName)
  const activeVariant = experiment.variation
  if (
    !isProduction &&
    ![true, "true"].includes(process.env.GATSBY_DISABLE_AB_TEST_LOGS || "")
  ) {
    if (AB_TESTS_DATA[testName]?.deactivated) {
      console.error(`//  ABTestContainer ${testName}  IS DEACTIVATED`)
    } else {
      console.info("ABTestContainer", {
        testName,
        id: AB_TESTS_DATA[testName]?.lsKey,
        activeVariant,
        variants,
        FallbackComponent,
        props,
      })
      if (!activeVariant) {
        console.info(`// ABTestContainer ${testName} IN CONTROL GROUP`)
      }
      for (const [variant] of variants) {
        if (activeVariant === variant) {
          console.info(
            `// ABTestContainer ${testName} variant ${variant} IS ACTIVE`
          )
        }
        console.info(
          `window.localStorage.setItem('${AB_TESTS_DATA[testName]?.lsKey}',${variant}); // Enable ABTestContainer ${testName} variant ${variant}`
        )
      }
    }
  }
  if (!FallbackComponent) {
    FallbackComponent = () => <></>
  }

  if (!activeVariant) {
    return <FallbackComponent {...props} /> || null
  }

  if (AB_TESTS_DATA[testName].deactivated) {
    storageService.removeItem({ id: AB_TESTS_DATA[testName].lsKey })
    const cleanupLsKeys = AB_TESTS_DATA[testName].cleanupLsKeys || []
    cleanupLsKeys.forEach((lsKey) => storageService.removeItem({ id: lsKey }))

    return <FallbackComponent {...props} /> || null
  }

  for (const [variant, Component] of variants) {
    if (String(variant) === String(activeVariant) && Component) {
      return (
        <Component variant={variant} {...AB_TESTS_DATA[testName]} {...props} />
      )
    }
  }

  return <FallbackComponent {...props} /> || null
}

export default ABTestContainer
