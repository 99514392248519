import { useEffect } from "react"

import { useQuery, useQueryClient, userClient } from "@trueskin-web/apis"
import { useAuth } from "@trueskin-web/context"

const useCurrentSubscriptionSummary = (deps = []) => {
  const queryClient = useQueryClient()

  const {
    user: {
      subscription: { id: subscriptionId },
    },
  } = useAuth()

  // TODO: this effect always resets the "currentSubscriptionSummary" from the react-query cache
  // so we actually never have anything in the cache, even if we set staleTime
  // which makes Einkaufe page do multiple queries, which sucks
  // so maybe we should look into a better way to handle this (maybe via a context with state?)
  useEffect(() => {
    queryClient.invalidateQueries("currentSubscriptionSummary")
  }, [queryClient, subscriptionId])

  return useQuery(
    ["currentSubscriptionSummary", subscriptionId, ...deps],
    userClient.getSubscriptionSummary,
    {
      // staleTime: 10 * 60 * 1000, // 10 minute
      onSuccess: (updatedSubscriptionSummary) => {
        const cachedSubscriptionSummary = queryClient.getQueryData([
          "currentSubscriptionSummary",
          subscriptionId,
          ...deps,
        ])

        // always force an update of /users/me when subscription status changes because the payment is async and the UI is
        if (
          updatedSubscriptionSummary?.status !==
          cachedSubscriptionSummary?.status
        ) {
          return queryClient.refetchQueries("currentUser")
        }
      },
    }
  )
}

export default useCurrentSubscriptionSummary
