import { useEffect } from "react"

import { ABTestContainer, AB_TESTS } from "@trueskin-web/ab-experiments"
import { Button, Container, SeparatorWithText } from "@trueskin-web/components"
import { useVWOExperimentStatus, vwoService } from "@trueskin-web/services"

const Steppers = () => {
  const experiment = useVWOExperimentStatus(AB_TESTS.LongTermDiscounts)
  useEffect(() => {
    console.log("experiment", experiment)
  }, [experiment])

  return (
    <Container
      sx={{ p: 20, display: "flex", flexDirection: "column", gap: 40 }}
    >
      <div sx={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <pre>{JSON.stringify(experiment, null, 2)}</pre>
        <Button
          onClick={() =>
            vwoService.triggerVWOCampaign(AB_TESTS.LongTermDiscounts)
          }
        >
          Trigger VWO Campaign
        </Button>
        {/*window.localStorage.setItem('{key}',1)*/}
      </div>
      <SeparatorWithText text={"Active experiment"} />
      <ABTestContainer
        testName={AB_TESTS.LongTermDiscounts}
        variants={[
          [1, () => <div>variant 1 of experiment</div>],
          [2, () => <div>variant 2 of experiment</div>],
        ]}
        fallbackComponent={() => <div>{"no active experiment"}</div>}
      />
    </Container>
  )
}

export default Steppers
