import { formatNumber } from "@trueskin-web/functions"

import StarRating from "./star-rating"

const ContentRating = ({ rating, description, className }) => {
  const rate = parseFloat(rating).toFixed(1)

  if (isNaN(rate)) {
    return null
  }

  return (
    <div className={className}>
      <div
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: "white",
          py: "2px",
          px: 2,
          borderRadius: "999px",
        }}
      >
        <span
          sx={{
            pr: 1,
            fontSize: 1,
          }}
        >
          {formatNumber(rate)}/5
        </span>
        <StarRating
          rating={rate}
          size="sm"
          sx={{
            color: "goldDark",
          }}
        />
      </div>
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          sx={{
            maxWidth: "fit-content",
            mt: 1,
            mb: [0, 1],
            fontSize: 0,
            "*": {
              p: 0,
              m: 0,
              fontSize: 12,
              fontWeight: "normal",
              fontStyle: "normal",
              listStyle: "none",
            },
          }}
        ></div>
      )}
    </div>
  )
}

export default ContentRating
