import { useEffect, useRef } from "react"

const ProgressSteps = ({
  className,
  steps = 4,
  completedSteps = 1,
  color = "primary",
}) => {
  const ref = useRef()

  useEffect(() => {
    const height = ref.current.clientHeight

    document
      .querySelector(":root")
      .style.setProperty("--progress-steps-height", `${height}px`)

    return () => {
      document
        .querySelector(":root")
        .style.setProperty("--progress-steps-height", "0px")
    }
  }, [])

  return (
    <div
      ref={ref}
      className={className}
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${steps}, 1fr)`,
        gridGap: [4, 6],
      }}
    >
      {[...Array(steps)].map((_, index) => (
        <div
          key={index}
          sx={{
            pt: 1,
            backgroundColor: index < completedSteps ? color : "lightGray",
          }}
        />
      ))}
    </div>
  )
}

export default ProgressSteps
