// Inspiration https://almonk.github.io/pylon/
const VStack = ({ spacing = 4, ...otherPros }) => (
  <div
    {...otherPros}
    sx={{
      display: "flex",
      flexDirection: "column",
      "& > *:not(:last-of-type)": {
        mb: spacing,
      },
    }}
  />
)

export default VStack
