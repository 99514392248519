import type { CommitmentPlanSummaryResponse } from "@trueskin/vortex-sdk"
import { add, format } from "date-fns"
import { UseQueryOptions } from "react-query/types/react/types"

import { orderClient, useQuery } from "@trueskin-web/apis"

import { TLongTermDiscountsTimelineItem } from "../types"

const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

type TTLongTermDiscountedPlanLength = 1 | 2 | 4 | 6 | 12
type TLongTermDiscountedPlan = CommitmentPlanSummaryResponse
// {
//   commitment: {
//     length: TTLongTermDiscountedPlanLength // 4
//     purchaseDates: string[] // ["2024-09-10", "2024-09-11"]
//     position: number
//     monthsToGo: number
//   }
// }

type LongTermDiscountedPlanResponse = TLongTermDiscountedPlan
// {
//   data: TLongTermDiscountedPlan
// }
type TLongTermDiscountedPlanError = unknown

type TLongTermDiscountedPlanTimeline = TLongTermDiscountedPlan & {
  timeline: TLongTermDiscountsTimelineItem[]
}

export const useLongTermDiscountsTimeline = (
  { renewalDate }: { renewalDate: string },
  {
    enabled = true,
    staleTime = 60 * 1000, // 1 minute
    ...options
  }: UseQueryOptions<
    LongTermDiscountedPlanResponse,
    TLongTermDiscountedPlanError,
    TLongTermDiscountedPlanTimeline
  > = {}
) => {
  const query = useQuery<
    LongTermDiscountedPlanResponse,
    TLongTermDiscountedPlanError,
    TLongTermDiscountedPlanTimeline
    // TODO: Fix api method which gets called
  >("longTermDiscountsTimeline", orderClient.getLongTermDiscountedPlanSummary, {
    enabled,
    staleTime,
    ...options,
    select: (response) => ({
      ...response,
      timeline: processLongTermDiscountsTimeline({
        renewalDate,
        longTermPlan: response,
      }),
    }),
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    longTermPlan: data,
    isLongTermPlanLoading: isLoading,
    longTermPlanError: error,
  }
}

const processLongTermDiscountsTimeline = ({
  renewalDate,
  longTermPlan,
}: {
  renewalDate: string
  longTermPlan: TLongTermDiscountedPlan
}): TLongTermDiscountsTimelineItem[] => {
  const cycleDuration = isProduction
    ? { period: "month", value: 1 }
    : { period: "day", value: 1 }

  /**
   * TODO @fox: this needs extra logic
   * - if monthsToGo is 0 then the below logic still kicks in, which is bad
   * - if monthsToGo === 2 => last order, computed on user.subscription?.renewalDate
   * - if monthsToGo >= 3 => need to compute next empty date to simulate next invoice, then compute the rest of the empty dates start from user.subscription?.renewalDate
   */
  // const nextEmptyDate: Date =
  //   longTermPlan.commitment.monthsToGo % 2 === 0 // exact number of orders still left
  //     ? new Date(renewalDate as string) // then rely on the subscription renewal date, to correctly calculate the next empty date even if customers/cs updates the next renewal date
  //     : add(new Date(longTermPlan.commitment.purchaseDates.at(-1) as string), {
  //         [cycleDuration.period + "s"]: cycleDuration.value,
  //       }) // else start from the last purchase date
  const nextEmptyDate: Date = new Date(renewalDate as string)

  const emptyDates = new Array(
    longTermPlan.commitment.length - longTermPlan.commitment.purchases.length
  )
    .fill(0)
    .map((_, index) =>
      add(nextEmptyDate, {
        [cycleDuration.period + "s"]: index * cycleDuration.value,
      })
    )

  const timelineItems = new Array(longTermPlan.commitment.length)
    .fill(0)
    .map((_, index) => {
      if (index < longTermPlan.commitment.purchases.length) {
        return {
          date: (longTermPlan.commitment.purchases[index].paidAt ||
            longTermPlan.commitment.purchases[index].collectAt) as string,
          showCheckIcon: !!longTermPlan.commitment.purchases[index].paidAt,
          showPackageIcon: index % 2 === 0,
        }
      } else {
        return {
          date: format(
            emptyDates[index - longTermPlan.commitment.purchases.length],
            "yyyy-MM-dd"
          ),
          showCheckIcon: false,
          showPackageIcon: index % 2 === 0,
        }
      }
    })

  return timelineItems
}
