import { Fragment } from "react"

export const facebookIcon = {
  path: (
    <Fragment>
      <path
        d="M17 2H7a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7a5 5 0 0 0-5-5z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.75 7h-1.5a2.5 2.5 0 0 0-2.5 2.5V11h-1.5v2h1.5v4h2v-4h1.5l.5-2h-2V9.5a.5.5 0 0 1 .5-.5h1.5V7z"
        fill="currentColor"
      />
    </Fragment>
  ),
}
