import { ReactElement } from "react"

import { acneSuccessStoriesImages } from "./images"

export type PatientSuccessStory = {
  patientId: string
  name: string
  journeyDuration: number
  age: number
  condition: string
  severity: string
  images: {
    before: ReactElement | null // TODO: remove null after adding images
    after: ReactElement | null // TODO: remove null after adding images
  }
}

export const successStories: PatientSuccessStory[] = [
  {
    patientId: "65afab1c266af000299618b0",
    name: "Christin",
    journeyDuration: 1,
    age: 34,
    // condition: "Akne Comedonica",
    condition: "Akne Vulgaris (L70.0)",
    severity: "Mild",
    images: {
      before: acneSuccessStoriesImages["65afab1c266af000299618b0"].before,
      after: acneSuccessStoriesImages["65afab1c266af000299618b0"].after,
    },
  },
  // {
  //   patientId: "",
  //   name: "Florian",
  //   journeyDuration: 2,
  //   age: 22,
  //   condition: "Akne Comedonica",
  //   severity: "Mild",
  //   images: {
  //     before: null,
  //     after: null,
  //   },
  // },
  // {
  //   patientId: "xxx",
  //   name: "Anne",
  //   journeyDuration: 3,
  //   age: 999,
  //   // condition: "Akne Comedonica",
  //   condition: "Akne Vulgaris (L70.0)",
  //   severity: "Mild",
  //   images: {
  //     before: acneSuccessStoriesImages["xxx"].before,
  //     after: acneSuccessStoriesImages["xxx"].after,
  //   },
  // },
  {
    patientId: "65231687fef9770029a29483",
    name: "Berna",
    journeyDuration: 7,
    age: 23,
    condition: "Akne Vulgaris (L70.0)",
    severity: "Mild",
    images: {
      before: acneSuccessStoriesImages["65231687fef9770029a29483"].before,
      after: acneSuccessStoriesImages["65231687fef9770029a29483"].after,
    },
  },
  {
    patientId: "600de8066660bf002439ad91",
    name: "Alissa",
    journeyDuration: 8,
    age: 23,
    condition: "Akne Vulgaris (L70.0)",
    severity: "Mild",
    images: {
      before: acneSuccessStoriesImages["600de8066660bf002439ad91"].before,
      after: acneSuccessStoriesImages["600de8066660bf002439ad91"].after,
    },
  },
  // {
  //   patientId: "",
  //   name: "Angelo",
  //   journeyDuration: 8,
  //   age: 18,
  //   condition: "Akne Papulopustulosa",
  //   severity: "Mild",
  //   images: {
  //     before: null,
  //     after: null,
  //   },
  // },
  // {
  //   patientId: "",
  //   name: "Melina",
  //   journeyDuration: 6,
  //   age: 23,
  //   condition: "Akne Conglobata",
  //   severity: "Mild",
  //   images: {
  //     before: null,
  //     after: null,
  //   },
  // },
]
