import { Fragment } from "react"

import { useThemeUI } from "@trueskin-web/theme"

const Colors = () => {
  const {
    theme: { colors },
  } = useThemeUI()

  return (
    <div
      sx={{
        display: "inline-grid",
        gridTemplateColumns: "repeat(3, auto)",
        gridGap: 2,
        p: 2,
      }}
    >
      {Object.keys(colors)
        .sort()
        .map((colorName) => (
          <Fragment key={colorName}>
            <div
              sx={{
                backgroundColor: colors[colorName],
                width: 100,
              }}
            />
            <div>{colorName}</div>
            <div>
              {getComputedStyle(document.body).getPropertyValue(
                colors[colorName].slice(4, -1)
              )}
            </div>
          </Fragment>
        ))}
    </div>
  )
}

export default Colors
