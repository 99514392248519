import { ReactNode } from "react"
import { Divider } from "theme-ui"

export const CardSection = ({
  children,
  className,
  title,
}: {
  children: ReactNode
  className?: string
  title?: string
}) => {
  return (
    <div className={className} sx={{ px: 4 }}>
      {!!title && (
        <div sx={{ fontWeight: 700, fontSize: 16, lineHeight: "24px", mb: 3 }}>
          {title}
        </div>
      )}

      {children}
    </div>
  )
}

export const CardDivider = ({ className }: { className: string }) => {
  return (
    <Divider
      color="neutralsMediumGrey"
      m={0}
      sx={{ width: "100%" }}
      className={className}
    />
  )
}
