import { Fragment } from "react"
import { Helmet } from "react-helmet"

import {
  AccountLink,
  BackButton,
  HeaderActions,
  Heading,
} from "@trueskin-web/components"
import { authService, vwoService } from "@trueskin-web/services"
import { Routes } from "@trueskin-web/translations"

const PageHeading = ({
  children,
  className,
  color,
  title,
  size,
  description,
  enableBackNavigation,
  enableAccountLink,
}) => {
  if (!vwoService.isJourneyStatusEnabled()) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <div
      sx={{
        display: ["block", "contents"],
        backgroundColor: ({ colors }) => [colors[color] || color, "inherit"],
      }}
    >
      <div
        sx={{
          pt: enableBackNavigation ? 2 : 4,
          px: 4,
          pb: 4,
          display: [null, "none"],
        }}
        className={className}
      >
        <Helmet
          meta={[
            {
              name: "theme-color",
              content: ({ colors }) => colors[color] || color,
            },
          ]}
        />

        {enableBackNavigation ? (
          <BackButton
            href={
              typeof enableBackNavigation === "string"
                ? enableBackNavigation
                : null
            }
            onClick={
              typeof enableBackNavigation === "function"
                ? enableBackNavigation
                : null
            }
          />
        ) : null}

        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: description ? 2 : null,
          }}
        >
          <Heading
            size={
              size ||
              (enableBackNavigation
                ? "md"
                : description
                ? "lg"
                : !color
                ? "lg"
                : null)
            }
            sx={{
              lineHeight: 1.25,
              // push down the title to align the account link component a bit better
              mt: authService.getBdJwt() ? 0.5 : null,
            }}
          >
            {title}
          </Heading>

          {!enableAccountLink ? null : authService.getBdJwt() ? (
            <HeaderActions />
          ) : (
            <AccountLink url={Routes.App.Account.url} />
          )}
        </div>

        {description}
      </div>

      {children}
    </div>
  )
}

export default PageHeading
