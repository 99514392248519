import type { UseQueryOptions, UseQueryResult } from "react-query"

import { orderClient, useQuery } from "@trueskin-web/apis"

import { OrderSummary } from "../../app/account/subscription/order.utils"
import { longTermDiscountsService } from "../../experiments"

type CurrentOrderSummaryResponse = OrderSummary
type CurrentOrderSummaryError = unknown

type TUseCurrentOrderSummary<
  TData = OrderSummary,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  lastOrderSummary: OrderSummary | undefined
  isLastOrderSummaryLoading: boolean
  lastOrderSummaryError: unknown
}

export const useLastOrderSummary = ({
  enabled = true,
  staleTime = 60 * 1000, // 1 minute
  ...options
}: UseQueryOptions<
  CurrentOrderSummaryResponse,
  CurrentOrderSummaryError,
  OrderSummary
> = {}): TUseCurrentOrderSummary => {
  const query = useQuery<
    CurrentOrderSummaryResponse,
    CurrentOrderSummaryError,
    OrderSummary
  >("lastOrderSummary", orderClient.getLastOrderSummary, {
    enabled,
    staleTime,
    ...options,
    select: (response: CurrentOrderSummaryResponse) =>
      longTermDiscountsService.applyOfferToOrderLineItems(response),
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    lastOrderSummary: data,
    isLastOrderSummaryLoading: isLoading,
    lastOrderSummaryError: error,
  }
}
