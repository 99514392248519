import type { NpsSettingsWithLinks } from "@trueskin/vortex-sdk"
import type { UseQueryOptions, UseQueryResult } from "react-query"

import { npsClient, useQuery } from "@trueskin-web/apis"

type NpsSettingsResponse = NpsSettingsWithLinks
type NpsSettingsError = unknown

type TUseNpsSettings<
  TData = NpsSettingsWithLinks,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  npsSettings: NpsSettingsWithLinks | undefined
  isNpsSettingsLoading: boolean
  npsSettingsError: unknown
}

export const useNpsSettings = ({
  enabled = true,
  staleTime = 5 * 60 * 1000, // 5 minute
  ...options
}: UseQueryOptions<
  NpsSettingsResponse,
  NpsSettingsError,
  NpsSettingsResponse
> = {}): TUseNpsSettings => {
  const query = useQuery<
    NpsSettingsResponse,
    NpsSettingsError,
    NpsSettingsResponse
  >("nps", npsClient.getNpsSettings, {
    enabled,
    staleTime,
    ...options,
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    npsSettings: data,
    isNpsSettingsLoading: isLoading,
    npsSettingsError: error,
  }
}
