import { OrderSummary } from "../../../app/account/subscription/order.utils"
import { LongTermDiscountOffer } from "../types"

export type LongTermDiscountsAction =
  | {
      type: "open_dialog"
      dialog: "offer" | "confirmation" | "success"
    }
  | {
      type: "close_dialog"
      dialog: "offer" | "confirmation" | "success"
    }
  | {
      type: "reset_state"
    }
  | {
      type: "load_upcoming_order"
    }
  | {
      type: "update_upcoming_order"
      upcomingOrder: OrderSummary
    }
  | {
      type: "select_offer"
      offer: LongTermDiscountOffer
    }
  | {
      type: "simulate_offer_on_upcoming_order"
    }
  | {
      type: "update_upcoming_order_with_offer"
      upcomingOrderWithOffer: OrderSummary
    }
  | {
      type: "save_offer"
    }

export type LongTermDiscountsState = {
  /**
   * Hash of the current customer
   */
  customer: {
    /**
     * The first name of the customer, potentially missing to match the API description
     */
    firstName?: string | null
  }

  /**
   * Hash of the current customer's subscription
   */
  subscription: {
    /**
     * The renewal date of the subscription, as string
     */
    renewalDate?: string | null
  }

  /**
   * The current available list of long term discount offers
   */
  availableOffers: LongTermDiscountOffer[]

  /**
   * The currently selected offer
   */
  selectedOffer?: LongTermDiscountOffer

  /**
   * Customer's upcoming order
   */
  upcomingOrder?: OrderSummary

  /**
   * Flag indicating if the upcoming order is being loaded
   */
  isLoadingUpcomingOrder: boolean

  /**
   * Customer's upcoming order with the selected offer applied to it
   */
  upcomingOrderWithOffer?: OrderSummary

  /**
   * Flag indicating if there is a simulation of a specific offer being computed
   */
  isSimulatingUpcomingOrderWithOffer: boolean

  /**
   * Flag indicating if the upcoming order is being saved with the offer applied to it
   */
  isSavingOffer: boolean

  /**
   * Flag indicating if the offer dialog should be shown
   */
  showOfferDialog: boolean

  /**
   * Flag indicating if the confirmation dialog should be shown
   */
  showConfirmationDialog: boolean

  /**
   * Flag indicating if the success dialog should be shown
   */
  showSuccessDialog: boolean
}

const resetState = (state: LongTermDiscountsState): LongTermDiscountsState => {
  return {
    ...state,
    selectedOffer: undefined,
    upcomingOrder: undefined,
    isLoadingUpcomingOrder: false,
    upcomingOrderWithOffer: undefined,
    isSimulatingUpcomingOrderWithOffer: false,
    isSavingOffer: false,
    showOfferDialog: false,
    showConfirmationDialog: false,
    showSuccessDialog: false,
  }
}

export function longTermDiscountsReducer(
  state: LongTermDiscountsState,
  action: LongTermDiscountsAction
): LongTermDiscountsState {
  switch (action.type) {
    case "open_dialog": {
      return {
        ...state,
        ...(action.dialog === "offer" && { showOfferDialog: true }),
        ...(action.dialog === "confirmation" && {
          showConfirmationDialog: true,
        }),
        ...(action.dialog === "success" && { showSuccessDialog: true }),
      }
    }
    case "close_dialog": {
      return {
        ...state,
        ...(action.dialog === "offer" && resetState(state)),
        ...(action.dialog === "confirmation" && {
          showConfirmationDialog: false,
          upcomingOrderWithOffer: undefined,
        }),
        ...(action.dialog === "success" && resetState(state)),
      }
    }
    case "reset_state": {
      return resetState(state)
    }
    case "load_upcoming_order": {
      return {
        ...state,
        isLoadingUpcomingOrder: true,
      }
    }
    case "update_upcoming_order": {
      return {
        ...state,
        upcomingOrder: action.upcomingOrder,
        isLoadingUpcomingOrder: false,
      }
    }
    case "select_offer": {
      return {
        ...state,
        selectedOffer: action.offer,
      }
    }
    case "simulate_offer_on_upcoming_order": {
      return {
        ...state,
        isSimulatingUpcomingOrderWithOffer: true,
      }
    }
    case "update_upcoming_order_with_offer": {
      return {
        ...state,
        upcomingOrderWithOffer: action.upcomingOrderWithOffer,
        isSimulatingUpcomingOrderWithOffer: false,
      }
    }
    case "save_offer": {
      return {
        ...state,
        isSavingOffer: true,
      }
    }
    default: {
      throw new Error(
        `Unhandled action ${action["type"] ?? JSON.stringify(action)}}`
      )
    }
  }
}
