import i18next from "./i18next"

type RouteSection = {
  path: string
}
// TODO: i think there is a better way to do this with interfaces
type Route = RouteSection & Record<string, RouteSection>

type Routes = Record<string, Route>

const mapRoutes = (currentRoute: Route, parentRoute = "") => {
  const path = currentRoute.path ? `/${currentRoute.path}` : ""
  const url = `${parentRoute}${path}`

  const route = !path ? {} : { path, url }

  const routes: Routes = Object.keys(currentRoute)
    .filter((name) => name.charAt(0) === name.charAt(0).toUpperCase())
    .reduce(
      (acc, name) => ({
        ...acc,
        [name]: mapRoutes(currentRoute[name], url),
      }),
      {}
    )

  return {
    ...route,
    ...routes,
  }
}

const Routes = mapRoutes(
  i18next.t("Routes")
) as typeof import("./de/portal-routes.json").Routes

export { Routes }
