import { createContext, useContext } from "react"

import { LongTermDiscountOffer } from "../types"

import { LongTermDiscountsState } from "./long-term-discounts-state"

export interface LongTermDiscountsContextState extends LongTermDiscountsState {
  /**
   * Opens a specific dialog
   * @param dialog The dialog to close, either "offer", "confirmation" or "success"
   */
  openDialog: (dialog: "offer" | "confirmation" | "success") => void

  /**
   * Closes a specific dialog
   * @param dialog The dialog to close, either "offer", "confirmation" or "success"
   */
  closeDialog: (dialog: "offer" | "confirmation" | "success") => void

  /**
   * Closes all opened dialogs and resets the state
   */
  closeAllDialogs: () => void

  /**
   * Selects a specific offer
   * @param offer The offer to select
   */
  selectOffer: (offer: LongTermDiscountOffer) => void

  /**
   * Saves the currently selected offer aka applies it as a voucher to the upcoming order
   */
  saveOffer: () => Promise<void>
}

export const LongTermDiscountsContext =
  createContext<LongTermDiscountsContextState | null>(null)

export function useLongTermDiscounts(): LongTermDiscountsContextState {
  const context = useContext(LongTermDiscountsContext)

  if (!context) {
    throw new Error(
      "useLongTermDiscounts must be used within a LongTermDiscountsProvider"
    )
  }

  return context
}
