import FieldError from "./field-error"
import { useFormControl } from "./form-control"

const FormErrorMessage = ({
  mobilePlaceholder,
  desktopPlaceholder,
  ...props
}) => {
  const formControl = useFormControl(props)

  const showMessage = formControl.isInvalid && props.children

  if (!showMessage) {
    props.children = " "
  }

  return (
    <FieldError
      data-testid="web-portal-form-error-message"
      sx={{
        display: showMessage
          ? null
          : [
              mobilePlaceholder ? null : "none",
              desktopPlaceholder ? "block" : "none",
            ],
      }}
      {...props}
    />
  )
}

export default FormErrorMessage
