import { ReactElement, useState } from "react"

import { Card, Icon } from "@trueskin-web/components"
import { formatCurrency } from "@trueskin-web/functions"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import { NON_MONTHLY_ADDONS } from "../../account/subscription/treatment/addons/addons"

import { CardDivider, CardSection } from "./card-helpers"
import { useOrderCard } from "./order-price-breakdown-card"
import { OrderPriceDetails } from "./utils/compute-order-price-details"

const PriceBreakdownDetails = ({
  details,
}: {
  details: { icon: any; description: string; hide?: boolean }[]
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 3,
        border: "1px solid lightGray",
        borderRadius: 8,
        color: "neutralsDarkGrey",
      }}
    >
      {details
        .filter((detail) => !detail.hide)
        .map((detail, index) => (
          <div
            key={index}
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Icon icon={detail.icon} />
            <span>{detail.description}</span>
          </div>
        ))}
    </Card>
  )
}
PriceBreakdownDetails.displayName = "PriceBreakdownDetails"

const PriceBreakdown = ({
  hideTotalsWithoutDiscounts,
  children,
  onPriceExpanded,
  onDiscountsExpanded,
}: {
  hideTotalsWithoutDiscounts?: boolean
  onPriceExpanded?: (expanded: boolean) => void
  onDiscountsExpanded?: (expanded: boolean) => void
  children?: (props: OrderPriceDetails) => ReactElement | null
}) => {
  const { order, orderPrices } = useOrderCard()

  const [isSetExpanded, setIsSetExpanded] = useState(false)
  const setLineItems = order.lineItems.filter((item) =>
    ["other", "medical_service"].includes(item.type)
  )
  const addonLineItems = order.lineItems
    .filter((item) => ["addon"].includes(item.type))
    .map((item) => ({
      ...item,
      oneTimeProduct: item.products?.some((product) =>
        NON_MONTHLY_ADDONS.includes(product.product._id)
      ),
    }))

  const [isDiscountExpanded, setIsDiscountExpanded] = useState(false)
  const discountLineItems = order.lineItems.filter(
    (item) => item.type === "discount"
  )

  return (
    <CardSection title="Zahlungsübersicht">
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
          gap: 3,
          fontSize: 14,
          lineHeight: "20px",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            color: "neutralsDarkGrey",
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              onPriceExpanded?.(!isSetExpanded)
              setIsSetExpanded(!isSetExpanded)
            }}
          >
            <div
              sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}
            >
              <div sx={{ display: "flex", alignItems: "center" }}>
                <span>FORMEL SKIN Behandlung</span>
                <Icon
                  size="sm"
                  icon={arrowRightIcon}
                  sx={{
                    width: 18,
                    height: 18,
                    transform:
                      (isSetExpanded ? "rotate(-90deg)" : "rotate(90deg)") +
                      " scale(1.25)",
                  }}
                />
              </div>
            </div>

            <div>{orderPrices.totalSetPriceWithoutDiscountsFormatted}</div>
          </div>

          {isSetExpanded && (
            <>
              {setLineItems.map((item: any) => (
                <div
                  key={item.title || item.heading}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ml: 2,
                  }}
                >
                  <span>{item.title || item.heading}</span>
                  <span>
                    {formatCurrency(
                      (item.amount * orderPrices.instalmentsCount) / 100,
                      order.values.currency,
                      2
                    )}
                  </span>
                </div>
              ))}
            </>
          )}

          {addonLineItems.map((item: any) => (
            <div
              key={item.title || item.heading}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                {item.title || item.heading}
                {item.oneTimeProduct ? " (Einmalig)" : ""}
              </span>
              <span>
                {formatCurrency(
                  (item.amount * orderPrices.instalmentsCount) / 100,
                  order.values.currency,
                  2
                )}
              </span>
            </div>
          ))}

          <div sx={{ display: "flex", justifyContent: "space-between" }}>
            <div sx={{ flex: 1 }}>Versand</div>
            <div>
              {order.values.shipping
                ? formatCurrency(
                    order.values.shipping / 100,
                    order.values.currency,
                    2
                  )
                : "KOSTENLOS"}
            </div>
          </div>

          {!!orderPrices.discount && discountLineItems?.length > 1 ? (
            <div
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => {
                onDiscountsExpanded?.(!isDiscountExpanded)
                setIsDiscountExpanded(!isDiscountExpanded)
              }}
            >
              <div sx={{ flex: 1 }}>
                <span>Rabatte</span>

                <Icon
                  size="sm"
                  icon={arrowRightIcon}
                  sx={{
                    width: 18,
                    height: 18,
                    transform:
                      (isDiscountExpanded
                        ? "rotate(-90deg)"
                        : "rotate(90deg)") + " scale(1.25)",
                  }}
                />
              </div>

              <div>{orderPrices.discountFormatted}</div>
            </div>
          ) : discountLineItems?.length === 1 ? (
            <div sx={{ display: "flex", justifyContent: "space-between" }}>
              <div sx={{ flex: 1 }}>
                <span>{discountLineItems[0].title}</span>
              </div>

              <div>{orderPrices.discountFormatted}</div>
            </div>
          ) : null}

          {isDiscountExpanded && (
            <>
              {discountLineItems.map((item: any) => (
                <div
                  key={item.title}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ml: 2,
                  }}
                >
                  <span>{item.title}</span>
                  <span>
                    {formatCurrency(
                      // (item.amount * orderPrices.instalmentsCount) / 100,
                      (item.amount * orderPrices.instalmentsCount) / 100,
                      order.values.currency,
                      2
                    )}
                  </span>
                </div>
              ))}
            </>
          )}
        </div>

        <CardDivider className={""} />

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
          }}
        >
          {orderPrices.instalmentsCount > 1 && (
            <div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div sx={{ flex: 1 }}>
                {orderPrices.instalmentsCount}-Monats-Gesamtsumme
              </div>

              <div sx={{ display: "flex", gap: 1 }}>
                {!hideTotalsWithoutDiscounts &&
                  order.values.totalWithoutDiscounts !== order.values.total && (
                    <span
                      sx={{
                        textDecorationLine: "line-through",
                        color: "neutralsDarkGrey",
                      }}
                    >
                      {orderPrices.totalWithoutDiscountsFormatted}
                    </span>
                  )}

                <span>{orderPrices.totalFormatted}</span>
              </div>
            </div>
          )}

          <div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div sx={{ flex: 1, fontWeight: 700 }}>
              {order.occurrence === "occ_once" ? "Summe" : "Monatliche Summe"}
            </div>

            <div sx={{ display: "flex", gap: 1 }}>
              {!hideTotalsWithoutDiscounts &&
                order.values.perInstalment.totalWithoutDiscounts !==
                  order.values.perInstalment.total && (
                  <span
                    sx={{
                      textDecorationLine: "line-through",
                      color: "neutralsDarkGrey",
                    }}
                  >
                    {orderPrices.totalPerMonthWithoutDiscountsFormatted}
                  </span>
                )}

              <span sx={{ fontWeight: 700 }}>
                {orderPrices.totalPerMonthFormatted}
              </span>
            </div>
          </div>
        </div>

        {!!children && children(orderPrices)}
      </div>
    </CardSection>
  )
}
PriceBreakdown.displayName = "PriceBreakdown"

export { PriceBreakdown, PriceBreakdownDetails }
