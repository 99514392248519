import { useFormControl } from "./form-control"
import Label from "./label"

const RequiredIndicator = (props) => (
  <span
    sx={{
      ml: 1,
    }}
    aria-hidden="true"
    children="*"
    {...props}
  />
)

const FormLabel = ({ children = "\u00A0", ...props }) => {
  const formControl = useFormControl(props)

  return (
    <Label
      sx={{
        opacity: formControl.isDisabled ? "0.4" : undefined,
      }}
      {...props}
    >
      {children}
      {formControl.isRequired && <RequiredIndicator />}
    </Label>
  )
}

export default FormLabel
