import {
  authService,
  homeService,
  initialConsultationService,
  trackingService,
  vwoService,
} from "@trueskin-web/services"

import { apiTofu, apiVortex } from "./api"
import * as marketingClient from "./marketing"

if (typeof window !== "undefined") {
  if (!authService.getJwt() && authService.getFirstname()) {
    authService.removeFirstname()
  }
}

function getCurrentUser() {
  if (!authService.getJwt()) {
    return Promise.resolve()
  }

  return apiVortex().getUsersMe()
}

/**
 *
 * @return {UserMeIdentityResponse|void}
 */
function getIdentity() {
  if (!authService.getJwt()) {
    return Promise.resolve()
  }

  return apiVortex().getUsersMeIdentity()
}

async function login({
  email: identifier,
  password,
  campaignTracking,
  touchPoints,
  temptoken,
  country,
}) {
  const loginRes = await apiVortex()
    .postAuthLocal({
      identifier,
      password,
      campaignTracking,
      touchPoints,
      temptoken,
      country,
    })
    .catch((error) => {
      throw {
        message: [
          {
            messages: [
              {
                id: error?.metadata?.formId || "Auth.form.error.invalid",
                message:
                  error?.metadata?.message || "Identifier or password invalid.",
              },
            ],
          },
        ],
      }
    })

  if (loginRes.jwt) {
    authService.saveJwt(loginRes.jwt)

    await apiVortex()
      .postAuthTofu()
      .then(async ({ tofuJwt }) => {
        if (tofuJwt) {
          authService.saveBdJwt(tofuJwt)

          await apiTofu(`touch-points`, {
            body: {
              touchPoints: trackingService.getAllTrackingTouchPoints(),
              captureStep: "login",
            },
          })
        }

        return null
      })
  }

  trackingService.clearTrackingTouchpoints()

  return loginRes.user
}

async function getBdJwtWithCoreToken() {
  const { tofuJwt } = await apiVortex().postAuthTofu()

  if (tofuJwt) {
    authService.saveBdJwt(tofuJwt)
  }

  return null
}

function requestLoginEmail({ email }) {
  const body = {
    email,
  }

  return apiVortex().postUsersAuthEmailOtp(body)
}

function emailLogin({
  emailOnlyToken,
  campaignTracking,
  touchPoints,
  temptoken,
  country,
}) {
  const body = {
    emailOnlyToken,
    campaignTracking,
    touchPoints,
    temptoken,
    country,
  }

  const request = apiVortex().postUsersLoginEmailOnlyConfirm(body)

  return request
    .then(({ jwt, user }) => {
      authService.saveJwt(jwt)
      return user
    })
    .then((user) =>
      apiVortex()
        .postAuthTofu()
        .then(async ({ tofuJwt }) => {
          if (tofuJwt) {
            authService.saveBdJwt(tofuJwt)

            await apiTofu(`touch-points`, {
              body: {
                touchPoints: trackingService.getAllTrackingTouchPoints(),
                captureStep: "login",
              },
            })
          }

          return null
        })
        .then(() => user)
    )
    .then((user) => {
      trackingService.clearTrackingTouchpoints()
      return user
    })
}

function logout() {
  // TODO: proprietary app related local storage keys should be prefixed to promote automatic cleanup
  authService.cleanupStorage()
  initialConsultationService.cleanupStorage()
  vwoService.cleanupStorage()
  homeService.cleanupStorage()
}

function register({
  email,
  provider,
  providerID,
  password,
  signedUpWithEmailOnly,
  tempToken,
  country,
  newsletterSubscription,
  campaignTracking,
  touchPoints,
}) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.SIGN_UP_SUBMITTED,
    data: {
      email,
      provider: provider || "user",
      temptoken: tempToken,
    },
  })

  const body = {
    email,
    password,
    signedUpWithEmailOnly,
    country,
    provider,
    providerID,
    temptoken: tempToken,
    newsletterSubscription,
    campaignTracking,
    touchPoints,
  }

  const request = apiVortex().postUsersRegister(body)

  return request.then(({ jwt, user }) => {
    authService.saveJwt(jwt)
    return user
  })
}

function forgotPassword({ email }) {
  const body = { email }

  return apiVortex().postAuthForgotPassword(body)
}

function resetPassword({ code, password, passwordConfirmation }) {
  const body = { code, password, passwordConfirmation }

  return apiVortex().postAuthResetPassword(body)
}

function confirmEmail({ code: confirmation }) {
  return apiVortex().getAuthEmailConfirmation(confirmation)
}

export {
  getCurrentUser,
  getIdentity,
  login,
  requestLoginEmail,
  emailLogin,
  logout,
  register,
  forgotPassword,
  resetPassword,
  confirmEmail,
  getBdJwtWithCoreToken,
}
