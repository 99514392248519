import { Card, CardCta } from "../../card"

const cards = [
  {
    title: "Card with simple text",
    content: () => <CardCta>Erinnere mich per SMS</CardCta>,
  },
  {
    title: "Card with complex text",
    content: () => <CardCta variant="outline">Erinnere mich per SMS</CardCta>,
  },
  {
    title: "Card with button",
    content: () => <CardCta variant="link">Erinnere mich per SMS</CardCta>,
  },
  {
    title: "Card with button",
    content: () => (
      <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CardCta>Erinnere mich per SMS</CardCta>
        <CardCta variant="outline">Erinnere mich per SMS</CardCta>
        <CardCta variant="link">Erinnere mich per SMS</CardCta>
      </div>
    ),
  },
]

const CardsWithCtas = () => {
  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {cards.map((card, index) => (
        <Card key={index}>{card.content()}</Card>
      ))}
    </div>
  )
}

export default CardsWithCtas
