import FieldMessage from "./field-message"
import { useFormControl } from "./form-control"

const FormMessage = ({ mobilePlaceholder, desktopPlaceholder, ...props }) => {
  const formControl = useFormControl(props)

  const showMessage = !formControl.isInvalid && props.children

  if (!showMessage) {
    props.children = " "
  }

  return (
    <FieldMessage
      sx={{
        display: showMessage
          ? null
          : [
              mobilePlaceholder ? null : "none",
              desktopPlaceholder ? "block" : "none",
            ],
      }}
      {...props}
    />
  )
}

export default FormMessage
