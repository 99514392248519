import { useEffect, useState } from "react"

import { amplitudeClient } from "@trueskin-web/apis"
import { Input, Section, Select } from "@trueskin-web/components"
import { formatDate, formatDateISO } from "@trueskin-web/functions"
import i18next, { Trans, useTranslation } from "@trueskin-web/translations"

import { ChurnPreventionPauseRenewalCalendar } from "./churn-prevention-pause-renewal-calendar"

const renewalReasons: [string] =
  i18next.t("Account.Subscription.Status.RenewalDialog.renewalReasons", {
    returnObjects: true,
  }) || []

export const ChurnPreventionPauseRenewalScreen = ({
  currentRenewalDate,
  newRenewalDate,
  onContinue,
  onDismiss,
  onBack,
}: any) => {
  const { t } = useTranslation()

  const [renewalDate, setRenewalDate] = useState(newRenewalDate)
  const [renewalReason, setRenewalReason] = useState()
  const [freeTextReason, setFreeTextReason] = useState("")

  const showFreeTextReason = renewalReason === renewalReasons.slice(-1)[0]
  const currentRenewalDateFormatted = formatDate(currentRenewalDate, {
    month: "long",
    day: "numeric",
  })

  useEffect(() => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.SHIFT_RENEWAL_DATE_CALENDAR_PAGE_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "pause_experiment",
      }
    )
  }, [])

  return (
    <Section
      title="Passe das Datum Deines nächsten Skin-Sets an"
      description={`Dein nächstes Skin-Set ist derzeit für den ${currentRenewalDateFormatted} geplant. Die Herstellung und Lieferung erfolgt innerhalb von 3-5 Tagen nach diesem Datum.`}
      content={
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            sx={{
              width: ["100%", "unset"],
              borderRadius: 8,
              boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
              border: "1px solid",
              borderColor: "neutralsMediumGrey",
              "> .rdp": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <ChurnPreventionPauseRenewalCalendar
              onDateChange={setRenewalDate}
              newRenewalDate={renewalDate}
            />
          </div>

          <div
            sx={{
              width: "100%",
              mt: 4,
            }}
          >
            <div
              sx={{
                mb: [2, 3],
                letterSpacing: "0.03em",
                fontWeight: 700,
              }}
            >
              <Trans i18nKey="Account.Subscription.Status.RenewalDialog.changeDateReason" />
            </div>

            <Select
              data-testid="web-portal-renewal-reason-select"
              placeholder={t(
                "Account.Subscription.Status.RenewalDialog.chooseOption"
              )}
              value={renewalReason}
              onChange={({ target: { value } }) => setRenewalReason(value)}
              sx={{
                borderRadius: 8,
                borderColor: "neutralsMediumGrey",
              }}
            >
              {renewalReasons.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>

            {showFreeTextReason && (
              <div sx={{ mt: 4 }}>
                <Input
                  data-testid="web-portal-renewal-free-text-reason"
                  type="text"
                  value={freeTextReason}
                  onChange={({ target: { value } }) => setFreeTextReason(value)}
                  sx={{ borderRadius: 8 }}
                />
              </div>
            )}
          </div>
        </div>
      }
      onDismiss={onDismiss}
      backLabel={onBack ? "Zurück" : undefined}
      onBack={onBack}
      primaryAction={() => {
        amplitudeClient.trackEvent(
          amplitudeClient.ANALYTIC_EVENTS
            .SHIFT_RENEWAL_DATE_CALENDAR_PAGE_CONTINUE_CLICKED,
          {
            [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "pause_experiment",
            [amplitudeClient.ANALYTIC_EVENT_PROPS.NEW_RENEWAL_DATE]:
              formatDateISO(renewalDate),
            [amplitudeClient.ANALYTIC_EVENT_PROPS.OLD_RENEWAL_DATE]:
              formatDateISO(currentRenewalDate),
            [amplitudeClient.ANALYTIC_EVENT_PROPS.REASON]: renewalReason,
          }
        )
        onContinue({
          renewalDate,
          renewalReason: !showFreeTextReason ? renewalReason : freeTextReason,
        })
      }}
      primaryActionLabel={t("Button.continue")}
      isPrimaryActionDisabled={
        !renewalDate ||
        !renewalReason ||
        (showFreeTextReason && !freeTextReason)
      }
      sx={{
        '[type="button"]': {
          borderRadius: 8,
        },
        '[data-testid="web-portal-section-primary-btn"][disabled]': {
          borderColor: "neutralsMediumGrey",
        },
      }}
    />
  )
}
