import { lockIcon } from "@trueskin-web/theme/icons/lockIcon"

import { Card, CardHint } from "../../card"
import Icon from "../../icon"

const cardFooters = [
  {
    description:
      "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
  },
  {
    description:
      "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
    icon: (
      <Icon
        icon={lockIcon}
        size="sm"
        sx={{ width: 20, height: 20, minWidth: 20 }}
      />
    ),
  },
]

const CardsWithFooter = () => {
  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {cardFooters.map((cardFooter, index) => (
        <Card key={index}>
          <CardHint {...cardFooter} />
        </Card>
      ))}
    </div>
  )
}

export default CardsWithFooter
