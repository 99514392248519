import * as trackingService from "@trueskin-web/services/src/tracking.js"

import { apiV2, apiVortex } from "./api"
import * as marketingClient from "./marketing"

function cancelSubscription({ subscriptionId, alsoRefund = false } = {}) {
  return apiVortex().postUsersMeSubscriptionsCancel(subscriptionId, {
    alsoRefund,
  })
}

function requestSubscriptionCancellation(subscriptionId) {
  return apiVortex().postUsersMeSubscriptionsRequestCancellation(subscriptionId)
}

function infoVoucher(voucherCode) {
  return apiV2(`customers/codes/${voucherCode}`).then((r) => r.data)
}

function purchaseOrder(
  campaignTracking,
  marketingEventData = null,
  v2PaymentMethodDetails = null
) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.PAYMENT_INITIATED,
    data: marketingEventData,
  })
  const data = {
    campaignTracking,
    touchPoints: trackingService.getAllTrackingTouchPoints(),
    ...v2PaymentMethodDetails,
  }

  return apiVortex()
    .postUsersMeOrderPurchase(data)
    .then(() => trackingService.clearTrackingTouchpoints())
    .catch((e) => {
      marketingClient.sendEvent({
        name: marketingClient.EVENT_TYPES_ENUM.PAYMENT_ERROR,
        data: marketingEventData,
      })
      throw e
    })
}

function removeScheduledCancellation() {
  return apiVortex().postSubscriptionsRemoveScheduledCancellation()
}

function createPaymentSession(gateway, data = {}) {
  if (data?.orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${data.orderId}/sessions/klarna`, {
      method: "POST",
    })
  }

  return apiV2(`payments/sessions/${gateway}`, { method: "POST", body: data })
}

function createBraintreePaymentSession(params) {
  const { queryKey } = params
  const [, orderId] = queryKey

  if (orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${orderId}/sessions/braintree`, {
      method: "POST",
    }).then((response) => response.data)
  }

  return createPaymentSession("braintree").then((response) => response.data)
}

function getPrimaryPaymentMethod() {
  return apiV2("payment-methods/primary")
}

function tokenizePaymentMethod(data) {
  if (data.orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${data.orderId}/tokenize`, {
      method: "POST",
      body: data,
    })
  }

  return apiV2("payment-methods/tokenize", { method: "POST", body: data })
}

export {
  cancelSubscription,
  requestSubscriptionCancellation,
  infoVoucher,
  purchaseOrder,
  removeScheduledCancellation,
  getPrimaryPaymentMethod,
  tokenizePaymentMethod,
  createPaymentSession,
  createBraintreePaymentSession,
}
