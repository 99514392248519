export type LongTermDiscountOffer = {
  discountPerMonth: number
  percentage: number
  months: number
  pricePerMonth: string
  voucherCode: string
}

// TODO @fox: this is not a type, but a constant. should be moved to a different file
export const LongTermDiscountOffers: LongTermDiscountOffer[] = [
  {
    discountPerMonth: 250,
    percentage: 5,
    months: 4,
    pricePerMonth: "41,50",
    voucherCode: "4-MONATS-SET-RABATT",
  },
  {
    discountPerMonth: 500,
    percentage: 10,
    months: 6,
    pricePerMonth: "39,00",
    voucherCode: "6-MONATS-SET-RABATT",
  },
  {
    discountPerMonth: 700,
    percentage: 15,
    months: 12,
    pricePerMonth: "37,00",
    voucherCode: "12-MONATS-SET-RABATT",
  },
]
