import { CardDivider, CardSection } from "./card-helpers"
import { DownloadInvoice } from "./download-invoice"
// import { LongTermDiscountsOrderPriceBreakdown } from "./long-term-discounts-order-price-breakdown"
import {
  OrderPriceBreakdownCard as OrderPriceBreakdownCardRoot,
  OrderPriceBreakdownRoot,
  OrderPriceCardContainer,
} from "./order-price-breakdown-card"
import { OrderProductsBreakdown } from "./order-products-breakdown"
import { PaymentTimeline } from "./payment-timeline"
import { PriceBreakdown, PriceBreakdownDetails } from "./price-breakdown"
import { PriceDetails } from "./price-details"

// TODO @fox: the root component here does too much and is missing the flexibility to control the container where the breakdown is rendered into
//  transition all instances to use OrderPriceBreakdown, which is just a data context container
/**
 * @deprecated Use OrderPriceBreakdown instead
 */
export const OrderPriceBreakdownCard = Object.assign(
  OrderPriceBreakdownCardRoot,
  {
    Section: CardSection,
    Divider: CardDivider,
    PriceBreakdown: PriceBreakdown,
    PriceDetails: PriceDetails,
    DownloadInvoice: DownloadInvoice,
    PaymentTimeline: PaymentTimeline,
    // LongTermDiscountsOrderPriceBreakdown: LongTermDiscountsOrderPriceBreakdown,
    PriceBreakdownDetails: PriceBreakdownDetails,
  }
)

export const OrderPriceBreakdown = Object.assign(
  {},
  {
    Root: OrderPriceBreakdownRoot,
    Card: OrderPriceCardContainer,
    Section: CardSection,
    Divider: CardDivider,
    PriceBreakdown: PriceBreakdown,
    PriceDetails: PriceDetails,
    ProductsBreakdown: OrderProductsBreakdown,
    PaymentTimeline: PaymentTimeline,
    DownloadInvoice: DownloadInvoice,
    // LongTermDiscountsOrderPriceBreakdown: LongTermDiscountsOrderPriceBreakdown,
    PriceBreakdownDetails: PriceBreakdownDetails,
  }
)

export type { OrderPriceDetails } from "./utils/compute-order-price-details"
