import { Trans } from "@trueskin-web/translations"

import StarRating from "./star-rating"

const TrustedShopsBadge = ({ logo, score, description }) => (
  <div
    sx={{
      display: "inline-flex",
      flexDirection: "column",
      p: "3px 5px",
      backgroundColor: "white",
      borderRadius: "8px",
      fontFamily: "sans-serif",
      color: "text",
    }}
  >
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {logo}
      <div
        sx={{
          fontSize: "8px",
          textAlign: "center",
        }}
      >
        <StarRating
          rating={score}
          size="xsm"
          sx={{
            color: "goldDark",
          }}
        />
        <div>{description}</div>
        <div>
          <span
            sx={{
              fontWeight: "bold",
            }}
          >
            {score}
          </span>
          /5.00
        </div>
      </div>
    </div>
    <div
      sx={{
        borderTop: "1px solid rgb(229, 229, 229)",
        fontSize: "7px",
        textAlign: "center",
      }}
    >
      <Trans i18nKey="Footer.TrustedShopsBadge.description" />
    </div>
  </div>
)

export default TrustedShopsBadge
