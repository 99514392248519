import { Fragment } from "react"

import {
  Button,
  Container,
  HeaderActions,
  HeaderNavLink,
  HeaderProps,
  Link,
  Logo,
} from "@trueskin-web/components"
import { useAuth, useBdAuth } from "@trueskin-web/context"
import { getHomepageUrl } from "@trueskin-web/functions"
import { vwoService } from "@trueskin-web/services"
import { useThemeUI } from "@trueskin-web/theme"
import { Routes, Trans } from "@trueskin-web/translations"

import { Apps, useApp } from "../hooks/use-app"

const HeaderLogo = ({
  disableNav,
  disableActions,
  backAction,
  backUrl,
}: HeaderProps) => {
  const { user } = useAuth()

  const isUserLogged = !!user

  if (disableNav) {
    if (backAction) {
      return (
        <Fragment>
          {disableActions ? null : (
            <Logo
              size="xsm"
              sx={{
                display: [null, "none"],
              }}
            />
          )}
          <Button
            variant="link"
            size="sm"
            onClick={backAction}
            sx={{
              display: disableActions ? null : ["none", "inline-flex"],
              flex: "none",
              p: 0,
            }}
          >
            <Logo size="xsm" />
          </Button>
        </Fragment>
      )
    }

    if (backUrl) {
      return (
        <Fragment>
          {disableActions ? null : (
            <Logo
              size="xsm"
              sx={{
                display: [null, "none"],
              }}
            />
          )}
          <Button
            variant="link"
            size="sm"
            as={Link}
            href={getHomepageUrl(isUserLogged)}
            sx={{
              display: disableActions ? null : ["none", "inline-flex"],
              flex: "none",
              p: 0,
            }}
          >
            <Logo size="xsm" />
          </Button>
        </Fragment>
      )
    }

    return <Logo size="xsm" />
  }

  return (
    <Link
      href={getHomepageUrl(isUserLogged)}
      aria-current="page"
      aria-label="Return Home"
      sx={{
        display: "inline-flex",
        flex: "none",
        alignItems: "center",
        color: "text",
      }}
    >
      <Logo size="xsm" />
    </Link>
  )
}

const HeaderNav = () => {
  const {
    user: {
      treatmentPlan,
      notifications,
      subscription: { status: subscriptionStatus } = {},
    } = {},
  } = useAuth()
  const { user: bdUser } = useBdAuth()
  const notificationsCount = notifications?.length ?? 0

  if (
    !treatmentPlan &&
    (subscriptionStatus === "canceled" || subscriptionStatus === "expired") &&
    !bdUser
  ) {
    return null
  }

  const app = useApp()
  const progressTabTitleKey = "Header.navMenu.progress"

  return (
    <nav
      sx={{
        display: ["none", "flex"],
        mx: 3,
        flexWrap: "wrap",
      }}
    >
      {app === Apps.bd ? (
        <>
          <HeaderNavLink href={Routes.App.Diagnose.Home.url}>
            <Trans i18nKey="Header.bdNavMenu.cases" />
          </HeaderNavLink>

          <HeaderNavLink href={Routes.App.Diagnose.Account.url}>
            <Trans i18nKey="Header.bdNavMenu.profile" />
          </HeaderNavLink>
        </>
      ) : (
        <>
          <HeaderNavLink href={Routes.App.Home.url}>
            <Trans i18nKey="Header.navMenu.home" />
          </HeaderNavLink>

          <HeaderNavLink href={Routes.App.Plan.url}>
            <Trans i18nKey="Header.navMenu.plan" />
          </HeaderNavLink>

          <HeaderNavLink href={Routes.App.Consultations.url}>
            <Trans i18nKey="Header.navMenu.consultations" />
          </HeaderNavLink>

          {notificationsCount > 0 ? (
            <div
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                variant: "text.caps",
                fontFamily: "monospace",
                fontSize: 0,
                color: "white",
                backgroundColor: "attention",
                px: 1,
                minWidth: 20,
              }}
            >
              <div
                sx={{
                  pl: "1px",
                }}
              >
                {notificationsCount}
              </div>
            </div>
          ) : null}

          {!treatmentPlan ? null : (
            <HeaderNavLink href={Routes.App.Progress.url}>
              <Trans i18nKey={progressTabTitleKey} />
            </HeaderNavLink>
          )}
        </>
      )}
    </nav>
  )
}

const Header = ({
  disableNav,
  disableActions,
  disableSeparator,
  backAction,
  backUrl,
  additionalStyles,
}: HeaderProps) => {
  const { theme } = useThemeUI()

  const isJourneyStatusEnabled = vwoService.isJourneyStatusEnabled()

  return (
    <header
      sx={{
        display: isJourneyStatusEnabled ? ["none", "block"] : null,
      }}
    >
      <Container
        sx={{
          maxWidth: "100%",
          minWidth: `min(100%, ${theme.sizes?.container}px)`,
          textAlign: "center",
          ...additionalStyles,
        }}
      >
        <div
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 3,
            maxWidth: "100%",
            minWidth: ["100%", `min(100%, ${theme.sizes?.element}px)`],
            borderBottom: disableSeparator
              ? undefined
              : disableNav && !disableActions
              ? undefined
              : [`1px solid ${theme.colors?.grey}`, "none"],
          }}
        >
          <HeaderLogo
            disableNav={disableNav}
            disableActions={disableActions}
            backAction={backAction}
            backUrl={backUrl}
          />

          {disableNav ? null : <HeaderNav />}

          {disableActions ? null : (
            <HeaderActions
              disableNav={disableNav}
              backAction={backAction}
              backUrl={backUrl}
            />
          )}
        </div>
      </Container>
    </header>
  )
}

export default Header
