import { useLocation } from "@reach/router"
import { Fragment, HTMLAttributes, ReactNode, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import {
  ChatbotSupport,
  ResetCache,
  UrlLocaleSwitcher,
  VoucherUrl,
} from "@trueskin-web/components"
import { SectionProps } from "@trueskin-web/components/src/section/section"
import { useAuth } from "@trueskin-web/context"

import { useApp } from "../hooks/use-app"

import Header from "./header"
import MobileTabBar from "./mobile-tab-bar/mobile-tab-bar"
import { BDTabs } from "./mobile-tab-bar/tabs/bd-tabs"
import { CoreTabs } from "./mobile-tab-bar/tabs/core-tabs"
import NpsDialog from "./nps/nps-dialog"
import TermsAndConditions from "./terms-and-conditions"

const appNavs = {
  default: CoreTabs,
  bd: BDTabs,
}

export interface LayoutProps extends Omit<SectionProps, "content"> {
  dataTestId?: string
  showBanner?: boolean
  disableSeparator?: boolean
  disableNav?: boolean
  disableNps?: boolean
  disableMobileNav?: boolean
  disableActions?: boolean
  disableSupportChat?: boolean
  disableVoucher?: boolean
  disableHeader?: boolean
  backAction?: () => void
  backUrl?: string
  sectionProps?: HTMLAttributes<HTMLElement>
  mainProps?: HTMLAttributes<HTMLElement>
  additionalHeaderStyles?: unknown
  children?: ReactNode
}

const Layout = ({
  children,
  dataTestId,
  disableSeparator = false,
  disableNav = false,
  disableNps = false,
  disableMobileNav = false,
  disableActions = false,
  disableSupportChat = false,
  disableVoucher = false,
  disableHeader = false,
  backAction,
  backUrl,
  sectionProps,
  mainProps,
  additionalHeaderStyles,
}: LayoutProps) => {
  const location = useLocation()
  const app = useApp()
  const {
    user: {
      showNps: userShowNps,
      treatmentPlan,
      subscription: { status: subscriptionStatus } = {},
    } = {},
  } = useAuth()
  const showNps = userShowNps && disableNav === false && disableNps === false

  useEffect(() => {
    trackingClient.trackTouchPoint(location)
  }, [location])

  const Tabs = appNavs[app]

  return (
    <Fragment>
      <ResetCache />
      {disableVoucher ? null : <VoucherUrl />}
      <UrlLocaleSwitcher />

      {disableHeader ? null : (
        <Header
          disableSeparator={disableSeparator}
          disableNav={disableNav}
          disableActions={disableActions}
          backAction={backAction}
          backUrl={backUrl}
          additionalStyles={additionalHeaderStyles}
        />
      )}

      <main data-testid={dataTestId} {...mainProps}>
        <section {...sectionProps}>{children}</section>

        {disableSupportChat ? null : (
          <section>
            <ChatbotSupport
              mobileNavReposition={
                !disableNav &&
                !(
                  !treatmentPlan &&
                  (subscriptionStatus === "canceled" ||
                    subscriptionStatus === "expired")
                )
              }
            />
          </section>
        )}
      </main>

      {disableNav || disableMobileNav ? null : (
        <MobileTabBar>
          <Tabs />
        </MobileTabBar>
      )}

      {showNps ? <NpsDialog /> : null}

      <TermsAndConditions />
    </Fragment>
  )
}

export default Layout
