import { useCallback, useState } from "react"

import {
  amplitudeClient,
  orderClient,
  useMutation,
  useQueryClient,
} from "@trueskin-web/apis"
import { Dialog } from "@trueskin-web/components"
import { getErrorMessage } from "@trueskin-web/functions"
import { useTranslation } from "@trueskin-web/translations"

import Addon from "./addon"

const AddonDialog = ({ data, onDismiss }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [isAddAddonSyncing, setIsAddAddonSyncing] = useState(false)

  const {
    isLoading: isAddUpcomingProductLoading,
    mutateAsync: addUpcomingProduct,
    error: addUpcomingProductError,
  } = useMutation(orderClient.addUpcomingProduct, {
    onSuccess: () =>
      (async () => {
        setIsAddAddonSyncing(true)

        await Promise.all([
          queryClient.refetchQueries("addonsUpcomingPresets"),
          queryClient.refetchQueries("upcomingOrderSummary"),
          queryClient.refetchQueries("upcomingOrderVariations"),
        ])

        setIsAddAddonSyncing(false)

        onDismiss()
      })(),
  })

  const handleAddUpcomingProduct = useCallback(() => {
    addUpcomingProduct(data.id)

    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.NEXT_ORDER_ADDON_CONFIRMED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.PRODUCT]:
          amplitudeClient.ADD_ON_PRODUCT_NAME[data.id],
      }
    )
  }, [data, addUpcomingProduct])

  const addon = { ...data, count: 1 }

  const primaryActionLabel = data.out_of_stock
    ? "Bald wieder verfügbar"
    : t("Account.Subscription.Treatment.AddonDialog.submitButton")
  return (
    <Dialog
      data-testid="web-portal-addons-dialog"
      aria-label="Addon Dialog"
      noMobileFullscreen
      title={t("Account.Subscription.Treatment.AddonDialog.title")}
      description={t("Account.Subscription.Treatment.AddonDialog.description")}
      onDismiss={onDismiss}
      primaryAction={handleAddUpcomingProduct}
      primaryActionLabel={primaryActionLabel}
      isPrimaryActionDisabled={data.out_of_stock}
      isPrimaryActionLoading={isAddUpcomingProductLoading || isAddAddonSyncing}
      secondaryAction={() => {
        onDismiss()
      }}
      secondaryActionLabel={t(
        "Account.Subscription.Treatment.AddonDialog.cancelButton"
      )}
      isSecondaryActionDisabled={
        isAddUpcomingProductLoading || isAddAddonSyncing
      }
      actionError={
        addUpcomingProductError
          ? getErrorMessage(addUpcomingProductError)
          : null
      }
      sx={{
        maxWidth: [null, 420],
      }}
    >
      <Addon
        data={addon}
        sx={{
          gridTemplateColumns: ["1fr 2fr", "1fr 2fr"],
          mt: 6,
        }}
      />
    </Dialog>
  )
}
export default AddonDialog
