import { Fragment } from "react"

import { vwoService } from "@trueskin-web/services"
import { Trans } from "@trueskin-web/translations"

import Heading from "./heading"

const FaqContainer = ({ image, children }) => {
  const isNewChat = vwoService.isNewChatLookAndFeel()

  return (
    <div
      sx={{
        my: isNewChat ? 2 : 8,
        flex: isNewChat ? 1 : undefined,
      }}
    >
      {!isNewChat && (
        <Heading
          as="h3"
          sx={{
            fontSize: 4,
            mt: 5,
          }}
        >
          <Trans i18nKey="FaqContainer.title" />
        </Heading>
      )}

      <div
        sx={{
          display: "flex",
          flexDirection: isNewChat ? "column" : ["column", "row"],
          gap: isNewChat ? [3, 4] : [6, 8],
        }}
      >
        <div
          sx={{
            flex: 1,
          }}
        >
          {!isNewChat && (
            <Fragment>
              <Trans i18nKey="FaqContainer.description" />
              <div
                sx={{
                  borderTopWidth: 1,
                  borderTopStyle: "solid",
                  borderColor: "primaryDark",
                  pt: 5,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {image}
              </div>
            </Fragment>
          )}
        </div>

        <div
          sx={{
            flex: 1,
            position: "relative",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default FaqContainer
