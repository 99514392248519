import { StaticImage } from "gatsby-plugin-image"

import { AB_TESTS } from "@trueskin-web/ab-experiments"
import { amplitudeClient } from "@trueskin-web/apis"
import { Button, Card } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { useVWOExperimentStatus } from "@trueskin-web/services"

import { useUpcomingOrderSummary } from "../../hooks"

import { LongTermDiscountsProvider, useLongTermDiscounts } from "./context"
import { LongTermDiscountsConfirmationDialog } from "./long-term-discounts-confirmation-dialog"
import { LongTermDiscountsOfferDialog } from "./long-term-discounts-offer-dialog"
import { LongTermDiscountsSuccessDialog } from "./long-term-discounts-success-dialog"
import { longTermDiscountsService } from "./long-term-discounts.service"

const LongTermDiscountsPromoCardInternal = ({
  className,
}: {
  className?: string
}) => {
  const {
    customer,
    showOfferDialog,
    showConfirmationDialog,
    showSuccessDialog,
    openDialog,
  } = useLongTermDiscounts()

  const { upcomingOrderSummary, isUpcomingOrderSummaryLoading } =
    useUpcomingOrderSummary()

  if (
    (isUpcomingOrderSummaryLoading ||
      longTermDiscountsService.isOfferActive(upcomingOrderSummary)) &&
    !(showOfferDialog || showConfirmationDialog || showSuccessDialog)
  ) {
    return null
  }

  return (
    // this extra pb is required to make the card look good when journey status card experiment is enabled
    // the mt is required to make the card look good when the checkin is not enabled (which sucks)
    <div sx={{ pb: 3 }} className={className}>
      <Card
        sx={{
          gap: 2,
          flexDirection: "row",
          alignItems: "center",
          pr: 0,
          overflow: "hidden",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            flex: "1 0 0",
          }}
        >
          <div sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <span sx={{ fontSize: 18, lineHeight: "26px", fontWeight: 700 }}>
              {customer.firstName}, sichere Dir jetzt Deinen Treue-Rabatt
            </span>

            <span sx={{ fontSize: 12, lineHeight: "16px" }}>
              Spare jeden Monat
            </span>
          </div>

          <div>
            <Button
              data-testid="longTermDiscounts-OpenOfferDialog-btn"
              rounded
              onClick={() => {
                amplitudeClient.trackEvent(
                  amplitudeClient.ANALYTIC_EVENTS.PROMO_BANNER_CLICKED,
                  {
                    [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
                      "longterm_discounts",
                  }
                )
                openDialog("offer")
              }}
            >
              Mehr erfahren
            </Button>
          </div>
        </div>

        <StaticImage
          src="../../images/rebranded/long-term-discounts-promo-small.png"
          alt=""
          sx={{ width: 150, height: 120, mr: "-50px" }}
        />
      </Card>

      {showOfferDialog && <LongTermDiscountsOfferDialog />}

      {showConfirmationDialog && <LongTermDiscountsConfirmationDialog />}

      {showSuccessDialog && <LongTermDiscountsSuccessDialog />}
    </div>
  )
}

export const LongTermDiscountsPromoCard = ({
  className,
}: {
  className?: string
}) => {
  const { user } = useAuth()
  const { isLongTermDiscountsVariantActive } = useVWOExperimentStatus(
    AB_TESTS.LongTermDiscounts
  )

  // const { upcomingOrderSummary, isUpcomingOrderSummaryLoading } =
  //   useUpcomingOrderSummary({
  //     enabled: isLongTermDiscountsVariantActive(1),
  //   })

  if (
    !isLongTermDiscountsVariantActive(1) ||
    user?.subscription?.paymentOrder?.instalments?.count !== 2
    // ||
    // isUpcomingOrderSummaryLoading ||
    // longTermDiscountsService.isOfferActive(upcomingOrderSummary)
  ) {
    return null
  }

  return (
    <LongTermDiscountsProvider>
      <LongTermDiscountsPromoCardInternal className={className} />
    </LongTermDiscountsProvider>
  )
}
