import { useState } from "react"

import { vwoService } from "@trueskin-web/services"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Heading from "./heading"
import Icon from "./icon"
import Tappable from "./tappable"

const ExpansionPanel = ({
  className,
  icon,
  iconAsImage,
  title,
  onExpand,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isNewChat = vwoService.isNewChatLookAndFeel()
  return (
    <div
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderColor: isNewChat ? "lightGrey" : "text",
        mb: 3,
      }}
      className={className}
    >
      <Tappable
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          pb: 3,
        }}
        onClick={() => {
          if (!isExpanded) {
            onExpand?.(title)
          }

          setIsExpanded(!isExpanded)
        }}
      >
        {icon && (
          <Icon icon={icon} size="lg" asImage={iconAsImage} sx={{ mr: 2 }} />
        )}
        <Heading
          as={"h3"}
          sx={{
            fontSize: [2, 4],
            textAlign: "left",
            whiteSpace: "normal",
            fontFamily: "body",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          {title}
        </Heading>
        <Icon
          icon={arrowRightIcon}
          size="lg"
          sx={{
            ml: "auto",
            transform: isExpanded ? "rotate(90deg)" : null,
            flexShrink: 0,
          }}
        />
      </Tappable>
      <div
        sx={{
          display: isExpanded ? "block" : "none",
          mb: 2,
        }}
        {...props}
      ></div>
    </div>
  )
}

export default ExpansionPanel
