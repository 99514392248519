import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { ApolloError, useBdQuery } from "@trueskin-web/gql"
import { GET_CURRENT_PATIENT_PROFILE } from "@trueskin-web/gql/src/bd/index"
import { authService } from "@trueskin-web/services"

// TODO: generate these types and hooks using codegen

export type Address = {
  id: string
  firstName: string
  lastName: string
  salutation: "MR" | "MS"
  country: string
  city: string
  address: string
  postalCode: string
}

export type PatientProfile = {
  id: string
  firstName?: string
  lastName?: string
  salutation?: "MR" | "MS"
  createdAt: string
  phoneNumber?: string
  billingAddress?: Address
  shippingAddress?: Address
}

export type BdPatient = {
  id: string
  accountId: string
  email: string
  registrationDate: string
  agreedToTermsAndConditionsAt: string
  profile?: PatientProfile
  medicalData?: {
    gender?: "MALE" | "FEMALE"
    birthdate?: string
  }
  settings?: {
    pushNotificationsEnabled: boolean
  }
}

export type BdAuthContext = {
  user: BdPatient | null
  isLoading?: boolean
  error?: ApolloError | undefined
}

const BdAuthContext = createContext<BdAuthContext>({
  user: null,
})

function BdAuthProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<BdPatient | null>(null)

  const {
    data,
    loading: isLoading,
    error,
  } = useBdQuery<{ currentPatient: BdPatient }>(GET_CURRENT_PATIENT_PROFILE, {
    skip: !authService.getBdJwt(),
    errorPolicy: "all",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (data?.currentPatient) {
      setUser(data.currentPatient)
    }
  }, [data])

  useEffect(() => {
    const firstname = authService.getFirstname()
    const hasFirstName = firstname?.length && firstname !== "Anonymous"

    // avoid overwriting the LS name if one is already set, or there isn't an actual value to set
    if (!hasFirstName && user?.profile?.firstName) {
      authService.saveFirstname(user?.profile.firstName)
    }
  }, [user])

  const value = useMemo(
    () => ({
      isLoading,
      user,
      error,
    }),
    [isLoading, user, error]
  )

  return <BdAuthContext.Provider value={value} {...props} />
}

function useBdAuth() {
  const context = useContext(BdAuthContext)

  if (context === undefined) {
    throw new Error(`useBdAuth must be used within a BdAuthProvider`)
  }

  // trackUser(context)
  return context
}

export { BdAuthProvider, useBdAuth }
