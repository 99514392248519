const previewBranchPatterns = ["funnel", "cla"]

const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"
const gitBranch = process.env.GATSBY_GIT_BRANCH || ""

export const envDetails = {
  isProduction,
  gitBranch,
  isK8sDeployment: process.env.GATSBY_IS_K8S_DEPLOYMENT === "true",
  name: process.env.GATSBY_ENV_NAME || "",

  /**
   * This is the host for the API, which is used to route the requests to the correct environment.
   * For production apps we don't need a host, since the API is on the same domain as the web apps.
   */
  apiHost: isProduction
    ? ""
    : process.env.GATSBY_API_DOMAIN?.length
    ? "https://" + process.env.GATSBY_API_DOMAIN
    : "",

  /**
   * This is the prefix for the API, which is used to route the requests to the correct environment.
   * For production apps we don't need a prefix, since the API is on the same domain as the web apps.
   * For preview branches we need to always route to /development-de since there's no separate API for each preview branch.
   * For all other cases we use the GATSBY_API_PREFIX env variable or default to "".
   */
  apiPrefix: isProduction
    ? ""
    : previewBranchPatterns.some((branchName) =>
        gitBranch?.startsWith(branchName)
      ) // preview branches always point back to development-de
    ? "/development-de"
    : process.env.GATSBY_API_PREFIX || "",
}

/**
 * This is the list of local API origins that are used for local development to be able to connect to your own running API instances.
 * If any of these are not set when running the web apps locally, the hosts will always default back to `apiHost` and `apiPrefix`.
 */
const LocalApiOrigins = {
  v2: process.env.GATSBY_API_V2_URL || "",
  bff: process.env.GATSBY_GQL_URL || "",
  tofu: process.env.GATSBY_API_TOFU_URL || "",
}

// Tofu API is not supported in "preview/*" branches, so we need to always default to /development-de
const tofuApiPrefix = () => {
  if (!envDetails.isProduction && envDetails.gitBranch.startsWith("preview")) {
    return "/development-de"
  }

  return envDetails.apiPrefix
}

export const hosts = {
  v2ApiUrl: envDetails.isProduction
    ? "/api/v2" // TODO: for now. we should switch to gateway.formelskin.de and use GATSBY_API_DOMAIN
    : LocalApiOrigins.v2.length
    ? LocalApiOrigins.v2
    : envDetails.apiHost + envDetails.apiPrefix + "/api/v2",
  bffApiUrl: envDetails.isProduction
    ? "/api/gql" // TODO: for now. we should switch to gateway.formelskin.de and use GATSBY_API_DOMAIN
    : LocalApiOrigins.bff.length
    ? LocalApiOrigins.bff
    : envDetails.apiHost + envDetails.apiPrefix + "/api/gql",
  tofuApiUrl: envDetails.isProduction
    ? "https://gateway.formelskin.de/api/tofu" // TODO: for now this needs to be hardcoded. we should switch to gateway.formelskin.de and use GATSBY_API_DOMAIN
    : LocalApiOrigins.tofu.length
    ? LocalApiOrigins.tofu
    : envDetails.apiHost + tofuApiPrefix() + "/api/tofu",
}

console.log("env details", {
  hosts,
  envDetails,
})
