import { Fragment, useState } from "react"

import Input from "../input"
import PageHeading from "../page-heading"
import Textarea from "../textarea"
import ToggleSwitch from "../toggle-switch"

const PageHeadings = () => {
  const [color, setColor] = useState("#E7ECFF")

  const [title, setTitle] = useState("Hello user")

  const [size, setSize] = useState("sm")

  const [description, setDescription] = useState(
    "Willkommen in Deinem Benutzerkonto!"
  )

  const [enableBackNavigation, setEnableBackNavigation] = useState(false)

  const [enableAccountLink, setEnableAccountLink] = useState(false)

  return (
    <Fragment>
      <PageHeading
        color={color}
        title={title}
        size={size}
        description={description}
        enableBackNavigation={enableBackNavigation}
        enableAccountLink={enableAccountLink}
      />

      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridGap: 2,
          p: 2,
          mt: 6,
        }}
      >
        <div>color</div>
        <Input
          value={color}
          onChange={(event) => setColor(event.target.value)}
        />

        <div>title</div>
        <Input
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />

        <div>size</div>
        <Input value={size} onChange={(event) => setSize(event.target.value)} />

        <div>description</div>
        <Textarea
          value={description}
          rows="4"
          onChange={(event) => setDescription(event.target.value)}
          sx={{
            resize: "auto",
          }}
        />

        <div>enable back navigation</div>
        <ToggleSwitch
          value={!!enableBackNavigation}
          onChange={() => setEnableBackNavigation((value) => !value)}
        />

        <div>enable account link</div>
        <ToggleSwitch
          value={!!enableAccountLink}
          onChange={() => setEnableAccountLink((value) => !value)}
        />
      </div>
    </Fragment>
  )
}

export default PageHeadings
