import { useId } from "@reach/auto-id"
import { forwardRef } from "react"

import FormControl from "./form-control"
import FormLabel from "./form-label"
import FormMessage from "./form-message"
import ToggleSwitch from "./toggle-switch"

const ToggleControl = forwardRef(
  ({ input, label, message, className, ...props }, ref) => {
    const id = useId()

    return (
      <FormControl className={className}>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <FormMessage
          sx={{
            mb: 3,
            justifyContent: "flex-start",
          }}
        >
          {message}
        </FormMessage>
        <ToggleSwitch id={id} ref={ref} {...input} {...props} />
      </FormControl>
    )
  }
)

export default ToggleControl
