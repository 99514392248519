import de from "./de/site-routes.json"
import en from "./en/site-routes.json"

const locale = process.env.GATSBY_I18N_LOCALE
const [language] = locale.split("-")

const resources = {
  de: de.Slugs,
  en: en.Slugs,
}

const mapSlug = (accum, name) => {
  const slug = resources[language][name] || {}
  const { path } = slug
  let url = ""

  if (path) {
    url = /https?:\/\//.test(path) ? path : `/${path}`
  }

  return {
    ...accum,
    [name]: { ...slug, url },
  }
}

const Slugs = Object.keys(resources[language]).reduce(mapSlug, {})

export default Slugs
