import { useLocation } from "@reach/router"
import { navigate as gatsbyNavigate } from "gatsby"

const ENABLE_FORCED_NAVIGATION = false
const navigate = (to, options) => {
  if (!ENABLE_FORCED_NAVIGATION) {
    return gatsbyNavigate(to, options)
  }
  const currentPath = window.location.pathname
  const currentPathIsFunnel = currentPath.includes("/app/start/")
  const currentPathIsCla = currentPath.includes("/app/") && !currentPathIsFunnel
  const toPathIsFunnel = to.includes("/app/start/")
  const toPathIsCla = to.includes("/app/") && !toPathIsFunnel

  if (
    (currentPathIsCla && toPathIsCla) ||
    (currentPathIsFunnel && toPathIsFunnel)
  ) {
    window.location.href = to
  } else {
    return gatsbyNavigate(to, options)
  }
}

const useQueryState = () => {
  const location = useLocation()
  const url = new URL(location.href)

  const pushUrl = (newUrl) => window.history.pushState({}, "", newUrl)
  return {
    removeParam: (param) => {
      url.searchParams.delete(param)
      pushUrl(url)
    },
    setParam: (param, value) => {
      url.searchParams.set(param, value)
      pushUrl(url)
    },
    appendParam: (param, value) => {
      url.searchParams.append(param, value)
      pushUrl(url)
    },
    getParam: (param) => {
      return url.searchParams.get(param)
    },
    getSearchString() {
      return url.search
    },
    getAppendedSearchString(paramName, value) {
      url.searchParams?.append(paramName, value)
      return url.searchParams?.toString()
    },
  }
}

export { navigate, useQueryState }
