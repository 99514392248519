import { useThemeUI } from "@trueskin-web/theme"

import Styleguide from "./styleguide"

const Demo = ({ variant }) => {
  const { theme } = useThemeUI()

  return (
    <div
      sx={{
        p: 2,
      }}
    >
      {Object.entries(theme[variant]).map(([prop, value], index) => (
        <div
          key={`${prop}-${value}`}
          sx={{
            mt: index !== 0 && 2,
            [variant === "fonts" ? "fontFamily" : `${variant.slice(0, -1)}`]:
              value,
          }}
        >
          {prop} {value}
          {variant === "fontSizes" ? "px" : undefined}
        </div>
      ))}
    </div>
  )
}

const sections = [
  "fonts",
  "fontSizes",
  "fontWeights",
  "lineHeights",
  "letterSpacings",
].map((section) => ({
  name: section,
  component: () => <Demo variant={section} />,
  type: "section",
}))

const Fonts = () => <Styleguide items={sections} />

export default Fonts
