import "url-search-params-polyfill"

import { getCookie, setCookie } from "@trueskin-web/cookies"
import { trackingService } from "@trueskin-web/services"

function storeTikTokIfPresent(searchParams) {
  if (searchParams.has("ttclid")) {
    // https://ads.tiktok.com/marketing_api/docs?id=1701890979375106 The ttclid is valid for 7 days.
    setCookie("_ttclid", searchParams.get("ttclid"), 7)
  }
}

export function getTikTokClid() {
  return getCookie("_ttclid")
}

const LAST_TOUCH_POINT_INDEX = 3

let trackedOnThisSPALoad = false
export const trackTouchPoint = (location) => {
  if (trackedOnThisSPALoad) {
    return false
  }
  trackedOnThisSPALoad = true
  const { search: searchParamsString, pathname } = location
  const searchParams = new URLSearchParams(searchParamsString)
  const params = Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value
      return acc
    },
    {}
  )

  const allTouchPoints = trackingService.getAllTrackingTouchPoints()
  let minutesSinceLastTouchPoint = null
  if (allTouchPoints.length) {
    const lastTouchPoint = allTouchPoints[allTouchPoints.length - 1]
    const lastTouchPointDate = new Date(lastTouchPoint.date)
    const difference = new Date().getTime() - lastTouchPointDate.getTime()
    minutesSinceLastTouchPoint = Math.ceil(difference / (1000 * 60))
  }
  const referrer = document?.referrer || ""
  const hasReferrer =
    referrer.length &&
    ["formelskin.de", "typeform.com", window?.location?.hostname].every(
      (ref) => !referrer.includes(ref)
    )
  const hasTrackingParams = Object.keys(params).some((param) =>
    [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "utm_keyword",
      "matchtype",
      "fbclid",
      "gclid",
    ].includes(param)
  )
  const touchPoint = {
    params,
    referrer,
    pathname,
    minutesSinceLastTouchPoint,
  }
  if (
    minutesSinceLastTouchPoint === null ||
    minutesSinceLastTouchPoint >= 60 ||
    hasReferrer ||
    hasTrackingParams
  ) {
    trackingService.addNewTouchPoint(touchPoint)
  }
}
export const trackCampaignIfPresent = (location) => {
  trackTouchPoint(location)
  const { search: searchParamsString, pathname: target } = location

  if (!searchParamsString) {
    return
  }

  const searchParams = new URLSearchParams(searchParamsString)

  storeTikTokIfPresent(searchParams)
  if (
    !searchParams.has("utm_source") &&
    !searchParams.has("utm_medium") &&
    !searchParams.has("utm_campaign") &&
    !searchParams.has("utm_content") &&
    !searchParams.has("utm_term") &&
    !searchParams.has("utm_keyword") &&
    !searchParams.has("matchtype") &&
    !searchParams.has("fbclid") &&
    !searchParams.has("gclid")
  ) {
    return
  }

  const storedTouchpoints = trackingService.getTrackingTouchpoints()
  let touchpoints = storedTouchpoints ? storedTouchpoints : Array(4).fill(null)
  let overwriteLastTouchpoint = true
  const newTouchpoint = {
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
    utm_content: searchParams.get("utm_content"),
    utm_term: searchParams.get("utm_term"),
    utm_keyword: searchParams.get("utm_keyword"),
    matchtype: searchParams.get("matchtype"),
    referral: document?.referrer,
    target,
    fbclid: searchParams.get("fbclid"),
    gclid: searchParams.get("gclid"),
  }
  // we replace the last touch point
  // and the 1st,2nd,3rd position that matches the same as the last touch point
  if (searchParams.has("optimize_redirect")) {
    const lastTP = JSON.stringify(touchpoints[LAST_TOUCH_POINT_INDEX])
    touchpoints = touchpoints.map((tp) => {
      if (JSON.stringify(tp) === lastTP) {
        return null
      }
      return tp
    })
  }

  for (let i = 0; i < touchpoints.length; i++) {
    if (touchpoints[i] === null) {
      touchpoints[i] = newTouchpoint
      overwriteLastTouchpoint = false
      if (i !== touchpoints.length - 1) {
        touchpoints[touchpoints.length - 1] = newTouchpoint
      }
      break
    }
  }

  if (overwriteLastTouchpoint) {
    touchpoints[touchpoints.length - 1] = newTouchpoint
  }

  trackingService.saveTrackingTouchpoints(touchpoints)
}
