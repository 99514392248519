import { format } from "date-fns"
import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { AB_TESTS, AB_TESTS_DATA } from "@trueskin-web/ab-experiments"
import {
  amplitudeClient,
  authClient,
  marketingClient,
  useMutation,
  useQuery,
  useQueryClient,
  userClient,
} from "@trueskin-web/apis"
import { apiVortex } from "@trueskin-web/apis/src/api"
import { tofuGqlClient, useGqlMutation } from "@trueskin-web/gql"
import { authService, storageService, vwoService } from "@trueskin-web/services"

const AuthContext = createContext()

function AuthProvider(props) {
  const queryClient = useQueryClient()

  const {
    status,
    isLoading,
    isError,
    data: user,
    error,
  } = useQuery("currentUser", authClient.getCurrentUser)

  const { isLoading: isUserIdentityLoading, data: userIdentity } = useQuery(
    "userIdentity",
    authClient.getIdentity
  )

  const extractIdentity = ({ id, firstName, lastName }) => ({
    id,
    firstName,
    lastName,
  })

  const { mutateAsync: login } = useMutation(authClient.login, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const { mutateAsync: emailLogin } = useMutation(authClient.emailLogin, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const [tofuSync] = useGqlMutation(tofuGqlClient.importTofuUser, {
    context: {
      headers: {
        "x-vortex-enabled-list": apiVortex().getEnabledList().join(","),
      },
    },
    onCompleted: ({ importTofuUser: { jwt, user } }) => {
      authService.saveJwt(jwt)
      queryClient.setQueryData("currentUser", user)
      queryClient.setQueryData("userIdentity", extractIdentity(user))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const { mutateAsync: logout } = useMutation(authClient.logout, {
    onSuccess: async () => {
      amplitudeClient.removeIdentifier()
      queryClient.clear()
    },
  })

  const { mutateAsync: register } = useMutation(authClient.register, {
    onSuccess: async (data) => {
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
      await queryClient.refetchQueries("currentUser")
    },
  })

  const confirmEmail = useMutation(authClient.confirmEmail, {
    onSuccess: async ({ jwt, user }) => {
      authService.saveJwt(jwt)
      queryClient.setQueryData("userIdentity", extractIdentity(user))
      await queryClient.refetchQueries("currentUser")
    },
  })

  const [renewalDateDialog, setRenewalDateDialog] = useState(false)

  useEffect(() => {
    // only set firstname if it hasn't already been set by BD.
    if (userIdentity && !authService.getFirstname()) {
      authService.saveFirstname(userIdentity.firstName || "Anonymous")
    }
  }, [userIdentity])

  useEffect(() => {
    if (user) {
      authService.saveUserCreationTimestamp(user.createdAt)
      authService.saveUserTreatmentDetails(user)
      authService.saveUserChatStatus(user)
      authService.saveUserSubscriptionType(user.subscription)
      authService.saveUserSubscriptionCycles(user)
      authService.saveUserAverageNPS(user)
      authService.saveUserAydCount(user)
      authService.saveUserConfirmedDiagnoses(user.medicalDiagnosis)
      authService.trackUser(user)
      // TODO @fox: dirties hack evaaaaa Merry Christmas ho ho ho
      try {
        if (
          user._id === "66ebfd54acacc244e00c5ae8" &&
          [null, undefined, ""].includes(
            storageService.getItem({
              id: AB_TESTS_DATA.longTermDiscounts.lsKey,
            })
          )
        ) {
          storageService.setItem({
            id: AB_TESTS_DATA.longTermDiscounts.lsKey,
            data: "1",
          })
        }
      } catch (e) {
        // no-op
      }
    }
  }, [user])

  const value = useMemo(
    () => ({
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      confirmEmail,
      renewalDateDialog,
      setRenewalDateDialog,
    }),
    [
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      confirmEmail,
      renewalDateDialog,
      setRenewalDateDialog,
    ]
  )

  return <AuthContext.Provider value={value} {...props} />
}

function trackUser(context) {
  if (context?.user?._id) {
    if (marketingClient.getConsent("Google Analytics")) {
      if (!window.dataLayer) {
        window.dataLayer = []
      }

      const hasAlreadySentUserId = window.dataLayer.some(
        ({ event: storedEvent }) =>
          storedEvent === marketingClient.EVENT_TYPES_ENUM.SET_USER_ID
      )

      if (!hasAlreadySentUserId) {
        marketingClient.trackEvent({
          name: marketingClient.EVENT_TYPES_ENUM.SET_USER_ID,
          custom_data: {
            userId: context.user._id,
            signUpDate: format(new Date(context.user.createdAt), "yyyy-MM-dd"),
            redoInitialQuestionnaire: context.user.redoInitialQuestionnaire,
            treatmentGoal: context.user.treatmentGoals?.join(","),
            ...vwoService.getVwoExperimentsEventData(),
          },
        })
      }
    }

    if (!amplitudeClient.isUserIdentified()) {
      amplitudeClient.identifyUser(context.user._id)
    }
  }
}

/**
 * @returns {{
 *  user: import('@trueskin/vortex-sdk').UserMe,
 *  userIdentity: import('@trueskin/vortex-sdk').UserIdentity
 *  isLoading: boolean,
 *  isError: boolean,
 *  status: import('react-query').QueryStatus
 * } & Record<string, any>}
 */
function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  trackUser(context)
  return context
}

export { AuthProvider, useAuth }
