import { Fragment } from "react"

export const pimplesIcon = {
  path: (
    <Fragment>
      <path
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        fill="#FDF3F6"
      />
      <path
        d="M4.21728 14.9422C4.73149 14.4133 5.2163 13.855 5.68643 13.2821C6.40631 12.3859 7.65509 11.2106 9.0214 10.8874C10.8431 10.4466 11.7099 10.7845 12.3564 11.7542C13.0028 12.7238 14.3985 14.4721 14.8245 15.4564C15.2506 16.4407 14.7217 19.4819 13.326 20.4662C11.9303 21.4505 8.81572 21.5534 7.94892 20.9069C7.08212 20.2605 5.04001 21.0098 4.73149 19.379C4.42296 17.7483 3.55617 15.7796 3.86469 15.3536C3.95284 15.236 4.07037 15.1038 4.21728 14.9422Z"
        fill="#DBA5BA"
      />
      <path
        d="M19.2175 10.006C19.2175 10.006 21.1715 9.24208 22.9198 10.006C24.668 10.77 25.1822 11.4164 24.433 12.4008C23.6837 13.3851 22.6994 15.1187 21.0686 15.1187C19.4379 15.1187 17.4692 14.1343 17.6896 13.2675C17.91 12.4008 17.146 10.3439 19.2175 10.006Z"
        fill="#DBA5BA"
      />
      <path
        d="M27.0627 15.1629C25.9902 15.2217 25.0205 15.8387 24.5357 16.8084C24.1684 17.5577 24.0362 18.1159 24.7561 18.1747C25.2115 18.2188 25.9314 18.2335 26.6366 18.2188C28.0029 18.2188 28.9138 16.6909 28.1645 15.5596C27.9735 15.2805 27.7532 15.1042 27.4887 15.1336C27.3418 15.1336 27.2096 15.1629 27.0627 15.1629Z"
        fill="#DBA5BA"
      />
      <path
        d="M17.7782 22.4939C17.7782 22.4939 16.9701 19.8641 18.3511 19.1443C19.7321 18.4244 20.0113 18.0277 21.0837 18.3215C22.1562 18.6154 22.9642 20.1286 23.2875 20.7162C23.5666 21.2304 24.7713 24.536 23.2287 25.3147C21.6861 26.0933 19.4383 25.3147 19.4383 25.3147L17.7928 22.4939H17.7782Z"
        fill="#DBA5BA"
      />
      <path
        d="M12.8261 24.4917C12.8261 23.8746 13.4285 23.4339 14.0015 23.6249C14.8389 23.904 15.7791 24.4329 15.9848 25.3438L15.7204 25.7257L14.4422 26.5485L13.7223 26.4603C12.7674 26.1665 12.8261 25.4907 12.8261 24.4917Z"
        fill="#DBA5BA"
      />
      <path
        d="M5.21652 14.472C5.68665 13.9872 6.14208 13.4877 6.56813 12.9588C7.22925 12.136 8.38988 11.0636 9.65334 10.7697C11.3429 10.3731 12.1362 10.6669 12.7386 11.5631C13.3409 12.4593 14.6338 14.0459 15.0304 14.9421C15.4271 15.8383 14.9276 18.615 13.6347 19.5112C12.3419 20.4073 9.46236 20.5102 8.66902 19.9078C7.87568 19.3055 5.98048 20.0107 5.68665 18.5121C5.39282 17.0136 4.59948 15.2359 4.89331 14.8393C4.96676 14.7364 5.0843 14.6042 5.21652 14.472Z"
        fill="white"
      />
      <path
        d="M18.9091 9.97657C18.9091 9.97657 20.7015 9.28607 22.2882 9.97657C23.8748 10.6671 24.345 11.2694 23.6692 12.1656C22.9934 13.0618 22.0972 14.6485 20.5986 14.6485C19.1001 14.6485 17.3224 13.7523 17.5134 12.9589C17.7044 12.1656 17.0139 10.2851 18.9091 9.97657Z"
        fill="white"
      />
      <path
        d="M26.0927 14.6776C25.1083 14.7363 24.2269 15.2946 23.7861 16.1761C23.4482 16.8519 23.3307 17.3661 23.9771 17.4249C24.4032 17.4689 25.0496 17.4689 25.696 17.4689C26.9448 17.4689 27.7675 16.0733 27.0917 15.0302C26.9301 14.7804 26.7244 14.6188 26.4746 14.6335C26.3424 14.6335 26.2102 14.6629 26.0927 14.6629V14.6776Z"
        fill="white"
      />
      <path
        d="M17.6011 21.3773C17.6011 21.3773 16.8665 18.9679 18.1153 18.3068C19.3641 17.6457 19.6432 17.2931 20.6129 17.5575C21.5825 17.822 22.3318 19.203 22.6256 19.7466C22.8753 20.2167 23.9772 23.2285 22.5668 23.9483C21.1564 24.6682 19.1143 23.9483 19.1143 23.9483L17.6011 21.3773Z"
        fill="white"
      />
      <path
        d="M13.1938 24.0363C13.1938 23.478 13.7374 23.0667 14.2662 23.243C15.0302 23.5074 15.897 23.9922 16.0733 24.8149L15.8235 25.1675L14.6482 25.9168L13.9871 25.8433C13.1056 25.5789 13.1644 24.9619 13.1644 24.051L13.1938 24.0363Z"
        fill="white"
      />
    </Fragment>
  ),
  viewBox: "0 0 32 32",
  fill: "none",
}
