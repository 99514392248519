import { format } from "date-fns"
import { useCallback } from "react"
import { toast } from "react-toastify"

import {
  amplitudeClient,
  useMutation,
  useQueryClient,
  userClient,
} from "@trueskin-web/apis"
import { Card, Icon, Section } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import {
  formatDate,
  formatDateISO,
  getErrorMessage,
} from "@trueskin-web/functions"
import { arrowLongRightIcon } from "@trueskin-web/theme/icons/arrowLongRightIcon"

const CalendarCard = ({ accentError, date }: any) => {
  return (
    <Card
      sx={{
        p: 0,
        minWidth: 120,
        borderRadius: 16,
        boxShadow:
          "0px 82px 23px 0px rgba(0, 0, 0, 0.00), 0px 53px 21px 0px rgba(0, 0, 0, 0.01), 0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 3px 7px 0px rgba(0, 0, 0, 0.10);",
        alignItems: "flex-start",
        gap: 0,
      }}
    >
      <div
        sx={{
          display: "flex",
          py: "6px",
          background: accentError ? "statusNegativeDark" : "neutralsLightGrey",
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <span
          sx={{
            fontSize: 12,
            lineHeight: "16px",
            fontWeight: 700,
            color: accentError ? "white" : "neutralsDarkGrey",
            textTransform: "uppercase",
          }}
        >
          {formatDate(date, {
            month: "short",
          })}
        </span>
      </div>

      <div
        sx={{
          pt: "10px",
          pb: "14px",
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "0px 0px 16px 16px",
          borderRight: "2px solid",
          borderBottom: "2px solid",
          borderLeft: "2px solid",
          borderColor: "neutralsLightGrey",
        }}
      >
        <div
          sx={{
            width: "100%",
            textAlign: "center",
            color: accentError ? "statusNegativeDark" : "neutralsDarkGrey",
            fontWeight: 700,
            fontSize: 12,
            lineHeight: "16px",
          }}
        >
          {formatDate(date, {
            weekday: "short",
          })}
        </div>

        <div
          sx={{
            width: "100%",
            textAlign: "center",
            color: accentError ? "neutralsDeepBlack" : "neutralsDarkGrey",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "40px",
          }}
        >
          {formatDate(date, {
            day: "numeric",
          })}
        </div>
      </div>
    </Card>
  )
}

export const ChurnPreventionPauseSaveNewRenewalScreen = ({
  currentRenewalDate,
  renewalDate,
  renewalReason,
  onBack,
  onDismiss,
}: any) => {
  const queryClient = useQueryClient()
  const {
    user: { subscription },
  } = useAuth()

  const renewalDateFormatted = formatDate(renewalDate, {
    month: "long",
    day: "numeric",
  })

  const {
    mutateAsync: changeRenewalDate,
    isLoading,
    error,
  } = useMutation(userClient.changeRenewalDate, {
    onSuccess: () =>
      (async () => {
        toast("Deine Änderungen wurden erfolgreich gespeichert.")

        const { ANALYTIC_EVENT_PROPS } = amplitudeClient

        amplitudeClient.trackEvent(
          amplitudeClient.ANALYTIC_EVENTS.NEXT_ORDER_SHIFT_RENEWAL_SAVED,
          {
            [ANALYTIC_EVENT_PROPS.TYPE]: "pause_experiment",
            [ANALYTIC_EVENT_PROPS.NEW_RENEWAL_DATE]: formatDateISO(renewalDate),
            [ANALYTIC_EVENT_PROPS.OLD_RENEWAL_DATE]:
              formatDateISO(currentRenewalDate),
            [ANALYTIC_EVENT_PROPS.REASON]: renewalReason,
          }
        )

        return await Promise.all([
          queryClient.refetchQueries("currentUser"),
          queryClient.refetchQueries("currentSubscriptionSummary"),
          onDismiss(),
        ])
      })(),
  })

  const saveNewRenewalDate = useCallback(
    async ({ renewalDate, renewalReason }: any) => {
      await changeRenewalDate({
        subscriptionId: subscription?._id,
        renewalDate,
        renewalReason,
      })
    },
    [renewalDate, renewalReason, subscription, changeRenewalDate]
  )

  return (
    <Section
      title="Bestätige Deine Änderungen"
      description={`Dein nächstes Skin-Set ist für den ${renewalDateFormatted} geplant. Die Herstellung und Lieferung erfolgt innerhalb von 3-5 Tagen nach diesem Datum.`}
      content={
        <Card
          sx={{
            border: "1px solid lightGray",
            pt: 7,
            pb: "40px",
            px: 6,
            gap: "40px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <CalendarCard date={currentRenewalDate} />

            <div
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: "stretch",
              }}
            >
              <Icon
                icon={arrowLongRightIcon}
                size="xlg"
                color="statusNegativeDark"
              />
            </div>

            <CalendarCard date={renewalDate} accentError />
          </div>

          <div
            sx={{
              fontSize: 12,
              lineHeight: "16px",
              color: "neutralsDarkGrey",
              textAlign: "center",
            }}
          >
            Du hast während deiner Pause weiterhin Zugang zu allen Funktionen.
          </div>
        </Card>
      }
      onDismiss={onDismiss}
      onBack={onBack}
      backLabel={onBack ? "Zurück" : undefined}
      primaryAction={async () =>
        await saveNewRenewalDate({
          renewalDate: format(renewalDate, "yyyy-MM-dd"),
          renewalReason,
        })
      }
      primaryActionLabel="Änderungen bestätigen"
      isPrimaryActionLoading={isLoading}
      actionError={error ? getErrorMessage(error) : null}
      sx={{
        '[type="button"]': {
          borderRadius: 8,
        },
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "> .section-content": {
          flex: 1,
        },
      }}
      footnote={
        <div
          sx={{
            textAlign: "center",
            fontSize: 12,
            lineHeight: "16px",
            color: "textLight",
          }}
        >
          Dir werden bis zum {renewalDateFormatted} keine Kosten berechnet.
        </div>
      }
    />
  )
}
