import { useCallback, useSyncExternalStore } from "react"

const getSnapshot = (query: string) => () => {
  return window.matchMedia(query).matches
}

const getServerSnapshot = () => {
  throw Error("useMediaQuery is a client-only hook")
}

/**
 * Hook to subscribe to the browser's media query changes.
 * This hooks uses React's `useSyncExternalStore` to subscribe to the media query changes.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia|window.matchMedia}
 * @see {@link https://react.dev/reference/react/useSyncExternalStore|React.useSyncExternalStore}
 *
 * @param query The media query to subscribe to.
 * @returns Whether the media query is matched by the current browser window size.
 */
const useMediaQuery = (query: string): boolean => {
  const subscribe = useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query)

      matchMedia.addEventListener("change", callback)
      return () => {
        matchMedia.removeEventListener("change", callback)
      }
    },
    [query]
  )

  return useSyncExternalStore(subscribe, getSnapshot(query), getServerSnapshot)
}

/**
 * Hook to check if the current browser window size is small (similar to a mobile device).
 * This hook is useful for creating responsive components that need to change their behavior and/or appearance based on the browser's window size.
 *
 * The breakpoint which is checked against is set to `54em` (which is equivalent to `864px`).
 * This exact value (`54em`) is in sync with the breakpoints array set in our ThemeUI config object.
 *
 * @returns `true` if the current browser window size is small (mobile-like), `false` otherwise.
 */
export const useIsScreenSizeSmall = () =>
  useMediaQuery("screen and (max-width: 54em)")
