import { Fragment } from "react"

import { useThemeUI } from "@trueskin-web/theme"

const Spaces = () => {
  const {
    theme: { space },
  } = useThemeUI()

  return (
    <div
      sx={{
        display: "inline-grid",
        gridTemplateColumns: "repeat(3, auto)",
        alignItems: "center",
        gridGap: 2,
        p: 2,
      }}
    >
      {space.map((value, index) => (
        <Fragment key={value}>
          <div
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <div
              sx={{
                height: value,
                width: value,
                bg: "text",
              }}
            />
          </div>
          <div>{index}</div>
          <div>{value}px</div>
        </Fragment>
      ))}
    </div>
  )
}

export default Spaces
