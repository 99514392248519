import Button from "../../button"
import { Card } from "../../card"
import CdnImage from "../../cdn-image"

const cards = [
  {
    title: "Card with simple text",
    content: () => "Your first monthly check-in with Sarah is in 23 days",
  },
  {
    title: "Card with complex text",
    content: () => (
      <div>
        Your first monthly check-in with Sarah is in{" "}
        <div sx={{ color: "primary" }}>23 days</div>
      </div>
    ),
  },
  {
    title: "Card with button",
    content: () => <Button>Erinnere mich per SMS</Button>,
  },
  {
    title: "Card with image",
    content: () => (
      <CdnImage src="https://a.storyblok.com/f/239902/2400x1600/52ebbde03c/formelskin_howto_srgb_lowres_1209_mocked.jpg/m/1520x760" />
    ),
  },
]

const SimpleCards = () => {
  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {cards.map((card, index) => (
        <Card key={index}>{card.content()}</Card>
      ))}
    </div>
  )
}

export default SimpleCards
