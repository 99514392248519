import { apiVortex } from "./api"

function getCheckIn() {
  return apiVortex().getTypeformQuestionnaireUrl("checkin")
}

function getRepurchase() {
  return apiVortex().getTypeformQuestionnaireUrl("repurchase")
}

function submittedCheckIn({ responseId, imageUploadUid }) {
  return apiVortex().postConsultationsCheckInSubmitted({
    responseId,
    imageUploadUid,
  })
}

function submittedRepurchase({ responseId, imageUploadUid }) {
  return apiVortex().postConsultationsRepurchaseSubmitted({
    typeformResponseId: responseId,
    imageUploadUid,
  })
}

function submittedMidTwoMonthsUpdate({ responseId, imageUploadUid }) {
  return apiVortex().postConsultationsMid2MUpdateSubmitted({
    typeformResponseId: responseId,
    imageUploadUid,
  })
}

export {
  getCheckIn,
  getRepurchase,
  submittedCheckIn,
  submittedRepurchase,
  submittedMidTwoMonthsUpdate,
}
