import { RoundBordered } from "@trueskin-web/components/src/round-bordered"
import SeparatedLine from "@trueskin-web/components/src/separated-line"
import { V2Cards } from "@trueskin-web/components/src/styleguide/cards/v2-cards"

import CardsWithContent from "./cards-with-content"
import CardsWithCtas from "./cards-with-ctas"
import CardsWithFooter from "./cards-with-footer"
import ComplexCards from "./complex-cards"
import SimpleCards from "./simple-cards"
import TrackingCards from "./tracking-cards"

const Cards = () => {
  return (
    <div
      sx={{
        maxWidth: "400px",
        mx: "auto",
        background: "#E7ECFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SimpleCards />
      <SeparatedLine />
      <CardsWithFooter />
      <SeparatedLine />
      <CardsWithContent />
      <SeparatedLine />
      <CardsWithCtas />
      <SeparatedLine />
      <ComplexCards />
      <SeparatedLine />
      <TrackingCards />
      <SeparatedLine />
      <V2Cards />
      <RoundBordered margin={"4"}>
        Some random text in a round bordered container
      </RoundBordered>
    </div>
  )
}

export default Cards
