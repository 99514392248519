import { FORM_ERROR } from "final-form"
import { Fragment, useState } from "react"
import { Form } from "react-final-form"

import { getErrorMessage } from "@trueskin-web/functions"
import { useTranslation } from "@trueskin-web/translations"

import Button from "./button"
import Dialog from "./dialog"
import FieldError from "./field-error"

const ConfirmDelete = ({ onSubmit, onDismiss }) => (
  <Dialog
    data-testid="web-portal-confirm-delete-dialog"
    aria-label="Lieferadresse löschen?"
    title="Lieferadresse löschen?"
    onDismiss={onDismiss}
    primaryAction={onDismiss}
    primaryActionLabel="Abbrechen"
    secondaryAction={onSubmit}
    secondaryActionLabel="Löschen"
    description="Möchten Sie diese Lieferadresse wirklich löschen?"
  />
)

const EditableForm = ({
  editable,
  onSubmit,
  onDelete,
  onSuccess,
  disableSubmitButton = false,
  ...props
}) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const { t } = useTranslation()

  return (
    <Fragment>
      <Form
        onSubmit={async (values) => {
          try {
            await onSubmit(values)
            onSuccess()
          } catch (error) {
            return { [FORM_ERROR]: getErrorMessage(error) }
          }
        }}
        render={({
          handleSubmit,
          submitting,
          submitError,
          pristine,
          valid,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            {editable}

            {submitError && <FieldError>{submitError}</FieldError>}

            <Button
              data-testid="web-portal-editable-form-submit-btn"
              type="submit"
              size="lg"
              isLoading={submitting}
              isDisabled={disableSubmitButton ? !pristine && !valid : false}
              sx={{
                mt: 3,
                width: ["100%", "auto"],
              }}
            >
              {t("Button.save")}
            </Button>

            {onDelete ? (
              <Button
                data-testid="web-portal-editable-form-clear-btn"
                variant="outline"
                size="lg"
                onClick={() => setIsConfirmDelete(true)}
                isDisabled={submitting}
                sx={{
                  ml: [0, 3],
                  mt: 3,
                  width: ["100%", "auto"],
                }}
              >
                {t("Button.clear")}
              </Button>
            ) : null}
          </form>
        )}
        {...props}
      />
      {isConfirmDelete && (
        <ConfirmDelete
          onSubmit={async () => {
            await onDelete()
            setIsConfirmDelete(false)
            onSuccess()
          }}
          onDismiss={() => setIsConfirmDelete(false)}
        />
      )}
    </Fragment>
  )
}

export default EditableForm
