import React, { useEffect, useState } from "react"

interface StepProps extends React.HTMLProps<HTMLDivElement> {
  width: number
  height: number
  backgroundColor: string
  isExtremal?: boolean
  isFullWidth?: boolean
}

const Step: React.FC<StepProps> = ({
  isExtremal = false,
  isFullWidth = false,
  backgroundColor,
  width,
  height,
  ...props
}) => {
  const [stepWidth, setStepWidth] = useState<number | string>(width)

  useEffect(() => {
    if (isFullWidth) {
      setStepWidth("100%")
    } else if (isExtremal && !isFullWidth) {
      setStepWidth(width / 1.5)
    } else setStepWidth(width)
  }, [isExtremal, isFullWidth])

  return (
    <div
      sx={{
        width: stepWidth,
        height,
        backgroundColor,
        borderRadius: width / 2,
      }}
      {...props}
    />
  )
}

export default Step
