import { StaticImage } from "gatsby-plugin-image"

import i18next from "@trueskin-web/translations"

const products = {
  p_peeling: {
    title: i18next.t("Products.p_peeling.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_peeling.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_peeling.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    isAddon: true,
  },
  p_pimple_patches_day_night_40pcs_1: {
    out_of_stock: true,
    title: i18next.t("Products.p_pimple_patches_day_night_40pcs_1.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_pimple_patches-40pc.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_pimple_patches-40pc.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    isAddon: true,
  },
  p_pimple_patches_day_night_30pcs_1: {
    title: i18next.t("Products.p_pimple_patches.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_pimple_patch.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_pimple_patch.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    isAddon: true,
  },
  p_spf_50_1: {
    title: i18next.t("Products.p_spf_50_1.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_spf_50_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_spf_50_1.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    isAddon: true,
  },
  p_prescription_1: {
    title: i18next.t("Products.p_prescription_1.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/product_spotlight_p_prescription_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_medical_cream_1: {
    title: i18next.t("Products.p_medical_cream_1.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_medical_cream_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_medical_cream_1.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_cleanser_1: {
    title: i18next.t("Products.p_cleanser_1.title"),
    volume: 50,
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_cleanser_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_cleanser_1.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_cleanser_2: {
    title: i18next.t("Products.p_cleanser_2.title"),
    volume: 50,
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_cleanser_2.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_cleanser_2.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_moisturizer_1: {
    title: i18next.t("Products.p_moisturizer_1.title"),
    volume: 30,
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/product_spotlight_p_moisturizer_1.jpg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/product_p_moisturizer_1.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_moisturizer_2: {
    title: i18next.t("Products.p_moisturizer_2.title"),
    volume: 30,
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_moisturizer_2.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_moisturizer_2.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_moisturizer_3: {
    title: i18next.t("Products.p_moisturizer_3.title"),
    volume: 30,
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_spotlight_p_moisturizer_3.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_moisturizer_3.png"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
  p_formel_set_1: {
    title: i18next.t("Products.p_formel_set_1.title"),
    spotlightImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_formel_set_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
    productImage: ({ className }) => (
      <StaticImage
        src="../images/rebranded/product_p_formel_set_1.jpeg"
        alt=""
        objectFit="contain"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        className={className}
      />
    ),
  },
}

const xlProducts = [
  "p_cleanser_1",
  "p_cleanser_2",
  "p_moisturizer_1",
  "p_moisturizer_2",
  "p_moisturizer_3",
].reduce((acc, item) => {
  const product = products[item]
  acc[item + "_xl"] = {
    ...product,
    volume: product.volume * 2,
  }
  return acc
}, {})

const legacyProducts = {
  receipt: products.p_prescription_1,
  formula: products.p_medical_cream_1,
  moisturizer: products.p_moisturizer_1,
  cleanser: products.p_cleanser_1,
}

const deprecatedProducts = {
  "medical-cream": products.p_medical_cream_1,
}

const presetProducts = {
  preset_formel_only_sub_one_month: products.p_medical_cream_1,
  preset_formel_sub_one_month_keep_items: products.p_formel_set_1,
}

const productResources = {
  ...deprecatedProducts,
  ...legacyProducts,
  ...products,
  ...xlProducts,
  ...presetProducts,
}

const resources = {
  productResources,
}

const mapProductsResources = (apiProducts = []) =>
  apiProducts
    .filter(
      ({ product }) =>
        productResources[product] && !productResources[product].isAddon
    )
    .map(({ product, quantity }) => [...Array(quantity)].map(() => product))
    .flat()

const cosmeticProductIds = [
  "p_cleanser_1",
  "p_cleanser_2",
  "p_moisturizer_1",
  "p_moisturizer_2",
  "p_moisturizer_3",
  "p_cleanser_1_xl",
  "p_cleanser_2_xl",
  "p_moisturizer_1_xl",
  "p_moisturizer_2_xl",
  "p_moisturizer_3_xl",
]

export const isCosmeticProduct = (productId) =>
  cosmeticProductIds.includes(productId)

export { productResources, mapProductsResources, products }

export default resources
