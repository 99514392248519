import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

const SubscriptionContext = createContext()

const SubscriptionProvider = (props) => {
  const [renewalDateDialog, setRenewalDateDialog] = useState(false)
  const [entryScreen, setEntryScreen] = useState(null)

  const openRenewalDateDialog = useCallback(
    (entryScreen) => {
      entryScreen && setEntryScreen(entryScreen)
      setRenewalDateDialog(true)
    },
    [setRenewalDateDialog, setEntryScreen]
  )

  const closeRenewalDateDialog = useCallback(() => {
    setEntryScreen(null)
    setRenewalDateDialog(false)
  }, [setRenewalDateDialog, setEntryScreen])

  // fallback for the other places where setRenewalDateDialog(false) is called
  useEffect(() => {
    if (renewalDateDialog === false && entryScreen) {
      setEntryScreen(null)
    }
  }, [renewalDateDialog])

  const value = useMemo(
    () => ({
      renewalDateDialog,
      setRenewalDateDialog,
      entryScreen,
      openRenewalDateDialog,
      closeRenewalDateDialog,
    }),
    [
      renewalDateDialog,
      openRenewalDateDialog,
      closeRenewalDateDialog,
      entryScreen,
    ]
  )

  return <SubscriptionContext.Provider value={value} {...props} />
}

const useSubscriptionContext = () => useContext(SubscriptionContext)

export { SubscriptionProvider, useSubscriptionContext }
