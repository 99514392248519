import { apiV2 } from "./api"

function getPaymentLinkById(paymentLinkId) {
  return apiV2(`payment-links/${paymentLinkId}`).then(
    (response) => response.data
  )
}

function chargePaymentLink(paymentLinkId, paymentDetails) {
  return apiV2(`payment-links/${paymentLinkId}/charge`, {
    body: {
      ...paymentDetails,
    },
  })
}

export { getPaymentLinkById, chargePaymentLink }
