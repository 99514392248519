import { StaticImage } from "gatsby-plugin-image"
import { ReactNode } from "react"

import { amplitudeClient } from "@trueskin-web/apis"

import { EntryScreenType } from "./types"

const entryScreenHeading: Record<EntryScreenType, string> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]:
    "Meine Behandlung pausieren",
  [EntryScreenType.PauseTreatmentFromCancellation]:
    "Meine Behandlung pausieren",
  [EntryScreenType.TakeABreak]:
    "Möchtest Du lieber eine Pause einlegen und Dein nächstes Skin-Set verschieben?",
  [EntryScreenType.EarlySet]:
    "Möchtest Du Dein nächstes Skin-Set stattdessen früher haben?",
}

const entryScreenDescription: Record<EntryScreenType, string> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]:
    "Dein nächstes Skin-Set ist für den {DATE} geplant.",
  [EntryScreenType.PauseTreatmentFromCancellation]:
    "Dein nächstes Skin-Set ist für den {DATE} geplant.",
  [EntryScreenType.TakeABreak]: "Derzeit geplant für den {DATE}",
  [EntryScreenType.EarlySet]: "Derzeit geplant für den {DATE}",
}

const entryScreenDetailsList: Record<EntryScreenType, string[]> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]: [
    "Pausiere bis zu {SHIFT_MONTHS} Monate",
    "Während Deiner Pause werden Dir keine Kosten berechnet",
    "Behandlung früher fortsetzen oder jederzeit kündigen",
  ],
  [EntryScreenType.PauseTreatmentFromCancellation]: [
    "Pausiere bis zu {SHIFT_MONTHS} Monate",
    "Während Deiner Pause werden Dir keine Kosten berechnet",
    "Behandlung früher fortsetzen oder jederzeit kündigen",
  ],
  [EntryScreenType.TakeABreak]: [
    "Pausiere bis zu {SHIFT_MONTHS} Monate",
    "Während Deiner Pause werden Dir keine Kosten berechnet",
    "Verschiebe den Termin, wenn du es dir anders überlegst.",
  ],
  [EntryScreenType.EarlySet]: [
    "Plane dein Skin-Set frühestens für den {EARLIEST_DATE}",
    "Während Deiner Pause werden Dir keine Kosten berechnet",
    "Verschiebe den Termin, wenn du es dir anders überlegst.",
  ],
}

const entryScreenImage: Record<EntryScreenType, ReactNode> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]: (
    <StaticImage
      src="./images/pause-formel-2x.png"
      alt="pause-treatment-image"
      sx={{ height: 250, maxWidth: 330 }}
      objectFit="contain"
    />
  ),
  [EntryScreenType.PauseTreatmentFromCancellation]: (
    <StaticImage
      src="./images/pause-formel-2x.png"
      alt="pause-treatment-image"
      sx={{ height: 250, maxWidth: 330 }}
      objectFit="contain"
    />
  ),
  [EntryScreenType.TakeABreak]: (
    <StaticImage
      src="./images/pause-box-2x.png"
      alt="pause-treatment-image"
      sx={{ height: 250, maxWidth: 330 }}
      objectFit="contain"
    />
  ),
  [EntryScreenType.EarlySet]: (
    <StaticImage
      src="./images/pause-box-2x.png"
      alt="pause-treatment-image"
      sx={{ height: 250, maxWidth: 330 }}
      objectFit="contain"
    />
  ),
}

const entryScreenPageViewTracking: Record<EntryScreenType, () => void> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]: () => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.PAUSE_TREATMENT_PAGE_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.SOURCE]: "next_order_management",
      }
    )
  },
  [EntryScreenType.PauseTreatmentFromCancellation]: () => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.PAUSE_TREATMENT_PAGE_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.SOURCE]:
          "cancellation_manage_dialog",
      }
    )
  },
  [EntryScreenType.TakeABreak]: () => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "pause_treatment_more",
      }
    )
  },
  [EntryScreenType.EarlySet]: () => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
          "pause_treatment_product_less",
      }
    )
  },
}

const entryScreenContinueClickedTracking: Record<EntryScreenType, () => void> =
  {
    [EntryScreenType.PauseTreatmentFromNextOrderManagement]: () => {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.PAUSE_TREATMENT_PAGE_CONTINUE_CLICKED,
        {}
      )
    },
    [EntryScreenType.PauseTreatmentFromCancellation]: () => {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.PAUSE_TREATMENT_PAGE_CONTINUE_CLICKED,
        {}
      )
    },
    [EntryScreenType.TakeABreak]: () => {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_DEFLECTED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "pause_treatment_more",
        }
      )
    },
    [EntryScreenType.EarlySet]: () => {
      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_DEFLECTED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
            "pause_treatment_product_less",
        }
      )
    },
  }

const entryScreenCtaId: Record<EntryScreenType, string> = {
  [EntryScreenType.PauseTreatmentFromNextOrderManagement]:
    "churnPrevention-pauseTreatment-pauseEntryScreen-continue",
  [EntryScreenType.PauseTreatmentFromCancellation]:
    "churnPrevention-pauseTreatment-pauseEntryScreen-continue",
  [EntryScreenType.TakeABreak]:
    "churnPrevention-pauseTreatment-takeABreakEntryScreen-continue",
  [EntryScreenType.EarlySet]:
    "churnPrevention-pauseTreatment-earlySetEntryScreen-continue",
}

export {
  entryScreenHeading,
  entryScreenDescription,
  entryScreenDetailsList,
  entryScreenImage,
  entryScreenPageViewTracking,
  entryScreenContinueClickedTracking,
  entryScreenCtaId,
}
