import React, { useEffect, useRef } from "react"

type ClickOutsideProps = {
  children: React.ReactNode
  onClickOutside: () => void
}

const ClickOutside: React.FC<ClickOutsideProps> = ({
  onClickOutside,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClickOutside()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("touchstart", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    }
  }, [onClickOutside])

  return <div ref={wrapperRef}>{children}</div>
}

export default ClickOutside
