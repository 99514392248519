import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { useEffect } from "react"

import { storageService } from "@trueskin-web/services"

import Spinner from "./spinner"

const storageKeys = storageService.getStorageKeys()
const urlParam = "reset-cache"

const ResetCache = () => {
  const { search, pathname } = useLocation()

  const searchParams = new URLSearchParams(search)
  const urlResetCache = searchParams.get(urlParam)
  searchParams.delete(urlParam)

  const queryString = `${searchParams}` ? `?${searchParams}` : ""

  if (urlResetCache) {
    storageService.removeItem({ id: storageKeys[urlResetCache] })
  }

  useEffect(() => {
    if (urlResetCache) {
      navigate(`${pathname}${queryString}`, {
        replace: true,
      })
    }
  }, [urlResetCache, pathname, queryString])

  if (urlResetCache) {
    return <Spinner isFullPage disableScroll />
  }

  return null
}

export default ResetCache
