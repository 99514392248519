import React, { ButtonHTMLAttributes, ReactNode, forwardRef } from "react"

import Button, { variants } from "./button"
import Icon, { sizes } from "./icon"

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconSize?: keyof typeof sizes
  icon: ReactNode
  variant?: keyof typeof variants
  // TODO: inherit from button element
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, iconSize, variant = "link", ...props }, ref) => (
    <Button
      ref={ref}
      variant={variant}
      sx={{
        p: 0,
        touchAction: "manipulation",
      }}
      {...props}
    >
      <Icon
        icon={icon}
        focusable={false}
        color="currentColor"
        size={iconSize}
        aria-hidden
      />
    </Button>
  )
)

IconButton.displayName = "IconButton"

export default IconButton
