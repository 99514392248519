import { StaticImage } from "gatsby-plugin-image"
import { useEffect } from "react"

import { Section } from "@trueskin-web/components"
import { useTranslation } from "@trueskin-web/translations"

const FeedbackConfirmation = ({ onDismiss }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const timerId = setTimeout(onDismiss, 3000)

    return () => clearTimeout(timerId)
  }, [onDismiss])

  return (
    <Section
      title={t("Nps.FeedbackConfirmation.heading")}
      description={t("Nps.FeedbackConfirmation.description")}
      content={
        <div sx={{ mb: 6, textAlign: "center" }}>
          <StaticImage
            src="../../../images/feedback-success.png"
            alt=""
            objectFit="contain"
            height={120}
          />
        </div>
      }
    />
  )
}

export default FeedbackConfirmation
