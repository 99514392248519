import { useLocation } from "@reach/router"
import { useEffect, useRef, useState } from "react"

import { CardCta, Link } from "@trueskin-web/components"
import { conditionalFunction } from "@trueskin-web/functions"
import { useIsScreenSizeSmall } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { dotCircleIcon } from "@trueskin-web/theme/icons/dotCircleIcon"
import { Routes, useTranslation } from "@trueskin-web/translations"

import Icon, { sizes as iconSizes } from "./icon"

const pillWidth = 20
const pillGap = 4
const iconSize = iconSizes.sm
const completeColor = "black"
const incompleteColor = "grey"

const TrackingPills = ({ size, filled }) =>
  size === 0 ? null : (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: `${pillGap}px`,
        flex: size > 2 ? 1 : null,
        borderRadius: 3,
        backgroundColor: filled ? completeColor : null,
      }}
    >
      {Array.from({ length: size }).map((_, index) => (
        <div
          key={index}
          sx={{
            width:
              index === 0 || index === size - 1 ? pillWidth / 2 : pillWidth,
            height: 6,
            borderRadius: 3,
            backgroundColor: filled ? completeColor : incompleteColor,
          }}
        />
      ))}
    </div>
  )

const TrackingStatus = ({ steps, step: stepLabel }) => {
  const ref = useRef()

  const [unitPillsCount, setUnitPillsCount] = useState(0)

  const unitsCount = steps.length - 1
  const step = steps.indexOf(stepLabel) + 1

  const completeSize = step < 2 ? step : Math.floor((step - 1) * unitPillsCount)

  const incompleteSize = Math.floor(unitsCount * unitPillsCount - completeSize)

  const icon = step === steps.length ? checkCircleFilledIcon : dotCircleIcon

  useEffect(() => {
    const ctrl = new AbortController()

    const handleResize = () =>
      conditionalFunction({
        condition: () => ref?.current?.offsetWidth,
        success: () =>
          setUnitPillsCount(
            (ref?.current?.offsetWidth - iconSize.width) /
              (pillWidth + pillGap) /
              unitsCount
          ),
        signal: ctrl.signal,
      })

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      ctrl.abort()
      window.removeEventListener("resize", handleResize)
    }
  }, [ref.current])

  return (
    <div
      ref={ref}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <TrackingPills size={completeSize} filled />

      <Icon
        icon={icon}
        sx={{
          ...iconSize,
        }}
      />

      <TrackingPills size={incompleteSize} />
    </div>
  )
}

const OrderStatus = ({ className, step, hideTitle }) => {
  const location = useLocation()
  const isOrderHistoryPageActive = location.pathname.includes(
    Routes.App.Account.Shipping.url
  )
  const { t } = useTranslation()
  const isMobile = useIsScreenSizeSmall()

  const Wrapper = isMobile ? Link : (props) => <div {...props} />

  const steps = [
    t("OrderStatus.steps.planCreated"),
    t("OrderStatus.steps.formulaProduced"),
    t("OrderStatus.steps.packageSent"),
  ]

  return (
    <Wrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        color: "text",
        textDecoration: "none",
        flex: 1,
        justifyContent: "space-between",
      }}
      className={className}
      href={
        isMobile && !isOrderHistoryPageActive
          ? Routes.App.Account.Shipping.url
          : null
      }
    >
      {!hideTitle && (
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: "20px",
            pt: [null, 1],
          }}
        >
          {t("OrderStatus.title")}

          <Icon icon={arrowRightIcon} sx={{ display: ["block", "none"] }} />
        </div>
      )}

      <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TrackingStatus steps={steps} step={step} />

        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 6,
            fontSize: 12,
            lineHeight: "16px",
            "> div": {
              textAlign: "center",
              ":first-of-type": {
                textAlign: "left",
              },
              ":last-of-type": {
                textAlign: "right",
              },
            },
          }}
        >
          {steps.map((iStep, index) => (
            <div
              key={index}
              sx={{
                color: step === iStep ? "text" : "#75756C",
              }}
            >
              {iStep}
            </div>
          ))}
        </div>
      </div>

      {!isOrderHistoryPageActive && (
        <div sx={{ display: ["none", "flex"] }}>
          <CardCta
            as={Link}
            variant="outline"
            sx={{ width: "100%" }}
            href={Routes.App.Account.Shipping.url}
          >
            {t("OrderStatus.seeMoreDetails")}
          </CardCta>
        </div>
      )}
    </Wrapper>
  )
}

export default OrderStatus
