// import "@typeform/embed/build/css/popup.css"
import type { GatsbyBrowser } from "gatsby"
import { ToastContainer } from "react-toastify"

// import "react-toastify/dist/ReactToastify.css"
import { ApiProvider } from "@trueskin-web/apis"
import { AuthProvider } from "@trueskin-web/context"
import { GraphQLProvider } from "@trueskin-web/gql"
import { ThemeProvider } from "@trueskin-web/theme"
import { I18nProvider } from "@trueskin-web/translations"

import ErrorBoundary from "./src/app/components/error-boundary"

// This has been manually build from https://github.com/lipis/flag-icons
// When a new flag is required it should be built locally and just flag-icons.min.css and svgs
// import "./src/styles/flag-icons.min.css"
// import "./src/styles/global.css"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  // when using react-query in suspense mode, the network
  // errors are thrown from the provider
  <ErrorBoundary>
    <GraphQLProvider>
      <ApiProvider>
        <I18nProvider>
          <ThemeProvider>
            <AuthProvider>{element}</AuthProvider>
            <ToastContainer
              autoClose={3500}
              closeOnClick={true}
              position="top-center"
              hideProgressBar
            />
          </ThemeProvider>
        </I18nProvider>
      </ApiProvider>
    </GraphQLProvider>
  </ErrorBoundary>
)

// @ts-expect-error - this is a gatsby-browser file
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // changes of query params
  if (prevRouterProps?.location?.pathname === routerProps?.location?.pathname) {
    return false
  }

  return true
}
