import { Fragment } from "react"

import { lockIcon } from "@trueskin-web/theme/icons/lockIcon"

import { Card, CardBadge, CardContent, CardCta, CardHint } from "../../card"
import CdnImage from "../../cdn-image"
import Icon from "../../icon"

const DoctorImage = () => (
  <CdnImage
    src={
      "https://a.storyblok.com/f/239902/334x334/0bc855e288/doctor_17.webp/m/1272x0"
    }
    width="56"
    height="56"
    sx={{
      borderRadius: "50%",
      aspectRatio: 1,
    }}
  />
)

const HeroImage = () => (
  <CdnImage src="https://a.storyblok.com/f/239902/2400x1600/52ebbde03c/formelskin_howto_srgb_lowres_1209_mocked.jpg/m/1520x760" />
)

const cards = [
  {
    heroImage: <HeroImage />,
    content: { title: "Your first monthly check-in with Sarah is in 23 days" },
  },
  {
    heroImage: <HeroImage />,
    content: {
      title: (
        <div>
          Your first monthly check-in with Sarah is in{" "}
          <span sx={{ color: "primary" }}>23 days</span>
        </div>
      ),
      description:
        "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
      image: <DoctorImage />,
    },
    ctas: (
      <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CardCta>Erinnere mich per SMS</CardCta>
        <CardCta variant="outline">Erinnere mich per SMS</CardCta>
        <CardCta variant="link" size="sm">
          Erinnere mich per SMS
        </CardCta>
      </div>
    ),
    hint: {
      description:
        "Your pictures are subject to patient-doctor confidentiality and are only viewed by your doctor for the purpose of treatment.",
      icon: (
        <Icon
          icon={lockIcon}
          size="sm"
          sx={{ width: 20, height: 20, minWidth: 20 }}
        />
      ),
    },
    timeStatus: (
      <CardBadge
        description={
          <Fragment>
            Within <span sx={{ fontWeight: "bold" }}>23 days</span>
          </Fragment>
        }
      />
    ),
  },
]

const ComplexCards = () => {
  return (
    <div
      sx={{
        m: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {cards.map((card, index) => (
        <Card key={index}>
          {card.timeStatus}
          {card.heroImage}
          {card.content && <CardContent {...card.content} />}
          {card.ctas}
          {card.hint && <CardHint {...card.hint} />}
        </Card>
      ))}
    </div>
  )
}

export default ComplexCards
