import i18next from "@trueskin-web/translations"

import { apiVortex } from "./api"
import * as marketingClient from "./marketing"

const handleSaveAddressError = (error, addressType = "shippingAddress") => {
  const postalCode =
    error?.data?.fields?.[addressType]?.postalCode ||
    error?.metadata?.fields?.[addressType]?.postalCode

  if (postalCode) {
    const message = i18next.t("Generic.errors.invalidPostalCode")
    error.code ? (error.code = message) : (error.id = message)
  }

  throw error
}

function createShippingAddress(values) {
  return apiVortex()
    .postUsersMeAddress(values)
    .catch((err) => handleSaveAddressError(err))
}

function updateShippingAddress({ addressId, updates }) {
  return apiVortex()
    .putUsersMeAddress(addressId, updates)
    .catch((err) => handleSaveAddressError(err))
}

function deleteShippingAddress(addressId) {
  return apiVortex().deleteUsersMeAddress(addressId)
}

function selectShippingAddress(addressId) {
  return apiVortex().postUsersMeAddressSelect(addressId)
}

function updateBillingAddress({ billingAddressId, updates }) {
  return apiVortex()
    .putUsersMeAddress(billingAddressId, updates)
    .catch((err) => handleSaveAddressError(err, "billingAddress"))
}

function saveAddresses(values) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.SHIPPING_SUBMITTED,
    data: {
      phone: values.billingAddress.phoneNumber,
      country: values.billingAddress.country,
      city: values.billingAddress.city,
    },
  })

  return apiVortex().postUsersMeAddresses(values)
}

export {
  createShippingAddress,
  updateShippingAddress,
  deleteShippingAddress,
  selectShippingAddress,
  updateBillingAddress,
  saveAddresses,
}
