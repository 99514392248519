import { useNavigate } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import { ReactNode, useEffect } from "react"

import { amplitudeClient } from "@trueskin-web/apis"
import { Button, Card, Carousel, Dialog, Icon } from "@trueskin-web/components"
import CdnImage from "@trueskin-web/components/src/cdn-image"
import { useAuth } from "@trueskin-web/context"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { Routes } from "@trueskin-web/translations"

import { HorizontalList, HorizontalListItem } from "./horizontal-list"
import { PatientSuccessStory, successStories } from "./success-stories"

const amplitudeCancellationType = "patient_stories"

const ChurnPreventionSuccessStoriesDialog = ({
  onDismiss,
  onSelect,
  goToCancellation,
}: {
  onDismiss: () => void
  onSelect: (screen: string) => void
  goToCancellation: () => void
}) => {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: amplitudeCancellationType,
      }
    )
  }, [])

  const handleDismiss = () => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_CLOSED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: amplitudeCancellationType,
      }
    )

    onDismiss()
  }

  return (
    <Dialog
      data-testid="web-portal-renewal-dialog"
      aria-label="Renewal Dialog"
      onDismiss={handleDismiss}
      onBack={handleDismiss}
      backLabel={"Zurück"}
      // noDismissIcon
      title={
        <div sx={{ fontWeight: 700 }}>
          {user.firstName}, gib noch nicht auf—{" "}
          <span sx={{ color: "brandPrimary" }}>
            jede Behandlung bei Akne Vulgaris (L70.0) ist einzigartig.
          </span>
        </div>
      }
      description={
        <div sx={{ color: "neutralsDarkGrey" }}>
          Patient:innen mit ähnlicher Haut wie Deiner haben durch konsequente
          Anwendung echte Ergebnisse erzielt.
        </div>
      }
      sx={{
        "&[data-reach-dialog-content]": {
          display: "flex",
          flexDirection: "column",
          "& > section": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
          },
        },
        "&[data-reach-dialog-content] > section > .section-content": {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          mt: "12px !important",
        },
        ".section-header": {
          gridRowGap: 2,
          gridColumnGap: 0,
        },
      }}
    >
      {successStories
        // .filter((story) => story.condition === condition)
        .map((story) => (
          <SuccessStoryCard key={story.patientId} {...story} />
        ))}

      <div sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <div sx={{ fontSize: 20, lineHeight: "28px", fontWeight: 700 }}>
          {user.firstName}, was möchtest Du tun?
        </div>

        <SuccessStoryAction
          testId="churnPrevention-successStories-newAyd"
          title="Erhalte Unterstützung von Deiner Ärztin"
          description="Deine Ärztin ist hier, um Dir mit Deinem Behandlungsplan zu helfen."
          image={
            <CdnImage
              src={user?.highTouchDoctor?.imageUrl}
              width="40"
              height="40"
              sx={{
                borderRadius: "50%",
                aspectRatio: 1,
              }}
            />
          }
          onClick={async () => {
            amplitudeClient.trackEvent(
              amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_DEFLECTED,
              {
                [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
                  amplitudeCancellationType,
              }
            )

            await navigate(
              `${Routes.App.Consultations.NewQuestion.url}?category=maintenance`
            )
          }}
        />

        <SuccessStoryAction
          testId="churnPrevention-successStories-requestNewFormula"
          title="Erhalte eine Neubewertung Deines Behandlungsplans"
          description="Wenn Änderungen eine neue Formel erfordern, bekommst Du sie KOSTENLOS in ein paar Tagen zugeschickt."
          image={
            <StaticImage
              src="../../../../../../../images/rebranded/request_new_formula_mobile.jpeg"
              objectFit="contain"
              sx={{ height: 40, borderRadius: "50%" }}
              alt="Formel Package"
            />
          }
          onClick={async () => {
            amplitudeClient.trackEvent(
              amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_DEFLECTED,
              {
                [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
                  amplitudeCancellationType,
              }
            )

            onSelect("RequestNewFormulaDetailsDialog")
          }}
        />

        <Button
          data-testid="churnPrevention-successStories-continueToCancellation"
          isFullWidth
          rounded
          variant="ghost"
          color="black"
          onClick={() => {
            amplitudeClient.trackEvent(
              amplitudeClient.ANALYTIC_EVENTS.CANCELLATION_POPUP_CONFIRMED,
              {
                [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]:
                  amplitudeCancellationType,
              }
            )
            goToCancellation()
          }}
        >
          Weiter zur Kündigung
        </Button>
      </div>
    </Dialog>
  )
}

export { ChurnPreventionSuccessStoriesDialog }

const SuccessStoryCard = ({
  name,
  journeyDuration,
  age,
  condition,
  severity,
  images,
}: PatientSuccessStory) => {
  return (
    <Card sx={{ border: "1px solid", borderColor: "#E1E1E1", gap: 3 }}>
      <div>
        <div sx={{ fontSize: 18, lineHeight: "26px", fontWeight: 700 }}>
          {name}&#39;s {journeyDuration}-monatige Behandlung
        </div>

        <HorizontalList
          sx={{ fontSize: 12, lineHeight: "16px", color: "neutralsDarkGrey" }}
        >
          <HorizontalListItem>{age} Jahre</HorizontalListItem>
          <HorizontalListItem sx={{ color: "brandPrimary" }}>
            {condition}
          </HorizontalListItem>
          <HorizontalListItem>{severity}</HorizontalListItem>
        </HorizontalList>
      </div>

      <Carousel
        layout={{
          renderBottomCenterControls: "pageIndicator",
          // renderTopCenterControls: "pageIndicator",
          // renderTopLeftControls: "previousPage",
          // renderTopRightControls: "nextPage",
        }}
        sx={{ pb: 30 }}
      >
        {[images.before, images.after].map((img, index) => (
          <div key={name + index} sx={{ position: "relative" }}>
            {img}
            <div
              sx={{
                position: "absolute",
                bottom: 0,
                display: "flex",
                width: "100%",
              }}
            >
              <div
                sx={{
                  px: 2,
                  py: 1,
                  background: "rgba(0, 0, 0, 0.38)",
                  backdropFilter: "blur(4px)",
                  borderRadius: 8,
                  color: "white",
                  fontWeight: index === 0 ? 400 : 700,
                  mb: 2,
                  mx: "auto",
                  fontSize: 12,
                  lineHeight: "14px",
                }}
              >
                {index === 0
                  ? "Vorher"
                  : `Nach ${journeyDuration} ${
                      journeyDuration === 1 ? "Monat" : "Monaten"
                    }`}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Card>
  )
}

const SuccessStoryAction = ({
  testId,
  title,
  description,
  image,
  onClick,
}: {
  testId: string
  title: string
  description: string
  image: ReactNode
  onClick: () => void
}) => {
  return (
    <Card
      data-testid={testId}
      sx={{
        flexDirection: "row",
        border: "1px solid",
        borderColor: "#E1E1E1",
        py: 3,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div sx={{ width: 40, height: 40 }}>{image}</div>

      <div sx={{ flex: 1, display: "flex", alignItems: "center" }}>
        <div sx={{ flex: 1 }}>
          <div sx={{ fontWeight: 700, whiteSpace: "normal" }}>{title}</div>

          <div
            sx={{
              fontSize: 12,
              lineHeight: "16px",
              color: "neutralsDarkGrey",
              whiteSpace: "normal",
            }}
          >
            {description}
          </div>
        </div>

        <Icon icon={arrowRightIcon} />
      </div>
    </Card>
  )
}
