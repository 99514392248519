export const AB_TESTS = {
  TofuCartSummaryRedesign: "tofuCartSummaryRedesign",
  SocialAuth: "socialAuth",
  SkinSetFlexibility: "skinSetFlexibility", // skin set flexibility experiment RG-9
  LongTermDiscounts: "longTermDiscounts", // long term discounted "plans" experiment RG-8
  OrderPriceBreakdown: "orderPriceBreakdown", // Request new formula experiment RG-114
  AntiAging: "antiAging",
  ChurnPreventionMessageDoctor: "churnPreventionMessageDoctor", // Churn prevention tactic message doctor experiment RG-236
  ChurnPreventionPause: "churnPreventionPause", // Churn prevention pause experiment RG-256
  ChurnPreventionSuccessStories: "churnPreventionSuccessStories", // Churn prevention pause experiment 269
  SubscriptionTiers: "subscriptionTiers", // Subscription Tiers experiment AG-356
  // TODO: change this to MoreInfoClaIntro
  MoreInfoClaIntro: "moreInfoClaIntro", // CLA hide AYD experiment RG-292
  MoreInfoClaWelcome: "moreInfoClaWelcome", // CLA hide AYD experiment RG-294
  MoreInfoClaWelcomeHideAyd: "moreInfoClaWelcomeHideAyd", // CLA hide AYD experiment RG-294 + RG-296
  AydFAQs: "aydFAQs", // CLA FAQs within AYD experiment RG-298
  SinglePurchasePriceIncrease: "singlePurchasePriceIncrease",
  PersonalizedTreatment: "personalizedTreatment", // AG-448
} as const

export type AB_TESTS_OPTIONS = (typeof AB_TESTS)[keyof typeof AB_TESTS]

export const AB_TESTS_DATA: {
  [key in AB_TESTS_OPTIONS]: {
    lsKey: string
    campaignURL?: string
    deactivated?: boolean
    routes?: Record<string, string>
    cleanupLsKeys?: string[] // If the test is deactivated, AbTestContainer will remove these keys from localStorage
  }
} = {
  [AB_TESTS.TofuCartSummaryRedesign]: {
    lsKey: `__vwo__${AB_TESTS.TofuCartSummaryRedesign}`,
    deactivated: false,
  },
  [AB_TESTS.SocialAuth]: {
    lsKey: `__vwo__${AB_TESTS.SocialAuth}`,
    deactivated: false,
  },
  [AB_TESTS.SkinSetFlexibility]: {
    lsKey: `__vwo__${AB_TESTS.SkinSetFlexibility}`,
    campaignURL: `/vwo/${AB_TESTS.SkinSetFlexibility}`,
  },
  [AB_TESTS.LongTermDiscounts]: {
    lsKey: `__vwo__${AB_TESTS.LongTermDiscounts}`,
    campaignURL: `/vwo/${AB_TESTS.LongTermDiscounts}`,
  },
  [AB_TESTS.OrderPriceBreakdown]: {
    lsKey: `__vwo__${AB_TESTS.OrderPriceBreakdown}`,
    deactivated: false,
  },
  [AB_TESTS.AntiAging]: {
    lsKey: `__vwo__${AB_TESTS.AntiAging}`,
    deactivated: false,
    cleanupLsKeys: [
      `__vwo__${AB_TESTS.AntiAging}`,
      `__vwo__${AB_TESTS.AntiAging}_running`,
      "__vwo__testPresetType",
    ],
  },
  [AB_TESTS.ChurnPreventionMessageDoctor]: {
    lsKey: `__vwo__${AB_TESTS.ChurnPreventionMessageDoctor}`,
    deactivated: false,
  },
  [AB_TESTS.ChurnPreventionPause]: {
    lsKey: `__vwo__${AB_TESTS.ChurnPreventionPause}`,
    deactivated: false,
  },
  [AB_TESTS.ChurnPreventionSuccessStories]: {
    lsKey: `__vwo__${AB_TESTS.ChurnPreventionSuccessStories}`,
    // campaignURL: `/vwo/${AB_TESTS.ChurnPreventionSuccessStories}`,
    deactivated: false,
  },
  [AB_TESTS.SubscriptionTiers]: {
    lsKey: `__vwo__${AB_TESTS.SubscriptionTiers}`,
    deactivated: true,
    cleanupLsKeys: [
      `__vwo__${AB_TESTS.SubscriptionTiers}`,
      `__vwo__${AB_TESTS.SubscriptionTiers}_running`,
      "__vwo__testPresetType",
    ],
  },
  [AB_TESTS.MoreInfoClaIntro]: {
    lsKey: `__vwo__${AB_TESTS.MoreInfoClaIntro}`,
    deactivated: false,
  },
  [AB_TESTS.MoreInfoClaWelcome]: {
    lsKey: `__vwo__${AB_TESTS.MoreInfoClaWelcome}`,
    deactivated: false,
  },
  [AB_TESTS.MoreInfoClaWelcomeHideAyd]: {
    lsKey: `__vwo__${AB_TESTS.MoreInfoClaWelcomeHideAyd}`,
    deactivated: false,
  },
  [AB_TESTS.AydFAQs]: {
    lsKey: `__vwo__${AB_TESTS.AydFAQs}`,
    deactivated: false,
  },
  [AB_TESTS.SinglePurchasePriceIncrease]: {
    lsKey: `__vwo__${AB_TESTS.SinglePurchasePriceIncrease}`,
    deactivated: true,
    cleanupLsKeys: [
      `__vwo__${AB_TESTS.SinglePurchasePriceIncrease}`,
      `__vwo__${AB_TESTS.SinglePurchasePriceIncrease}_running`,
      "__vwo__testPresetType",
    ],
  },
  [AB_TESTS.PersonalizedTreatment]: {
    lsKey: `__vwo__${AB_TESTS.PersonalizedTreatment}`,
    deactivated: false,
  },
}
