import { de } from "date-fns/locale"
import { Fragment } from "react"
import { DayPicker, useNavigation } from "react-day-picker"
import "react-day-picker/dist/style.css"

import { Heading, IconButton } from "@trueskin-web/components"
import { formatDate } from "@trueskin-web/functions"
import { useThemeUI } from "@trueskin-web/theme"
import { arrowLongRightIcon } from "@trueskin-web/theme/icons/arrowLongRightIcon"

const Caption = ({ displayMonth, icon, dividerColor, bold }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()

  return (
    <Heading
      size="xsm"
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
      }}
    >
      <span sx={{ fontWeight: bold ? "bold" : "normal" }}>
        {formatDate(displayMonth, {
          month: "long",
          year: "numeric",
        })}
      </span>

      <IconButton
        icon={icon}
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        sx={{ ml: "auto", transform: "rotate(180deg)" }}
      />

      <span
        sx={{
          mx: 2,
          color: dividerColor || "textLight",
        }}
      >
        |
      </span>

      <IconButton
        disabled={!nextMonth}
        icon={icon}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </Heading>
  )
}

const Calendar = ({
  mode = "single",
  selected,
  onSelect,
  modifiers,
  rounded,
  icon,
  headerCaptionDividerColor = "textLight",
  headerTextBold = false,
  ...props
}) => {
  const {
    theme: { colors, fontSizes },
  } = useThemeUI()

  return (
    <Fragment>
      <DayPicker
        showOutsideDays
        fixedWeeks
        locale={de}
        mode={mode}
        selected={selected}
        onSelect={onSelect}
        modifiers={modifiers}
        modifiersStyles={{
          selected: {
            color: "white",
            background: colors.primary,
          },
        }}
        modifiersClassNames={{
          highlighted: "highlighted",
        }}
        styles={{
          head: {
            color: colors.primary,
          },
          head_cell: {
            fontSize: fontSizes[1],
          },
          day: {
            opacity: 1,
          },
          button: {
            borderRadius: rounded ? "50%" : 0,
            fontSize: fontSizes[1],
            fontWeight: "bold",
          },
        }}
        components={{
          Caption: (props) => {
            return (
              <Caption
                {...props}
                icon={icon || arrowLongRightIcon}
                dividerColor={headerCaptionDividerColor}
                bold={headerTextBold}
              />
            )
          },
        }}
        sx={{
          ".highlighted": {
            background: "lightGreen",
          },
          ".rdp-button": {
            color: colors.text,
            "&:disabled": {
              color: "disabled",
            },
            "&:not(:disabled):hover, &:not(:disabled):focus": {
              background: "primaryLight",
              color: "white",
            },
          },
        }}
        {...props}
      />
    </Fragment>
  )
}

export default Calendar
