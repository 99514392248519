import {
  BaseHttpRequest,
  VortexApi,
  request as __request,
} from "@trueskin/vortex-sdk"
import axios from "axios"
import "cross-fetch/polyfill"

import { getCookie } from "@trueskin-web/cookies"
import { hosts } from "@trueskin-web/core"
import { sleep } from "@trueskin-web/functions"
import { i18nLocale } from "@trueskin-web/locales"
import { authService } from "@trueskin-web/services"
import * as storageService from "@trueskin-web/services/src/storage.js"

import { logout } from "./auth"
import { getConsent, trackEvent } from "./marketing"

const storageTofuJwtKey = storageService.getStorageKeys().TOFU_JWT
const setHeader = (headers, headerKey, headerValue) => {
  if (!headers || !headerKey || !headerValue) {
    return
  }

  if (headerKey === "Authorization") {
    headers[headerKey] = `Bearer ${headerValue}`

    return
  }

  headers[headerKey] = headerValue
}

async function onErrorInterceptor(response) {
  try {
    const traceId = response.headers.get("x-fs-trace-id")

    const data = await response.json()

    if (response.status >= 500) {
      return { ...data, traceId }
    }

    // v1 has classic data: { data, error, message, statusCode }
    // v2 has custom error on data: { data, error }
    // TODO: update when v2 error mapping changes and remove these comments
    const error = data.statusCode ? data : data.error

    return { ...error, traceId }
  } catch {
    throw "Please check your internet connection and try again"
  }
}

async function onSuccessInterceptor(response) {
  if (response.status === 401 || response.status === 403) {
    logout()
    window.location.assign(window.location)

    return
  }

  if (response.status === 204) {
    return ""
  }

  if (response.ok) {
    const data = await response.clone().json()

    if (data.error) {
      throw response
    }

    if (data._event) {
      if (Array.isArray(data._event)) {
        data._event.forEach((event) => trackEvent(event))
      } else {
        trackEvent(data._event)
      }
    }

    return data
  }

  throw response
}

async function onFetchError(response, url, options, retries, interval) {
  if (retries < 1) {
    throw response
  }

  await sleep(interval)
  return doFetch(url, options, retries - 1, interval)
}

async function doFetch(url, options, retries, interval) {
  try {
    let response = await window.fetch(url, options)

    if (response.status >= 500) {
      response = await onFetchError(response, url, options, retries, interval)
    }

    return response
  } catch (err) {
    throw await onFetchError(err, url, options, retries, interval)
  }
}

async function client({
  url,
  endpoint,
  request: { body, params, ...config } = {},
  retries = 0,
  interval = 500,
}) {
  let apiUrl = endpoint.startsWith("http") ? endpoint : `${url}/${endpoint}`

  if (params) {
    apiUrl += `?${new URLSearchParams(params)}`
  }

  const headers =
    body instanceof FormData ? {} : { "content-type": "application/json" }

  setHeader(
    headers,
    "Authorization",
    // Short term solution for TOFU web journey experiment
    // TODO If the experiment succeeds, a long term solution will be made
    url === hosts.tofuApiUrl
      ? storageService.getItem({ id: storageTofuJwtKey })
      : authService.getJwt()
  )

  setHeader(headers, "x-fs-locale", i18nLocale())
  setHeader(headers, "x-current-page", window.location.href)
  setHeader(headers, "x-user-consent-fb", getConsent("Facebook Pixel"))
  setHeader(headers, "x-fbc", getCookie("_fbc"))
  setHeader(headers, "x-fbp", getCookie("_fbp"))
  setHeader(headers, "x-ttclid", getCookie("_ttclid"))

  const apiConfig = {
    method: body ? "POST" : "GET",
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
    body:
      body instanceof FormData ? body : body ? JSON.stringify(body) : undefined,
  }

  try {
    const response = await doFetch(apiUrl, apiConfig, retries, interval)

    return await onSuccessInterceptor(response)
  } catch (err) {
    throw await onErrorInterceptor(err)
  }
}

const apiV2 = async (endpoint, request, retries, interval) =>
  client({
    url: hosts.v2ApiUrl,
    endpoint,
    request,
    retries,
    interval,
  })

const apiTofu = async (endpoint, request, retries, interval) =>
  client({
    url: hosts.tofuApiUrl,
    endpoint,
    request,
    retries,
    interval,
  })

class AxiosHttpRequestWithRetry extends BaseHttpRequest {
  axiosInstance = axios.create()

  constructor(config) {
    super(config)

    this.axiosInstance.interceptors.response.use(
      function (response) {
        if (response.status === 204) {
          return response
        }

        if (response.status === 200) {
          const data = response.data

          if (data.error) {
            return response
          }

          if (data._event) {
            if (Array.isArray(data._event)) {
              data._event.forEach((event) => trackEvent(event))
            } else {
              trackEvent(data._event)
            }
          }

          return response
        }

        return response
      },
      function (error) {
        const response = error.response

        if (response) {
          if (response.status === 401 || response.status === 403) {
            logout()
            window.location.assign(window.location)

            return Promise.reject(response?.data || response)
          }

          return Promise.reject(response?.data || response)
        }

        return Promise.reject(error)
      }
    )
  }

  request(options) {
    return __request(this.config, options, this.axiosInstance)
  }
}

const apiVortex = () => {
  const api = new VortexApi(
    {
      BASE: hosts.v2ApiUrl + "/vortex",
      HEADERS: {
        "x-fs-locale": i18nLocale(),
        "x-current-page": window.location.href,
        "x-user-consent-fb": getConsent("Facebook Pixel"),
        "x-fbc": getCookie("_fbc"),
        "x-fbp": getCookie("_fbp"),
        "x-ttclid": getCookie("_ttclid"),
      },
      TOKEN: authService.getJwt(),
    },
    AxiosHttpRequestWithRetry
  )

  const VORTEX_ENV_ENABLED_LIST =
    `${process.env.GATSBY_VORTEX_ENABLED_LIST}`
      ?.split(",")
      ?.map((it) => it.trim()) || []

  const VORTEX_LOCAL_ENABLED_LIST =
    window.localStorage
      .getItem("VORTEX_ENABLED_LIST")
      ?.split(",")
      ?.map((it) => it.trim()) || []

  api.vortex.getEnabledList = () => {
    return Array.from(
      new Set(VORTEX_ENV_ENABLED_LIST.concat(VORTEX_LOCAL_ENABLED_LIST))
    )
  }

  api.vortex.isMethodEnabled = function (method) {
    const isEnabled =
      this.getEnabledList().includes("*") ||
      this.getEnabledList().includes(method)

    if (!isEnabled && process.env.GATSBY_DEPLOY_ENVIRONMENT !== "production") {
      if (this.getEnabledList().includes("*")) {
        console.warn(
          `[Vortex] Method ${method} will run via Vortex thanks to VORTEX_ALL_ENABLED(*) flag`
        )
        return true
      }
      console.warn(`[Vortex] Method ${method} is not enabled`)
    }

    if (isEnabled) {
      console.info(`[Vortex] Method ${method} is called`)
    }

    return isEnabled
  }
  return api.vortex
}

export { apiV2, apiTofu, apiVortex }
