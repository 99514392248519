import Label from "./label"
import ToggleSwitch from "./toggle-switch"

const ToggleField = ({
  label = "\u00A0",
  value = false,
  message = "",
  onChange = () => {},
  isDisabled,
  ...props
}) => (
  <div {...props}>
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {label && (
        <Label
          sx={{
            flex: 1,
          }}
        >
          {label}
        </Label>
      )}
      <ToggleSwitch
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        sx={{
          ml: label ? 4 : "auto",
          mb: 2,
        }}
      />
    </div>

    <div>{message}</div>
  </div>
)

export default ToggleField
