import { Fragment, useEffect, useRef, useState } from "react"
import { Field, Form } from "react-final-form"

import {
  composeValidators,
  getErrorMessage,
  maxLength,
  required,
} from "@trueskin-web/functions"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { sentimentHappyIcon } from "@trueskin-web/theme/icons/sentimentHappyIcon"
import { sentimentNeutralIcon } from "@trueskin-web/theme/icons/sentimentNeutralIcon"
import { sentimentSadIcon } from "@trueskin-web/theme/icons/sentimentSadIcon"
import { sentimentVeryHappyIcon } from "@trueskin-web/theme/icons/sentimentVeryHappyIcon"
import { sentimentVerySadIcon } from "@trueskin-web/theme/icons/sentimentVerySadIcon"
import { Trans, useTranslation } from "@trueskin-web/translations"

import Button from "./button"
import FieldError from "./field-error"
import HStack from "./h-stack"
import Icon from "./icon"
import InputNumberControl from "./input-number-control"
import TextareaControl from "./textarea-control"

export const sentimentRatingIcons = [
  sentimentVerySadIcon,
  sentimentSadIcon,
  sentimentNeutralIcon,
  sentimentHappyIcon,
  sentimentVeryHappyIcon,
]

const SentimentRating = ({
  ratingTitle,
  feedbackTitle,
  className,
  rating,
  onClick: onSubmit = (rating, feedback) => {},
}) => {
  const { t } = useTranslation()

  const submitButtonRef = useRef()
  const resetButtonRef = useRef()

  const [rate, setRate] = useState()

  useEffect(() => {
    if (rate > 3) {
      submitButtonRef?.current?.click()
    }
  }, [rate])

  useEffect(() => {
    setRate()
    resetButtonRef?.current?.click()
  }, [onSubmit])

  return (
    <div
      className={className}
      sx={{
        bg: "lightGray",
        p: 4,
      }}
    >
      <Form
        onSubmit={(values) => onSubmit(values.rating, values.feedback)}
        render={({
          form,
          handleSubmit,
          submitting,
          submitError,
          submitSucceeded,
        }) =>
          rating ? (
            <Fragment>
              <div
                sx={{
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                <Trans i18nKey="SentimentRating.responseSuccess" />
              </div>

              <HStack
                sx={{
                  display: "inline-flex",
                  whiteSpace: "nowrap",
                }}
              >
                {sentimentRatingIcons.map((sentimentIcon, position) => (
                  <Icon
                    key={position}
                    icon={sentimentIcon}
                    color={rating - 1 === position ? "primary" : "textDark"}
                  />
                ))}
              </HStack>
            </Fragment>
          ) : submitSucceeded ? (
            <Fragment>
              <Icon icon={checkCircleFilledIcon} color="primary" />

              <div
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                }}
              >
                <Trans i18nKey="SentimentRating.responseSuccess" />
              </div>

              <Button
                ref={resetButtonRef}
                type="reset"
                onClick={() => form.reset()}
                sx={{
                  display: "none",
                }}
              />
            </Fragment>
          ) : (
            <form
              onSubmit={handleSubmit}
              sx={{
                maxWidth: [null, 480],
              }}
            >
              <Field
                name="rating"
                component={InputNumberControl}
                defaultValue={rate}
                sx={{
                  display: "none",
                }}
              />

              {!rate || rate > 3 ? (
                <Fragment>
                  {ratingTitle ? (
                    <div
                      sx={{
                        mb: 2,
                      }}
                    >
                      {ratingTitle}
                    </div>
                  ) : (
                    <div
                      sx={{
                        mb: 2,
                        fontWeight: "bold",
                      }}
                    >
                      <Trans i18nKey="SentimentRating.ratingTitle" />
                    </div>
                  )}

                  <HStack
                    sx={{
                      display: "inline-flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {sentimentRatingIcons.map((sentimentIcon, position) => (
                      <Icon
                        key={position}
                        icon={sentimentIcon}
                        color="primary"
                        onClick={() => {
                          const selection = position + 1

                          setRate(selection)
                        }}
                        sx={{
                          cursor: "pointer",
                          pointerEvents: rate > 3 ? "none" : null,
                        }}
                      />
                    ))}
                  </HStack>
                </Fragment>
              ) : (
                <Fragment>
                  {feedbackTitle ? (
                    <div
                      sx={{
                        mb: 2,
                      }}
                    >
                      {feedbackTitle}
                    </div>
                  ) : (
                    <div
                      sx={{
                        mb: 2,
                        fontWeight: "bold",
                      }}
                    >
                      <Trans i18nKey="SentimentRating.feedbackTitle" />
                    </div>
                  )}

                  <Field
                    name="feedback"
                    placeholder={t("SentimentRating.feedbackFieldPlaceholder")}
                    component={TextareaControl}
                    validate={composeValidators(required, maxLength(600))}
                    maxLength="601"
                    rows="4"
                    type="text"
                    size="lg"
                    sx={{
                      textarea: {
                        color: "text",
                        py: 3,
                        px: 4,
                      },
                    }}
                  />
                </Fragment>
              )}

              <Button
                ref={submitButtonRef}
                type="submit"
                isFullWidth
                isLoading={submitting}
                sx={{
                  mt: 2,
                  display: !rate || rate > 3 ? "none" : null,
                }}
              >
                <Trans i18nKey="Button.send" />
              </Button>

              <Button
                ref={resetButtonRef}
                onClick={() => form.reset()}
                type="reset"
                sx={{
                  display: "none",
                }}
              />

              {submitError ? (
                <FieldError>{getErrorMessage(submitError)}</FieldError>
              ) : null}
            </form>
          )
        }
      />
    </div>
  )
}

export default SentimentRating
