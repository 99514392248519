import { Button, Icon } from "@trueskin-web/components"
import { openInNewIcon } from "@trueskin-web/theme/icons/openInNewIcon"

export const ShippingUrlButton = ({ shippingUrl }: { shippingUrl: string }) => {
  return (
    <Button as="a" href={shippingUrl} target="_blank" isFullWidth rounded>
      Lieferung verfolgen
      <Icon icon={openInNewIcon} sx={{ ml: 1 }} />
    </Button>
  )
}
