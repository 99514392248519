import React from "react"

import { sizes } from "@trueskin-web/components/src/icon"

import SectionContent from "./section-content"
import SectionFooter from "./section-footer"
import SectionHeader from "./section-header"

export interface SectionProps extends Partial<HTMLDivElement> {
  title?: string
  description?: string | React.ReactNode
  onBack?: () => void
  backLabel?: string
  onDismiss?: () => void
  content?: React.ReactNode
  primaryAction?: () => void
  primaryActionLabel?: string
  isPrimaryActionLoading?: boolean
  isPrimaryActionDisabled?: boolean
  secondaryAction?: () => void
  secondaryActionLabel?: string
  isSecondaryActionLoading?: boolean
  isSecondaryActionDisabled?: boolean
  isSecondaryActionStylePrimary?: boolean
  reverseActions?: boolean
  rounded?: boolean
  actionError?: string
  footnote?: string
  dataTestId?: string
  heading?: string
  dismissIconSize?: keyof typeof sizes
}

const Section: React.FC<SectionProps> = ({
  className,
  heading,
  title,
  description,
  onBack,
  backLabel,
  onDismiss,
  content,
  primaryAction,
  primaryActionLabel,
  isPrimaryActionLoading,
  isPrimaryActionDisabled,
  secondaryAction,
  secondaryActionLabel,
  isSecondaryActionLoading,
  isSecondaryActionDisabled,
  isSecondaryActionStylePrimary,
  reverseActions,
  rounded,
  actionError,
  footnote,
  dataTestId,
  dismissIconSize,
}) => (
  <section
    data-testid={dataTestId}
    className={className}
    sx={{
      "section:not(:first-of-type)": {
        // only apply if a heading is used
        mt: heading ? 5 : 4,
      },
    }}
  >
    <SectionHeader
      className={"section-header"}
      heading={heading}
      title={title}
      description={description}
      onBack={onBack}
      backLabel={backLabel}
      onDismiss={onDismiss}
      dismissIconSize={dismissIconSize}
    />

    <SectionContent className={"section-content"} content={content} />

    <SectionFooter
      className={"section-footer"}
      primaryAction={primaryAction}
      primaryActionLabel={primaryActionLabel}
      isPrimaryActionLoading={isPrimaryActionLoading}
      isPrimaryActionDisabled={isPrimaryActionDisabled}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      isSecondaryActionLoading={isSecondaryActionLoading}
      isSecondaryActionDisabled={isSecondaryActionDisabled}
      isSecondaryActionStylePrimary={isSecondaryActionStylePrimary}
      reverseActions={reverseActions}
      rounded={rounded}
      actionError={actionError}
      footnote={footnote}
    />
  </section>
)

export default Section
