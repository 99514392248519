import { apiVortex } from "./api"

function getNpsSettings() {
  return apiVortex().getNps()
}

function submitScore({ npsScoreId, score, source }) {
  return apiVortex().postScore(npsScoreId, { score, source })
}

function trackReview({ npsScoreId, platform }) {
  return apiVortex().postScoreReview(npsScoreId, {
    platform,
  })
}

function submitFeedback({ npsScoreId, feedback, tags, sourcePath }) {
  return apiVortex().postScoreFeedback(npsScoreId, {
    feedback,
    tags,
    sourcePath,
  })
}

function submitAnswers({ npsScoreId, sourcePath, answers }) {
  return apiVortex().postScoreAnswers(npsScoreId, {
    sourcePath,
    answers,
  })
}

export {
  getNpsSettings,
  submitScore,
  trackReview,
  submitFeedback,
  submitAnswers,
}
