/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { DetailedHTMLProps, MetaHTMLAttributes } from "react"
import Helmet, { HelmetProps } from "react-helmet"

import { i18nLanguage } from "@trueskin-web/locales"

export type SeoProps = HelmetProps & {
  lang?: string
  description?: string
}

const siteMetadata = {
  title: "FORMEL SKIN",
  description: "FORMEL SKIN",
  author: undefined,
}

const Seo = ({ lang, meta, title, description, ...props }: SeoProps) => (
  <Helmet
    htmlAttributes={{
      lang: lang || i18nLanguage(),
    }}
    title={title}
    titleTemplate={`%s | ${siteMetadata.title}`}
    meta={(
      [
        {
          key: `description`,
          name: `description`,
          content: description || siteMetadata.description,
        },
        {
          key: `og:title`,
          property: `og:title`,
          content: title,
        },
        {
          key: `og:description`,
          property: `og:description`,
          content: description || siteMetadata.description,
        },
        {
          key: `og:type`,
          property: `og:type`,
          content: `website`,
        },
        {
          key: `twitter:card`,
          name: `twitter:card`,
          content: `summary`,
        },
        {
          key: `twitter:creator`,
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          key: `twitter:title`,
          name: `twitter:title`,
          content: title,
        },
        {
          key: `twitter:description`,
          name: `twitter:description`,
          content: description || siteMetadata.description,
        },
        {
          key: `google-site-verification`,
          name: `google-site-verification`,
          content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION_KEY,
        },
      ] as DetailedHTMLProps<
        MetaHTMLAttributes<HTMLMetaElement>,
        HTMLMetaElement
      >[]
    ).concat(meta ?? [])}
    {...props}
  />
)

export default Seo
