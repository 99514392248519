import { differenceInDays } from "date-fns"

import { marketingClient } from "@trueskin-web/apis"
import { getCookie, removeCookie, setCookie } from "@trueskin-web/cookies"
import { mapSubscriptionType } from "@trueskin-web/functions"

import * as storageService from "./storage"

const chatReleaseDate =
  process.env.GATSBY_FEATURE_AYD_CHAT_RELEASE_DATE ?? "1714514400000"

const storageKeys = storageService.getStorageKeys()
const storageTypes = storageService.getStorageTypes()
const storageKeyJwt = storageKeys.AUTH_JWT
const bdStorageKeyJwt = storageKeys.TOFU_JWT
const storageKeyFirstname = storageKeys.FIRSTNAME
const storageKeyUserCreatedAt = storageKeys.USER_CREATED_AT
const storageKeyTempToken = storageKeys.TEMP_TOKEN
const storageKeyTofuPhotosUploadSuccess = storageKeys.TOFU_PHOTOS_UPLOAD_SUCCESS

const getJwt = () => storageService.getItem({ id: storageKeyJwt })

const getBdJwt = () => storageService.getItem({ id: bdStorageKeyJwt })

const getFirstname = () => storageService.getItem({ id: storageKeyFirstname })

const getTempToken = () => storageService.getItem({ id: storageKeyTempToken })

const saveBdJwt = (jwt) => {
  storageService.setItem({ id: bdStorageKeyJwt, data: jwt })
}

const saveJwt = (jwt) => {
  storageService.setItem({ id: storageKeyJwt, data: jwt })
}

const saveFirstname = (firstname) => {
  storageService.setItem({ id: storageKeyFirstname, data: firstname })
}

const saveTempToken = (temptoken) => {
  storageService.setItem({ id: storageKeyTempToken, data: temptoken })
}

const removeJwt = () => {
  // core
  storageService.removeItem({ id: storageKeyJwt })

  // bd
  storageService.removeItem({ id: bdStorageKeyJwt })
  storageService.removeItem({ id: storageKeyTofuPhotosUploadSuccess })
}

const removeFirstname = () => {
  storageService.removeItem({ id: storageKeyFirstname })
}

const removeTempToken = () => {
  storageService.removeItem({ id: storageKeyTempToken })
}

const saveUserCreationTimestamp = (utcDate) => {
  if (!utcDate) {
    return
  }

  const timestamp = new Date(utcDate).getTime()

  setCookie(storageKeyUserCreatedAt, timestamp)
}

const removeUserCreationTimestamp = () => {
  removeCookie(storageKeyUserCreatedAt)
}

const saveUserTreatmentDetails = (user) => {
  const { indication } = user.treatmentPlan || {}
  const ingredients = user.activeIngredients.join(", ")
  const vwoFormulaSegments = user.vwoFormulaSegments.join(", ")
  const storageKeys = storageService.getStorageKeys()
  setCookie(storageKeys.VWO_TREATMENT_INDICATION, indication?.name)
  setCookie(storageKeys.VWO_TREATMENT_INGREDIENTS, ingredients)
  setCookie(storageKeys.VWO_FORMULA_SEGMENTS, vwoFormulaSegments)
}

const saveUserChatStatus = (user) => {
  // soft rollback of new ayd chat experiment RG-283
  // if (
  //   new Date(user?.subscription?.createdAt) >=
  //   new Date(parseInt(chatReleaseDate))
  // ) {
  //   setCookie(storageKeys.VWO_NEW_CHAT_ELIGIBLE, true)
  // }
}
const saveUserSubscriptionCycles = (user) => {
  setCookie(storageKeys.VWO_SUBSCRIPTION_CYCLES, user?.subscriptionCycles || 0)
}

const saveUserAverageNPS = (user) => {
  if (user?.averageNPS) {
    setCookie(storageKeys.VWO_AVERAGE_NPS, user.averageNPS)

    if (
      [undefined, null, ""].includes(
        getCookie(storageKeys.VWO_NPS_LONG_TERM_ELIGIBILITY)
      ) &&
      user.averageNPS < 7
    ) {
      setCookie(storageKeys.VWO_NPS_LONG_TERM_ELIGIBILITY, true)
    }
  }
}

const saveUserAydCount = (user) => {
  if (![undefined, null, ""].includes(user?.aydCount)) {
    setCookie(storageKeys.VWO_USER_AYD_COUNT, user.aydCount)

    // also set specific experiment related eligibility cookie only once
    // otherwise the user is first added to the experiment and then removed, so goal tracking is not accurate
    if (
      [undefined, null, ""].includes(
        getCookie(storageKeys.VWO_CHURN_PREVENTION_MESSAGE_DOCTOR_ELIGIBILITY)
      ) &&
      user.aydCount === 0
    ) {
      setCookie(
        storageKeys.VWO_CHURN_PREVENTION_MESSAGE_DOCTOR_ELIGIBILITY,
        true
      )
    }
  }
}

const saveUserSubscriptionType = (subscription) =>
  setCookie(
    storageKeys.VWO_SUBSCRIPTION_TYPE,
    mapSubscriptionType(subscription)
  )

const saveUserConfirmedDiagnoses = (diagnoses) => {
  const diagnosesValue = diagnoses
    ?.filter(
      (diagnosis) =>
        diagnosis &&
        diagnosis.type &&
        diagnosis.condition &&
        diagnosis?.type === "CONFIRMED"
    )
    ?.map((diagnosis) => diagnosis.condition)
    .join(",")
  if (diagnosesValue?.length) {
    setCookie(storageKeys.VWO_CONFIRMED_DIAGNOSES, diagnosesValue)
  }
}

const trackUser = (user) => {
  if (
    !storageService.getItem({
      id: storageKeys.INITIAL_TRACKING_EVENT_SENT,
      storageType: storageTypes.SESSION,
    }) &&
    differenceInDays(new Date(), new Date(user.createdAt)) > 0
  ) {
    const isActive =
      !!user.subscription &&
      !user.subscription.isCanceled &&
      !user.subscription.isExpired

    const paymentOrder = user.subscription?.paymentOrder
    const productType = paymentOrder?.lineItems?.length
      ? paymentOrder?.lineItems[0].description
      : ""

    const customData = {
      userId: user.id,
      isUnconverted: !user.subscription,
      isActive,
      productType,
      subscriptionCycles: user.subscriptionCycles,
    }

    marketingClient.trackEvent({
      name: marketingClient.EVENT_TYPES_ENUM.RETURNING_USER_EVENT,
      custom_data: { ...customData },
    })

    storageService.setItem({
      id: storageKeys.INITIAL_TRACKING_EVENT_SENT,
      storageType: storageTypes.SESSION,
      data: "true",
    })
  }
}

// TODO: proprietary app related local storage keys should be prefixed to promote automatic cleanup
const cleanupStorage = () => {
  removeJwt()
  removeFirstname()
  removeUserCreationTimestamp()
}

export {
  getJwt,
  getBdJwt,
  getFirstname,
  getTempToken,
  saveJwt,
  saveBdJwt,
  saveFirstname,
  saveTempToken,
  removeJwt,
  removeTempToken,
  removeFirstname,
  saveUserCreationTimestamp,
  cleanupStorage,
  saveUserTreatmentDetails,
  saveUserSubscriptionType,
  saveUserSubscriptionCycles,
  saveUserAverageNPS,
  saveUserChatStatus,
  saveUserConfirmedDiagnoses,
  saveUserAydCount,
  trackUser,
}
