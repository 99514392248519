import { createContext, useContext } from "react"

const PageContext = createContext()

const PageProvider = ({ isExternal, ...props }) => {
  const value = {
    isExternal,
  }
  return <PageContext.Provider value={value} {...props} />
}

const usePageContext = () => useContext(PageContext)

export { PageProvider, usePageContext }
