import React, { ReactNode } from "react"

import { Trans } from "@trueskin-web/translations"

import Button from "../button"
import FaqLink from "../faq-link"
import FieldError from "../field-error"

interface SectionFooterProps extends Partial<HTMLDivElement> {
  primaryAction?: () => void
  primaryActionLabel?: string | ReactNode
  isPrimaryActionLoading?: boolean
  isPrimaryActionDisabled?: boolean
  secondaryAction?: () => void
  secondaryActionLabel?: string | ReactNode
  isSecondaryActionLoading?: boolean
  isSecondaryActionDisabled?: boolean
  isSecondaryActionStylePrimary?: boolean
  reverseActions?: boolean
  rounded?: boolean
  actionError?: string
  footnote?: string | boolean | ReactNode
}

const SectionFooter: React.FC<SectionFooterProps> = ({
  className,
  primaryAction,
  primaryActionLabel,
  isPrimaryActionLoading,
  isPrimaryActionDisabled,
  secondaryAction,
  secondaryActionLabel,
  isSecondaryActionLoading,
  isSecondaryActionDisabled,
  isSecondaryActionStylePrimary,
  reverseActions,
  rounded,
  actionError,
  footnote,
}) => {
  const actions = [
    primaryAction ? (
      <Button
        sx={{ borderRadius: rounded ? "8px" : "0px" }}
        key="web-portal-section-primary-btn"
        data-testid="web-portal-section-primary-btn"
        onClick={primaryAction}
        variant="solid"
        isLoading={isPrimaryActionLoading}
        isDisabled={isPrimaryActionDisabled}
      >
        {primaryActionLabel || <Trans i18nKey="Button.continue" />}
      </Button>
    ) : null,
    secondaryAction ? (
      <Button
        sx={{ borderRadius: rounded ? "8px" : "0px" }}
        key="web-portal-section-secondary-btn"
        data-testid="web-portal-section-secondary-btn"
        onClick={secondaryAction}
        variant={isSecondaryActionStylePrimary ? "solid" : "outline"}
        isLoading={isSecondaryActionLoading}
        isDisabled={isSecondaryActionDisabled}
      >
        {secondaryActionLabel || <Trans i18nKey="Button.cancel" />}
      </Button>
    ) : null,
  ].filter(Boolean)

  if (reverseActions) {
    actions.reverse()
  }
  return primaryAction || secondaryAction || footnote ? (
    <section
      className={className}
      sx={{
        display: "grid",
        gap: 3,
      }}
    >
      {actions.map((action) => action)}
      {actionError ? <FieldError>{actionError}</FieldError> : null}

      {footnote ? (
        footnote === true ? (
          <FaqLink sx={{ mt: 4, textAlign: "center" }} />
        ) : typeof footnote === "string" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: footnote,
            }}
            sx={{
              whiteSpace: "pre-line",
            }}
          />
        ) : (
          <div
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {footnote}
          </div>
        )
      ) : null}
    </section>
  ) : null
}

export default SectionFooter
