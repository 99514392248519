const Label = ({ children = "\u00A0", ...props }) => (
  <div
    sx={{
      display: "inline-block",
      mb: 1,
      textAlign: "left",
      verticalAlign: "middle",
      fontWeight: "bold",
      color: "text",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      variant: "vwo.form.label",
    }}
    {...props}
  >
    {children}
  </div>
)

export default Label
