import { Card } from "@trueskin-web/components"

import { CardSection } from "./card-helpers"
import { useOrderCard } from "./order-price-breakdown-card"

const PriceDetails = () => {
  const { order } = useOrderCard()

  return (
    <CardSection title="Zahlungsübersicht">
      <Card
        sx={{
          border: "1px solid lightGray",
        }}
      ></Card>
    </CardSection>
  )
}
PriceDetails.displayName = "PriceDetails"

export { PriceDetails }
