import { useCallback } from "react"

import { amplitudeClient, orderClient, useQuery } from "@trueskin-web/apis"
import { OrderStatus, Spinner } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { choiceIcon } from "@trueskin-web/theme/icons/choiceIcon"
import { refillIcon } from "@trueskin-web/theme/icons/refillIcon"
import { useTranslation } from "@trueskin-web/translations"

import {
  OrderPriceBreakdown,
  type OrderPriceDetails,
} from "../../../app/components/orders"

import { RejectedOrder } from "./rejected-order"
import { ShippingUrlButton } from "./shipping-url-button"

export const OrderPriceBreakdownHistoryCardContent = ({
  orderHistory,
  currentV2OrderId,
}: {
  orderHistory: any
  currentV2OrderId?: string | null
}) => {
  const { v2OrderId, invoiceUrl, shippingUrl } = orderHistory

  const { user } = useAuth()
  const { t } = useTranslation()

  const { data, isLoading, isFetching } = useQuery(
    ["historicalOrderInfo", v2OrderId],
    () => orderClient.getHistoricalOrderInfo(v2OrderId),
    {
      // staleTime: 5 * 60 * 1000, // 5 minutes
    }
  )

  const isOrderRejected = getCurrentStepNumber(user.subscription) === 1
  const showShippingUrl = Boolean(shippingUrl)
  const isHistoricalOrder = currentV2OrderId !== v2OrderId

  const trackingStep =
    showShippingUrl || isOrderRejected
      ? null
      : data?.products?.length
      ? t("OrderStatus.steps.formulaProduced")
      : t("OrderStatus.steps.planCreated")

  const handleOnPriceExpanded = useCallback(
    (expanded: boolean) => {
      if (!expanded) {
        return
      }

      const eventType = !orderHistory.type
        ? null
        : orderHistory.type === "receipt"
        ? "prescription"
        : orderHistory.type === "single"
        ? "single"
        : `recurring_${data?.summary?.instalments.count}m`

      amplitudeClient.trackEvent(
        amplitudeClient.ANALYTIC_EVENTS.ORDER_HISTORY_PAGE_VIEWED,
        {
          [amplitudeClient.ANALYTIC_EVENT_PROPS.PAGE]: "order_history_page",
          ...(eventType && {
            [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: eventType,
          }),
        }
      )
    },
    [orderHistory, data]
  )

  if (isLoading || isFetching) {
    return (
      <div sx={{ maxHeight: 150, textAlign: "center" }}>
        <Spinner />
      </div>
    )
  }

  if (!data?.summary) {
    return null
  }

  if (isOrderRejected && !isHistoricalOrder) {
    return <RejectedOrder data={data} />
  }

  return (
    <OrderPriceBreakdown.Root
      order={data.summary}
      invoices={data.invoices}
      products={data.products}
      subscriptionRenewalDate={user.subscription.renewalDate}
    >
      <OrderPriceBreakdown.Section sx={{ mt: 4 }}>
        {showShippingUrl ? (
          <ShippingUrlButton shippingUrl={shippingUrl} />
        ) : trackingStep && !isHistoricalOrder ? (
          <OrderStatus step={trackingStep} hideTitle />
        ) : null}
      </OrderPriceBreakdown.Section>

      <OrderPriceBreakdown.Divider sx={{ my: 4 }} />

      <OrderPriceBreakdown.ProductsBreakdown />
      <OrderPriceBreakdown.Divider sx={{ my: 4 }} />

      <OrderPriceBreakdown.PriceBreakdown
        onPriceExpanded={handleOnPriceExpanded}
      >
        {(props: OrderPriceDetails) => {
          if (orderHistory.type !== "recurring" || isHistoricalOrder) {
            return null
          }

          return (
            <OrderPriceBreakdown.PriceBreakdownDetails
              details={[
                {
                  icon: choiceIcon,
                  description: `Die Gesamtsumme von ${props.totalFormatted} werden in zwei monatliche Zahlungen von je ${props.totalPerMonthFormatted} aufgeteilt.`,
                  hide: props.instalmentsCount < 2,
                },
                {
                  icon: refillIcon,
                  description:
                    props.instalmentsCount > 1
                      ? `Dein Plan verlängert sich automatisch alle 2 Monate zum regulären Preis von 88,00 € (44,00 € mtl.).`
                      : `Dein Plan verlängert sich automatisch jeden  Monat zum regulären Preis von 49,00 €.`,
                },
              ]}
            />
          )
        }}
      </OrderPriceBreakdown.PriceBreakdown>

      {orderHistory.type === "recurring" &&
        data?.summary?.instalments?.count > 1 && (
          <>
            <OrderPriceBreakdown.Divider sx={{ my: 4 }} />
            <OrderPriceBreakdown.PaymentTimeline />
          </>
        )}

      {Boolean(invoiceUrl) && (
        <>
          <OrderPriceBreakdown.Divider sx={{ my: 4 }} />
          <OrderPriceBreakdown.Section>
            <OrderPriceBreakdown.DownloadInvoice invoiceUrl={invoiceUrl} />
          </OrderPriceBreakdown.Section>
        </>
      )}
    </OrderPriceBreakdown.Root>
  )
}

//TODO: BE support for the first step https://trueskin.atlassian.net/browse/FS-3954
const getCurrentStepNumber = ({ progressStep, status, paymentOrder, type }) => {
  if (status === "canceled" || paymentOrder.paymentFailed) {
    return 1
  }

  switch (progressStep) {
    case "rejected":
      return 1
    case "waitingDoctorEvaluation":
    case "treatmentIssued":
    case "pharmacyStarted":
      return 2
    case "pharmacyPreparing":
      return 3
    case "packageSent":
      return type === "receipt" ? 3 : 4
    default:
      return 1
  }
}
