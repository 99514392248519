import { useRef } from "react"

import { Button, ChatbotMessenger } from "@trueskin-web/components"

import { OrderPriceBreakdown } from "../../../app/components/orders"

export const RejectedOrder = ({ data }: any) => {
  return (
    <OrderPriceBreakdown.Root
      order={data.summary}
      invoices={data.invoices}
      products={data.products}
    >
      <OrderPriceBreakdown.Section sx={{ mt: 4 }}>
        <OrderRejectedCta />
      </OrderPriceBreakdown.Section>

      {data?.products?.length > 0 && (
        <>
          <OrderPriceBreakdown.Divider sx={{ my: 4 }} />

          <div sx={{ opacity: 0.5 }}>
            <OrderPriceBreakdown.ProductsBreakdown />
          </div>
        </>
      )}
    </OrderPriceBreakdown.Root>
  )
}

const OrderRejectedCta = () => {
  const chatbotRef = useRef()

  return (
    <>
      <div>
        <div
          sx={{
            fontSize: 18,
            lineHeight: "26px",
            fontWeight: 700,
            color: "statusNegativeDark",
            mb: 1,
          }}
        >
          Bestellung Stoniert
        </div>

        <div
          sx={{
            fontSize: 14,
            lineHeight: "20px",
            mb: 2,
          }}
        >
          Deine Bestellung wurde storniert. Falls hier ein Fehler vorliegt,
          kontaktiere bitte das Support-Team.
        </div>

        <Button
          rounded
          onClick={() => {
            if (chatbotRef?.current) {
              chatbotRef.current.loadChatbot()
            }
          }}
        >
          Support-Team kontaktieren
        </Button>
      </div>

      <ChatbotMessenger ref={chatbotRef} />
    </>
  )
}
