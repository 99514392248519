import { ReactNode, useCallback, useReducer } from "react"

import { orderClient, useMutation, useQueryClient } from "@trueskin-web/apis"
import { useAuth } from "@trueskin-web/context"

import { useUpcomingOrderSummary } from "../../../hooks"
import { products } from "../../../utils/resources"
import { longTermDiscountsService } from "../long-term-discounts.service"
import { LongTermDiscountOffer, LongTermDiscountOffers } from "../types"

import {
  LongTermDiscountsState,
  longTermDiscountsReducer,
} from "./long-term-discounts-state"
import { LongTermDiscountsContext } from "./use-long-term-discounts"

export interface LongTermDiscountsProviderProps {
  children?: ReactNode
}

export const LongTermDiscountsProvider = ({
  children,
}: LongTermDiscountsProviderProps) => {
  const queryClient = useQueryClient()

  const {
    user: { firstName, subscription },
  } = useAuth()
  const renewalDate = subscription?.renewalDate

  const addonProducts = Object.entries(products)
    .filter(([, product]: any) => product.isAddon)
    .map(([productId]: any) => productId)

  const initialState: LongTermDiscountsState = {
    customer: { firstName },
    subscription: {
      renewalDate,
    },
    availableOffers: LongTermDiscountOffers,
    isLoadingUpcomingOrder: false,
    isSimulatingUpcomingOrderWithOffer: false,
    isSavingOffer: false,
    showOfferDialog: false,
    showConfirmationDialog: false,
    showSuccessDialog: false,
  }

  const [state, dispatch] = useReducer(longTermDiscountsReducer, initialState)

  const { refetch: loadUpcomingOrder } = useUpcomingOrderSummary({
    enabled: false,
    onSuccess: (response) =>
      (async () => {
        dispatch({
          type: "update_upcoming_order",
          upcomingOrder: response,
        })
      })(),
  })

  const {
    mutateAsync: simulateUpcomingOrder,
    error: simulateUpcomingOrderError,
  } = useMutation(orderClient.simulateCustomizeUpcomingOrder, {
    onSuccess: (response) =>
      (async () => {
        dispatch({
          type: "update_upcoming_order_with_offer",
          upcomingOrderWithOffer: longTermDiscountsService.resetDiscounts(
            longTermDiscountsService.applyOfferToOrderLineItems(response.data)
          ),
        })
      })(),
  })

  const { mutateAsync: addUpcomingVoucher, error: addUpcomingProductError } =
    useMutation(orderClient.addUpcomingVoucher, {
      onSuccess: () =>
        (async () => {
          await Promise.allSettled([
            queryClient.refetchQueries("upcomingOrderSummary"),
            queryClient.refetchQueries("getUpcomingSummary"),
            queryClient.refetchQueries("upcomingOrderVariations"),
            // queryClient.refetchQueries("formelOnlyUpcomingPresets"),
            queryClient.refetchQueries("currentSubscriptionSummary"),
            queryClient.refetchQueries("addonsUpcomingPresets"),
          ])

          // dispatch({
          //   type: "reset_state",
          // })
        })(),
    })

  const openDialog = useCallback(
    async (dialog: "offer" | "confirmation" | "success") => {
      dispatch({
        type: "open_dialog",
        dialog,
      })
      // if the dialog is the offer dialog, we need to fetch the upcoming order
      if (dialog === "offer") {
        dispatch({
          type: "load_upcoming_order",
        })
        await loadUpcomingOrder()
      }

      // if the dialog is confirmation dialog we need to make a simulation call to get the possible upcoming order variation
      if (dialog === "confirmation") {
        dispatch({
          type: "simulate_offer_on_upcoming_order",
        })
        await simulateUpcomingOrder({
          addProducts: state.upcomingOrder?.items?.filter(
            (item: { product: string }) =>
              ![
                "p_shipping_1",
                "p_medical_cream_1",
                "p_base_price_discriminator_1_tofu",
                ...addonProducts,
              ].includes(item.product) // TODO: this feels weird to always have to add the products back. makes the client usage harder
          ),
          removeProductIds: [],
          addVoucherCodes: state.selectedOffer?.voucherCode
            ? [state.selectedOffer?.voucherCode]
            : [],
        })
      }
    },
    [dispatch, state]
  )

  const closeDialog = useCallback(
    (dialog: "offer" | "confirmation" | "success") => {
      dispatch({
        type: "close_dialog",
        dialog,
      })
    },
    [dispatch]
  )

  const closeAllDialogs = useCallback(() => {
    dispatch({
      type: `reset_state`,
    })
  }, [dispatch])

  const selectOffer = useCallback(
    (offer: LongTermDiscountOffer) => {
      dispatch({
        type: "select_offer",
        offer,
      })
    },
    [dispatch]
  )

  const saveOffer = useCallback(async () => {
    dispatch({
      type: "save_offer",
    })

    await addUpcomingVoucher(state.selectedOffer?.voucherCode)
  }, [dispatch, state])

  return (
    <LongTermDiscountsContext.Provider
      value={{
        ...state,
        selectOffer,
        saveOffer,
        openDialog,
        closeDialog,
        closeAllDialogs,
      }}
    >
      {children}
    </LongTermDiscountsContext.Provider>
  )
}
