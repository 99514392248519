import { useId } from "@reach/auto-id"
import { forwardRef, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import { getCountry } from "@trueskin-web/locales"
import { useThemeUI } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import FormMessage from "./form-message"

const PhoneNumberInput = forwardRef(
  ({ input, meta, required, label, message, className, ...props }, ref) => {
    const id = useId()
    const {
      theme: { colors },
    } = useThemeUI()

    const [country] = useState(getCountry().toLocaleLowerCase())

    const { onChange, ...inputProps } = input
    const isInvalid =
      (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
      meta.touched

    return (
      <FormControl
        data-testid={props.dataTestId}
        isInvalid={isInvalid}
        isRequired={required}
        className={className}
        sx={{
          "& .react-tel-input": {
            ".flag-dropdown": {
              borderWidth: 0,
              borderRight: "1px solid",
              ".selected-flag, &.open .selected-flag": {
                backgroundColor: "transparent",
              },
            },
            ".form-control, .flag-dropdown": {
              borderColor: isInvalid ? "attention" : "text",
              transition: "all .1s ease-in",
            },
            "&:hover, &:focus-within": {
              ".form-control, .flag-dropdown": {
                borderColor: isInvalid ? colors.attention : "primary",
              },
            },
            "&:focus-within": {
              boxShadow: ({ colors }) =>
                `0 0 0 1px ${isInvalid ? colors.attention : colors.primary}`,
              ".flag-dropdown": {
                borderRightWidth: "2px",
              },
            },
          },
        }}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <PhoneInput
          country={country}
          inputStyle={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
            lineHeight: "body",
            borderRadius: 0,
          }}
          buttonStyle={{
            borderRadius: 0,
            backgroundColor: "transparent",
          }}
          onChange={(value) => onChange(`+${value}`)}
          ref={ref}
          {...inputProps}
          {...props}
        />
        <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
        <FormMessage>{message}</FormMessage>
      </FormControl>
    )
  }
)

export default PhoneNumberInput
