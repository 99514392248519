import { apiTofu } from "./api"

function submitInitialQ({ fields, sessionId }) {
  return apiTofu(`questionnaires/sessions/${sessionId}/external-data`, {
    body: fields,
  })
}

function getAttachmentsDetails(caseId) {
  return apiTofu(`cases/${caseId}/attachments`)
}

function uploadImage({ caseId, attachId, file }) {
  const formData = new FormData()

  formData.append("file", file)
  return apiTofu(`cases/${caseId}/attachments/${attachId}`, {
    body: formData,
  })
}

function uploadMessageImage({ caseId, file, msgId }) {
  const formData = new FormData()

  formData.append("file", file)
  return apiTofu(`cases/${caseId}/messages/${msgId}`, {
    body: formData,
  })
}

export {
  submitInitialQ,
  getAttachmentsDetails,
  uploadImage,
  uploadMessageImage,
}
