import ConditionalWrap from "conditional-wrap"

import { lockIcon } from "@trueskin-web/theme/icons/lockIcon"
import i18next from "@trueskin-web/translations/src/i18next"

import Icon from "./icon"

const Lock = ({
  className,
  children,
  condition,
  text = i18next.t("Lock.title"),
  defaultSize,
  aspectRatio = [3 / 4, 4 / 3],
}) => (
  <ConditionalWrap
    condition={condition}
    wrap={(element) => (
      <div
        className={className}
        sx={{
          position: "relative",
          pointerEvents: "none",
          userSelect: "none",
          border: "1px",
          borderColor: "primary",
          aspectRatio: defaultSize ? null : aspectRatio,
          overflow: "hidden",
          "> *:not(:last-child)": {
            opacity: 0.05,
          },
        }}
      >
        {element}
        <div
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: ["80%", 420],
              maxWidth: ["80%", "75%"],
              mx: "auto",
            }}
          >
            <Icon
              icon={lockIcon}
              sx={{
                alignSelf: "center",
              }}
            />
            <div
              sx={{
                mt: 3,
                fontWeight: "bold",
                fontSize: 1,
                textAlign: "center",
                whiteSpace: "pre-line",
              }}
            >
              {text}
            </div>
          </div>
        </div>
      </div>
    )}
  >
    {children}
  </ConditionalWrap>
)

export default Lock
