import { Global, css } from "@emotion/react"
import ConditionalWrap from "conditional-wrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, useEffect, useState } from "react"

import { useAuth } from "@trueskin-web/context"
import { getHomepageUrl } from "@trueskin-web/functions"
import { getCountries, getCountry, saveCountry } from "@trueskin-web/locales"
import { arrowLongRightIcon } from "@trueskin-web/theme/icons/arrowLongRightIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { triangleDownIcon } from "@trueskin-web/theme/icons/triangleDownIcon"
import i18next, { Routes, Trans } from "@trueskin-web/translations"

import AccountLink from "./account-link"
import Button from "./button"
import Dialog from "./dialog"
import Icon from "./icon"
import IconButton from "./icon-button"
import Link from "./link"
import Logo from "./logo"
import NavLink from "./nav-link"

const countries = getCountries()

const MobileNestedNavLinks = ({ openByDefault, label, subLinks }) => {
  const [isOpen, setOpen] = useState(openByDefault)

  return (
    <div
      sx={{
        display: "flex",
        flexFlow: "column",
        py: 4,
        borderBottom: "1px",
        borderBottomColor: "lightGray",
      }}
    >
      <NavLink
        isExpandable
        aria-expanded={isOpen}
        sx={{
          p: 0,
          display: "flex",
          alignItems: "center",
          fontSize: 4,
          justifyContent: "space-between",
        }}
        onClick={() => setOpen((value) => !value)}
      >
        {label}
      </NavLink>

      <div
        sx={{
          pt: isOpen ? 4 : 0,
          pb: isOpen ? 2 : 0,
          // sadly, we're required to set at least the container max height explicitly :(
          maxHeight: isOpen ? "360px" : 0,
          overflow: "hidden",
          transitionProperty: "all",
          transitionDuration: "0.3s",
          transitionTimingFunction: "cubic-bezier(0, 0.4, 0.2, 1)",
        }}
      >
        {subLinks.map((subLink) => (
          <MobileNavSubLink key={subLink.label} {...subLink} />
        ))}
      </div>
    </div>
  )
}

const MobileNavSubLink = ({ targetUrl, label, image, showImageOnMobile }) => {
  const showImage = showImageOnMobile && !!image

  return (
    <NavLink
      href={targetUrl}
      isFullWidth
      sx={{
        p: 0,
      }}
    >
      <ConditionalWrap
        condition={showImage}
        wrap={(children) => (
          <div
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              gap: 3,
              mb: 2,
              pr: 4,
              textAlign: "left",
              border: "1px",
              borderColor: "grey",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <GatsbyImage
              image={image.imageFile.childImageSharp.gatsbyImageData}
              alt=""
              sx={{
                maxWidth: 80,
                flexShrink: 0,
                bg: "grey",
              }}
              imgStyle={{
                borderRadius: "8px 0 0 8px",
              }}
            />
            {children}
            <Icon icon={arrowLongRightIcon} size="sm" />
          </div>
        )}
      >
        <div
          sx={{
            display: "flex",
            flexGrow: 1,
            pt: showImage ? 0 : 2,
            pr: 4,
            fontSize: 2,
            lineHeight: 1.5,
            color: "text",
          }}
        >
          {label}
        </div>
      </ConditionalWrap>
    </NavLink>
  )
}

const MobileNavLink = ({
  targetUrl,
  label,
  openByDefault,
  subLinks = [],
  ...props
}) => {
  if (subLinks.length > 0) {
    return (
      <MobileNestedNavLinks
        openByDefault={openByDefault}
        label={label}
        subLinks={subLinks}
      />
    )
  }

  return (
    <NavLink
      href={targetUrl}
      isFullWidth
      sx={{
        py: 4,
        px: 0,
        justifyContent: "flex-start",
        fontSize: 4,
        lineHeight: 1.4,
        border: "0 0 1px",
        borderBottomColor: "lightGray",
      }}
      {...props}
    >
      {label}
    </NavLink>
  )
}

const HeaderMobileLogo = () => {
  const { user } = useAuth()

  const isUserLogged = !!user

  return (
    <Button
      variant="link"
      size="sm"
      as={Link}
      href={getHomepageUrl(isUserLogged)}
      aria-current="page"
      aria-label="Return Home"
      sx={{
        p: 0,
      }}
    >
      <Logo size="xsm" />
    </Button>
  )
}

const HeaderMobileNav = ({ links }) => (
  <nav
    sx={{
      display: "flex",
      flexFlow: "column",
      py: 4,
      px: 5,
    }}
  >
    {links.map((link) => (
      <MobileNavLink
        key={link.label}
        doNotTrack={link.label === "FAQ"}
        target={link.label === "FAQ" ? "_blank" : undefined}
        {...link}
      />
    ))}
  </nav>
)

const HeaderMobileActions = ({ cta }) => {
  const { userIdentity } = useAuth()

  const [country, setCountry] = useState("")
  const [countrySelectorVisible, setCountrySelectorVisible] = useState(false)

  useEffect(() => {
    setCountry(getCountry(false))
  }, [])

  return (
    <Fragment>
      {!userIdentity && country && countries.length > 0 ? (
        <div>
          <Button
            onClick={() =>
              countries.length > 1 ? setCountrySelectorVisible(true) : null
            }
            variant="link"
            sx={{
              mb: 5,
              py: 0,
              px: 5,
              justifyContent: "flex-start",
              fontSize: 4,
              textDecoration: "none",
              cursor: countries.length > 1 ? undefined : "default",
              "&:hover, &:focus, &.focus": {
                color: countries.length > 1 ? undefined : "text",
              },
            }}
          >
            <span
              className={`fi fis fi-${country.code.toLowerCase()}`}
              sx={{
                mr: 2,
              }}
            />
            {`${country.code}`}
            {countries.length > 1 && (
              <Icon
                icon={triangleDownIcon}
                size="xsm"
                sx={{
                  ml: 1,
                  transform: countrySelectorVisible ? "rotate(180deg)" : "",
                }}
              />
            )}
          </Button>
        </div>
      ) : null}

      <div
        sx={{
          display: "grid",
          gridAutoColumns: "1fr 1fr",
          gap: 2,
          width: "100%",
          mt: "auto",
          py: 4,
          px: 5,
          position: "sticky",
          bottom: 0,
          borderTop: "1px",
          borderTopColor: "lightGrey",
          bg: "background",
        }}
      >
        {!userIdentity ? (
          <Fragment>
            <Button
              variant="outline"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: Routes.App.Login.url,
                }).click()
              }}
              sx={{ gridColumn: 1 }}
            >
              <Trans i18nKey="Header.login" />
            </Button>

            {cta ? (
              <Button
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
                sx={{ gridColumn: 2 }}
              >
                {cta.label}
              </Button>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <div
              sx={{
                gridColumn: 1,
                fontFamily: "monospace",
                textTransform: "uppercase",
                fontSize: 1,
                lineHeight: "heading",
              }}
            >
              Logged in as
            </div>
            <AccountLink
              sx={{
                gridColumn: 1,
                flexDirection: "row-reverse",
                justifyContent: "flex-end",
                fontSize: 3,
                fontWeight: "body",
              }}
            />
          </Fragment>
        )}
      </div>

      {countrySelectorVisible && (
        <CountrySelectionDialog
          onDismiss={() => setCountrySelectorVisible(false)}
          onClick={(country) => {
            saveCountry(country.code)
            setCountry(country)

            setCountrySelectorVisible(false)
            window.location.assign(window.location.origin)
          }}
          countries={countries}
        />
      )}
    </Fragment>
  )
}

const CountrySelectionDialog = ({ onDismiss, onClick, countries }) => (
  <Dialog
    sx={{
      maxWidth: 480,
    }}
    aria-label="country selector"
    title={i18next.t("Header.shippingTo")}
    onDismiss={onDismiss}
  >
    {countries.map((country, index) => (
      <Button
        isFullWidth
        key={country.code}
        onClick={() => onClick(country)}
        variant="link"
        sx={{
          justifyContent: "flex-start",
          alignItems: "baseline",
          mt: index > 0 ? 2 : null,
          px: 0,
          textDecoration: "none",
        }}
      >
        <span
          className={`fi fis fi-${country.code.toLowerCase()}`}
          sx={{
            mr: 4,
          }}
        />
        <span
          sx={{
            variant: "text.caps",
            fontSize: 1,
          }}
        >
          {country.label}
        </span>
      </Button>
    ))}
  </Dialog>
)

const HeaderMobile = ({ links, cta, isOpen, onClose }) => (
  <Fragment>
    {isOpen && (
      <Global
        styles={css`
          body {
            // mobile ios hack 🙄
            position: fixed;
            width: 100%;
            overflow-y: hidden;
          }
        `}
      />
    )}
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        width: "100%",
        top: 0,
        bottom: 0,
        transform: isOpen ? "none" : "translateX(100%)",
        right: 0,
        bg: "background",
        overflowY: "auto",
        zIndex: 8,
        transitionProperty: "all",
        transitionDuration: "0.4s",
        transitionTimingFunction: "cubic-bezier(0, 0.4, 0.2, 1)",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: 3,
          px: 4,
        }}
      >
        <HeaderMobileLogo />

        <IconButton
          icon={closeIcon}
          iconSize="lg"
          title="Schließen"
          onClick={onClose}
        />
      </div>

      <HeaderMobileNav links={links} />

      <HeaderMobileActions cta={cta} />
    </div>
  </Fragment>
)

export default HeaderMobile
