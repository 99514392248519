import { SubscriptionsEntity } from "@trueskin/vortex-sdk"
import { addDays, isAfter } from "date-fns"

const isSubscriptionDisabled = (
  subscription: SubscriptionsEntity = {} as SubscriptionsEntity
) =>
  Boolean(
    subscription.isExpired ||
      subscription.status === "expired" ||
      subscription.cancellationScheduled ||
      subscription.isCanceled ||
      subscription.status === "canceled" ||
      subscription.pauseOn ||
      subscription.status === "paused"
  )

// urgent hotfix quick hack ofc
const isSubscriptionDisabledDuplicate = (
  subscription: SubscriptionsEntity = {} as SubscriptionsEntity
) =>
  Boolean(
    subscription.isExpired ||
      subscription.status === "expired" ||
      // subscription.cancellationScheduled ||
      subscription.isCanceled ||
      subscription.status === "canceled" ||
      subscription.pauseOn ||
      subscription.status === "paused"
  )

const isMid2MCheckinOpen = (user: any) => {
  if (user?.subscription?.paymentOrder?.instalments.count !== 2) {
    return false
  }

  // hardcoded to 30 days as per the current strapi configuration for production
  // hardcoded to 0 days for non-production environments, for easier testing
  const showMid2MCheckinAfterDays =
    process.env.GATSBY_DEPLOY_ENVIRONMENT === "production" ? 30 : 0
  const showMid2MCheckinAfterDate = addDays(
    new Date(user.subscription.startDate),
    showMid2MCheckinAfterDays
  )

  const checkInState = user.checkin

  const isCheckinImageUploadRequired = !!user.subscription.consultations.find(
    (consultation: any) =>
      consultation.type === "checkin" &&
      consultation.status === "waitingfileupload"
  )

  const mid2mUpdateConsultationIsSubmitted =
    !!user.subscription.consultations.find(
      (consultation: any) => consultation.type === "mid2mUpdate"
    )

  const checkInIsOpen =
    user.isCheckinRequired ||
    isCheckinImageUploadRequired ||
    checkInState.state === "early"

  if (
    !mid2mUpdateConsultationIsSubmitted &&
    !checkInIsOpen &&
    isAfter(new Date(), showMid2MCheckinAfterDate)
  ) {
    return true
  } else {
    return false
  }
}

export {
  isSubscriptionDisabled,
  isSubscriptionDisabledDuplicate,
  isMid2MCheckinOpen,
}
