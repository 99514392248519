import { ThemeUIStyleObject } from "@theme-ui/css/src/types"
import { ReactNode } from "react"

import { sizer } from "@trueskin-web/functions"
import { questionIcon } from "@trueskin-web/theme/icons/questionIcon"

export const sizes = {
  xxlg: {
    height: 80,
    width: 80,
  },
  xlg: {
    height: 40,
    width: 40,
  },
  lg: {
    height: 32,
    width: 32,
  },
  md: {
    height: 24,
    width: 24,
  },
  sm: {
    height: 16,
    width: 16,
  },
  xsmsm: {
    height: 14,
    width: 14,
  },
  xsm: {
    height: 12,
    width: 12,
  },
}

export interface IconProps extends React.ComponentPropsWithoutRef<"div"> {
  size?: keyof typeof sizes
  // TODO: need a better type here
  icon: string | ReactNode
  asImage?: boolean
  // TODO: require palette
  color?: string
  role?: string
  focusable?: boolean
  sx?: ThemeUIStyleObject
}

const Icon = ({
  icon,
  asImage = false,
  size = "md",
  color = "currentColor",
  role = "presentation",
  focusable = false,
  ...props
}: IconProps) => {
  if (typeof icon === "string") {
    if (asImage) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            ...sizer(sizes, size),
          }}
          {...props}
        >
          <image
            xlinkHref={icon}
            sx={{
              ...sizer(sizes, size),
            }}
            {...props}
          />
        </svg>
      )
    }

    return (
      <div
        sx={{
          maskImage: `url(${icon})`,
          maskRepeat: "no-repeat",
          maskPosition: "center",
          maskSize: "100%",
          flexShrink: 0,
          flexGrow: 0,
          display: "inline-block",
          verticalAlign: "middle",
          backgroundColor: color,

          ...sizer(sizes, size),
        }}
        {...props}
      />
    )
  }

  return (
    <svg
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        flexShrink: 0,
        backfaceVisibility: "hidden",
        color,
        "&:not(:root)": {
          overflow: "hidden",
        },
        ...sizer(sizes, size),
      }}
      // @ts-expect-error TODO: not sure what type to use for svg like this
      viewBox={icon?.viewBox || "0 0 24 24"}
      focusable={focusable}
      role={role}
      // @ts-expect-error TODO: not sure what type to use for svg like this

      fill={icon?.fill}
      {...props}
    >
      {/* @ts-expect-error TODO: not sure what type to use for svg like this */}
      {icon?.path || questionIcon.path}
    </svg>
  )
}

export default Icon
