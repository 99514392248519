import { isAfter, isBefore, startOfDay } from "date-fns"
import { useMemo, useState } from "react"

import { useAuth } from "@trueskin-web/context"
import { renewalDialogService } from "@trueskin-web/services"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Calendar from "../../../app/components/calendar"
import useCurrentSubscriptionSummary from "../../../app/hooks/use-current-subscription-summary"

export const ChurnPreventionPauseRenewalCalendar = ({
  onDateChange,
  newRenewalDate,
}: any) => {
  const {
    user: { subscription },
  } = useAuth()

  const { data: subscriptionSummary } = useCurrentSubscriptionSummary()

  const maxDate = useMemo(
    () => renewalDialogService.getMaxRenewalDate(subscription),
    [subscription]
  )

  const renewalDate = useMemo(
    () =>
      renewalDialogService.getRenewalDate(subscription, subscriptionSummary),
    [subscription, subscriptionSummary]
  )

  const [date, setDate] = useState(newRenewalDate || renewalDate)

  const isDayDisabled = (day) =>
    // isSameDay(day, renewalDate) ||
    !isAfter(day, new Date()) || !isBefore(day, startOfDay(maxDate))

  return (
    <Calendar
      icon={arrowRightIcon}
      rounded
      selected={date}
      onSelect={(date) => {
        setDate(date)
        onDateChange(date)
      }}
      disabled={isDayDisabled}
      modifiers={{
        currentRenewalDate: renewalDate,
      }}
      modifiersClassNames={{
        currentRenewalDate: "current-renewal-date",
      }}
      headerCaptionDividerColor="neutralsMediumGrey"
      headerTextBold={true}
      sx={{
        ".rdp-day_selected": {
          borderRadius: "50% !important",
        },
        ".current-renewal-date": {
          backgroundColor: "white",
          color: "brandPrimary",
          borderColor: "brandPrimary",
          borderRadius: "50% !important",
        },
      }}
    />
  )
}
