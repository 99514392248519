import { ReactNode } from "react"

import { RoundBordered } from "@trueskin-web/components/src/round-bordered"

import Heading from "../heading"

export const CardV2 = ({
  headingLeft,
  headingRight,
  image,
  children,
  margin,
  marginBottom,
}: {
  headingLeft?: string
  headingRight?: string
  image: ReactNode
  children: ReactNode
  margin?: string
  marginBottom?: string
}) => (
  <RoundBordered margin={margin} marginBottom={marginBottom}>
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {headingLeft ? (
        <Heading size="xsm" sx={{ lineHeight: 1.33, fontWeight: "bold" }}>
          {headingLeft}
        </Heading>
      ) : null}
      {headingRight ? (
        <Heading size="xsm" sx={{ lineHeight: 1.33, fontWeight: "bold" }}>
          {headingRight}
        </Heading>
      ) : null}
    </div>
    {image ? (
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "flex-start",
          justifyContent: "left",
        }}
      >
        {image}

        <div>{children}</div>
      </div>
    ) : (
      <div>{children}</div>
    )}
  </RoundBordered>
)
