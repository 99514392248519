import {
  Action,
  Container,
  IconList,
  SeparatorWithText,
} from "@trueskin-web/components"
import { HorizontalIconList } from "@trueskin-web/components/src/lists/horizontal-icon-list"
import { eventIcon } from "@trueskin-web/theme/icons/eventIcon"
import { packageIcon } from "@trueskin-web/theme/icons/packageIcon"
import { stethoscopeIcon } from "@trueskin-web/theme/icons/stethoscopeIcon"

const Steppers = () => {
  return (
    <Container
      sx={{ p: 20, display: "flex", flexDirection: "column", gap: 40 }}
    >
      <div sx={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <IconList
          items={[
            {
              icon: eventIcon,
              text: "Formel, Reinigung and Pflege alle 2 Monate geliefert",
            },
            {
              icon: packageIcon,
              text: "Unbegrenzte Nachrichten mit Deiner Ärztin/Deinem Arzt",
            },
            {
              icon: stethoscopeIcon,
              text: "Monatliche Check-ins",
            },
          ]}
        ></IconList>
        <HorizontalIconList
          items={[
            {
              icon: eventIcon,
              text: "Formel, Reinigung and Pflege alle 2 Monate geliefert",
            },
            {
              icon: packageIcon,
              text: "Unbegrenzte Nachrichten mit Deiner Ärztin/Deinem Arzt",
            },
            {
              icon: stethoscopeIcon,
              text: "Monatliche Check-ins",
            },
          ]}
        ></HorizontalIconList>
      </div>
      <SeparatorWithText text={"button list"} />
      <div>
        <Action description={"just description"} />
        <Action title={"simple with title"} />
        <Action title={"title"} description={"description"} />
        <Action
          icon={stethoscopeIcon}
          title={"title"}
          description={"description and icon"}
        />
        <Action icon={stethoscopeIcon} title={"title with icon"} />
        <Action title={"rounded action"} isRounded={true} />
        <Action title={"disabled action"} isDisabled={true} />
      </div>
    </Container>
  )
}

export default Steppers
