import type { UseQueryOptions, UseQueryResult } from "react-query"

import { paymentClient, useQuery } from "@trueskin-web/apis"

type PrimaryPaymentMethodResponse = unknown
type PrimaryPaymentMethodError = unknown

type TUsePrimaryPaymentMethod<
  TData = PrimaryPaymentMethodResponse,
  TError = unknown
> = UseQueryResult<TData, TError> & {
  primaryPaymentMethod: PrimaryPaymentMethodResponse | undefined
  isPrimaryPaymentMethodLoading: boolean
  primaryPaymentMethodError: unknown
}

export const usePrimaryPaymentMethod = ({
  enabled = true,
  staleTime,
  // staleTime = 10 * 60 * 1000, // 10 minute; could be easily made to cache even more
  ...options
}: UseQueryOptions<
  PrimaryPaymentMethodResponse,
  PrimaryPaymentMethodError,
  PrimaryPaymentMethodResponse
> = {}): TUsePrimaryPaymentMethod => {
  const query = useQuery<
    PrimaryPaymentMethodResponse,
    PrimaryPaymentMethodError,
    PrimaryPaymentMethodResponse
  >("primaryPaymentMethod", paymentClient.getPrimaryPaymentMethod, {
    enabled,
    staleTime,
    // refetchOnWindowFocus: "always",
    ...options,
  })

  const { data, isLoading, error } = query

  return {
    ...query,
    primaryPaymentMethod: data,
    isPrimaryPaymentMethodLoading: isLoading,
    primaryPaymentMethodError: error,
  }
}
