import {
  Identify,
  getUserId,
  identify,
  init,
  reset,
  setUserId,
  track,
} from "@amplitude/analytics-browser"

const amplitudeApiKey = process.env.GATSBY_AMPLITUDE_API_KEY
const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

const logLevels = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  VERBOSE: 3,
  DEBUG: 4,
}

const ANALYTIC_EVENTS = {
  INITIAL_PICTURE_UPLOAD_PAGE_VIEWED: "initial picture upload page - viewed",
  INITIAL_PICTURES_SUBMITTED: "initial pictures - submitted",
  HOME_PAGE_VIEWED: "home page - viewed",
  HOME_ORDER_STATUS_CLICKED: "home order status - clicked",
  HOME_YOUR_ROUTINE_CLICKED: "home your routine - clicked",
  HOME_YOUR_PLAN_CLICKED: "home your plan - clicked",
  HOME_MESSAGES_CLICKED: "home messages - clicked",
  HOME_YOUR_PROGRESS_CLICKED: "home your progress - clicked",
  HOME_REFER_A_FRIEND_CLICKED: "home refer a friend - clicked",
  TREATMENT_PLAN_PAGE_VIEWED: "treatment plan page - viewed", // EVT-2
  TREATMENT_PLAN_DOCTOR_NOTE_EXPANDED: "treatment plan doctor note - expanded",
  TREATMENT_PLAN_FIRST_FORMEL_TAB_CLICKED:
    "treatment plan first formel tab - clicked",
  TREATMENT_PLAN_SECOND_FORMEL_TAB_CLICKED:
    "treatment plan second formel tab - clicked",
  TREATMENT_PLAN_INGREDIENTS_CLICKED: "treatment plan ingredients - clicked",
  TREATMENT_PLAN_TIPS_CLICKED: "treatment plan tipps - clicked",
  TREATMENT_PLAN_SECOND_ROUTINE_CLICKED:
    "treatment plan second routine - clicked",
  MESSAGES_PAGE_VIEWED: "messages page - viewed",
  MESSAGES_ARTICLE_VIEWED: "messages - article - viewed",
  MESSAGES_CONTENT_PAGE_VIEWED: "messages - content page - viewed",
  MESSAGES_AYD_CLICKED: "messages AYD - clicked", // EVT-29
  MESSAGES_MENU_CLICKED: "messages - menu - clicked",
  MESSAGES_SUB_MENU_CLICKED: "messages - sub-menu - clicked",
  MESSAGES_MEDICAL_QUESTION_SELECTED: "messages medical question - selected",
  MESSAGES_NON_MEDICAL_QUESTION_SELECTED:
    "messages non-medical question - selected",
  MESSAGES_AYD_SENT: "messages AYD - sent", // EVT-5
  MESSAGES_AYD_RATING_SUBMITTED: "messages - AYD rating - submitted", // EVT-123
  MESSAGES_AYD_THEME_SELECTION: "messages AYD - theme selection", // EVT-158
  MESSAGES_AYD_PHOTO_PROMPT_CLICKED: "messages AYD - photo prompt clicked", // EVT-159
  MESSAGES_ARTICLE_RATING_SUBMITTED: "messages - article rating - submitted",
  MESSAGES_CONTENT_PAGE_RATING_SUBMITTED:
    "messages - content rating - submitted",
  MESSAGES_MEDICAL_FAQ_CLICKED: "messages - medical FAQ - clicked",
  ROUTINE_PAGE_VIEWED: "routine page - viewed",
  ROUTINE_PAGE_FIRST_FORMEL_CLICKED: "routine page first formel - clicked",
  ROUTINE_PAGE_SECOND_FORMEL_CLICKED: "routine page second formel - clicked",
  NEXT_ORDER_PAGE_VIEWED: "next order page - viewed",
  NEXT_ORDER_PRODUCT_DETAIL_PAGE_VIEWED: "product detail page - viewed",
  NEXT_ORDER_SHIFT_RENEWAL_CLICKED: "next order shift renewal - clicked",
  NEXT_ORDER_SHIFT_RENEWAL_SAVED: "next order shift renewal - saved", // EVT-53
  NEXT_ORDER_SET_CUSTOMISATION_CLICKED:
    "next order set customisation - clicked",
  NEXT_ORDER_SET_CUSTOMISATION_SAVED: "next order set customisation - saved",
  NEXT_ORDER_SET_SIZE_CLICKED: "next order set size - clicked",
  NEXT_ORDER_SET_SIZE_CONFIRMED: "next order set size - confirmed",
  NEXT_ORDER_ADDON_CLICKED: "next order add-on - clicked",
  NEXT_ORDER_ADDON_CONFIRMED: "next order add-on - confirmed",
  NEXT_ORDER_SHIFT_RENEWAL_BOTTOM_CLICKED:
    "next order shift renewal bottom - clicked",
  NEXT_ORDER_CANCELLATION_CLICKED: "next order cancellation - clicked",
  TREATMENT_PROGRESS_PAGE_VIEWED: "progress page - viewed", // #32
  TREATMENT_PROGRESS_FRONT_IMAGE_CLICKED: "progress view front - clicked",
  TREATMENT_PROGRESS_RIGHT_IMAGE_CLICKED: "progress view right - clicked",
  TREATMENT_PROGRESS_LEFT_IMAGE_CLICKED: "progress view left - clicked",
  TREATMENT_PROGRESS_UPLOAD_PHOTOS_CLICKED:
    "progress pictures uploading page - clicked",
  TREATMENT_PROGRESS_PHOTOS_UPLOADED: "progress pictures - uploaded",
  TREATMENT_PROGRESS_PHOTOS_EXPANSION_CLICKED:
    "progress pictures expansion - clicked",
  NPS_OVERLAY_VIEWED: "nps overlay - viewed",
  NPS_OVERLAY_CLOSED: "nps overlay - closed",
  NPS_SUBMITTED: "nps - submitted",
  NPS_REASON_SUBMITTED: "nps reason - submitted",
  CHECK_IN_QUESTIONNAIRE_PAGE_VIEWED: "check-in questionnaire page - viewed",
  CHECK_IN_QUESTIONNAIRE_SUBMITTED: "check-in questionnaire - submitted",
  CHECK_IN_QUESTIONNAIRE_CLOSED: "check-in questionnaire - closed",
  CHECK_IN_PICTURES_UPLOAD_PAGE_VIEWED: "check-in picture upload page - viewed",
  CHECK_IN_PICTURES_SUBMITTED: "check-in pictures - submitted",
  CHECK_IN_PICTURES_SKIPPED: "check-in pictures - skipped",
  CHECK_IN_PICTURES_CLOSED: "check-in pictures - closed",
  ORDER_HISTORY_PAGE_VIEWED: "order history page - viewed",
  MID_2M_QUESTIONNAIRE_PAGE_VIEWED: "Mid-2M-update questionnaire page - viewed",
  MID_2M_QUESTIONNAIRE_SUBMITTED: "Mid-2M-update questionnaire - submitted",
  MID_2M_QUESTIONNAIRE_CLOSED: "Mid-2M-update questionnaire - closed",
  MID_2M_PICTURE_UPLOAD_PAGE_VIEWED:
    "Mid-2M-update picture upload page - viewed",
  MID_2M_PICTURES_SUBMITTED: "Mid-2M-update pictures - submitted",
  MID_2M_PICTURES_SKIPPED: "Mid-2M-update pictures - skipped",
  MID_2M_PICTURES_CLOSED: "Mid-2M-update pictures - closed",
  CANCELLATION_MANAGE_DIALOG_VIEWED: "cancellation manage dialog - viewed",
  CANCELLATION_REASON_PAGE_VIEWED: "cancellation reason page - viewed", // EVT-175
  CANCELLATION_REASON_CLICKED: "cancellation reason - clicked",
  CANCELLATION_POPUP_VIEWED: "cancellation pop-up - viewed", // EVT-56
  CANCELLATION_POPUP_PROCESSED: "cancellation pop-up - cancellation confirmed",
  CANCELLATION_POPUP_CONFIRMED: "cancellation pop-up - user confirmed", // EVT-143
  CANCELLATION_POPUP_DEFLECTED: "cancellation pop-up - cancellation deflected", // EVT-105
  CANCELLATION_POPUP_CLOSED: "cancellation pop-up - closed", // EVT-106
  CANCELATION_QUESTIONNAIRE_VIEWED: "cancellation questionnaire - viewed",
  CANCELATION_QUESTIONNAIRE_CONFIRMED: "cancellation questionnaire - confirmed",
  CANCELATION_REACTIVATE_ACCOUNT: "cancelation - reactivate account",
  PLAN_FEEDBACK_PAGE_VIEWED: "plan feedback page - viewed",
  ONBOARDING_CANCELATION_FLOW_GET_HELP:
    "onboarding - cancelation flow - get help",
  ONBOARDING_WHAT_HAPPENS_NEXT_VIEWED:
    "onboarding - what happens next - viewed",
  ONBOARDING_CAROUSEL_VIEWED: "onboarding - carousel - viewed",
  REMINDER_POPUP_VIEWED: "reminder pop-up - viewed",
  REMINDER_POPUP_USER_CONFIRMED: "reminder pop-up - user confirmed",
  REMINDER_POPUP_CONFIRMED: "reminder pop-up - reminder confirmed",
  TREATMENT_PLAN_EXPLORATION_CLICKED:
    "treatment plan formula discover more - clicked",
  TREATMENT_PLAN_EXPLORATION_PAGE_VIEWED: "formula page - viewed",
  TREATMENT_PLAN_EXPLORATION_SECTION_EXPANDED:
    "formula page - section expanded",
  TREATMENT_PLAN_EXPLORATION_RATING_SUBMITTED:
    "formula page - rating submitted",
  JOURNEY_STATUS_VIEWED: "journey card - viewed",
  JOURNEY_STATUS_PRIMARY_CLICKED: "journey card - primary button clicked",
  JOURNEY_STATUS_SECONDARY_CLICKED: "journey card - secondary button clicked",
  JOURNEY_STATUS_LINK_CLICKED: "journey card - link clicked",
  DIAGNOSIS_PAGE_VIEWED: "diagnosis page - viewed", // EVT-182
  DIAGNOSIS_PAGE_SECTION_EXPANDED: "diagnosis page - section expanded", // EVT-183
  VOUCHER_REMOVE_CLICKED: "voucher remove - clicked", // EVT-185
  PROMO_BANNER_CLICKED: "promo banner - clicked", // EVT-186
  PROMO_OFFER_DIALOG_VIEWED: "promo offer pop up - viewed", // EVT-187
  PROMO_OFFER_CONTINUE_CLICKED: "promo offer continue - clicked", // EVT-188
  PROMO_OFFER_SUMMARY_CONFIRMED: "promo offer summary - confirmed", // EVT-189
  REQUEST_NEW_FORMULA_DIALOG_BACK_BUTTON_CLICKED:
    "request formula chat dialog - back button clicked", // EVT-192
  REQUEST_NEW_FORMULA_DIALOG_CLOSED:
    "request formula chat dialog - dismiss button clicked", // EVT-193
  ORDER_PRICE_SUMMARY_EXPANDED: "pricing summary section - expanded", // EVT-194
  PAUSE_TREATMENT_BUTTON_CLICKED: "pause treatment button - clicked", // EVT-197
  PAUSE_TREATMENT_PAGE_VIEWED: "pause treatment page - viewed", // EVT-198
  PAUSE_TREATMENT_PAGE_CONTINUE_CLICKED:
    "pause treatment page - continue clicked", // EVT-199
  SHIFT_RENEWAL_DATE_CALENDAR_PAGE_CONTINUE_CLICKED:
    "shift renewal date calendar page - continue clicked", // EVT-200
  SHIFT_RENEWAL_DATE_CALENDAR_PAGE_VIEWED:
    "shift renewal date calendar page - viewed", // EVT-201
  MORE_INFOS_VIEWED: "more infos - viewed", // EVT-205
  MORE_INFOS_SUBMITTED: "more infos - submitted", // EVT-206
  MORE_INFOS_SKIPPED: "more infos - skipped", // EVT-207
  ONBOARDING_MORE_INFOS_CLICKED: "onboarding - more infos - clicked", // EVT-208
  MESSAGES_FAQ_EXPANSION_CLICKED: "messages - FAQ expansion - clicked", // EVT-212
  MESSAGES_QUESTION_ANSWERED_CLICKED: "messages - question answered - clicked", // EVT-213
  MESSAGES_QUESTION_NOT_ANSWERED_CLICKED:
    "messages - question not answered - clicked", // EVT-214
}

const BD_ANALYTICS_EVENTS = {
  BD_ONBOARDING_WELCOME_SCREEN: "Onboarding — Welcome Screen",
  BD_ONBOARDING_SKIN_ANALYSIS: "Onboarding — Skin analysis",
  BD_LOGIN_SCREEN: "Login Screen",
  BD_LOGIN_SCREEN_VIA_PASSWORD: "Login via Password Screen",
  BD_QUESTION: "Question —",
  BD_CREATE_AN_ACCOUNT: "Create an account",
  BD_TITLE_AND_FULL_NAME: "Title and full name",
  BD_BIRTHDAY_AND_PHONE_NUMBER: "Birthday and phone number",
  BD_PAYMENT_SCREEN: "Payment screen",
  BD_PAYMENT_SYSTEM_SCREEN_OPENED: "Payment system screen — Opened",
  BD_PAYMENT_SYSTEM_SCREEN_PAID: "Payment system screen — Paid",
  BD_COUNTRY_CITY_ADDRESS: "Country, city and address",
  BD_UPLOAD_PHOTO: "Upload photo",
  BD_UPLOAD_PHOTO_POPUP_OPENED: "Upload photo — Popup Opened",
  BD_POPUP_NOTIFICATIONS: "Popup — Notifications",
  BD_NOTIFICATION_PERMISSIONS_STATUS: "Notification permissions status",
  BD_MAIN_SCREEN_CASES: "bd_case_overview_page",
  BD_SEE_CASE_DETAILS: "bd_see_case_details",
  BD_CASE_OPENED: "bd_case_details_page",
  BD_CASE_OPENED_PHOTOS: "Case — Opened — Photos",
  BD_CASE_OPENED_QUESTIONNAIRE: "Case — Opened — Questionnaire",
  BD_HOW_IT_WORKS_OPENED: "How it works — Opened",
  BD_TREATMENT_PLAN_OPENED: "bd_treatmentplan_page",
  BD_PRESCRIPTION_REQUEST_OPENED: "bd_select_prescription_option",
  BD_PRESCRIPTION_REQUEST_ADDRESS_CONFIRMATION:
    "Prescription Request — Address confirmation",
  BD_PRESCRIPTION_REQUEST_CONFIRMATION_SCREEN:
    "Prescription Request — Confirmation screen",
  BD_SUBMIT_PRESCRIPTION_REQUEST: "bd_submit_prescription_request",
  BD_CHAT_OPENED: "bd_chat_page",
  BD_CHAT_OPENED_SENT_REPLY: "bd_send_chat_message",
  BD_UPLOAD_CHAT_PHOTOS: "bd_upload_chat_photos",
  BD_SELECT_CHAT: "bd_select_chat",
  BD_PROFILE_OPENED: "bd_profile_page",
  BD_PROFILE_OPENED_EDIT_DETAILS: "bd_edit_information_page",
  BD_SAVE_PERSONAL_DETAIL_CHANGES: "bd_save_personal_detail_changes",
  BD_PROFILE_OPENED_DELETE_ACCOUNT: "Profile — Opened — Delete account",
  BD_GET_PERSONALISED_PLAN_CLICK: "Get Personalised plan — Click",
  BD_QUESTIONNAIRE_DELETE_CASE: "Questionnaire — Delete case",
  BD_WELCOME_BACK: "Welcome_back",
  BD_WELCOME_BACK_POPUP_OPENED: "welcome_back_popup_opened",
  BD_WELCOME_BACK_POPUP_CLOSED: "welcome_back_popup_closed",
  BD_WELCOME_BACK_POPUP_CONTINUE_PRESSED: "welcome_back_popup_continue_pressed",
  BD_WELCOME_BACK_POPUP_RESET_PRESSED: "welcome_back_popup_reset_pressed",
  BD_WELCOME_BACK_SCREEN: "Welcome back Screen",
  BD_WELCOME_BACK_SCREEN_APP: "app",
  BD_WELCOME_BACK_SCREEN_WEB: "web-platform",
  BD_UNIFIED_ACCOUNTS_WELCOME_SCREEN: "UnifiedAccountWelcomeScreen",
  BD_LOGIN_SCREEN_POPUP_OPENED: "Login Screen — Popup Opened",
  BD_REGISTER: "Register Screen",
  BD_SENT_FEEDBACK: "Sent feedback",
  BD_CREATE_NEW_CASE: "bd_create_new_case",
  BD_SELECT_FORMELSKIN_OPTION: "bd_select_formelskin_option",
  BD_DIAGNOSIS_PAGE: "bd_diagnosis_page",
  BD_PRESCRIPTION_OPTION_PAGE: "bd_prescription_option_page",
  BD_FORMELSKIN_OPTION_PAGE: "bd_formelskin_option_page",
  BD_SELECT_VIEW_PHOTOS: "bd_select_view_photos",
  BD_SELECT_QUESTIONNAIRE: "bd_select_questionnaire",
  BD_RATE_FORMEL_SKIN: "bd_rate_formel_skin",
}

const ANALYTIC_PROPERTY_VALUES = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  SIGN_UP: "sing_up",
  LOG_IN: "log_in",
  MAKE_PHOTO: "make_photo",
  UPLOAD_PHOTO: "upload_photo",
  ALLOW: "allow",
  DO_NOT_ALLOW: "do_not_allow",
  ACTIVE_TAB: "active_tab",
  COMPLETED_TAB: "completed_tab",
  CASE: "case",
  TREATMENT_PLAN: "treatment_plan",
  ONBOARDING: "onboarding",
  MAIN_SCREEN: "main_screen",
  PUSH_NOTIFICATION: "push_notification",
  EMAIL: "email",
  POPUP: "popup",
  GO_BACK: "go_back",
  CLOSE: "close",
  ON: "on",
  OFF: "off",
  TRUE: "true",
  FALSE: "false",
  QUESTIONNAIRE: "questionnaire",
  QUESTIONNAIRE_RESET: "questionnaire_reset",
  CHAT: "chat",
  YES: "yes",
  NO: "no",
  RATING: "rating",
  REVIEW: "review",
  CONTINUE: "continue",
  ACTIVE: "active",
  COMPLETED: "completed",
  HOME: "home",
}

const ANALYTIC_OPTIONS = {
  // User property
  BIOLOGICAL_SEX: "biological_sex",
  DATE_OF_BIRTH: "date_of_birth",
  ACCOUNT_CREATED_AT: "account_created_at",
  NOTIFICATIONS_STATUS: "notifications_status",
  LAST_CONSULTATION_PHOTOS: "last_consultation_photos",
  LAST_QUESTIONNAIRE_ANSWERS: "last_q_",
  PROMO_CODE: "promo_code",
  PAID_FOR_CONSULTATION: "paid_for_consultation",
  CONSULTATION_COUNT_CREATED: "consultation_count_created",
  CONSULTATION_COUNT_PAID: "consultation_count_paid",
  LAST_CONSULTATION_STATUS: "last_consultation_status",
  CASE_ELIGIBILITY_STATUS: "case_eligibility_status",
  // Event property
  SCREEN_NUMBER: "screen_number",
  SOURCE: "source",
  TITLE: "title",
  ANSWER: "answer",
  SCREEN: "screen",
  ACTION: "action",
  PHOTOS: "photos",
  TYPE: "type",
  STATUS: "status",
  CASE_STATUS: "case_status",
  CASE_ID: "case_id",
  CASE_CREATED_AT: "case_created_at",
  CASE_ACTIVATED_AT: "case_activated_at",
  CASE_PAID_AT: "case_paid_at",
  TAB: "tab",
}

const WELCOME_BACK_EVENT_VALUES = {
  CLOSE: "close",
  CONTINUE: "continue",
  DISMISS: "dismiss",
  RESTART_QUESTIONNAIRE: "restart_questionnaire",
}

const ADD_ON_PRODUCT_NAME = {
  p_spf_50_1: "SPF",
  p_peeling: "Peeling",
  p_pimple_patches_day_night_40pcs_1: "Pimplepatches40pcs",
  p_pimple_patches_day_night_30pcs_1: "Pimplepatches30pcs",
}

const ANALYTIC_EVENT_PROPS = {
  PAGE: "Page",
  TOPIC: "Topic",
  TYPE: "Type",
  ACTION: "Action",
  NUMBER_OF_PICTURES_SENT: "# of pictures sent",
  NUMBER_OF_CHARACTERS_SENT: "# of characters sent",
  OLD_RENEWAL_DATE: "Old renewal date",
  NEW_RENEWAL_DATE: "New renewal date",
  REASON: "Reason",
  NPS_VALUE: "NPS-value",
  AB_TEST_VARIANT: "A/B test variant",
  CATEGORY: "Category",
  SUB_CATEGORY: "Sub-category",
  RATING: "Rating",
  QUESTION: "Question",
  SECTION: "Section",
  URL: "URL",
  STATUS: "status",
  MESSAGES_STATUS: "Messages Status",
  SOURCE: "Source",
  PRODUCT: "Product",
}

const isBrowser = () => typeof window !== "undefined"

const logErrorMessage = (error) =>
  console.error("Logging analytics event error:", error)

if (isBrowser() && !amplitudeApiKey) {
  console.error(
    "Amplitude API key is not provided, Amplitude is not initialized"
  )
}
if (isBrowser() && amplitudeApiKey) {
  try {
    init(amplitudeApiKey, null, {
      logLevel: isProduction ? logLevels.WARN : logLevels.VERBOSE,
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const trackEvent = (eventName, data) => {
  if (isBrowser() && !amplitudeApiKey) {
    console.table(...["[Amplitude Event]", eventName, data].filter(Boolean))
  }
  try {
    track(eventName, data)
  } catch (error) {
    logErrorMessage(error)
  }
}

const identifyUser = (id) => {
  try {
    setUserId(id)
  } catch (error) {
    logErrorMessage(error)
  }
}

const setUserProperties = (props) => {
  try {
    const propertiesKeys = Object.keys(props)

    propertiesKeys.forEach((key) => {
      const event = new Identify()
      event.set(key, props[key])

      identify(event)
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const isUserIdentified = () => {
  try {
    return getUserId()
  } catch (error) {
    logErrorMessage(error)
  }
}

const removeIdentifier = () => {
  try {
    reset()
  } catch (error) {
    logErrorMessage(error)
  }
}

export {
  ANALYTIC_EVENTS,
  BD_ANALYTICS_EVENTS,
  ANALYTIC_OPTIONS,
  ANALYTIC_EVENT_PROPS,
  WELCOME_BACK_EVENT_VALUES,
  ANALYTIC_PROPERTY_VALUES,
  ADD_ON_PRODUCT_NAME,
  trackEvent,
  identifyUser,
  setUserProperties,
  removeIdentifier,
  isUserIdentified,
}
