import { ThemeUIStyleObject } from "@theme-ui/css/src/types"
import { Link as GatsbyLink } from "gatsby"
import { HTMLAttributes, ReactNode } from "react"

import { usePageContext } from "@trueskin-web/context"

// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  activeClassName?: string
  partiallyActive?: boolean
  activeStyle?: Record<string, unknown>
  newTab?: string
  children?: ReactNode
  href?: HTMLAnchorElement["href"]
  sx?: Partial<ThemeUIStyleObject>
}

const Link = ({
  children,
  href,
  activeClassName,
  partiallyActive,
  activeStyle,
  newTab,
  ...otherProps
}: LinkProps) => {
  const pageContext = usePageContext()
  const isInternalLink = /^\/(?!\/)/.test(href) && !newTab

  if (pageContext?.isExternal) {
    return (
      <a href={href} {...otherProps}>
        {children}
      </a>
    )
  }

  if (isInternalLink) {
    return (
      <GatsbyLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        activeStyle={activeStyle}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={href} {...otherProps}>
      {children}
    </a>
  )
}

export default Link
