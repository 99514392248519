import { HTMLAttributes, forwardRef } from "react"

import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Icon from "./icon"

interface NavListItemProps extends HTMLAttributes<HTMLLIElement> {
  rightArrow?: boolean
  topRightIcon?: boolean
}
export const NavListItem = forwardRef<HTMLLIElement, NavListItemProps>(
  ({ rightArrow, ...props }, ref) => {
    const rightIcon = rightArrow && (
      <Icon
        sx={{
          ...(props.topRightIcon ? { alignSelf: "flex-start" } : {}),
        }}
        icon={arrowRightIcon}
      />
    )
    return (
      <li
        sx={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          alignItems: "center",
          alignContent: "center",
          p: 3,
        }}
        {...props}
        ref={ref}
      >
        <span sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {props.children}
        </span>
        {rightIcon}
      </li>
    )
  }
)

export const NavList = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    return (
      <nav ref={ref} {...props}>
        <ul sx={{ p: 0 }}>{props.children}</ul>
      </nav>
    )
  }
)
