import { indetermination } from "@trueskin-web/theme/animations"

const ProgressBar = ({
  className,
  size = 5,
  color = "primary",
  backgroundColor = "grey",
}) => (
  <div
    className={className}
    sx={{
      height: size,
      backgroundColor,
      overflow: "hidden",
      borderRadius: "1em",
    }}
  >
    <div
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: color,
        animation: `${indetermination} 1s infinite linear`,
        transformOrigin: "0% 35%",
      }}
    />
  </div>
)

export default ProgressBar
