import { Button } from "@trueskin-web/components"

export const ChurnPreventionCancelSubscriptionCta = ({ onClick }: any) => {
  return (
    <Button
      data-testid="web-portal-profile-cancel-subscription-btn"
      variant="outline"
      rounded
      size="md"
      isFullWidth
      color="black"
      sx={{
        borderColor: "neutralsMediumGrey",
      }}
      onClick={onClick}
    >
      Account verwalten
    </Button>
  )
}
