import { StaticImage } from "gatsby-plugin-image"
import { useCallback, useEffect } from "react"

import { amplitudeClient } from "@trueskin-web/apis"
import { Button, Card, Dialog, Icon, Spinner } from "@trueskin-web/components"
import { formatCurrency, formatDate } from "@trueskin-web/functions"
import { calendarEventAvailableIcon } from "@trueskin-web/theme/icons/calendarEventAvailableIcon"
import { calendarTodayIcon } from "@trueskin-web/theme/icons/calendarTodayIcon"
import { choiceIcon } from "@trueskin-web/theme/icons/choiceIcon"
import { refillIcon } from "@trueskin-web/theme/icons/refillIcon"
import { stethoscopeIcon } from "@trueskin-web/theme/icons/stethoscopeIcon"
import { verifiedUserIcon } from "@trueskin-web/theme/icons/verifiedUserIcon"

import { OrderSummary } from "../../app/account/subscription/order.utils"
import {
  OrderPriceBreakdownCard,
  OrderPriceDetails,
} from "../../app/components/orders"

import { useLongTermDiscounts } from "./context"
import { longTermDiscountsService } from "./long-term-discounts.service"
import { LongTermDiscountOffer } from "./types"

const heroImages = {
  set4months: (
    <StaticImage
      src="../../images/rebranded/4set-min.jpg"
      alt=""
      objectFit="contain"
    />
  ),
  set6months: (
    <StaticImage
      src="../../images/rebranded/6set-min.jpg"
      alt=""
      objectFit="contain"
    />
  ),
  set12months: (
    <StaticImage
      src="../../images/rebranded/12set-min.jpg"
      alt=""
      objectFit="contain"
    />
  ),
} as const

export const LongTermDiscountsConfirmationDialog = () => {
  const currentDialog = "confirmation"
  const nextDialog = "success"

  const longTermDiscountsState = useLongTermDiscounts()

  const {
    subscription,
    isSimulatingUpcomingOrderWithOffer,
    openDialog,
    closeDialog,
    isSavingOffer,
    saveOffer,
  } = longTermDiscountsState

  const upcomingOrderWithOffer =
    longTermDiscountsState.upcomingOrderWithOffer as OrderSummary
  const selectedOffer =
    longTermDiscountsState.selectedOffer as LongTermDiscountOffer

  const handleContinue = useCallback(async () => {
    await saveOffer()
    // should probably have been on the mutation itself, but I'm too tired
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.PROMO_OFFER_SUMMARY_CONFIRMED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "longterm_discounts",
        discount_selection: `${selectedOffer?.percentage}%`,
      }
    )
    openDialog(nextDialog)
  }, [saveOffer, openDialog, nextDialog])

  useEffect(() => {
    amplitudeClient.trackEvent(
      amplitudeClient.ANALYTIC_EVENTS.PROMO_OFFER_DIALOG_VIEWED,
      {
        [amplitudeClient.ANALYTIC_EVENT_PROPS.TYPE]: "longterm_discounts",
      }
    )
  }, [])

  const imageKey =
    selectedOffer.months === 4
      ? "set4months"
      : selectedOffer.months === 6
      ? "set6months"
      : "set12months"

  if (
    !isSimulatingUpcomingOrderWithOffer &&
    !longTermDiscountsService.isOfferActive(upcomingOrderWithOffer)
  ) {
    return (
      <Dialog
        isOpen={true}
        onDismiss={() => closeDialog(currentDialog)}
        sx={{
          "&[data-reach-dialog-content]": {
            pb: 0,
            display: "flex",
            flexDirection: "column",
            "& > section": {
              // height: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            },
          },
          "&[data-reach-dialog-content] > section > .section-content": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          },
        }}
      >
        <div
          sx={{
            flex: 1,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
            lineHeight: "32px",
            fontWeight: 700,
          }}
        >
          Es tut uns Leid! Dieses Angebot ist bereits abgelaufen. Bitte gehe
          zurück und versuche es erneut mit einem anderen Angebot.
        </div>

        <div sx={{ pt: 3, px: 4, pb: 5 }}>
          <Button
            rounded
            isFullWidth
            onClick={() => closeDialog(currentDialog)}
            data-testid="longTermDiscounts-SaveOffer-btn"
          >
            Zurück
          </Button>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={true}
      backLabel="Zurück"
      onBack={() => closeDialog(currentDialog)}
      onDismiss={() => closeDialog(currentDialog)}
      title={
        <div sx={{ fontSize: 24, lineHeight: "32px" }}>
          Bestätige Dein Rabattangebot
        </div>
      }
      sx={{
        "&[data-reach-dialog-content]": {
          pb: 0,
        },
        "&[data-reach-dialog-content] > section > .section-content": {
          display: "flex",
          flexDirection: "column",
          gap: 4,
        },
      }}
    >
      {isSimulatingUpcomingOrderWithOffer ? (
        <Spinner isFullPage />
      ) : (
        <>
          <Card
            sx={{
              border: "1px solid lightGray",
              pt: 0,
              gap: 0,
            }}
          >
            <div>{heroImages[imageKey]}</div>

            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                fontSize: 14,
                lineHeight: "20px",
              }}
            >
              <div sx={{ display: "flex", gap: 2 }}>
                <Icon
                  icon={calendarEventAvailableIcon}
                  sx={{ width: 20, height: 20 }}
                />

                <div>
                  Du sparst insgesamt{" "}
                  {formatCurrency(
                    ((selectedOffer?.discountPerMonth + 500) *
                      selectedOffer?.months) /
                      100,
                    upcomingOrderWithOffer?.currency,
                    2
                  )}
                </div>
              </div>

              <div sx={{ display: "flex", gap: 2 }}>
                <Icon icon={refillIcon} sx={{ width: 20, height: 20 }} />

                <div>
                  Skin-Set alle 2 Monate geliefert
                  <br />
                  <span sx={{ color: "neutralsDarkGrey" }}>
                    (Insgesamt {selectedOffer?.months / 2})
                  </span>
                </div>
              </div>

              <div sx={{ display: "flex", gap: 2 }}>
                <Icon icon={choiceIcon} sx={{ width: 20, height: 20 }} />

                <div>2 Monate Vorrat in jedem Skin-Set</div>
              </div>

              <div sx={{ display: "flex", gap: 2 }}>
                <Icon icon={stethoscopeIcon} sx={{ width: 20, height: 20 }} />

                <div>
                  Beinhaltet Arzt-Nachrichten, Fortschrittsberichte, monatliche
                  Check-ins und Rezepturanpassungen
                </div>
              </div>
            </div>
          </Card>

          <div>
            <OrderPriceBreakdownCard
              order={upcomingOrderWithOffer}
              overrideInstalmentsCount={selectedOffer.months}
              subscriptionRenewalDate={subscription?.renewalDate}
            >
              <OrderPriceBreakdownCard.PriceBreakdown
                hideTotalsWithoutDiscounts
              >
                {(props: OrderPriceDetails) => (
                  <OrderPriceBreakdownCard.PriceBreakdownDetails
                    details={[
                      {
                        icon: calendarTodayIcon,
                        description: `Die Gesamtkosten von ${props.totalFormatted} werden in ${props.instalmentsCount} monatliche Zahlungen à ${props.totalPerMonthFormatted} aufgeteilt. Sofern es weitere Rabatte gibt, können diese angewendet werden.`,
                      },
                      {
                        icon: verifiedUserIcon,
                        description: `Dein Rabatt beginnt am ${formatDate(
                          new Date(props.renewalDate),
                          { month: "long", day: "numeric", year: "numeric" }
                        )} und gilt für ${
                          props.instalmentsCount
                        } Monate. Zahlungen für die vollen ${
                          props.instalmentsCount
                        } Monate sind erforderlich.`,
                      },
                    ]}
                  />
                )}
              </OrderPriceBreakdownCard.PriceBreakdown>
            </OrderPriceBreakdownCard>
          </div>

          <div
            sx={{
              pb: 2,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            // sx={{
            //   position: ["fixed", "initial"],
            //   left: [0, null],
            //   // bottom: isVisible ? 0 : "-100%",
            //   bottom: [0, null],
            //   zIndex: [1, null],
            //   // transitionProperty: "opacity, top, bottom",
            //   // transitionDuration: "250ms",
            //   // transitionTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
            //   // boxShadow: "0 12px 24px 0 #000",
            //   // borderTop: totalProducts > 3 ? "1px solid" : null,
            //   // borderColor: totalProducts > 3 ? "#E1E1E1" : null,
            //   borderTop: "1px solid #E1E1E1",
            //   backgroundColor: "white",
            //   // px: 2,
            //   width: ["100vw", "100%"],
            //   // pb: "40px",
            //   // pt: 2,
            //   p: 4,
            //   pt: 3,
            //   display: "flex",
            //   flexDirection: "column",
            //   gap: 2,
            // }}
          >
            <Button
              rounded
              isFullWidth
              onClick={handleContinue}
              isLoading={isSavingOffer}
              data-testid="longTermDiscounts-SaveOffer-btn"
            >
              Jetzt Angebot bestätigen
            </Button>

            <div sx={{ fontSize: 12, lineHeight: "16px", textAlign: "center" }}>
              Der Betrag wird Dir zum{" "}
              {subscription.renewalDate
                ? formatDate(new Date(subscription.renewalDate), {
                    month: "long",
                    day: "numeric",
                  })
                : "n/a"}{" "}
              berechnet
            </div>
          </div>
        </>
      )}
    </Dialog>
  )
}
